import React, { PropsWithChildren } from 'react';
import * as S from './team-small-card.styled';
import { IPopupIconButtonOption, PopupWrapper, SVGWrapper, Typography } from '@ratedpower/components';
import { DotsSvg } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import TeamCover from '../team-cover';

interface IProps {
  title: string;
  popupIconOptions?: IPopupIconButtonOption[];
  onClick?: () => void;
  onClickOption?: (selectedOption: IPopupIconButtonOption) => void;
  selected?: boolean;
  cover?: string;
}

const TeamSmallCard: React.FC<PropsWithChildren<IProps>> = ({
  cover,
  title,
  popupIconOptions,
  selected,
  onClick,
  onClickOption,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <S.CardContainer onClick={onClick} selected={selected}>
      <TeamCover cover={cover} width={60} height={88} />
      <S.CardContent>
        <S.CardTitleContainer>
          <Typography weight="bold" size="l">
            {title}
          </Typography>
          {popupIconOptions && (
            <PopupWrapper
              options={popupIconOptions.map((teamAction: IPopupIconButtonOption) => ({
                ...teamAction,
                label: t(teamAction.label),
              }))}
              onClickOption={onClickOption}
            >
              <SVGWrapper icon={DotsSvg} size="s" />
            </PopupWrapper>
          )}
        </S.CardTitleContainer>
        {children}
      </S.CardContent>
    </S.CardContainer>
  );
};

export default TeamSmallCard;
