import styled from 'styled-components';

export const ModalWrapper = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  padding-bottom: ${({ theme }) => theme.v2.spacing.m};
  display: grid;
`;

export const ModalForm = styled.form`
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  display: grid;
`;
