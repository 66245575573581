import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ISubscriptionPlanCreate } from 'types/subscription-plan';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useNotificationToast } from './use-notification-toast';
import {
  CREATE_SUBSCRIPTION_PLAN,
  GET_PLANS,
  GET_SUPPORT_CURRENT_PLAN,
  GET_SUPPORT_PLANS,
} from 'services/subscription-plan-gql';

export interface IUseAddPlan {
  newPlanModal: IUseModal;
  handleCreateNewPlan: (newPlan: ISubscriptionPlanCreate, sendWelcomeEmail: boolean) => void;
}

export const useAddPlan = (supportView: boolean): IUseAddPlan => {
  const { id: supportPagesCompanyId } = useParams<{ id: string }>();
  const { errorNotification, successNotification } = useNotificationToast();
  const newPlanModal = useModal();

  const [createPlan] = useMutation(CREATE_SUBSCRIPTION_PLAN, {
    onCompleted: () => {
      successNotification('company:new-plan-created');
      newPlanModal.setModalOpen(false);
    },
    onError: (graphqlError: any) => errorNotification(graphqlError),
    refetchQueries: supportView ? [GET_SUPPORT_CURRENT_PLAN, GET_SUPPORT_PLANS] : [GET_PLANS],
  });

  const handleCreateNewPlan = (newPlan: ISubscriptionPlanCreate, sendWelcomeEmail: boolean) => {
    createPlan({
      variables: {
        companyId: supportPagesCompanyId,
        subscriptionPlanCreateDTO: newPlan,
        sendWelcomeEmail,
      },
    });
  };

  return {
    newPlanModal,
    handleCreateNewPlan,
  };
};
