import { useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { GET_CAPEX_TEMPLATES, UPDATE_CAPEX_TEMPLATE, DELETE_CAPEX_TEMPLATE } from 'services/capex-gql';
import { ICapexTemplate, IUseCapexTemplateObject, ICapexServerFilter } from 'types/capex';
import { getSortDirection } from 'utils/sort';
import { useSearch } from 'utils/hooks/use-search';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';
import {
  dataToCapexTemplate,
  initialCapexFilters,
  pageTabs as tabs,
  PAGE_SIZE_CAPEX_PRIVATE,
  PAGE_SIZE_CAPEX_PUBLIC,
} from 'utils/constants/capex-constants';
import { IServerFilter } from 'types/filter';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import { toDayEnd } from 'utils/date';
import { useNavigate } from 'react-router-dom';

interface IUseCapexTemplatesArgs {
  infiniteScroll: boolean;
  templatesPageSize?: number;
  defaultCompanyId?: string;
}

export function useCapexTemplates({
  infiniteScroll,
  templatesPageSize,
  defaultCompanyId,
}: IUseCapexTemplatesArgs): IUseCapexTemplateObject {
  const [pageInfo, setPageInfo] = useState<{ totalPages: number; pageNumber: number }>({
    totalPages: 0,
    pageNumber: 0,
  });
  const { getSearchFilter, inputSearchProps } = useSearch();
  const user = useSelector((state: RootState) => state.currentUser.profile);
  const companyIdQueryParam = useQuerySearchParams().get('companyId');
  const { companyId: userCompanyId } = { ...user };
  const companyId = defaultCompanyId || companyIdQueryParam || userCompanyId;

  const [tabSelected, setTabSelected] = useState(getTabSelected());
  const [capexServerFilter, setCapexServerFilter] = useState<ICapexServerFilter>({
    filters: [],
    isIncludedRatedPower: true,
  });

  const navigate = useNavigate();
  const { currencyOptions } = useSelector((state: RootState) => state.currencies);
  const { isAuthorized } = useAuthorization();

  function getTabSelected() {
    if (companyIdQueryParam) return tabs[1].id;
    return localStorage.getItem('capex-type') || tabs[0].id;
  }

  useEffect(() => {
    setCapexServerFilter({
      isIncludedRatedPower: true,
      filters: [
        {
          field: 'COMPANY_ID',
          operator: 'IN',
          values: [companyId],
        },
        { field: 'PUBLISHED_DATE', operator: 'GREATER_OR_EQUAL', values: [initialCapexFilters.dateRange.from] },
        { field: 'PUBLISHED_DATE', operator: 'LESS_OR_EQUAL', values: [toDayEnd(new Date()).toISOString()] },
      ],
    });
  }, [companyId]);

  const filters =
    tabSelected === tabs[1].id
      ? capexServerFilter?.filters
      : capexServerFilter?.filters.filter((filter: IServerFilter) => filter.field !== 'COMPANY_ID');

  const filtersCount = useMemo(
    () =>
      capexServerFilter?.filters.reduce((previousValue: number, currentValue: IServerFilter) => {
        if (currentValue.field === 'CREATED_BY' || currentValue.field === 'CURRENCY') {
          return previousValue + currentValue.values.length;
        }
        if (currentValue.field === 'COMPANY_ID' && tabSelected === tabs[1].id) {
          return previousValue + currentValue.values.length;
        }
        if (currentValue.field === 'PUBLISHED_DATE') {
          return previousValue + 1;
        }
        return previousValue;
      }, 0),
    [capexServerFilter?.filters, tabSelected]
  );

  const {
    data: fetchedPrivateTemplates,
    loading: loadingYourTemplates,
    paginationComponent: privatePaginationComponent,
    refetch: refetchPrivateTemplates,
    error: errorYourTemplates,
  } = useCustomQuery(
    GET_CAPEX_TEMPLATES,
    {
      variables: {
        ...(!!companyIdQueryParam && isAuthorized(Permission.FINANCIAL_SUPPORT_READ) && { companyId }),
        pagination: {
          page: 0,
          size: templatesPageSize || PAGE_SIZE_CAPEX_PRIVATE,
        },
        sorting: {
          property: 'publishedDate',
          direction: getSortDirection(false),
        },
        filters,
        isIncludedRatedPower: capexServerFilter?.isIncludedRatedPower ?? true,
        isPublicTemplates: false,
        search: getSearchFilter(),
      },
      fetchPolicy: 'cache-and-network',
      skip: tabSelected === tabs[0].id,
    },
    infiniteScroll
  );

  const {
    data: fetchedPublicTemplates,
    loading: loadingSampleTemplates,
    paginationComponent: publicPaginationComponent,
    error: errorSampleTemplate,
    refetch: refetchPublicTemplates,
  } = useCustomQuery(
    GET_CAPEX_TEMPLATES,
    {
      variables: {
        pagination: {
          page: 0,
          size: templatesPageSize || PAGE_SIZE_CAPEX_PUBLIC,
        },
        sorting: {
          property: 'publishedDate',
          direction: getSortDirection(false),
        },
        filters,
        isIncludedRatedPower: true,
        isPublicTemplates: true,
        search: getSearchFilter(),
      },
      fetchPolicy: 'cache-and-network',
      skip: tabSelected === tabs[1].id,
    },
    infiniteScroll
  );
  const [updateCapexTemplate] = useMutation(UPDATE_CAPEX_TEMPLATE, {
    onCompleted: () => {
      tabSelected === tabs[0].id ? refetchPublicTemplates() : refetchPrivateTemplates();
    },
  });
  const [deleteCapexTemplate] = useMutation(DELETE_CAPEX_TEMPLATE, {
    onCompleted: () => {
      tabSelected === tabs[0].id ? refetchPublicTemplates() : refetchPrivateTemplates();
    },
  });

  const privateTemplates = useMemo(
    () =>
      handleTemplatesResponse(fetchedPrivateTemplates).filter(
        (template: ICapexTemplate) => template.type === 'private'
      ),

    [fetchedPrivateTemplates]
  );

  const publicTemplates = useMemo(
    () =>
      handleTemplatesResponse(fetchedPublicTemplates).filter((template: ICapexTemplate) => template.type === 'public'),

    [fetchedPublicTemplates]
  );

  function handleTemplatesResponse(templatesResponse: any): ICapexTemplate[] {
    if (templatesResponse) {
      const { result = { content: [] } } = templatesResponse;
      if (result && result?.content.length) {
        setPageInfo(templatesResponse.result?.pageInfo);
        return result?.content.map((template: any): ICapexTemplate | undefined =>
          dataToCapexTemplate(template, currencyOptions)
        );
      }
    }
    return [];
  }

  const handleClone = (templateId: string, clone?: boolean, templatePreselectedValues?: ICapexTemplate) => {
    navigate(`${templateId}/create${companyIdQueryParam ? `?companyId=${companyIdQueryParam}` : ''}`, {
      state: { clone, templatePreselectedValues },
    });
  };

  const handleGoToCreate = (templatePreselectedValues: ICapexTemplate) => {
    navigate(`create${companyIdQueryParam ? `?companyId=${companyIdQueryParam}` : ''}`, {
      state: { templatePreselectedValues },
    });
  };

  const handleRedirectToNextStep = (templateId: string) => {
    const templateFound: ICapexTemplate = getTemplateFromList(templateId);
    const search = companyIdQueryParam ? `?companyId=${companyIdQueryParam}` : '';
    if (templateFound.type === 'public' && !isAuthorized(Permission.FINANCIAL_SUPPORT_WRITE)) {
      navigate({ pathname: `${templateId}/view`, search });
      return;
    }
    navigate({ pathname: `${templateId}/edit`, search });
  };

  const updateTemplateField = (fieldName: string, fieldValue: string, id: string) => {
    updateCapexTemplate({ variables: { form: { id, [fieldName]: fieldValue } } });
  };

  const deleteTemplate = (id: string) => {
    deleteCapexTemplate({ variables: { id } });
  };

  const getTemplatesByTab = (): ICapexTemplate[] => {
    return tabSelected === tabs[0].id ? publicTemplates : privateTemplates;
  };

  const getTemplateFromList = (templateId: string): ICapexTemplate => {
    const templatesList = getTemplatesByTab();
    return templatesList.find((element) => element.id === templateId) || ({} as ICapexTemplate);
  };

  return {
    deleteTemplate,
    updateTemplateField,
    handleClone,
    handleGoToCreate,
    handleRedirectToNextStep,
    setTabSelected,
    setCapexServerFilter,
    tabSelected,
    filtersCount: companyIdQueryParam ? filtersCount - 2 : filtersCount - 1, // date filters are counting 2
    templates: getTemplatesByTab(),
    endOfList: pageInfo.totalPages - pageInfo.pageNumber === 1,
    paginationComponent: tabSelected === tabs[0].id ? publicPaginationComponent : privatePaginationComponent,
    currencies: currencyOptions,
    loadingTemplates: loadingSampleTemplates || loadingYourTemplates,
    error: errorSampleTemplate || errorYourTemplates,
    inputSearchProps,
    userCompanyId: userCompanyId || '',
  };
}
