import { useReducer, useState } from 'react';

export interface IForm {
  [key: string]: any;
}

function useForm(onSubmit: null | (() => void), initialInputs: IForm = {}) {
  const [inputs, setInputs] = useReducer(
    (prevState: IForm, newProps: IForm) => ({
      ...prevState,
      ...newProps,
    }),
    initialInputs
  );
  const [formError, setFormError] = useState<any>(null);

  function handleSubmit(e: any) {
    if (!onSubmit) return;
    if (e) {
      e.preventDefault();
    }
    onSubmit();
  }

  function handleInputChange(e: any) {
    if (!!formError) setFormError(null);
    setInputs({ [e.target.name]: e.target.value });
  }

  function handleInputChangeManual(name: string, value: any) {
    if (!!formError) setFormError(null);
    setInputs({ [name]: value });
  }

  function clearValue(name: string) {
    handleInputChangeManual(name, '');
  }

  return {
    inputs,
    setInputs,
    handleInputChange,
    handleInputChangeManual,
    handleSubmit,
    formError,
    setFormError,
    clearValue,
  };
}

export default useForm;
