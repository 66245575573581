import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CreatedTeam } from 'types/teams';
import { useModal } from 'utils/hooks/use-modal';
import NewTeamModal from '../new-team-modal';
import AssignMembersModal from '../assign-members-modal';
import { Button } from '@ratedpower/components';

const NewTeamButtonWrapper: React.FC = () => {
  const newTeamModal = useModal();
  const assignMembersModal = useModal();
  const { t } = useTranslation();
  const createdTeamRef = useRef<CreatedTeam | undefined>();

  return (
    <>
      <Button variant="accent" text={t('user:teams.create-new-team')} onClick={() => newTeamModal.setModalOpen(true)} />
      {newTeamModal.isOpen && (
        <NewTeamModal
          title={t('user:teams.create-new-team')}
          isActive={newTeamModal.isOpen}
          disableClickOutside
          onCreateTeam={(createdTeam: CreatedTeam) => {
            createdTeamRef.current = createdTeam;
            newTeamModal.setModalOpen(false);
            assignMembersModal.setModalOpen(true);
          }}
          onClose={() => newTeamModal.setModalOpen(false)}
        />
      )}
      {assignMembersModal.isOpen && (
        <AssignMembersModal
          teamId={createdTeamRef.current?.id || ''}
          title={t('user:teams.add-members-to-team', { team: createdTeamRef.current?.name })}
          isActive={true}
          disableClickOutside
          onClose={() => assignMembersModal.setModalOpen(false)}
        />
      )}
    </>
  );
};

export default NewTeamButtonWrapper;
