import React, { useState } from 'react';
import * as S from '../designs-tab/designs-tab.styled';
import { useDesignsTab } from '../../use-designs-tab';
import { useTranslation } from 'react-i18next';
import { RatedPowerLoader, RPTable, Typography } from '@ratedpower/components';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import DesignsTabFilters from './designs-tab-filters';
import { ContentTopBar } from 'components/content-top-bar';
import { IServerFilter } from 'types/filter';
import { designsTabInitialFilters } from 'utils/constants/support';

const DesignsTab: React.FC = () => {
  const { t } = useTranslation();
  const [isFiltersPanelOpened, setFiltersPanelOpened] = useState(false);
  const { inputSearchProps, table, error, loading, paginationComponent, setCurrentFilters, currentFilters } =
    useDesignsTab();

  const toggleFilterPanel = (opened: boolean) => () => setFiltersPanelOpened(opened);
  const handleApplyFilters = (filters: IServerFilter[]) => {
    setCurrentFilters(filters);
    toggleFilterPanel(false)();
  };

  const handleClearFilters = () => {
    setCurrentFilters([]);
    toggleFilterPanel(false)();
  };

  const hasFilters =
    !!currentFilters.length &&
    JSON.parse(JSON.stringify(currentFilters)) !== JSON.parse(JSON.stringify(designsTabInitialFilters));

  return (
    <>
      <S.PageContainer>
        <S.TopBarContainer>
          <Typography>{hasFilters ? t('project:filtered-results') : ''}</Typography>
          <ContentTopBar filterOnClick={toggleFilterPanel(true)} searchOnChange={inputSearchProps.onChange} />
        </S.TopBarContainer>
        <S.TableContainer>
          {loading ? (
            <RatedPowerLoader />
          ) : error ? (
            <ErrorRetrievingData />
          ) : (
            <RPTable table={table as any} zeroResultsFoundText={t('zero-results-found')} />
          )}
          {paginationComponent}
        </S.TableContainer>
        <DesignsTabFilters
          isOpen={isFiltersPanelOpened}
          onApply={handleApplyFilters}
          onClose={toggleFilterPanel(false)}
          onClear={handleClearFilters}
        />
      </S.PageContainer>
    </>
  );
};

export default DesignsTab;
