import styled from 'styled-components';

export const HomeProjectsDesignsContainer = styled.div`
  padding: 0 ${({ theme }) => theme.v2.spacing.m};
  gap: ${({ theme }) => theme.v2.spacing.m};
  flex: 1;
  position: relative;
  display: flex;
  min-height: 198px;
`;

export const HomeDesignItemContainer = styled.div`
  border-radius: ${({ theme }) => theme.v2.radius.s};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.m};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 276px;
`;

export const HomeDesignTextWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
`;

export const HomeDesignItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    flex: 1;
    max-width: 135px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const HomeDesignItemSubheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
