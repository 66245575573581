import React from 'react';
import saveAs from 'file-saver';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';
import { useRestClient } from 'utils/hooks/use-rest-client';
import { SCREEN_LOCATION } from 'utils/constants/toasts';
import { CrossSvg } from 'assets/icons';
import { getApiUrl } from 'utils/api_url';
import { useTranslation } from 'react-i18next';
import { Button } from '@ratedpower/components';

export interface IDownloadFiles {
  entityType: string;
  entityId: string;
}

const DownloadFiles: React.FC<IDownloadFiles> = ({ entityType, entityId }) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { getFile } = useRestClient();

  const handleDowloadArchives = async () => {
    let endpoint = `${getApiUrl()}`;
    switch (entityType) {
      case 'SITE':
        endpoint += `/sites/${entityId}/kml`;
        break;
      case 'METEO_DATA':
        endpoint += `/meteo-datas/${entityId}/download-original`;
        break;
      case 'ELEVATION':
        endpoint += `/elevations/${entityId}/download-original`;
        break;
      case 'HORIZON':
        endpoint += `/horizons/${entityId}/download-original`;
        break;
      case 'MODULE':
        endpoint += `/api/v1/module-models/${entityId}/download`;
        break;
      case 'INVERTER':
        endpoint += `/api/v1/module-inverter/${entityId}/download`;
        break;
      default:
        endpoint = '';
        break;
    }

    const file = await getFile(endpoint);
    if (!file.data || !!file.error) {
      addToast(
        SCREEN_LOCATION.topCenter,
        <>
          <CrossSvg />
          {file.error.message}
        </>,
        ToastType.ERROR,
        4000
      );
      return;
    }
    const fileName = file.response.headers['content-disposition'].split('filename=')[1];
    saveAs(file.data, fileName.replace(/"/g, ''));
  };

  return entityType === 'COMPANY' ? (
    <>-</>
  ) : (
    <Button onClick={handleDowloadArchives} variant="link" text={t('support:download')} noPadding textUnderlined />
  );
};

export default DownloadFiles;
