import styled from 'styled-components';
import { BodyTr } from '@ratedpower/components';

export const PlanHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.m};
`;

export const PlanHistoryTable = styled.div`
  ${BodyTr} {
    td {
      white-space: nowrap;
      vertical-align: baseline;

      .timelapse-cell {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
      }

      .more-icon-button {
        svg {
          width: 16px;
        }
      }
    }

    .sticky-column {
      position: sticky;
      right: 0;
      top: 0;
    }
  }
`;
