export const MOBILE = 768;

export const TABLET = 992;

export const DESKTOP = 1200;

export const LARGE_DESKTOP = 1400;

export const DEVICES = {
  MOBILE_PORTRAIT: 'MOBILE PORTRAIT',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
  LARGE_DESKTOP: 'LARGE DESKTOP',
};
