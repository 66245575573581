import styled from 'styled-components';

export const DesignGridItemNote = styled.div`
  padding: ${({ theme }) => `${theme.v2.spacing.xs} ${theme.v2.spacing.m} ${theme.v2.spacing.m} ${theme.v2.spacing.m}`};
  border-top: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
`;

export const DesignGridItemNoteDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DesignGridItemNoteCreatedBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
