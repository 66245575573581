import styled from 'styled-components';

export const DesignsGridContainer = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.l};
  column-gap: ${({ theme }) => theme.v2.spacing.xs};
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 328px));
  grid-auto-rows: 1fr;
  justify-content: space-between;
`;
