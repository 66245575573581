import React from 'react';

interface IUploadFile {
  required?: boolean;
  accept?: string;
  onFileChange?: (file: File) => void;
  maxMbSize?: number;
  noFileInfo?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const UploadFile: React.FC<IUploadFile> = ({
  required,
  accept,
  onFileChange,
  maxMbSize,
  noFileInfo = false,
  inputRef,
}) => {
  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: {
    target: { validity: ValidityState; files: any };
  }) => {
    validity.valid && onFileChange && onFileChange(file);
  };
  return (
    <input
      size={maxMbSize}
      data-testid="input-file-id"
      type="file"
      required={required}
      accept={accept}
      onChange={onChange}
      title={noFileInfo ? '' : undefined}
      ref={inputRef}
    />
  );
};

export default UploadFile;
