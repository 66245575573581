import React from 'react';
import { INotification } from 'types/notifications';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data/index';
import NotificationsList from 'components/notifications/notifications-list/notifications-list';
import ZeroCreated2 from 'components/error-components/zero-created-2/zero-created-2';
import { ZeroDesignsSvg } from 'assets/icons/index';
import { useTranslation } from 'react-i18next';

interface IProps {
  notifications: INotification[];
  loading: boolean;
  error: string;
  paginationComponent: JSX.Element;
  isNotificationsPanel?: boolean;
}

const NotificationsWrapper: React.FC<IProps> = ({
  notifications,
  loading,
  paginationComponent,
  error,
  isNotificationsPanel = false,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div>
      {notifications.length > 0 ? (
        <>
          {error && <ErrorRetrievingData />}
          <NotificationsList
            notifications={notifications}
            loading={loading}
            isNotificationsPanel={isNotificationsPanel}
          />
          {paginationComponent}
        </>
      ) : (
        <ZeroCreated2
          Icon={ZeroDesignsSvg}
          title={t('communication:no-notifications.title')}
          subtitle={t('communication:no-notifications.subtitle')}
        />
      )}
    </div>
  );
};

export default NotificationsWrapper;
