import { useQuery } from '@apollo/client';
import SideBar from 'components/bars/side-bar/side-bar';
import HelpButton from 'components/buttons/help-button/help-button';
import SessionExpirationModal from 'components/modals/session-expiration-modal/session-expiration-modal';
import NewFeaturesModal from 'components/modals/new-features-modal';
import React, { Suspense, useEffect } from 'react';
import useSessionExpirationModal from 'utils/hooks/use-session-expiration-modal';
import { Banner, LinkText, Notification, RatedPowerLoader, ScreenLoader, Typography } from '@ratedpower/components';
import { RootState } from 'redux/root-reducer';
import { ToastProvider } from 'utils/hooks/use-toasts';
import { useModal } from 'utils/hooks/use-modal';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { restrictGoogleMapsAPI } from 'redux/current-user-slice';
import { GET_POP_UP } from 'services/new-feature-gql';
import { BUSINESS_EMAIL } from 'utils/constants/contact';
import { toDayEnd } from 'utils/date';
import { isGoogleMapsAPIRestricted } from 'utils/geolocation';
import { useCapexCurrencies } from 'utils/hooks/capex';
import { useVersionControl } from 'utils/hooks/use-version-control';
import LayoutTopbar from 'components/bars/layout-topbar/layout-topbar';
import * as S from 'app.styled';
import { ErrorType } from 'components/error-components/error-notifier';
import GeneralError from 'pages/error/general-error/general-error';
import ErrorForbidden from 'pages/error/error-forbidden/error-forbidden';
import { findPageMatch, isRudderstackReady, rudderstrackPage, updateRudderstackCookiesDomain } from 'rudderstack/utils';
import { useTheme } from 'styled-components';
import { isDesignProcess as checkIsDesignProcess } from 'utils/navigation/navigation';
// TODO - Uncomment this line to enable LaunchDarkly
// import { useLaunchDarklySetContext } from 'utils/hooks/use-launch-darkly-set-context';

const Layout: React.FC = () => {
  const { subscriptionPlan, profile } = useSelector((state: RootState) => state.currentUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useCapexCurrencies();
  // TODO - Uncomment this line to enable LaunchDarkly
  // useLaunchDarklySetContext();
  const location = useLocation();
  const isDesignProcess = checkIsDesignProcess(location.pathname);
  const notificationIsOnTop: boolean = new Date() < new Date(new Date('2024-07-08'));
  const theme = useTheme();

  const { loading, extendUserSession, endUserSession, showExpirationModal, closeExpirationModal } =
    useSessionExpirationModal();

  useEffect(() => {
    updateRudderstackCookiesDomain();
  }, []);

  useEffect(() => {
    async function checkUserLocation() {
      const isRestricted = await isGoogleMapsAPIRestricted();
      if (isRestricted) {
        dispatch(restrictGoogleMapsAPI());
      }
    }

    checkUserLocation();
  }, []);

  useEffect(() => {
    if (isRudderstackReady) {
      const pageName = findPageMatch(location.pathname);
      const pageToSend = pageName ?? location.pathname;
      rudderstrackPage(pageToSend);
    }
  }, [location]);

  const { data } = useQuery(GET_POP_UP, {
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      if (data.result) {
        const popupVersionSplitted = data.result.version.split('.');
        const currentVersionSplitted = String(process.env.REACT_APP_VERSION).split('.');
        const sameVersions = String(process.env.REACT_APP_VERSION) === data.result.version;
        const currentVersionIsGreater =
          currentVersionSplitted.reduce((result: number, segment: string, index: number) => {
            if (result === 0 && segment !== popupVersionSplitted[index]) {
              result = segment > popupVersionSplitted[index] ? 1 : -1;
            }
            return result;
          }, 0) === 1;

        if (
          new Date() < toDayEnd(new Date(data.result.expirationDate)) &&
          (currentVersionIsGreater || sameVersions) &&
          localStorage.getItem('popup-modified-date') !== data.result.modifiedDate
        ) {
          localStorage.setItem('popup-modified-date', data.result.modifiedDate);
          newFeatureModal.setModalOpen(true);
        }
      }
    },
  });

  const serverError = useSelector((state: RootState) => state.serverError.error);
  const content = React.useMemo(() => {
    switch (serverError) {
      case ErrorType.NO_INTERNET:
      case ErrorType.GENERAL:
        return <GeneralError />;
      case ErrorType.FORBIDDEN:
        return <ErrorForbidden />;
      default:
        return <Outlet />;
    }
  }, [serverError]);

  const newFeatureModal = useModal();
  const { showPopup, onAccept } = useVersionControl();
  if (loading || !profile) return <RatedPowerLoader />;

  return (
    <ToastProvider>
      {!isDesignProcess && <LayoutTopbar />}
      <div id="main">
        <SideBar />
        {showPopup && (
          <S.CustomNotificationContainer>
            <Notification
              status="informative"
              description={
                <Typography color={theme.v2.text.secondary.default}>{t('common:new-update-available')}</Typography>
              }
              button={{
                text: t('action:update'),
                onClick: () => onAccept(),
                variant: 'link',
              }}
              width="300px"
            />
          </S.CustomNotificationContainer>
        )}
        <div id="content">
          <S.CurrentPageContentContainer $isDesignProcess={isDesignProcess}>
            {!!subscriptionPlan && subscriptionPlan.banner.general && (
              <Banner
                status={subscriptionPlan.banner.general.type}
                description={
                  <Typography className="banner-description">
                    <Trans i18nKey="contact-us-in-order-to-keep-using-your-subscription">
                      <></>
                      <LinkText textUnderlined href={BUSINESS_EMAIL}>
                        contact us
                      </LinkText>
                    </Trans>
                  </Typography>
                }
                header={subscriptionPlan.banner.general.cause}
                closable
              />
            )}
            {notificationIsOnTop && (
              <Banner
                status={'warning'}
                header="Scheduled Maintenance Notice."
                description="RatedPower will not be available on Sunday, July 7th, starting at 8:00 AM (CET) | 2:00 AM (EST)
                      for up to 20 hours. Thank you for your understanding."
                closable
              />
            )}
            <Suspense fallback={<ScreenLoader />}>{content}</Suspense>
          </S.CurrentPageContentContainer>

          <HelpButton />
        </div>

        {showExpirationModal && (
          <SessionExpirationModal
            isActive={showExpirationModal}
            onClose={closeExpirationModal}
            extendSession={extendUserSession}
            endUserSession={endUserSession}
          />
        )}
      </div>
      {newFeatureModal.isOpen && (
        <NewFeaturesModal
          title={data?.result.title}
          sections={data?.result?.sections}
          isActive={newFeatureModal.isOpen}
          onClose={() => {
            newFeatureModal.setModalOpen(false);
          }}
        />
      )}
    </ToastProvider>
  );
};

export default Layout;
