import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { RadioOption, RadioGroupOptionList, IRadioOption } from '@ratedpower/components';
import CustomDates from './custom-dates';
import { DateRange } from 'types/filter';
import { dateFilters } from 'utils/constants/filters';
import { getOnCurrentTimezoneDate, getShortDate, toDayEnd } from 'utils/date';

interface IProps {
  id?: string;
  onChangeDateRange: (newDateRange: DateRange) => void;
  currentDateRange: DateRange;
}

const DateFilter: React.FC<IProps> = ({ id, currentDateRange, onChangeDateRange }) => {
  const [isCustomDatesShown, showCustomDates] = useState(false);

  useEffect(() => {
    showCustomDates(
      !dateFilters.find((dateFilter) => getShortDate(dateFilter.value) === getShortDate(currentDateRange.from))
    );
  }, [currentDateRange.from]);

  return (
    <>
      <RadioGroupOptionList $direction="column">
        {dateFilters.map((option: IRadioOption) => (
          <RadioOption
            id={id + option.label}
            key={option.label}
            option={{ label: t(`${option.label}`), value: option.value }}
            checked={!isCustomDatesShown && getShortDate(currentDateRange.from) === getShortDate(option.value)}
            onChange={() => {
              onChangeDateRange({
                from: option.value,
                to: getOnCurrentTimezoneDate(toDayEnd(new Date())),
              });
              showCustomDates(false);
            }}
          />
        ))}
        <RadioOption
          id={id || 'custom-date'}
          option={{ label: t('filters.custom'), value: t('filters.custom') }}
          onChange={() => {
            showCustomDates(true);
          }}
          checked={isCustomDatesShown}
        />
      </RadioGroupOptionList>
      <>
        {isCustomDatesShown && (
          <CustomDates currentDateRange={currentDateRange} onChangeDateRange={onChangeDateRange} />
        )}
      </>
    </>
  );
};

export default DateFilter;
