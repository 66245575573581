import { IOption } from '@ratedpower/components';

export function getOptionForValue(value: string, options: IOption[]) {
  return options.find((opt) => opt.value === value) as IOption;
}

export function arrayToSelectorOptions(options: Array<string>, translation?: string) {
  return options.map((opt: string) => ({
    label: translation ? `${translation}${opt}` : opt,
    value: opt,
  }));
}
