import React from 'react';
import DesignGridItemErrorCard from './error-card/design-grid-item-error-card';
import DesignGridItemLoading from './loading/design-grid-item-loading';
import DesignItemDetails from '../../list/item/design-item-details';
import { DESIGN_ACTIONS as action } from 'utils/constants/designs';
import { CardHeaderWrapper, DesignGridItemContainer, PositionedTag } from './design-grid-item.styled';
import { IBessDesign, IDesign, SIMULATION_CATEGORY } from 'types/design';
import { Link } from 'react-router-dom';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { GenericStatus } from 'utils/constants/status';
import DesignGridItemNote from 'pages/designs/grid/item/design-grid-item-note/design-grid-item-note';
import { buildRelativeDesignProjectUrl } from 'utils/navigation/navigation';
import { getTagTextConfig, selectedColor } from 'utils/design-type-functions';
import BessDesignItemDetails from 'pages/designs/list/item/bess-design-item-details';
import { DesignGridItemHeaderDetails } from './header/design-grid-item-header-details';
import DesignGridItemHeader from './header/design-grid-item-header';
import { BessDesignGridItemHeaderDetails } from 'pages/standalone-bess/designs/bess-design-grid-item-header-details';

interface IProps {
  item: IDesign | IBessDesign;
  showDetails?: boolean;
  selected: boolean;
  onPerform: (action: string, item: IDesign | IBessDesign) => void;
  onEditName: (newName: string) => void;
}

const DesignGridItem: React.FC<IProps> = ({ item, showDetails, selected, onPerform, onEditName }) => {
  const { t } = useTranslation(),
    { canEditDesign } = useCanEditDesigns(),
    canCloneDesigns: boolean = useCanCloneDesigns(),
    canEditThisDesign: boolean = canEditDesign(item.createdBy.id, item.projectTeamId),
    designProcessUrl = buildRelativeDesignProjectUrl(item.id, item.simulationCategory);

  function isPVDesign(item: IDesign | IBessDesign): item is IDesign {
    return item.simulationCategory === SIMULATION_CATEGORY.PV;
  }

  const handleStopPropagation = (e: any): void => {
    e.stopPropagation();
  };

  const handleSelect = (): void => {
    onPerform(action.select, item);
  };

  const handleFavorite = (): void => {
    onPerform(action.favorite, item);
  };

  const handleClone = (e: any): void => {
    e.preventDefault();
    handleStopPropagation(e);
    onPerform(action.clone, item);
  };

  const handleUnarchive = (): void => {
    onPerform(action.unarchive, item);
  };

  const handleArchive = (): void => {
    onPerform(action.archive, item);
  };

  const handleDelete = (): void => {
    onPerform(action.delete, item);
  };

  const handleEditNotes = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    onPerform(action.editNotes, item);
  };

  const handleViewNotes = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    onPerform(action.viewNotes, item);
  };

  if (item.simulationResult === GenericStatus.IN_PROGRESS || item.simulationResult === GenericStatus.QUEUED) {
    return <DesignGridItemLoading showDetails={!!showDetails} />;
  }

  if (item.simulationResult !== GenericStatus.IN_PROGRESS && item.simulationResult !== 'OK') {
    return (
      <DesignGridItemErrorCard
        canCloneDesign={canCloneDesigns}
        canEditDesign={canEditThisDesign}
        item={item}
        selected={selected}
        onPerform={onPerform}
        handleSelect={handleSelect}
        handleFavorite={handleFavorite}
        handleClone={handleClone}
        handleDelete={handleDelete}
        handleEditName={onEditName}
        handleEditNotes={handleEditNotes}
        handleViewNotes={handleViewNotes}
        showDetails={showDetails}
      />
    );
  }

  return (
    <Link to={designProcessUrl}>
      <DesignGridItemContainer $isDefault className={classNames({ selected })}>
        <div className="card-image">
          <PositionedTag color={selectedColor(item)}>{t(getTagTextConfig(item))}</PositionedTag>
          <RemoteImage width={326} height={200} url={item.satellitePicture.thumbnail} alt="Layout view" />
        </div>
        <CardHeaderWrapper>
          <DesignGridItemHeader
            canCloneDesign={canCloneDesigns}
            canEditDesign={canEditThisDesign}
            item={item}
            selected={selected}
            handleSelect={handleSelect}
            handleClone={handleClone}
            handleDelete={handleDelete}
            handleUnarchive={handleUnarchive}
            handleArchive={handleArchive}
            handleEditName={onEditName}
            handleFavorite={handleFavorite}
          />
          {isPVDesign(item) ? (
            <DesignGridItemHeaderDetails item={item} />
          ) : (
            <BessDesignGridItemHeaderDetails item={item} />
          )}
        </CardHeaderWrapper>
        <div
          className={classNames('card-detail-row', {
            hidden: !showDetails,
          })}
        >
          {showDetails &&
            (isPVDesign(item) ? <DesignItemDetails {...{ item }} /> : <BessDesignItemDetails {...{ item }} />)}
        </div>
        <DesignGridItemNote
          item={item}
          canEditNotes={canEditThisDesign}
          handleEditNotes={handleEditNotes}
          handleViewNotes={handleViewNotes}
        />
      </DesignGridItemContainer>
    </Link>
  );
};

export default DesignGridItem;
