import React from 'react';
import { IHomeProjectsMapTooltip } from 'pages/home/home-projects-map/home-projects-map-tooltip/types';
import * as S from 'utils/maps/map-popups.styled';
import { Separator, Typography } from '@ratedpower/components';

interface IProps {
  project: IHomeProjectsMapTooltip;
  onClickProjectName: (id: string) => void;
}

const HomeProjectsMapTooltip: React.FC<IProps> = ({ project, onClickProjectName }) => {
  const { id, name, city, country } = project;

  return (
    <>
      <S.MapPopupRow onClick={() => onClickProjectName(id)}>
        <Typography category="title" size="m" weight="bold">
          {name}
        </Typography>
      </S.MapPopupRow>
      <Separator />
      <S.MapPopupRow>
        <Typography category="display" size="s">
          {city}
        </Typography>
      </S.MapPopupRow>
      <S.MapPopupRow>
        <Typography category="display" size="s">
          {country}
        </Typography>
      </S.MapPopupRow>
    </>
  );
};

export default HomeProjectsMapTooltip;
