import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Dropdown, IOption, Typography } from '@ratedpower/components';

interface IProps {
  countries: IOption[];
  editing: boolean;
  loading: boolean;
  countrySelected: number;
  onChange: (value: IOption[]) => void;
}

const CompanyCountrySelector: React.FC<IProps> = ({ loading, countries, editing, countrySelected, onChange }) => {
  const countrySelectedOption: IOption =
    countries?.find((country) => country.value === countrySelected) || (countries || [])[0];

  return (
    <>
      {loading ? (
        <Skeleton height={10} width={200} />
      ) : editing ? (
        <Dropdown
          name="country"
          size="l"
          disabled={!editing}
          onChange={onChange}
          options={countries}
          readonly={!editing}
          value={[countrySelectedOption]}
        />
      ) : (
        <Typography size="l">{countrySelectedOption?.label}</Typography>
      )}
    </>
  );
};

export default CompanyCountrySelector;
