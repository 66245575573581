import React from 'react';
import * as S from './notifications-button.styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { BellSvg } from 'assets/icons';
import { paths } from 'components/bars/side-bar/sidebar-list-items';
import { TopbarMyAccountContainer } from 'components/bars/layout-topbar/topbar-my-account/topbar-my-account.styled';
import { SVGWrapper } from '@ratedpower/components';
import { useGetNotificationsCount } from 'utils/hooks/use-get-notifications-count';
import { useTheme } from 'styled-components';

const NotificationsButton: React.FC = () => {
  const { pathname } = useLocation(),
    navigate = useNavigate(),
    notificationsCount = useGetNotificationsCount(),
    theme = useTheme();

  const handleLogoClick = () => {
    navigate(paths.notifications);
  };

  return (
    <TopbarMyAccountContainer $active={pathname.startsWith(paths.notifications)} onClick={handleLogoClick}>
      <SVGWrapper icon={BellSvg} size="l" color={theme.v2.icon.primary.default} />
      {notificationsCount > 0 && (
        <S.ParentContainer>
          <S.UnreadCounter />
        </S.ParentContainer>
      )}
    </TopbarMyAccountContainer>
  );
};

export default NotificationsButton;
