import { useMutation, useQuery } from '@apollo/client';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyLogo } from 'redux/current-user-slice';
import { RootState } from 'redux/store';
import { UPDATE_SUPPORT_COMPANY } from 'services/support-gql';
import { GET_COMPANY_ACCOUNT_INFO, UPDATE_COMPANY_EXTERNAL } from 'services/user-gql';
import { IOption } from 'types/common';
import { ROLE_TYPE } from 'types/role';
import useForm from 'utils/hooks/use-form';
import { useTranslatedCountries } from 'utils/hooks/use-translated-countries';

interface IUseCompanyDetails {
  inputs: { [key: string]: any };
  handleInputChangeManual: (name: string, value: any) => void;
  image: File | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  countries: IOption[];
  cantEditCompanyInfo: boolean;
  data:
    | {
        id: any;
        name: any;
        email: any;
        logo: any;
        country: any;
      }
    | undefined;
  handleDiscard: () => void;
  handleEdit: () => void;
  handleSubmitForm: (e: any) => void;
  loadingUpdateCompany: boolean;
}

const trasnformQueryToInfo = (data: any) => {
  if (data)
    return {
      id: data.companyAccountInfo.company.id,
      name: data.companyAccountInfo.company.name,
      email: data.companyAccountInfo.company.email,
      logo: data.companyAccountInfo.company.logo.high,
      country: data.companyAccountInfo.company.country,
    };
};

export const useCompanyDetails = (): IUseCompanyDetails => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const { isAuthorized } = useAuthorization();
  const { profile } = useSelector((state: RootState) => state.currentUser);

  const { loading, data: accountInfo } = useQuery(GET_COMPANY_ACCOUNT_INFO);
  const { countries } = useTranslatedCountries(true, 'id');
  const data = trasnformQueryToInfo(accountInfo);
  const { inputs, setInputs, handleInputChangeManual, handleSubmit } = useForm(handleSubmitForm);
  const cantEditCompanyInfo = isAuthorized(Permission.COMPANY_WRITE) && profile?.roleType === ROLE_TYPE.EXTERNAL;

  const onCompleteEdit = (response: any) => {
    if (profile?.logo) {
      if (profile.logo.includes('low')) {
        dispatch(setCompanyLogo(response?.companyUpdateSuperUser?.logo.highResUrl));
      } else {
        dispatch(setCompanyLogo(response?.companyUpdateSuperUser?.logo.lowResUrl));
      }
    }
    setIsEditing(false);
  };

  const [updateCompanyExternal, { loading: loadingUpdateCompany }] = useMutation(UPDATE_COMPANY_EXTERNAL, {
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
    onCompleted: onCompleteEdit,
    refetchQueries: [GET_COMPANY_ACCOUNT_INFO],
  });

  const [updateCompanySupport, { loading: loadingUpdateCompanySupport }] = useMutation(UPDATE_SUPPORT_COMPANY, {
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
    onCompleted: onCompleteEdit,
    refetchQueries: [GET_COMPANY_ACCOUNT_INFO],
  });

  const [image, setImage] = useState<File>();

  function handleSubmitForm() {
    if (profile?.roleType === 'EXTERNAL') {
      updateCompanyExternal({
        variables: {
          companyUpdateSuperUserDTO: {
            id: data?.id,
            countryId: inputs.country || data?.country?.id,
          },
          image: image || undefined,
        },
      });
    } else {
      updateCompanySupport({
        variables: {
          companyForm: [
            {
              id: data?.id,
              email: inputs.email || data?.email,
              name: inputs.name || data?.name,
              countryId: inputs.country || data?.country?.id,
            },
          ],
          image: image || undefined,
        },
      });
    }
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDiscard = () => {
    setIsEditing(false);
    setInputs({
      name: data?.name,
      email: data?.email,
      country: data?.country?.id,
    });
    setImage(undefined);
  };

  return {
    isEditing,
    image,
    setImage,
    setIsEditing,
    loading,
    data,
    countries,
    cantEditCompanyInfo,
    handleDiscard,
    handleEdit,
    inputs,
    handleInputChangeManual,
    handleSubmitForm: handleSubmit,
    loadingUpdateCompany: loadingUpdateCompany || loadingUpdateCompanySupport,
  };
};
