import i18n from 'i18n';
import { ISelectorOptions } from 'types/selector.types';
import { createLanguageOptions, supportedLanguages } from './locales';

interface IExported {
  shortLanguageOptions: ISelectorOptions[];
  languageOptions: ISelectorOptions[];
  languageWithEnglishOptions: ISelectorOptions[];
  systemUnitsOptions: ISelectorOptions[];
  temperatureUnitsOptions: ISelectorOptions[];
}

const exported: IExported = {
  shortLanguageOptions: [],
  languageOptions: [],
  languageWithEnglishOptions: [],
  systemUnitsOptions: [],
  temperatureUnitsOptions: [],
};

function translateUserPreferences() {
  exported.shortLanguageOptions = supportedLanguages.map((locale) => {
    return { label: locale.toUpperCase(), value: locale };
  });

  exported.languageOptions = createLanguageOptions([
    { label: i18n.t('preferences:english'), value: 'en' },
    { label: i18n.t('preferences:spanish'), value: 'es' },
    { label: i18n.t('preferences:french'), value: 'fr' },
    { label: i18n.t('preferences:italian'), value: 'it' },
    { label: i18n.t('preferences:portuguese'), value: 'pt' },
    { label: i18n.t('preferences:german'), value: 'de' },
    { label: i18n.t('preferences:chinese'), value: 'zh' },
    { label: i18n.t('preferences:japanese'), value: 'jp' },
  ]);

  exported.languageWithEnglishOptions = createLanguageOptions([
    { label: 'English', value: 'en' },
    { label: 'Spanish (Español)', value: 'es' },
    { label: 'French (Français)', value: 'fr' },
    { label: 'Italian (Italiano)', value: 'it' },
    { label: 'Portuguese (Português)', value: 'pt' },
    { label: 'German (Deutsch)', value: 'de' },
    { label: 'Chinese (中文)', value: 'zh' },
    { label: 'Japanese (日本語)', value: 'jp' },
  ]);

  exported.systemUnitsOptions = [
    { label: i18n.t('preferences:internationalSystem'), value: 'INTERNATIONAL' },
    { label: i18n.t('preferences:imperialSystem'), value: 'IMPERIAL' },
  ];

  exported.temperatureUnitsOptions = [
    { label: i18n.t('preferences:celsius'), value: 'INTERNATIONAL' },
    { label: i18n.t('preferences:fahrenheit'), value: 'IMPERIAL' },
  ];
}
translateUserPreferences();

i18n.on('languageChanged', () => translateUserPreferences());

export default exported;
