import styled from 'styled-components';

export const HomeDesignsContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
`;

export const HomeProjectsListContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
