export const getRedirectUri = () => {
  const currentHostName = window.location.host;
  if (currentHostName.endsWith('.ratedpower.us')) {
    return process.env.REACT_APP_AUTH0_CALLBACK_URL_US_OLD;
  }
  if (currentHostName.includes('us.ratedpower')) {
    return process.env.REACT_APP_AUTH0_CALLBACK_URL_US;
  }
  if (currentHostName.endsWith('.ratedpower.com')) {
    return process.env.REACT_APP_AUTH0_CALLBACK_URL_OLD;
  }
  return process.env.REACT_APP_AUTH0_CALLBACK_URL;
};
