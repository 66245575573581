import { useMemo } from 'react';
import { IAttribute } from 'utils/constants/design-attributes';
import { useConverter } from 'utils/conversor';
import { DimensionUnit, getDimensionUnitForArrayConvertion } from 'utils/conversor/dimension';
import { resolveKey } from 'utils/objects';

function getDimensionToConvert(data: any, attr: IAttribute): DimensionUnit {
  const dimensionToConvert = getDimensionUnitForArrayConvertion(
    data.map((e: any) => {
      const itemValue = resolveKey(e, attr.key);
      const castToNumber =
        typeof itemValue === 'number' ? itemValue : typeof itemValue === 'string' ? parseFloat(itemValue) : 0;
      return castToNumber;
    })
  );
  return dimensionToConvert;
}

const mergeLabels = (arr: any[]) => {
  const valueMap: any = {};

  arr.forEach((obj) => {
    const { codeAreaOk, gcr } = obj;
    const codeAreaNumber = codeAreaOk.match(/(\d+)/);
    if (Object.prototype.hasOwnProperty.call(valueMap, gcr)) {
      valueMap[gcr].push(codeAreaNumber[0]);
    } else {
      valueMap[gcr] = [codeAreaNumber[0]];
    }
  });

  const mergedArray: any[] = [];
  for (const gcr in valueMap) {
    if (Object.prototype.hasOwnProperty.call(valueMap, gcr)) {
      mergedArray.push({
        codeAreaOk: valueMap[gcr].join(', '),
        gcr: gcr,
      });
    }
  }

  return mergedArray;
};

export interface IChartDimensionUnits {
  x: DimensionUnit | null;
  y: DimensionUnit | null;
}

interface IProps {
  data: any;
  xAttr: IAttribute;
  yAttr: IAttribute;
}

const useGCRPerAreaScatterChart = ({ data, xAttr, yAttr }: IProps) => {
  const { converters, convertValueOf } = useConverter();

  const dimensionUnits = useMemo<IChartDimensionUnits>(() => {
    const initialState: IChartDimensionUnits = { x: null, y: null };
    if (!data || !xAttr || !yAttr) return initialState;

    const state = { ...initialState };

    if (xAttr.converter && xAttr.converter.name === 'dimension') {
      const overrideUnit =
        xAttr.converter.options && xAttr.converter.options.dimension ? xAttr.converter.options.dimension : null;
      state.x = overrideUnit ?? getDimensionToConvert(data, xAttr);
    }
    if (yAttr.converter && yAttr.converter.name === 'dimension') {
      const overrideUnit =
        yAttr.converter.options && yAttr.converter.options.dimension ? yAttr.converter.options.dimension : null;
      state.y = overrideUnit ?? getDimensionToConvert(data, yAttr);
    }

    return state;
  }, [data, xAttr, yAttr]);

  const formattedData = useMemo<any[]>(() => {
    if (!data) return [];

    const designsMap = data.map((design: any) => {
      const mergedArray = mergeLabels(design.inputsPlotAreas);
      return mergedArray.map((item) => ({
        filterColor: design.fillColor,
        name: [`${design.number}. ${design.name} - AA-Polygon${item.codeAreaOk}`],
        x: Number(converters?.percentage.toShow(Number(item.gcr)).value),
        y: Number(
          convertValueOf(yAttr, design, { decimals: 2, ...(!!dimensionUnits.y && { dimension: dimensionUnits.y }) })
        ),
      }));
    });

    return designsMap;
  }, [data, dimensionUnits, converters]);

  return {
    dimensionUnits,
    formattedData,
  };
};

export default useGCRPerAreaScatterChart;
