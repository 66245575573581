import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM } from 'services/team-gql';
import { IUserMinInfoDTO } from 'types/users';

export const useListTeamManagers = (): IUserMinInfoDTO[] => {
  const { id: companyIdFromUrl } = useParams<{ id: string }>();
  const {
    currentUser: { profile },
  } = useSelector((state: RootState) => state);

  const { data: teamManagers } = useQuery(GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM, {
    variables: { companyId: companyIdFromUrl || profile?.companyId },
    fetchPolicy: 'network-only',
  });

  return teamManagers?.result;
};
