import React, { useMemo } from 'react';
import { CartesianGrid, Label, ResponsiveContainer, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { IAttribute } from 'utils/constants/design-attributes';
import { DesignComparisonGenericChartContainer } from '../design-comparison-generic-chart.styled';
import useDesignComparisonLineChart, { IChartDimensionUnits } from './use-design-comparison-line-chart';
import { Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';

interface IChartAxisData {
  [key: string]: string;
}

export interface IPropsDesignComparisonLineChart {
  title: string;
  data: any[];
  complexAttr: IAttribute;
  xAttr: IAttribute;
  yAttr: IAttribute;
  tickFormatterX?: any;
  tickFormatterY?: any;
  formatUnits?: (dimensionUnits: IChartDimensionUnits) => IChartAxisData;
  formatAxisLabels?: (dimensionUnits: IChartDimensionUnits) => IChartAxisData;
  tooltipLabels: IChartAxisData;
}
const DesignComparisonLineChart: React.FC<IPropsDesignComparisonLineChart> = ({
  title,
  data,
  xAttr,
  yAttr,
  complexAttr,
  tooltipLabels,
  tickFormatterX,
  tickFormatterY,
  ...optionalProps
}) => {
  const theme = useTheme();
  const { formattedData, dimensionUnits } = useDesignComparisonLineChart({ data, xAttr, yAttr, complexAttr });
  const units = useMemo<IChartAxisData>(() => {
    if (!optionalProps.formatUnits) return { x: '', y: '' };
    return optionalProps.formatUnits(dimensionUnits);
  }, [dimensionUnits, optionalProps]);

  const axisLabels = useMemo<IChartAxisData>(() => {
    if (!optionalProps.formatAxisLabels) return tooltipLabels;
    return optionalProps.formatAxisLabels(dimensionUnits);
  }, [dimensionUnits, optionalProps, tooltipLabels]);

  return (
    <DesignComparisonGenericChartContainer>
      <Typography weight="bold" color={theme.v2.text.secondary.default}>
        {title}
      </Typography>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart margin={{ top: 8, right: 32, left: 32, bottom: 16 }} data={formattedData}>
          <CartesianGrid stroke="#EEEEEE" strokeDasharray="5 5" />

          <Tooltip formatter={(value, name) => [`${value} ${units.y}`, tooltipLabels[name]]} />
          <XAxis
            dataKey="x"
            // tickCount={6}
            // type="number"
            domain={['auto', 'auto']}
            padding={{ right: 20, left: 20 }}
            tickFormatter={tickFormatterX}
          >
            <Label
              position="insideBottom"
              offset={-15}
              style={{ fill: theme.v2.text.secondary.default }}
              value={axisLabels.x}
            />
          </XAxis>
          <YAxis
            tickCount={6}
            type="number"
            domain={['auto', 'auto']}
            padding={{ top: 20, bottom: 20 }}
            tickFormatter={tickFormatterY}
          >
            <Label
              angle={-90}
              position="insideLeft"
              offset={-5}
              style={{ textAnchor: 'middle', fill: theme.v2.text.secondary.default }}
              value={axisLabels.y}
            />
          </YAxis>
          {data.map((item, key) => (
            <Line key={key} type="monotone" dataKey={item.id} stroke={data[key].fillColor} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </DesignComparisonGenericChartContainer>
  );
};

export default DesignComparisonLineChart;
