import styled from 'styled-components';

export const Container = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  align-items: center;
`;

export const LeftTopbarContainer = styled.div`
  display: flex;
  align-items: center;
`;
