import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

export const useCanEditDesigns = () => {
  const { isAuthorized } = useAuthorization();

  const profile = useSelector((state: RootState) => state.currentUser.profile);

  const canEditDesign = (creatorId: string, teamId?: string) => {
    const projectIsPublic = !teamId;

    return (
      isAuthorized(Permission.SIMULATION_SUPPORT_WRITE) ||
      isAuthorized(Permission.SIMULATION_WRITE_IN_USER_COMPANY) ||
      (isAuthorized(Permission.SIMULATION_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR) &&
        (!!teamId || (creatorId === profile?.id && projectIsPublic))) ||
      (isAuthorized(Permission.SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR) && creatorId === profile?.id)
    );
  };

  return { canEditDesign };
};
