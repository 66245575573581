import { useQuery } from '@apollo/client';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { t } from 'i18next';
import { useState } from 'react';
import { GET_TEAMS_BY_COMPANY } from 'services/team-gql';
import { IServerFilter, ISort } from 'types/filter';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useSearch } from 'utils/hooks/use-search';
import { getSortDirection } from 'utils/sort';

interface IUseListTeamsProps {
  infiniteScroll?: boolean;
  pageSize?: number;
  companyId?: string;
  serverFilters: IServerFilter[];
}

export const useListCompanyTeams = ({ infiniteScroll, pageSize, companyId, serverFilters }: IUseListTeamsProps) => {
  const { isAuthorized } = useAuthorization();
  const { inputSearchProps, getSearchFilter } = useSearch();
  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('name'),
      code: 'name',
    },
    ascendent: false,
  });

  const {
    data: customQueryData,
    error: customError,
    refetch,
    loading,
    paginationComponent,
  } = useCustomQuery(
    GET_TEAMS_BY_COMPANY,
    {
      variables: {
        companyId,
        pagination: {
          page: 0,
          size: pageSize,
        },
        filters: serverFilters,
        sorting: {
          property: sortedBy.selector.code,
          direction: getSortDirection(sortedBy.ascendent),
        },
        search: getSearchFilter(),
      },
      fetchPolicy: 'network-only',
      skip: (!infiniteScroll && !pageSize) || !companyId || !isAuthorized(Permission.TEAM_SUPPORT_READ),
    },
    infiniteScroll
  );

  const { data, error } = useQuery(GET_TEAMS_BY_COMPANY, {
    variables: {
      companyId,
      filters: serverFilters,
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
    },
    fetchPolicy: 'network-only',
    skip: !!infiniteScroll || !!pageSize || !companyId || !isAuthorized(Permission.TEAM_SUPPORT_READ),
  });

  return {
    loading,
    error: !infiniteScroll && !pageSize ? error : customError,
    teams: !infiniteScroll && !pageSize ? data?.result?.content : customQueryData?.result?.content,
    sortedBy,
    paginationComponent,
    inputSearchProps,
    isFirstPage: customQueryData?.result?.pageInfo.pageNumber === 0,
    setSortedBy,
    refetch,
  };
};
