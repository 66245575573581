import React from 'react';
import * as S from './zero-created.styled';
import { Trans } from 'react-i18next';
import { Typography } from '@ratedpower/components';

interface IProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  message: string;
  selectedTab: string;
}
const ZeroCreated: React.FC<IProps> = ({ Icon, message, selectedTab }) => {
  const getMessage = () => {
    switch (selectedTab) {
      case 'designs':
        return `${message}:zero-${message}s-created`;
      case 'favorites':
        return `${message}:no-favorite-${message}s`;
      case 'archived':
        return `${message}:no-archived-${message}s`;
    }
  };

  return (
    <S.ZeroCreatedContainer>
      <Icon />
      <Typography className="info">
        <Trans i18nKey={getMessage()}>
          <b>You haven't got any {message} yet!</b>
          <br />
          <br />
          Start by clicking the <b>&quot;Start Design&quot;</b> button on the top right corner.
        </Trans>
      </Typography>
    </S.ZeroCreatedContainer>
  );
};

export default ZeroCreated;
