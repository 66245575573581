import { IProjectListItem } from 'types/projects';
import { point } from '@turf/helpers';
import { GeoJSONFeature, GeoJSONFeatureCollection } from 'utils/maps/geojson';

export const useProjectsMapBox = () => {
  function convertListItemToGeoJSON(projects: IProjectListItem[]): GeoJSONFeature[] {
    return projects.map((elem: IProjectListItem) => {
      const newPoint: any = point([elem.longitude, elem.latitude], {
        data: {
          content: {
            name: elem.name,
            id: elem.id,
            createdBy: elem.createdBy,
            publishedDate: elem.publishedDate,
            numDesigns: elem.numDesigns,
          },
          dataType: 'PROJECT_DATA',
        },
        icon: 'CUSTOM_MARKER',
      });
      return newPoint;
    });
  }

  function createFeatureCollection(array: Array<GeoJSONFeature>): GeoJSONFeatureCollection {
    return {
      type: 'FeatureCollection',
      features: array,
    };
  }

  return {
    convertListItemToGeoJSON,
    createFeatureCollection,
  };
};
