import { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ICurrentUser, setProfile } from 'redux/current-user-slice';
import { UPDATE_USER_BY_PROFILE } from 'services/user-gql';
import { IUserForm } from 'types/users';

interface IUseProfileForm {
  handleEditProfile: (form: IUserForm) => void;
  setProfileForm: Dispatch<SetStateAction<boolean>>;
  profileForm: boolean;
}

export const useProfileForm = (profile?: ICurrentUser): IUseProfileForm => {
  const [profileForm, setProfileForm] = useState(false);
  // Update user
  const [updateUser] = useMutation(UPDATE_USER_BY_PROFILE);
  const dispatch = useDispatch();

  const handleEditProfile = async (form: IUserForm) => {
    if (!profile) return;
    const { name, lastname: surname, jobTitle } = form;
    const userForm = { name, surname, jobTitle };

    try {
      await updateUser({ variables: { userForm } });

      const updatedProfileUser: ICurrentUser = {
        ...profile,
        ...{ name, jobTitle, lastname: surname },
      };
      setProfileForm(false);
      dispatch(setProfile(updatedProfileUser));
    } catch (err) {
      setProfileForm(false);
    }
  };

  return {
    handleEditProfile,
    setProfileForm,
    profileForm,
  };
};
