export const SCREEN_LOCATION: {[key: string]: ToastLocation} = {
  bottomLeft: 'bottom-left',
  bottomCenter: 'bottom-center',
  bottomRight: 'bottom-right',
  topLeft: 'top-left',
  topCenter: 'top-center',
  topRight: 'top-right',
};

export type ToastLocation = 'bottom-left' | 'bottom-center' | 'bottom-right' | 'top-left' | 'top-center' | 'top-right';
