export enum operators {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  IN = 'IN',
  GREATER = 'GREATER',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LESS = 'LESS',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
  LIKE = 'LIKE',
  BETWEEN = 'BETWEEN',
}
