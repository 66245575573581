import React, { useRef } from 'react';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';
import { SCREEN_LOCATION } from 'utils/constants/toasts';
import { CheckSvg, CrossSvg } from 'assets/icons';
import { Trans, useTranslation } from 'react-i18next';
import { CapexEntryProperty } from 'utils/constants/capex-constants';

interface IPreviousValue {
  mapKey: number;
  price: number;
  tax: number;
  name: string;
}

export interface IUseCopyPaste {
  onPaste: (mapKey: number, param: CapexEntryProperty, event: any) => void;
  handleUpdate: (text: string, param: CapexEntryProperty, mapKey: number) => void;
  handleUndo: (param: CapexEntryProperty) => void;
}

export const useCopyPaste = (
  onChange: (mapKey: number, param: CapexEntryProperty, value: string | number) => void,
  hashMapEntries: any
) => {
  const previousValues = useRef<IPreviousValue[]>([]);
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const onPaste = (mapKey: number, param: CapexEntryProperty, event: any) => {
    handleUpdate(event.clipboardData.getData('text/plain'), param, mapKey);
  };

  const handleSavePrevious = (items: IPreviousValue[]) => {
    previousValues.current = items;
  };

  const handleUpdate = (text: string, param: CapexEntryProperty, mapKey: number) => {
    const lines: any[] =
      param === 'name'
        ? text.split('\n')
        : text.split('\n').map((item: string) => parseFloat(item.toString().replace(',', '.')));

    const hasColumns = text.search('\t') > 0;

    const writeableMapKeys: number[] = [];
    const previousValues: IPreviousValue[] = [];
    hashMapEntries.current.forEach((value: any) => {
      if (value.mapKey >= mapKey && value.priceDefinition && writeableMapKeys.length < lines.length) {
        writeableMapKeys.push(value.mapKey);
        previousValues.push({
          mapKey: value.mapKey,
          price: value.priceDefinition.price,
          tax: value.priceDefinition.tax,
          name: value.name,
        });
      }
    });

    const slicedLines =
      writeableMapKeys.length < lines.length ? lines.slice(0, Number(writeableMapKeys.length)) : lines;

    const hasIllegalChars = param === 'name' ? [] : slicedLines.filter((element: string) => /^[a-zA-Z]/g.test(element));

    handleSavePrevious(previousValues);

    if (hasColumns) {
      addToast(
        SCREEN_LOCATION.topCenter,
        <>
          <CrossSvg width={24} />
          {t('capex:paste-rows-error')}
        </>,
        ToastType.ERROR_CAPEX,
        4000
      );
    }

    if (slicedLines.length === hasIllegalChars.length) {
      addToast(
        SCREEN_LOCATION.topCenter,
        <>
          <CrossSvg width={24} />
          {t('capex:paste-error-only-numeric')}
        </>,
        ToastType.ERROR_CAPEX,
        4000
      );
    }
    if (!hasColumns && lines.length !== hasIllegalChars.length && writeableMapKeys.length !== hasIllegalChars.length) {
      lines.forEach((item: string | number, index: number) => {
        if (writeableMapKeys.length > index) onChange(writeableMapKeys[index], param, item);
      });

      addToast(
        SCREEN_LOCATION.topCenter,
        <>
          <CheckSvg />
          {hasIllegalChars.length && param !== 'name' ? (
            <Trans i18nKey="capex:paste-incomplete">
              You pasted {{ number: Number(slicedLines.length) - hasIllegalChars.length }} of{' '}
              {{ total: Number(lines.length) }} values.
            </Trans>
          ) : writeableMapKeys.length < lines.length ? (
            <Trans i18nKey="capex:paste-incomplete">
              You pasted {{ number: Number(writeableMapKeys.length) }} of {{ total: Number(lines.length) }} values.
            </Trans>
          ) : (
            <Trans i18nKey="capex:paste-confirm">You pasted {{ number: Number(slicedLines.length) }} values.</Trans>
          )}
        </>,
        ToastType.SUCCESS,
        10000
      );
    }
  };

  return {
    onPaste,
    handleUpdate,
  };
};
