import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useModal } from 'utils/hooks/use-modal';

export const useUnsavedChangesModal = (hasUnsavedChanges: boolean) => {
  const modal = useModal();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && hasUnsavedChanges
  );

  const onCancel = () => {
    if (blocker && blocker.reset) blocker.reset();
    modal.setModalOpen(false);
  };

  const onProceed = () => {
    if (blocker && blocker.proceed) blocker.proceed();
  };

  useEffect(() => {
    if (blocker.state !== 'blocked') return;
    modal.setModalOpen(true);
  }, [blocker.state]);

  return {
    isOpen: modal.isOpen,
    onProceed,
    onCancel,
  };
};
