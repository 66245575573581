import { t } from 'i18next';
import { DESIGN_COMPARISON_CHARTS } from 'utils/constants/design-comparison';
import { IComparisonFilter } from 'types/design-comparison.types';
import { IDesignAttributes } from 'utils/constants/design-attributes';
import { IPropsDesignComparisonMoneyBarChart } from '../design-comparison-money-bar-chart/design-comparison-money-bar-chart';
import { getTickFormatter } from './utils';
import { ChartFunction } from './types';
import { CalculationResult } from 'pages/design-process/outputs/financial/types';

const designsWithNumberedName = (designs) =>
  designs.map((design) => ({ ...design, name: `${design.number}. ${design.name}` }));

export const graphicMoneyBarAttributes = (
  designs: any[],
  designAttributes: IDesignAttributes,
  activeAttribute: IComparisonFilter
): IPropsDesignComparisonMoneyBarChart | null => {
  const chartFunction = chartFunctions[activeAttribute.label];
  if (!chartFunction) return null;
  return chartFunction(designs, designAttributes);
};

const getCapexVsDesignAttributes = (
  designs: any[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonMoneyBarChart => ({
  title: t('design:comparison-charts.capex-design'),
  data: designsWithNumberedName(designs),
  xAttr: {
    key: ['name'],
    label: 'common:name',
    render: (data: any) => data,
  },
  tickFormatterX: getTickFormatter(10, true),
  yAttr: designAttributes.financial.totalPrice,
  tooltipLabels: {
    x: '',
    y: `${t('capex:capex')}`,
  },
  formatUnits: () => ({
    x: '',
    y: '',
  }),
  formatAxisLabels: () => ({
    x: '',
    y: `${t('capex:capex')}`,
  }),
});

const getLcoeVsDesignAttributes = (
  designs: any[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonMoneyBarChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.lcoeCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.lcoe-design'),
    data: designsWithNumberedName(filteredDesigns),
    xAttr: {
      key: 'name',
      label: 'common:name',
      render: (data: any) => data,
    },
    tickFormatterX: getTickFormatter(10, true),
    yAttr: designAttributes.financial.lcoe,
    tooltipLabels: {
      x: '',
      y: `${t('capex:lcoe')}`,
    },
    formatUnits: () => ({
      x: '',
      y: '/MWh',
    }),
    formatAxisLabels: () => ({
      x: '',
      y: `${t('capex:lcoe')} [m.u./MWh]`,
    }),
  };
};

const chartFunctions: {
  [key: string]: ChartFunction<IPropsDesignComparisonMoneyBarChart>;
} = {
  [DESIGN_COMPARISON_CHARTS.capexVsDesign]: getCapexVsDesignAttributes,
  [DESIGN_COMPARISON_CHARTS.lcoeVsDesign]: getLcoeVsDesignAttributes,
};
