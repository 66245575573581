import React from 'react';
import { IOverlayFilter } from 'utils/hooks/use-filters';
import { IUseModal } from 'utils/hooks/use-modal';
import { ISearchProps } from 'utils/hooks/use-search';
import { IDataTableHeader, IOption } from './common';
import { ICompanyDTO } from './company.types';
import { DateRange, IServerFilter, ISort } from './filter';
import { ISubscriptionPlanCreate } from './subscription-plan';
import { IConverters } from './preferences.types';
import { IUserMinInfoDTO, USER_TYPE } from './users';
import { Table } from '@tanstack/react-table';
import { IRole } from './role';
import { NavigateFunction } from 'react-router-dom';

export enum AlertSeverity {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  CHECK = 'CHECK',
}

export enum StatusSupport {
  SW_REVIEW,
  SW_SOLVED,
  SW_FUTURE,
  SW_INVALID,
  CS_REVIEW,
  CS_SOLVED,
  CS_SW_SOLVED,
  CS_SW_SOLVED_FUTURE,
  CS_SW_SOLVED_INVALID,
  BD_REVIEW,
  BD_SOLVED,
}

export enum CommunicationChannel {
  NONE,
  MAIL,
  PHONE,
}

export enum LinkedEntityType {
  COMPANY = 'COMPANY',
  SITE = 'SITE',
  SIMULATION = 'SIMULATION',
  SIMULATION_STANDALONE_BESS = 'SIMULATION_STANDALONE_BESS',
  METEO_DATA = 'METEO_DATA',
  ELEVATION = 'ELEVATION',
  MODULE = 'MODULE',
  INVERTER = 'INVERTER',
}

export interface IUser {
  id: string;
  name: string;
}

export interface ISupportUser extends IUser {
  surname: string;
  numManagedCompanies: number;
}

export interface INewSupportUser {
  id?: string;
  name: string;
  lastname: string;
  email: string;
  type?: string;
  language?: string;
  jobTitle: string;
  roleId?: string;
  status?: string;
}

export interface ISupportUserListItem {
  id: string;
  name: string;
  lastname: string;
  email: string;
  numManagedCompanies?: number;
  publishedDate: Date;
  lastLogin?: Date;
  status: string;
  role?: IRole;
  type?: string;
  language?: string;
  jobTitle: string;
}

export interface IEditUserTemplate {
  id?: string;
  name: string;
  lastname: string;
  email: string;
  role: IRole | null;
  language: string | null;
  jobTitle: string | null;
}

export interface ISupporCreateCompany {
  name: string;
  email: string;
  countryId: number;
  userLimit: number;
  unlimitedUsers: boolean;
  supportedById?: string;
  businessManagerId?: string;
  salesDevRepresentativeId?: string;
  salesforceId?: string;
  currentPlan: ISubscriptionPlanCreate;
  organizationId?: string;
  enverusId?: string;
}

export interface ISupportUpdateCompany {
  id: string;
  name: string;
  email: string;
  countryId: number;
  userLimit: number;
  unlimitedUsers: boolean;
  supportedById?: string;
  businessManagerId?: string;
  salesDevRepresentativeId?: string;
  salesforceId?: string;
  maxSiteToProjectDistance?: number;
  showLogoInDocuments?: boolean;
}

export interface INewCompanyFormFields extends INewCompanyMandatoryFields {
  countryId: { value: number }[];
  salesforceId: { value: string };
  supportedById: { value: string }[];
  businessManagerId: { value: string }[];
  salesDevRepresentativeId: { value: string }[];
  companyWarningMessage: { value: string };
  customPlanType: { value: string }[];
  notes: { value: string };
  currency: { value: string }[];
  plan: { value: string }[];
  unlimitedUsers: { checked: boolean };
  sendWelcomeEmail: { checked: boolean };
  maxSiteToProjectDistance: { value: number };
  showLogoInDocuments: { checked: boolean };
}

export interface INewCompanyMandatoryFields {
  name: { value: string };
  email: { value: string };
  price: { value: number };
  projectsPurchased: { value: number };
  userLimit: { value: number };
  expirationDate: { value: string };
  startDate: { value: string };
}

export interface IUpdateSubscriptionPlanFormFields extends IUpdateSubscriptionPlanMandatoryFields {
  currency: { value: string }[];
  startDate: { value: string };
  expirationDate: { value: string };
  extensionDate: { value: string };
}

export interface IUpdateSubscriptionPlanMandatoryFields {
  price: { value: number };
  projectsPurchased: { value: number };
}

export interface ISupportCompanyForm {
  companyForm: ISupporCreateCompany | ISupportUpdateCompany;
  image?: File;
  sendWelcomeEmail?: boolean;
}

export interface ISupportedCompany extends ICompanyDTO {
  active: boolean;
}

export interface ISupportAlert {
  active: boolean;
  alertSeverity: AlertSeverity;
  alertType: string;
  company: {
    id: string;
    name: string;
  };
  communicationChannel: CommunicationChannel;
  createdBy: IUserMinInfoDTO;
  description: string;
  entityId: string;
  entityName: string;
  id: string;
  archived: boolean;
  lastModified: Date;
  linkedEntityType: LinkedEntityType;
  linkedEntityOwner: IUserMinInfoDTO;
  projectId: string;
  projectName: string;
  publishedDate: Date;
  statusSupport: StatusSupport;
  teamName?: string;
}

export interface IOverlayProps {
  filters: IOverlayFilter[];
  isOpen: boolean;
  loading: boolean;
  onApply: () => void;
  onClear: () => void;
  onSelect: (filter: string, value: any, checked: boolean) => void;
}

export interface IUseAllCompanies {
  allCompaniesObject: {
    table: Table<ISupportedCompany>;
    error: boolean;
    paginationComponent: string | JSX.Element;
  };
  topBarObject: {
    companiesPerPage: IOption;
    setCompaniesPerPage: React.Dispatch<React.SetStateAction<IOption>>;
    showMoveTo: boolean;
    setShowMoveTo: React.Dispatch<React.SetStateAction<boolean>>;
    companiesFiltersHook: IAllCompaniesFilters;
    inputSearchProps: ISearchProps;
    handleMoveTo: (user: string, type: USER_TYPE) => void;
  };
}

export interface IUseActivityTab {
  table: Table<ISupportAlert>;
  companyName: string;
  inputSearchProps: ISearchProps;
  optionSelected: string;
  infoModal: IUseModal;
  notesModal: IUseModal;
  query: {
    fetchedData: any;
    paginationComponent: string | JSX.Element;
    loading: boolean;
    error: boolean;
    fetchData: () => void;
  };
  filtersPanelOpened: boolean;
  setServerFilters: (filters: IServerFilter[]) => void;
  setFiltersPanelOpened: (opened: boolean) => void;
  handleEditNotes: (id: string, notes: string) => void;
  handleChangeView: (newValue: string) => void;
  activitiesPerPage: IOption;
  setActivitiesPerPage: (option: IOption) => void;
}

export interface IOptionsAttributes {
  sortedBy: ISort;
  onToggle: (id: string, active: boolean) => void;
  setSortedBy: (filter: ISort) => void;
}

export interface ISupportFilters {
  filters: IOverlayFilter[];
  overlayOpen: boolean;
  serverFilters: IServerFilter[];
  setServerFilters: (serverFilters: IServerFilter[]) => void;
  onApply: () => void;
  onClear: () => void;
  onSelect: (filter: string, value: any, checked: boolean) => void;
  setOverlayOpen: (e: boolean) => void;
}

export interface IAllCompaniesFilters extends ISupportFilters {
  handleSingleSelection: (filter: string, value: any, checked: boolean) => void;
  setCustomDates: (from: string, to: string, field: string) => void;
  onChangeCountry: (countries: string[]) => void;
}

export interface IActivityOptions {
  sortedBy: ISort;
  infoModal: IUseModal;
  notesModal: IUseModal;
  activityView: string;
  hasWritePermissions: boolean;
  navigate: NavigateFunction;
  onArchive: (id: string) => void;
  onSort: (sort: ISort) => void;
}

export interface IProjectsOptions {
  converters: IConverters | undefined;
  sortedBy: ISort;
  companyId: string;
  hasWritePermissions: boolean;
  onChange: (id: string, status: string, distance: number) => void;
  onSort: (sort: ISort) => void;
}

export interface IUsersOptions {
  sortedBy: ISort;
  companyEmail: string;
  canDeleteUsers: boolean;
  canChangePermissions: boolean;
  canResendPassword: boolean;
  roles: IRole[];
  onSelectRole: (option: string, id: string) => void;
  onSort: (sort: ISort) => void;
  handleAction: (id: string, action: string) => void;
}

export interface ISupportAlertSend {
  id: string;
  description: string;
  statusSupport: StatusSupport;
  archived: boolean;
  communicationChannel: CommunicationChannel;
}

export interface IActivityTabAttribute extends IDataTableHeader {
  className?: (data: any) => string;
}

export interface ILinkedEntityInfoDTO {
  error: string;
  message: string;
}

export interface IActivityTabFilters {
  [key: string]: any;
  teamIds: string[];
  alertTypes: string[];
  entityTypes: string[];
  users: string[];
  dateRange: DateRange;
}

export interface IProjectFileFilters {
  [key: string]: any;
  numDesigns: string[];
  country: string[];
  users: string[];
  dateRange: DateRange;
}

export interface IDesignsTabFilters {
  [key: string]: any;
  status: string[];
  dateRange: DateRange;
  simulationResults: string[];
  teamIds: string[];
  users: string[];
}
export interface ISupportUserFilters {
  [key: string]: any;
  status: string;
  teamIds: string[];
  roleIds: string[];
  userIds: string[];
}

export interface IOrganizationSearchFields {
  domain?: string;
  salesforceId?: string;
}

export interface IOrganization {
  name: string;
  domain: string;
  id: string;
  salesforceId: string;
  companyCreated: boolean;
  enverusId?: string;
}
