import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import { FeaturedFlagsProvider } from 'utils/hooks/use-feature-flags';
import AppContainer from '../app.styled';
import { ScreenLoader, ThemeProvider } from '@ratedpower/components';
import { Outlet } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { useErrorNotifier } from 'components/error-components/error-notifier';

import { Helmet } from 'react-helmet';
import GlobalStyle from 'globalStyles';
import { Auth0Provider } from '@auth0/auth0-react';
import ApolloAuthProvider from './apollo-auth-provider';
import { getRedirectUri } from 'utils/redirect_uri';

export const featuredFlags = [
  { name: 'revamp', isActive: true },
  { name: 'test2', isActive: false },
  { name: 'test', isActive: true },
  { name: 'inputs-per-area', isActive: true },
];

const AppLayout = () => {
  const showError = useErrorNotifier();
  return (
    <I18nextProvider i18n={i18n}>
      <Auth0Provider
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        authorizationParams={{
          redirect_uri: getRedirectUri(),
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
        useRefreshTokens
        useRefreshTokensFallback
      >
        <FeaturedFlagsProvider flags={featuredFlags}>
          <React.Suspense fallback={<ScreenLoader />}>
            <ThemeProvider>
              <GlobalStyle />
              <AppContainer>
                <Helmet titleTemplate="%s - RatedPower" defaultTitle="RatedPower — Smart energy flow" />
                <div data-test="component-app" />
                <ApolloAuthProvider showError={showError}>
                  <Outlet />
                </ApolloAuthProvider>
              </AppContainer>
            </ThemeProvider>
          </React.Suspense>
        </FeaturedFlagsProvider>
      </Auth0Provider>
    </I18nextProvider>
  );
};

const AppLayoutWithLD = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
})(AppLayout);

export default AppLayoutWithLD;
