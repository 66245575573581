import React, { useEffect, useState } from 'react';
import { ChevronLeftSvg, ChevronRightSvg } from 'assets/icons';
import * as S from './carousel-pagination.styled';

interface IPaginationProps {
  currentPage: number;
  totalPages: number;
  onChange: (page: number) => void;
}

const CarouselPagination = (props: IPaginationProps) => {
  const { currentPage, totalPages, onChange } = props;

  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    onChange(page);
  }, [page]);

  const handleNextPageClick = () => {
    setPage((prevState) => prevState + 1);
  };

  const handlePrevPageClick = () => {
    setPage((prevState) => prevState - 1);
  };

  return (
    <>
      {page > 1 && <S.PrevCarouselIconButton size="medium" Icon={ChevronLeftSvg} onClick={handlePrevPageClick} />}
      {Number(page + 1) <= Number(totalPages) && (
        <S.NextCarouselIconButton size="medium" Icon={ChevronRightSvg} onClick={handleNextPageClick} />
      )}
    </>
  );
};

export default CarouselPagination;
