import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.l};
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  flex-grow: 1;

  th {
    background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  }

  th:nth-child(1) {
    min-width: 200px;
  }
`;
