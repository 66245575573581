import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISidebarState {
  collapsed: boolean;
}

const initialState: ISidebarState = {
  collapsed: true,
};

const sidebarSlice = createSlice({
  initialState,
  name: 'sidebar',
  reducers: {
    setSidebar(state, action: PayloadAction<ISidebarState>) {
      const { collapsed } = action.payload;
      state.collapsed = collapsed;
    },
    collapseSidebar(state) {
      state.collapsed = true;
    },
  },
});

export const { setSidebar, collapseSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
