export interface IUseCapexTaxes {
  handleTaxes: (option: string, tax: number, entryId: number) => void;
}

export function useCapexTaxes(hashMapEntries: any): IUseCapexTaxes {
  function handleTaxes(option: string, tax: number, entryId: number) {
    if (option && tax) {
      switch (option) {
        case 'all':
          setAllTaxes(tax);
          break;
        case 'category':
          setCategoryTaxes(tax, entryId);
          break;
        case 'subcategory':
          setSubCategoryTaxes(tax, entryId);
          break;
      }
    }
  }

  function setAllTaxes(newTax: number) {
    hashMapEntries.current.forEach((value: any) => {
      if (!!value.priceDefinition) value.priceDefinition.tax = newTax;
    });
  }

  function setCategoryTaxes(newTax: number, entryId: number) {
    const entry = hashMapEntries.current.get(entryId);
    if (!entry) return;
    const index = entry.tagIndex;

    hashMapEntries.current.forEach((value: any) => {
      if (value.tagIndex[0] === index[0]) {
        if (!!value.priceDefinition) value.priceDefinition.tax = newTax;
      }
    });
  }
  function setSubCategoryTaxes(newTax: number, entryId: number) {
    const entry = hashMapEntries.current.get(entryId);
    if (!entry) return;
    const index = entry.tagIndex.split('.');
    index.pop();
    hashMapEntries.current.forEach((item: any) => {
      const itemIndex = item.tagIndex.split('.');
      itemIndex.pop();
      if (JSON.stringify(index) === JSON.stringify(itemIndex)) {
        item.priceDefinition.tax = newTax;
      }
    });
  }

  return {
    handleTaxes,
  };
}
