import styled from 'styled-components';

export const comparisonMaxWidth = '278px';

export const DesignComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ComparisonTableContainer = styled.table`
  position: relative;
  display: flex;
  width: 100%;
  overflow: auto;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    width: 100%;
    column-gap: 16px;

    .notes-edit {
      cursor: pointer;
      padding-left: 4px;
      min-width: 14px;
      height: 14px;
    }

    .cell {
      width: ${comparisonMaxWidth};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: ${({ theme }) => `${theme.v2.spacing.xs} ${theme.v2.spacing.s}`};
      border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};

      &.highlight {
        background-color: ${({ theme }) => theme.v2.bg.secondary.default};
      }
    }
  }

  .headers {
    position: sticky;
    left: 0;
    z-index: 5;
    height: 40px;
    width: 170px;
    background-color: ${({ theme }) => theme.v2.bg.secondary.default};
    border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};

    &.last-header {
      background-color: ${({ theme }) => theme.v2.bg.primary.default};
      height: calc(100% + 10px);
      border: none;
    }
  }
`;

export const ComparisonContent = styled.div`
  background: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.v2.spacing.s} ${theme.v2.spacing.m}`};
  height: calc(100vh - 18px - 64px); /* - padding - topbar */
`;

export const ComparisonSubTab = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 1.8rem;
`;

export const ComparisonTopRightControls = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const ComparisonDesignsPanel = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.v2.spacing.l} 0;
  max-width: 100%;
  height: 100%;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  position: relative;

  &.hidden {
    display: none;
    overflow: hidden;
  }
`;
