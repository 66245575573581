import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IHelpButtonState {
  isShown: boolean;
  right: number;
  bottom: number;
}

const initialState: IHelpButtonState = {
  isShown: true,
  right: 25,
  bottom: 15,
};

const helpButtonSlice = createSlice({
  initialState,
  name: 'helpButton',
  reducers: {
    setHelpButtonX(state, action: PayloadAction<number>) {
      state.right = action.payload;
    },
    setHelpButtonY(state, action: PayloadAction<number>) {
      state.bottom = action.payload;
    },
    resetHelpButton(state) {
      state.right = 25;
      state.bottom = 15;
    },
    showHelpButton(state, action: PayloadAction<boolean>) {
      state.isShown = action.payload;
    },
  },
});

export const { setHelpButtonX, setHelpButtonY, showHelpButton, resetHelpButton } = helpButtonSlice.actions;

export default helpButtonSlice.reducer;
