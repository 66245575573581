import { useQuery } from '@apollo/client';
import { GET_ROLES_BY_CURRENT_USER } from 'services/roles-gql';

export const useRolesByCurrentUser = (skip?: boolean) => {
  const { data: rolesByCurrentUser } = useQuery(GET_ROLES_BY_CURRENT_USER, {
    fetchPolicy: 'network-only',
    skip,
  });

  return {
    rolesByCurrentUser: rolesByCurrentUser ? rolesByCurrentUser.result : [],
  };
};
