import styled from 'styled-components';

export const ProjectsContainer = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .main-view {
    display: flex;
    width: 100%;
    position: relative;
    flex-grow: 1;
  }

  .split-view {
    display: flex;
    width: 100%;
    flex-grow: 1;
    overflow-x: hidden;

    .split-item-2 {
      width: 50%;
      overflow: auto;
    }
    .split-item-1 {
      width: 100%;
    }
    .split-item-0 {
      width: 0;
      overflow: hidden;
    }
  }
`;

export const ProjectsBanner = styled.div`
  padding: ${({ theme }) => `${theme.v2.spacing.m} ${theme.v2.spacing.l} 0`};
`;
