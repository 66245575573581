import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HomeProjectItemContainer = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  display: flex;
  height: max-content;
  align-items: stretch;
`;

export const HomeProjectInfoContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  padding: ${({ theme }) => `${theme.v2.spacing.m} ${theme.v2.spacing.xs}`};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  min-width: 276px;

  p + p {
    margin-top: 0;
  }
`;

export const ProjectLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 13px;
    height: 13px;
    color: ${({ theme }) => theme.black};
  }

  p {
    flex: 1;
  }
`;
