import React from 'react';
import ErrorFooter from '../error-footer/error-footer';
import * as S from './error-not-found.styled';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@ratedpower/components';
import { ErrorIcon } from 'pages/error/error-page-container.styled';
import { useNavigate } from 'react-router-dom';

const ErrorNotFound: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <S.ErrorNotFoundContainer>
      <ErrorIcon />

      <Typography weight="bold">{t('errors:page-not-found')}</Typography>

      <Typography size="s">{t('errors:url-not-found')}</Typography>

      <ErrorFooter />

      <Button variant="accent" text={t('errors:unexpected-error-solution')} onClick={() => navigate('/projects')} />
    </S.ErrorNotFoundContainer>
  );
};

export default ErrorNotFound;
