type TabValues = 'npv' | 'irr' | 'lcoe' | 'cash-flows' | 'roi';

export type IDesignResultsFinancialTabContent = {
  label: string;
  title: string;
  value: TabValues;
  content: JSX.Element;
};

export type IDesignResultsFinancialTabs = IDesignResultsFinancialTabContent[];

type CapexResult = {
  currency: string;
  specificPrice: number;
  totalPrice: number;
};

export type FinancialResultsDTO = {
  lcoe: number;
  discountedPayback: number;
  irr: number;
  npv: number;
  payback: number;
  roi: number;
  yearlyResults: YearlyResult[];
  lcoeCalculationResult: CalculationResult;
  discountedPaybackCalculationResult: CalculationResult;
  irrCalculationResult: CalculationResult;
  paybackCalculationResult: CalculationResult;
  roiCalculationResult: CalculationResult;
};

export enum CalculationResult {
  CORRECT = 'CORRECT',
  INFINITY = 'INFINITY',
  NAN = 'NAN',
}

export type YearlyResult = {
  year: number;
  lcoe: number;
  lcoeCalculationResult: CalculationResult;
  npv: number;
  irr: number;
  irrCalculationResult: CalculationResult;
  roi: number;
  roiCalculationResult: CalculationResult;
  accFreeCashFlow: number;
  revenue: number;
  cost: number;
};

export type FinancialChartsData = {
  design: {
    outputs: {
      capexResult: CapexResult;
      financialResultsDTO: FinancialResultsDTO;
    };
  };
};
