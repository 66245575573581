import styled from 'styled-components';
import { Modal, ModalHeader, ModalNodeTitle, ModalBody } from '@ratedpower/components';

export const SessionExpirationModalContainer = styled(Modal)`
  align-items: center;
  color: ${({ theme }) => theme.gray_darker};
  display: flex;
  flex-direction: column;

  ${ModalBody} {
    text-align: center;
  }

  ${ModalHeader} {
    justify-content: center;
    height: auto;
    padding: 6px;

    ${ModalNodeTitle} {
      flex-grow: 0;
    }
  }

  svg {
    color: ${({ theme }) => theme.primary};
    height: 2.5rem;
    width: 2.5rem;
  }
`;

export const CountDown = styled.div`
  font-size: 2rem;
  margin: 1rem 0;
`;
