import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoPrimarySvg } from 'assets/icons';
import { paths } from 'components/bars/side-bar/sidebar-list-items';
import { TopbarWrapper } from '@ratedpower/components';
import TopbarMyAccount from 'components/bars/layout-topbar/topbar-my-account/topbar-my-account';
import NotificationsButton from 'components/bars/layout-topbar/notifications-buttom/notifications-button';

const LayoutTopBar: React.FC = () => {
  const navigate = useNavigate();

  const handleLogoClick = (): void => {
    navigate(paths.home);
  };

  return (
    <TopbarWrapper
      type={'main'}
      left={<LogoPrimarySvg onClick={handleLogoClick} width={'140px'} height={'55px'} />}
      right={
        <>
          <NotificationsButton />
          <TopbarMyAccount />
        </>
      }
    />
  );
};

export default LayoutTopBar;
