import React from 'react';
import { TopbarMyAccountContainer } from 'components/bars/layout-topbar/topbar-my-account/topbar-my-account.styled';
import { AccountSvg } from 'assets/icons';
import { Modal, PopupWrapper, SVGWrapper, Typography } from '@ratedpower/components';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { useTranslation } from 'react-i18next';
import { paths } from 'components/bars/side-bar/sidebar-list-items';
import { useLocation } from 'react-router';
import { useTopbarMyAccount } from 'components/bars/layout-topbar/topbar-my-account/use-topbar-my-account';
import { useTheme } from 'styled-components';

const TopbarMyAccount: React.FC = () => {
  const { pathname } = useLocation(),
    { t } = useTranslation(),
    { profile } = useSelector((state: RootState) => state.currentUser),
    { options, onClickOption, handleLogout, handleCancelLogout, modal } = useTopbarMyAccount(),
    theme = useTheme();

  return (
    <>
      <PopupWrapper options={options} onClickOption={onClickOption} popupPosition="leftCenter">
        <TopbarMyAccountContainer $active={pathname.startsWith(paths.profile)}>
          <SVGWrapper icon={AccountSvg} size={'l'} color={theme.v2.icon.primary.default} />
          <Typography color={'inherit'}>{t('common:topbar.greeting', { name: profile?.name })}</Typography>
        </TopbarMyAccountContainer>
      </PopupWrapper>
      <Modal
        isActive={modal.isOpen}
        hideCloseIcon
        onClose={handleCancelLogout}
        onClickSecondaryBtn={handleCancelLogout}
        onClickPrimaryBtn={handleLogout}
        title={t('auth:logout')}
        secondaryBtnText={t('action:cancel')}
        primaryBtnText={t('auth:logout')}
      >
        <Typography>{t('auth:logout-description')}</Typography>
      </Modal>
    </>
  );
};

export default TopbarMyAccount;
