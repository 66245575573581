import React, { useMemo, useRef } from 'react';
import { ColumnDef, RowData, TableOptions, getCoreRowModel, useReactTable } from '@tanstack/react-table';
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    editRow: (rowIndex: number, editable: boolean) => void;
    addRow: (newRow: TData) => void;
  }
}

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

export const useEditableRPTable = <T extends { id?: string }>(
  data: T[],
  columns: ColumnDef<T, any>[],
  setData: React.Dispatch<React.SetStateAction<T[]>>,
  options?: Partial<TableOptions<T>>
) => {
  const currentDataRef = useRef<T[]>([]);
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  return useReactTable({
    data: useMemo(() => data, [data]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        skipAutoResetPageIndex();
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      editRow: (rowIndex, editable) => {
        skipAutoResetPageIndex();
        if (editable === true) {
          currentDataRef.current = [...data];
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  editable,
                };
              }
              return {
                ...row,
                editable: !editable,
              };
            })
          );
          return;
        }
        const cancelNewUser = !currentDataRef.current[0].id;
        if (cancelNewUser) {
          currentDataRef.current = currentDataRef.current.slice(1);
        }
        setData(currentDataRef.current.map((user) => ({ ...user, editable: false })));
      },
      addRow: (newRow) => {
        skipAutoResetPageIndex();
        currentDataRef.current = [...data];
        setData((old) => [newRow, ...old.map((oldRole) => ({ ...oldRole, editable: false }))]);
      },
    },
    ...options,
  });
};
