import React from 'react';
import * as S from './error-footer.styled';
import { SUPPORT_EMAIL } from 'utils/constants/contact';
import { Trans } from 'react-i18next';
import { Typography } from '@ratedpower/components';

const ErrorFooter: React.FC = () => (
  <S.ErrorFooterContainer>
    <Typography size="s">
      <Trans i18nKey="errors:contact-customer-support" values={{ mail: SUPPORT_EMAIL }}>
        <S.MailTo href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</S.MailTo>
      </Trans>
    </Typography>
  </S.ErrorFooterContainer>
);
export default ErrorFooter;
