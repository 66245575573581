import styled, { css } from 'styled-components';

const activeAndHoverStyles = css`
  border: ${({ theme }) => `1px solid ${theme.v2.stroke.accent.default}`};
  color: ${({ theme }) => theme.v2.text.accent.hover};
`;

export const TopbarMyAccountContainer = styled.div<{ $active?: boolean }>`
  color: ${({ theme }) => theme.v2.text.secondary.default};
  padding: ${({ theme }) => theme.v2.spacing.xs};
  border-radius: ${({ theme }) => theme.v2.spacing.xs};
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${activeAndHoverStyles}
    svg {
      fill: ${({ theme }) => theme.v2.icon.accent.default};
    }
  }

  ${({ $active }) => $active && activeAndHoverStyles};
`;
