import styled from 'styled-components';

export const MapPopupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  padding: ${({ theme }) => theme.v2.spacing.xs} 0;
`;

export const MapPopupRow = styled.div`
  padding: 0 ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
  align-items: center;
  flex-wrap: wrap;
`;

export const MapPopupWarningRow = styled(MapPopupRow)`
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`;
