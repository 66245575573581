import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';

export const useCanCloneDesigns = () => {
  const { isAuthorized } = useAuthorization();

  return (
    isAuthorized(Permission.SIMULATION_SUPPORT_WRITE) ||
    isAuthorized(Permission.SIMULATION_WRITE_IN_USER_COMPANY) ||
    isAuthorized(Permission.SIMULATION_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR) ||
    isAuthorized(Permission.SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR)
  );
};
