/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import * as S from 'components/modals/new-features-modal/new-features-modal.styled';
import { IModalProps, LinkText, Modal, Pagination, Typography } from '@ratedpower/components';
import { IPopupFormSection, IPopupListItem } from 'types/new-feature';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export interface INewFeaturesModal extends IModalProps {
  sections: IPopupFormSection[];
}

const NewFeaturesModal: React.FC<INewFeaturesModal> = (props) => {
  const { t } = useTranslation();
  const { sections } = props;
  const [currentSection, setCurrentSection] = useState(sections[0]);
  const currentSectionIdx = sections.findIndex((section) => section.id === currentSection?.id);
  const theme = useTheme();

  const textConverter = (text: string) => {
    const findLinksRegex = /\[([^\]]+)\]|\b([^[]+)(?=\s*\[|$)/g;
    const segments: any[] = [];
    let match;

    while ((match = findLinksRegex.exec(text)) !== null) {
      if (match[1] !== undefined) {
        segments.push('[' + match[1] + ']');
      } else if (match[2] !== undefined) {
        segments.push(match[2]);
      }
    }

    return printSegments(segments);
  };

  const printSegments = (orderedSegments: string[]) => {
    return orderedSegments.map((segment, index) => {
      const segmentMatchesBoldText = (inputSegment: string) => inputSegment.match(/([^*]*\*[^*]*){2}/g);

      const findBoldText = (matches: RegExpMatchArray) => {
        const results = matches.map((match) => match.match(/([^*]*)(\*[^*]*\*)([^*]*)/));
        return results.map((result) =>
          result
            ?.slice(1)
            .map((fragment) =>
              fragment.includes('*') ? (
                <b key={fragment}>{fragment.replace(/\*/g, '')}</b>
              ) : (
                <React.Fragment key={fragment}>{fragment}</React.Fragment>
              )
            )
        );
      };

      const findLinks = (currentSegment: string) => {
        if (currentSegment.includes('[')) {
          const linkParts = currentSegment.substring(1, currentSegment.length - 1).split('|');
          const boldTextMatches = segmentMatchesBoldText(linkParts[0]);
          return (
            <>
              <LinkText
                href={linkParts[1]}
                target="_blank"
                rel="noopener noreferrer"
                color={theme.v2.btn.link.text.hover}
                textUnderlined
                as={'a'}
                size="l"
              >
                {boldTextMatches ? findBoldText(boldTextMatches) : linkParts[0]}
              </LinkText>{' '}
            </>
          );
        } else {
          return <>{currentSegment} </>;
        }
      };

      const boldTextMatches = segmentMatchesBoldText(segment);
      if (boldTextMatches && !segment.includes('[')) {
        return findBoldText(boldTextMatches);
      }
      return <React.Fragment key={index}>{findLinks(segment)}</React.Fragment>;
    });
  };

  return (
    <Modal
      {...props}
      size="m"
      buttons={{
        primary: {
          text: t('action:close'),
          onClick: props.onClose,
        },
      }}
      hideCloseIcon
    >
      <S.ModalFeatureWrapper>
        <Typography category="title" size="xl">
          {currentSection?.title}
        </Typography>
        {currentSection?.description && (
          <Typography category="display" size="l">
            {textConverter(currentSection.description)}
          </Typography>
        )}
        {currentSection?.listItems && (
          <Typography category="display" size="l">
            <ul>
              {currentSection.listItems.map((item: IPopupListItem, index: number) => (
                <li key={item.id ?? `${index}-sectionItem`}>{textConverter(item.content)}</li>
              ))}
            </ul>
          </Typography>
        )}
      </S.ModalFeatureWrapper>
      <Pagination
        currentPage={currentSectionIdx + 1}
        totalPages={sections.length}
        onChange={(index) => setCurrentSection(sections[index - 1])}
      />
    </Modal>
  );
};

export default NewFeaturesModal;
