import React, { ReactElement, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { IOption } from 'types/common';
import { IRoleListItem, ROLE_STATUS, ROLE_TYPE } from 'types/role';
import classnames from 'classnames';
import {
  BulletPoint,
  Chip,
  ConditionalWrapper,
  InputSelect,
  SVGWrapper,
  Tooltip,
  Typography,
} from '@ratedpower/components';
import { getOriginalRolesColors, HIERARCHY_OPTIONS, ROLE_STATUSES, ROLE_TYPE_COLOR } from 'utils/constants/users';
import { IUserRoleSummary } from 'types/users';
import { TableActions } from '../roles-tab/roles-tab.styled';
import ColorPicker from 'components/color-picker/color-picker';
import { TrashSvg } from 'assets/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import EditableInputTextCell from 'components/tables/editable-input-text-cell';

const columnHelper = createColumnHelper<IRoleListItem>();

export const useGetRolesTableColumns = (
  sortableHeader: (label: string, code: string) => ReactElement,
  userRoleSummary: IUserRoleSummary[],
  handleDeleteAction: (id?: string) => void,
  hasWritePermission: boolean,
  setSelectedRoleTypes: React.Dispatch<
    React.SetStateAction<
      | {
          [id: string]: ROLE_TYPE;
        }
      | undefined
    >
  >
) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const originalRolesColors = getOriginalRolesColors(theme);

  return useMemo(
    () => [
      columnHelper.accessor('color', {
        id: 'color',
        header: '',
        cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
          const { editable, name } = original;
          return editable ? (
            <ColorPicker
              onChangeColor={(newColor) => {
                table.options.meta?.updateData(index, id, newColor);
              }}
              editable
              colorWheelWidth={16}
              color={getValue() ?? originalRolesColors[name]}
            />
          ) : (
            <BulletPoint size="big" color={getValue() ?? originalRolesColors[name]} />
          );
        },
      }),
      columnHelper.accessor('hierarchyLevel', {
        header: () => sortableHeader('user:roles.hierarchy-level', 'hierarchyLevel'),
        cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
          return !original.editable ? (
            <Typography>{getValue()}</Typography>
          ) : (
            <InputSelect
              options={HIERARCHY_OPTIONS as IOption[]}
              value={[{ label: String(getValue()), value: String(getValue()) }]}
              onChange={(option: IOption[]) => {
                table.options.meta?.updateData(index, id, option[0].value);
              }}
              maxWidth="100px"
            />
          );
        },
      }),
      columnHelper.accessor('name', {
        header: () => sortableHeader('user:roles.name', 'name'),
        cell: (info) => <EditableInputTextCell info={info} />,
      }),
      columnHelper.accessor('id', {
        header: () => <Typography>{t('user:user-plural')}</Typography>,
        cell: (info) => (
          <Typography>{userRoleSummary.find(({ role }) => role?.id === info.getValue())?.userCounter || 0}</Typography>
        ),
      }),
      columnHelper.accessor('description', {
        header: () => <Typography>{t('description')}</Typography>,
        cell: (info) => (
          <Typography>{t(`user:roles.descriptions.${info.row.original.name}`, { defaultValue: '-' })}</Typography>
        ),
      }),
      columnHelper.accessor('status', {
        header: () => sortableHeader('user:status', 'status'),
        cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
          const newValue = getValue() === ROLE_STATUS.DISABLED ? ROLE_STATUS.ENABLED : ROLE_STATUS.DISABLED;
          const isNotEditable = (original.editable && !id) || !original.editable;
          return (
            <Chip
              onClick={() => {
                if (!isNotEditable) {
                  table.options.meta?.updateData(index, id, newValue);
                }
              }}
              label={t(`user:${ROLE_STATUSES[getValue() as string]}`)}
              $checked={getValue() === ROLE_STATUS.ENABLED}
            />
          );
        },
      }),
      columnHelper.accessor('type', {
        header: () => sortableHeader('user:roles.type', 'type'),
        cell: ({ getValue, row: { index, original }, column: { id: columnId }, table }) => {
          const { editable, id } = original;
          const type = getValue();

          const getTypeSelected = (selectedType: ROLE_TYPE): IOption => {
            return {
              value: selectedType,
              label: t(`user:roles.${selectedType}`),
            };
          };

          return id && !editable ? (
            <BulletPoint color={theme[ROLE_TYPE_COLOR[type]]}>{t(`user:roles.${type}`)}</BulletPoint>
          ) : (
            <InputSelect
              value={[getTypeSelected(type)]}
              onChange={(selectedType) => {
                setSelectedRoleTypes((mapSelectedRoleTypes) => ({
                  ...mapSelectedRoleTypes,
                  [id ?? 'New']: selectedType[0].value as ROLE_TYPE,
                }));
                table.options.meta?.updateData(index, columnId, selectedType[0].value);
              }}
              options={Object.values(ROLE_TYPE).map((roleType) => ({
                label: getTypeSelected(roleType).label,
                value: roleType,
              }))}
            />
          );
        },
      }),
      columnHelper.accessor('editable', {
        header: '',
        cell: (info) => {
          const { id, status } = info.row.original;

          if (!id) return '';

          const hasNoUsers = !userRoleSummary.find(({ role }) => role?.id === id)?.userCounter,
            noUsersAndDisabled = hasNoUsers && status === ROLE_STATUS.DISABLED;

          if (!hasWritePermission) return <></>;

          return (
            <TableActions
              className={classnames('table-actions', {
                disabled: noUsersAndDisabled,
              })}
            >
              <ConditionalWrapper
                condition={!noUsersAndDisabled}
                wrapper={(children) => <Tooltip description={t('user:roles.delete-tooltip')}>{children}</Tooltip>}
              >
                <SVGWrapper
                  icon={() => <TrashSvg />}
                  disabled={!noUsersAndDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (noUsersAndDisabled) {
                      handleDeleteAction(id);
                    }
                  }}
                  size="s"
                />
              </ConditionalWrapper>
            </TableActions>
          );
        },
      }),
    ],
    [userRoleSummary, sortableHeader]
  );
};
