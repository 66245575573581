import React from 'react';
import * as S from './empty-team-content.styled';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@ratedpower/components';
import { PlusSvg } from 'assets/icons';

interface IProps {
  onAddProject: () => void;
  onCreateProject: () => void;
}

const EmptyTeamContent: React.FC<IProps> = ({ onAddProject, onCreateProject }) => {
  const { t } = useTranslation();

  return (
    <S.EmptyContentContainer>
      <Typography>{t('user:teams.empty-projects-description')}</Typography>
      <S.EmptyContentButtons>
        <Button text={t('user:teams.add-existing-projects')} Icon={PlusSvg} variant="outlined" onClick={onAddProject} />
        <Button text={t('user:teams.create-new-project')} Icon={PlusSvg} onClick={onCreateProject} />
      </S.EmptyContentButtons>
    </S.EmptyContentContainer>
  );
};

export default EmptyTeamContent;
