import React, { Fragment } from 'react';
import * as S from './pv-design-current-plan.styled';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';
import { PvPlanCard, PvPlanContent } from '../pv-design-plans.styled';
import SkeletonList from 'utils/skeleton-list';

interface IProps {
  color: 'primary' | 'secondary' | 'purple' | 'yellow_dark' | 'red';
  planTitle: string;
  entries?: { label: string; value: string | number }[];
  loading: boolean;
}

const PvDesignCurrentPlan: React.FC<IProps> = ({ color, planTitle, entries, loading }) => {
  const { t } = useTranslation();

  return (
    <S.CurrentPlanContainer>
      <Typography weight="bold">{t('company:current-plan')}</Typography>
      <PvPlanCard color={color} size="big">
        <Typography className="current-plan-title" weight="bold">
          {t(`support:${planTitle}`)}
        </Typography>
        <PvPlanContent>
          {loading ? (
            <S.CurrentPlanLoading>
              <SkeletonList amount={7} height={16} width={430} />
            </S.CurrentPlanLoading>
          ) : (
            <>
              {entries?.map((entry) => (
                <S.PlanRowWrapper key={entry.label}>
                  <Typography weight="bold" as="span" size="s">
                    {t(entry.label)}
                  </Typography>
                  <Typography as="span" size="s">
                    {entry.value === 'unlimited' ? t('company:unlimited') : entry.value ?? '-'}
                  </Typography>
                </S.PlanRowWrapper>
              ))}
            </>
          )}
        </PvPlanContent>
      </PvPlanCard>
    </S.CurrentPlanContainer>
  );
};

export default PvDesignCurrentPlan;
