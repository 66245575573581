import { useQuery } from '@apollo/client';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useTranslation } from 'react-i18next';
import { GET_USER_TEAMS_ASSIGNED } from 'services/team-gql';
import { userTeamsAssignedColumns } from 'utils/teams';

export const useListUserTeamsAssigned = (userId: string) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const { data, error } = useQuery(GET_USER_TEAMS_ASSIGNED, { variables: { userId }, fetchPolicy: 'network-only' });

  const table = useReactTable({
    data: data?.getUserTeamsAssigned ?? [],
    columns: userTeamsAssignedColumns(t, isAuthorized(Permission.TEAM_READ_FROM_USER_TEAM)),
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
    error,
  };
};
