import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import { IUsersFilters } from 'types/users';
import { initialUserFilters, USER_STATUS } from 'utils/constants/users';
import { IServerFilter } from 'types/filter';
import { FilterSidebar, FilterSidebarItem, CheckboxGroup, RadioGroup } from '@ratedpower/components';
import { useQuery } from '@apollo/client';
import { GET_JOB_TITLES } from 'services/user-gql';
import { stringArraysSameElements } from 'utils/array';
import { track } from 'rudderstack/utils';
import { RudderstackEvent, UsersFilters } from 'rudderstack/types';

export interface IUsersTabFiltersPanel {
  id?: string;
  active: boolean;
  filterByStatus?: boolean;
  onHide: () => void;
  onApplyFilters: (filters: IServerFilter[]) => void;
}

const UsersTabFiltersPanel: React.FC<IUsersTabFiltersPanel> = ({
  id,
  active,
  filterByStatus = false,
  onHide,
  onApplyFilters,
}) => {
  const [currentFilters, setCurrentFilters] = useState<IUsersFilters>(initialUserFilters);
  const { data: jobTitles } = useQuery(GET_JOB_TITLES);
  const currentFiltersRef = useRef<IUsersFilters>(initialUserFilters);

  const handleApply = () => {
    const filters: IServerFilter[] = [];
    Object.entries(currentFilters).forEach(([key, value]) => {
      if (key === 'status' && value) {
        filters.push({ field: 'STATUS', operator: 'IN', values: [value] });
      }
      if (key === 'jobTitles' && value.length) {
        filters.push({ field: 'JOB_TITLE', operator: 'IN', values: value });
      }
    });
    onApplyFilters(filters);
    rudderstackTrackUserFilters();
    currentFiltersRef.current = currentFilters;
  };

  const rudderstackTrackUserFilters = () => {
    if (!stringArraysSameElements(currentFilters.jobTitles, currentFiltersRef.current.jobTitles)) {
      track(RudderstackEvent.APPLY_USERS_FILTERS, { filter_applied: UsersFilters.JOB_TITLE });
    }
    if (currentFilters.status !== currentFiltersRef.current.status) {
      track(RudderstackEvent.APPLY_USERS_FILTERS, { filter_applied: UsersFilters.STATUS });
    }
  };

  const onToggleJobTitle = (jobTitle: string, checked: boolean) => {
    if (checked) {
      setCurrentFilters({
        ...currentFilters,
        jobTitles: [...currentFilters.jobTitles, jobTitle],
      });
      return;
    }
    const index = currentFilters.jobTitles.findIndex((current) => current === jobTitle);
    currentFilters.jobTitles.splice(index, 1);
    setCurrentFilters({
      ...currentFilters,
      jobTitles: [...currentFilters.jobTitles],
    });
  };

  const handleReset = () => {
    setCurrentFilters(initialUserFilters);
  };

  return (
    <FilterSidebar
      onClose={onHide}
      isOpen={active}
      onApply={handleApply}
      onClear={handleReset}
      onClearTitle={t('action:reset')}
      onApplyTitle={t('action:apply')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      {filterByStatus && (
        <FilterSidebarItem title={t('user:filters.status')} hasBorderBottom>
          <RadioGroup
            selectedValue={currentFilters.status ?? ''}
            direction="column"
            id={`user-status-${id}`}
            onChange={(option) =>
              setCurrentFilters({
                ...currentFilters,
                status: option.value,
              })
            }
            options={[
              {
                label: t('all'),
                value: '',
              },
              ...Object.keys(USER_STATUS)
                .slice(0, -1)
                .map((option) => ({
                  label: t(`user:status-${USER_STATUS[option]}`),
                  value: option,
                })),
            ]}
          />
        </FilterSidebarItem>
      )}
      {jobTitles?.result && (
        <FilterSidebarItem title={t('user:job-title')} maxHeightContent="none">
          <CheckboxGroup
            direction="column"
            id={`job-title-${id}`}
            onChange={(newOption) => {
              onToggleJobTitle(newOption.value, newOption.checked);
            }}
            options={jobTitles.result.map((jobTitle: string) => ({
              checked: currentFilters.jobTitles.includes(jobTitle),
              label: t(`user:jobTitle.${jobTitle}`),
              value: jobTitle,
            }))}
          />
        </FilterSidebarItem>
      )}
    </FilterSidebar>
  );
};

export default UsersTabFiltersPanel;
