import { useMemo } from 'react';
import { IAttribute } from 'utils/constants/design-attributes';
import { useConverter } from 'utils/conversor';
import { DimensionUnit, getDimensionUnitForArrayConvertion } from 'utils/conversor/dimension';

export interface IChartDimensionUnits {
  x: DimensionUnit | null;
  y: DimensionUnit | null;
}

interface IProps {
  data: any;
  complexAttr: IAttribute;
  xAttr: IAttribute;
  yAttr: IAttribute;
}

const useDesignComparisonLineChart = ({ data, xAttr, yAttr, complexAttr }: IProps) => {
  const { converters, convertValueOf, getValueOf } = useConverter();

  const dimensionUnits = useMemo<IChartDimensionUnits>(() => {
    const initialState: IChartDimensionUnits = { x: null, y: null };
    if (!data || !xAttr || !yAttr) return initialState;
    const state = { ...initialState };

    const yKey = yAttr.key;

    if (typeof yKey === 'string' && !Array.isArray(yKey)) {
      if (yAttr.converter && yAttr.converter.name === 'dimension') {
        const yValues = data
          .map((e: any) => {
            const subArray = getValueOf(complexAttr, e);
            return !!subArray ? subArray.map((j: any) => j[yKey]) : [];
          })
          .flat();

        state.y = getDimensionUnitForArrayConvertion(yValues);
      }
    }

    return state;
  }, [data, xAttr, yAttr]);

  const formattedData = useMemo<any[]>(() => {
    if (!data) return [];

    let result: any[] = [];

    for (const item of data) {
      const arrayValue: any[] = getValueOf(complexAttr, item);
      const currentItemId = item.id;

      for (const itemData of arrayValue) {
        const x = getValueOf(xAttr, itemData);
        const y = convertValueOf(yAttr, itemData, {
          decimals: 2,
          ...(!!dimensionUnits.y && { dimension: dimensionUnits.y }),
        });
        const newYValue = { [currentItemId]: y };

        const xMatch = result.find((e) => e.x === x);
        if (!!xMatch) {
          result = result.filter((e) => e.x !== x);
          result.push({ ...xMatch, ...newYValue });
        } else {
          result.push({ x, ...newYValue });
        }
      }
    }

    return result;
  }, [data, dimensionUnits, converters]);

  return {
    dimensionUnits,
    formattedData,
  };
};

export default useDesignComparisonLineChart;
