import React from 'react';
import * as S from './teams-selector.styled';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { ITeam } from 'types/teams';
import { ALL_TEAMS_OPTION, OTHER_TEAMS_OPTION, PUBLIC_TEAMS_OPTION } from 'utils/constants/users';
import { useTranslation } from 'react-i18next';
import { IOption } from 'types/common';
import { Dropdown } from '@ratedpower/components';

export interface IProps {
  teams: ITeam[];
  onChangeTeam: (teamId: string) => void;
  padding?: string;
  addPublicOption?: boolean;
  addOthersOption?: boolean;
}

const TeamsSelector: React.FC<IProps> = ({ teams, onChangeTeam, padding, addOthersOption, addPublicOption }) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();

  const currentTeams = teams ? teams.map((team: ITeam) => ({ label: team.name, value: team.id })) : [];

  const teamsOptions = [
    ALL_TEAMS_OPTION(t),
    ...(addOthersOption && isAuthorized(Permission.TEAM_READ_FROM_USER_TEAM) ? [OTHER_TEAMS_OPTION(t)] : []),
    ...(addPublicOption ? [PUBLIC_TEAMS_OPTION(t)] : []),
    ...currentTeams,
  ];

  const companyHasTeams = !!teams?.length;

  const handleChangeTeam = (optionSelected: IOption[]) => {
    onChangeTeam(optionSelected[0].value);
  };

  return (
    <S.TeamsContainer $visible={companyHasTeams} $padding={padding}>
      <Dropdown options={teamsOptions} onChange={handleChangeTeam} value={[ALL_TEAMS_OPTION(t)]} isSearchable />
    </S.TeamsContainer>
  );
};

export default TeamsSelector;
