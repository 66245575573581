import React from 'react';
import useInView from 'react-cool-inview';
import { Loader } from './infinite-scroll.styled';

interface IInfiniteScroll {
  onLoad: () => void;
  loading: boolean;
}

const InfiniteScroll: React.FC<IInfiniteScroll> = ({ onLoad, loading }) => {
  const { observe } = useInView({
    onEnter: () => {
      onLoad();
    },
  });

  return <Loader ref={observe}>{loading && <div className="spinner" data-testid="spinner" />}</Loader>;
};

export default InfiniteScroll;
