import { useCallback, useReducer } from 'react';

const initialState = {
  // Array of previous state values updated each time we push a new state
  past: [],
  // Current state value
  present: null,
  // Will contain "future" state values if we undo (so we can redo)
  future: [],
};
// Our reducer function to handle state changes based on action
const reducer = (state: any, action: any) => {
  const { past, present, future } = state;
  switch (action.type) {
    case 'UNDO': {
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);
      return {
        past: newPast,
        present: previous,
        future: [present, ...future],
      };
    }
    case 'REDO': {
      const next = future[0];
      const newFuture = future.slice(1);
      return {
        past: [...past, present],
        present: next,
        future: newFuture,
      };
    }
    case 'SET': {
      const { newPresent } = action;
      if (newPresent === present) {
        return state;
      }
      const updatedPast = action.limit && past.length > action.limit ? past.slice(past.length - action.limit) : past;
      return {
        past: [...updatedPast, present],
        present: newPresent,
        future: [],
      };
    }
    case 'CLEAR': {
      const { initialPresent } = action;
      return {
        ...initialState,
        present: initialPresent,
      };
    }
  }
};
// Hook
export const useHistory = (initialPresent: any, limit?: number) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    present: initialPresent,
  });
  const canUndo = state.past.length !== 0;
  const canRedo = state.future.length !== 0;
  // Setup our callback functions
  // We memoize with useCallback to prevent unnecessary re-renders
  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: 'UNDO' });
    }
  }, [canUndo, dispatch]);
  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: 'REDO' });
    }
  }, [canRedo, dispatch]);
  const set = useCallback((newPresent?: any) => dispatch({ type: 'SET', newPresent, limit }), [dispatch]);
  const clear = (newPresent?: any) => {
    dispatch({ type: 'CLEAR', initialPresent: newPresent || initialPresent });
  };

  // If needed we could also return past and future state
  return { state: state.present, set, undo, redo, clear, canUndo, canRedo };
};
