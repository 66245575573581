import images from 'assets/images';
import kmlErrorImages from 'assets/images/design-process/kml-errors/index';

export interface ILocationError {
  title: string;
  subtitle: string;
  dynamicParams: string[];
  image: string;
  message: string;
}

interface IKmlOptions {
  [key: string]: ILocationError;
}

export const KML_ERRORS: IKmlOptions = {
  AREAS_INTERSECTING: {
    title: 'site.areaIntersecting.title',
    subtitle: 'site.areaIntersecting.subtitle',
    dynamicParams: ['area1', 'area2'],
    image: images.AreasIntersecting,
    message: 'site.areaIntersecting.message',
  },
  AREA_NO_POINT: {
    title: 'site.missingPoint.title',
    subtitle: 'site.missingPoint.subtitle',
    dynamicParams: ['point', 'area'],
    image: images.MissingPoint,
    message: 'site.missingPoint.message',
  },
  POINT_OUTSIDE: {
    title: 'site.pointOutside.title',
    subtitle: 'site.pointOutside.subtitle',
    dynamicParams: ['point', 'area'],
    image: images.PointOutside,
    message: 'site.pointOutside.message',
  },
  TOO_MANY_AREAS: {
    title: 'site.tooManyAreas.title',
    subtitle: 'site.tooManyAreas.subtitle',
    dynamicParams: ['type', 'areas'],
    image: images.ExceededAvailableAreas,
    message: 'site.tooManyAreas.message',
  },
  TOO_MANY_RESTRICTED_AREAS: {
    title: 'site.tooManyRestrictedAreas.title',
    subtitle: 'site.tooManyRestrictedAreas.subtitle',
    dynamicParams: ['type', 'areas'],
    image: images.ExceededRestrictedAreas,
    message: 'site.tooManyRestrictedAreas.message',
  },
  TOO_MANY_SUBSTATIONS: {
    title: 'site.tooManySubstations.title',
    subtitle: 'site.tooManySubstations.subtitle',
    dynamicParams: ['type', 'areas'],
    image: images.ExceededSubstations,
    message: 'site.tooManySubstations.message',
  },
  BAD_POLY_NAME: {
    title: 'site.badPolygonName.title',
    subtitle: 'site.badPolygonName.subtitle',
    dynamicParams: ['polygon'],
    image: images.PolygonNamedIncorrectly,
    message: 'site.badPolygonName.message',
  },
  KML_EMPTY: {
    title: 'site.kmlEmpty.title',
    subtitle: 'site.kmlEmpty.subtitle',
    dynamicParams: [],
    image: '',
    message: 'site.kmlEmpty.message',
  },
  SITE_TOO_FAR: {
    title: 'site.siteTooFar.title',
    subtitle: 'site.siteTooFar.subtitle',
    dynamicParams: ['min_distance', 'distance'],
    image: images.SiteTooFar,
    message: 'site.siteTooFar.message',
  },
  ILLEGAL_FEATURE: {
    title: 'site.illegalFeature.title',
    subtitle: 'site.illegalFeature.subtitle',
    dynamicParams: [],
    image: images.InvalidFeatureType,
    message: 'site.illegalFeature.message',
  },
  AREA_MISSING: {
    title: 'site.areaMissing.title',
    subtitle: 'site.areaMissing.subtitle',
    dynamicParams: ['polygon'],
    image: images.RequiredAreaMissing,
    message: 'site.areaMissing.message',
  },
  ST_MISSING: {
    title: 'site.areaMissing.title',
    subtitle: 'site.areaMissing.subtitle',
    dynamicParams: ['polygon'],
    image: images.RequiredSTMissing,
    message: 'site.areaMissing.message',
  },
  MAX_AREA_EXCEED: {
    title: 'site.maxAreaExceed.title',
    subtitle: 'site.maxAreaExceed.subtitle',
    dynamicParams: [],
    image: images.CombinedSurfaceAreaTooBig,
    message: 'site.maxAreaExceed.message',
  },
  AREA_COMPLEX: {
    title: 'site.areaComplex.title',
    subtitle: 'site.areaComplex.subtitle',
    dynamicParams: ['area'],
    image: images.AreaComplex,
    message: 'site.areaComplex.message',
  },
  GENERIC: {
    title: 'site.generic.title',
    subtitle: 'site.generic.subtitle',
    dynamicParams: [],
    image: '',
    message: 'site.generic.message',
  },
  MAX_BATTERY_AREA_EXCEED: {
    title: 'site.maxBatteryAreaExceeded.title',
    subtitle: 'site.maxBatteryAreaExceeded.subtitle',
    dynamicParams: [],
    image: '',
    message: 'site.maxBatteryAreaExceeded.message',
  },

  ONE_OHL_PER_SITE: {
    title: 'site.singleOHLlinePerSite.title',
    subtitle: 'site.singleOHLlinePerSite.subtitle',
    dynamicParams: [],
    image: '',
    message: 'site.singleOHLlinePerSite.message',
  },
  OHL_MIN_POINTS_NOT_MET: {
    title: 'site.minimumLinePoint.title',
    subtitle: 'site.minimumLinePoint.subtitle',
    dynamicParams: [],
    image: kmlErrorImages.OHLMinLinePoints,
    message: 'site.minimumLinePoint.message',
  },
  OHL_OUT_ST: {
    title: 'site.OHLOutsideFacility.title',
    subtitle: 'site.OHLOutsideFacility.subtitle',
    dynamicParams: [],
    image: kmlErrorImages.OHLlineStartsOutside,
    message: 'site.OHLOutsideFacility.message',
  },
  OHL_INTERSECTING: {
    title: 'site.OHLLineSelfIntersect.title',
    subtitle: 'site.OHLLineSelfIntersect.subtitle',
    dynamicParams: [],
    image: kmlErrorImages.OHLAreasIntersecting,
    message: 'site.OHLLineSelfIntersect.message',
  },
  OHL_LENGTH_EXCEEDED: {
    title: 'site.OHLLengthExceeded.title',
    subtitle: 'site.OHLLengthExceeded.subtitle',
    dynamicParams: [],
    image: '',
    message: 'site.OHLLengthExceeded.message',
  },
  BATTERY_AREA_INSIDE_INNER: {
    title: 'site.batteryAreaInsideInner.title',
    subtitle: '',
    dynamicParams: [],
    image: '',
    message: 'site.batteryAreaInsideInner.message',
  },
  MAX_LEVEL_EXCEEDED: {
    title: 'site.flexibleSchemaTooManyLevels.title',
    subtitle: '',
    dynamicParams: [],
    image: images.TooManyLevels,
    message: 'site.flexibleSchemaTooManyLevels.message',
  },
  MULTIPLE_NON_REDUNDANT_CONNECTION: {
    title: 'site.flexibleSchemaMultipleRedundantConnections.title',
    subtitle: '',
    dynamicParams: [],
    image: images.MultipleNonRedundantConnections,
    message: 'site.flexibleSchemaMultipleRedundantConnections.message',
  },
  FIRST_LEVEL_MISSING: {
    title: 'site.flexibleSchemaFirstLevelMissing.title',
    subtitle: '',
    dynamicParams: [],
    image: images.FirstLevelMissing,
    message: 'site.flexibleSchemaFirstLevelMissing.message',
  },
  BA_MISSING: {
    title: 'site.areaMissing.title',
    subtitle: 'site.areaMissing.subtitle',
    dynamicParams: ['polygon'],
    image: '',
    message: 'site.areaMissing.message',
  },
};

export const getMappedErrorType: any = ({
  errorComponents,
  errorType,
}: {
  errorComponents: string;
  errorType: string;
}) => {
  if (errorType === 'AREA_NO_POINT' && errorComponents.includes('AC')) {
    return KML_ERRORS.POINT_OUTSIDE;
  }
  if (errorType === 'TOO_MANY_AREAS') {
    if (errorComponents.includes('RESTRICTED')) {
      return KML_ERRORS.TOO_MANY_RESTRICTED_AREAS;
    }
    if (errorComponents.includes('SUBSTATION_AREA') || errorComponents.includes('ST')) {
      return KML_ERRORS.TOO_MANY_SUBSTATIONS;
    }
  }
  if (errorType === 'AREA_MISSING') {
    if (errorComponents.includes('BA')) {
      return KML_ERRORS.BA_MISSING;
    }
    if (!errorComponents.includes('AA')) {
      return KML_ERRORS.ST_MISSING;
    }
  }
  return KML_ERRORS[errorType] || KML_ERRORS.GENERIC;
};
