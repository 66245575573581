import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, FilterSidebar, FilterSidebarItem, RadioGroup, IRadioOption } from '@ratedpower/components';
import { ITeamsFilters } from 'types/teams';
import { IUserMinInfoDTO } from 'types/users';
import { filterCountProjectsOptions, filterCountMembersOptions, initialTeamFilters } from 'utils/teams';
import { useListTeamManagers } from './hooks/use-list-team-managers';
import { useListTeamCountries } from './hooks/use-list-team-countries';

interface ITeamsFilterPanel {
  isOpen: boolean;
  onApply: (filters: ITeamsFilters) => void;
  onClose: () => void;
}

const TeamsFilterPanel: React.FC<ITeamsFilterPanel> = ({ isOpen, onApply, onClose }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<ITeamsFilters>(initialTeamFilters);
  const teamManagers = useListTeamManagers();
  const countries = useListTeamCountries();

  const onToggleCheckboxFilter = (element: string, checked: boolean, objectKey: keyof ITeamsFilters) => {
    if (checked) {
      setFilters({
        ...filters,
        [objectKey]: [...filters[objectKey], element],
      });
      return;
    }
    const index = filters[objectKey].findIndex((current: string) => current === element);
    filters[objectKey].splice(index, 1);
    setFilters({
      ...filters,
      [objectKey]: [...filters[objectKey]],
    });
  };

  const onToggleRadioFilter = (element: string, objectKey: string) => {
    setFilters({
      ...filters,
      [objectKey]: [element],
    });
  };

  const onClear = () => {
    setFilters(initialTeamFilters);
    onApply(initialTeamFilters);
  };

  return (
    <FilterSidebar
      isOpen={isOpen}
      width="300px"
      onApply={() => onApply(filters)}
      onClose={onClose}
      onClear={onClear}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem title={t('user:teams.no-projects')} maxHeightContent="100%" hasBorderBottom>
        <RadioGroup
          id="countProjectsFilter"
          direction="column"
          selectedValue={filters.countProjects[0]}
          onChange={(newOption) => onToggleRadioFilter(newOption.value, 'countProjects')}
          options={filterCountProjectsOptions.map(
            (option) =>
              ({
                label: t(option.label as string),
                value: option.value,
              } as IRadioOption)
          )}
        />
      </FilterSidebarItem>
      <FilterSidebarItem title={t('user:teams.no-members')} maxHeightContent="100%" hasBorderBottom>
        <RadioGroup
          id="countMembersFilter"
          direction="column"
          selectedValue={filters.countMembers[0]}
          onChange={(newOption) => onToggleRadioFilter(newOption.value, 'countMembers')}
          options={filterCountMembersOptions.map(
            (option) =>
              ({
                label: t(option.label as string),
                value: option.value,
              } as IRadioOption)
          )}
        />
      </FilterSidebarItem>
      {!!teamManagers?.length && (
        <FilterSidebarItem title={t('user:teams.team-manager')} maxHeightContent="300px" hasBorderBottom>
          <CheckboxGroup
            id="teamMamangerFilter"
            direction="column"
            onChange={(newOption) => onToggleCheckboxFilter(newOption.value, newOption.checked, 'teamManagers')}
            options={teamManagers.map((teamManager: IUserMinInfoDTO) => ({
              label: teamManager.name + ' ' + teamManager.surname,
              value: teamManager.id,
              checked: filters.teamManagers.includes(teamManager.id),
            }))}
          />
        </FilterSidebarItem>
      )}
      {!!countries?.length && (
        <FilterSidebarItem title={t('filters.countries')} maxHeightContent="300px">
          <CheckboxGroup
            id="countriesFilter"
            direction="column"
            onChange={(newOption) => onToggleCheckboxFilter(newOption.value, newOption.checked, 'countries')}
            options={countries.map((country: string) => ({
              label: country,
              value: country,
              checked: filters.countries.includes(country),
            }))}
          />
        </FilterSidebarItem>
      )}
    </FilterSidebar>
  );
};

export default TeamsFilterPanel;
