export const getApiUrl = () => {
  const currentHostName = window.location.host;
  if (process.env.NODE_ENV === 'production' && (currentHostName.includes('.ratedpower.us') || currentHostName.includes('us.ratedpower'))) {
    if (currentHostName.endsWith('.ratedpower.us')) {
      return process.env.REACT_APP_API_URI_US_OLD;
    }
    return process.env.REACT_APP_API_URI_US;
  }
  if (currentHostName.endsWith('.ratedpower.com')) {
    return process.env.REACT_APP_API_URI_OLD;
  }
  return process.env.REACT_APP_API_URI;
};
