import React, { useEffect, useRef } from 'react';
import * as S from '../error-page-container.styled';
import ErrorFooter from '../error-footer/error-footer';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';
import { resetError } from 'redux/server-error-slice';
import { useDispatch } from 'react-redux';

const GeneralError: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current) {
      dispatch(resetError());
    } else {
      isMountedRef.current = true;
    }
  }, [pathname, isMountedRef]);

  return (
    <S.ErrorPageContainer>
      <S.ErrorIcon />

      <Typography weight="bold">
        {t(window.navigator.onLine ? 'errors:something-went-wrong' : 'errors:review-internet-conection')}
      </Typography>

      {window.navigator.onLine && (
        <>
          <Typography size="s">
            {t('errors:could-be-cache-problem')}
            {t('errors:try-fix-cache-problem')}
          </Typography>
          <ErrorFooter />
        </>
      )}
    </S.ErrorPageContainer>
  );
};

export default GeneralError;
