import React from 'react';
import * as S from './read-only-permissions-list.styled';
import { IRoleListItem } from 'types/role';
import { readOnlyPermissions } from './read-only-permissions';
import { useTranslation } from 'react-i18next';
import { BulletPoint, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';

interface IProps {
  element?: IRoleListItem;
}

const ReadOnlyPermissionsList: React.FC<IProps> = ({ element }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  if (!element) return <></>;

  return (
    <S.PermissionsContainer>
      {readOnlyPermissions(element?.name, t, theme).map(({ sectionTitle, permissions }) => (
        <S.PermissionGroup key={sectionTitle}>
          <Typography weight="bold">{sectionTitle}</Typography>
          {permissions.map((permission) => (
            <S.Permission key={permission.title}>
              <Typography weight="bold">{permission.title}</Typography>
              {permission.points?.map((point) => (
                <BulletPoint size="small" key={point} whiteSpace="inherit">
                  <Typography size="s">{point}</Typography>
                </BulletPoint>
              ))}
            </S.Permission>
          ))}
        </S.PermissionGroup>
      ))}
    </S.PermissionsContainer>
  );
};

export default ReadOnlyPermissionsList;
