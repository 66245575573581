import styled from 'styled-components';

export const DesignsCustomTooltipContainer = styled.div`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  padding: 1.2rem;
  border: 1px solid ${({ theme }) => theme.gray_300};

  .design-list {
    margin-top: 1.2rem;
    color: ${({ theme }) => theme.gray_dark};
  }
`;
