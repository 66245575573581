import { IUnits } from 'types/preferences.types';

export const lengthUnits: IUnits = {
  INTERNATIONAL: {
    L: {
      unit: 'km',
      toShow: (value) => value * 1e-3,
      toSend: (value) => value * 1e3,
    },
    M: {
      unit: 'm',
      toShow: (value) => value,
      toSend: (value) => value,
    },
    S: {
      unit: 'cm',
      toShow: (value) => value * 1e2,
      toSend: (value) => value * 1e-2,
    },
    XS: {
      unit: 'mm',
      toShow: (value) => value * 1e3,
      toSend: (value) => value * 1e-3,
    },
  },
  IMPERIAL: {
    L: {
      unit: 'mile',
      toShow: (value) => value / 1609,
      toSend: (value) => value * 1609,
    },
    M: {
      unit: 'ft',
      toShow: (value) => value / 0.3048,
      toSend: (value) => value * 0.3048,
    },
    S: {
      unit: 'ft',
      toShow: (value) => value / 0.3048,
      toSend: (value) => value * 0.3048,
    },
    XS: {
      unit: 'in',
      toShow: (value) => value / 0.0254,
      toSend: (value) => value * 0.0254,
    },
  },
};

export const areaUnits: IUnits = {
  INTERNATIONAL: {
    L: {
      unit: 'km²',
      toShow: (value) => value * 1e-6,
      toSend: (value) => value * 1e6,
    },
    M: {
      unit: 'm²',
      toShow: (value) => value,
      toSend: (value) => value,
    },
    S: {
      unit: 'cm²',
      toShow: (value) => value * 1e4,
      toSend: (value) => value * 1e-4,
    },
    XS: {
      unit: 'mm²',
      toShow: (value) => value * 1e6,
      toSend: (value) => value * 1e-6,
    },
  },
  IMPERIAL: {
    L: {
      unit: 'mi²',
      toShow: (value) => value / 2_590_002.59,
      toSend: (value) => value * 2_590_002.59,
    },
    M: {
      unit: 'ft²',
      toShow: (value) => value / 9.2903e-2,
      toSend: (value) => value * 9.2903e-2,
    },
    S: {
      unit: 'ft²',
      toShow: (value) => value / 9.2903e-2,
      toSend: (value) => value * 9.2903e-2,
    },
    XS: {
      unit: 'in²',
      toShow: (value) => value / 6.4516e-4,
      toSend: (value) => value * 6.4516e-4,
    },
  },
};
