import React from 'react';
import { IconButton, Typography } from '@ratedpower/components';
import { CloneSvg, FavouriteOutlineSvg, FavouriteSvg } from 'assets/icons';
import * as S from 'pages/home/home-designs/home-project-item/home-projects-designs/home-projects-designs.styled';
import { ButtonContainer } from 'pages/home/home-designs/home-project-item/home-projects-designs/home-projects-designs.styled';
import { useTheme } from 'styled-components';
import { IBessDesign, IDesign } from 'types/design';
import { useTranslation } from 'react-i18next';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';

interface IHomeDesignItemHeader {
  design: IDesign | IBessDesign;
  handleSwitchFavorite: (item: IDesign | IBessDesign) => Promise<void>;
  handleClone: (item: IDesign | IBessDesign) => Promise<void>;
}

const HomeDesignItemHeader = ({ design, handleSwitchFavorite, handleClone }: IHomeDesignItemHeader) => {
  const theme = useTheme(),
    { t } = useTranslation(),
    canCloneDesign = useCanCloneDesigns(),
    favoriteFontColor = design.isFavorite ? theme.v2.icon.primary.active : theme.v2.icon.primary.default;

  const handleClick =
    (handler: (item: IDesign | IBessDesign) => Promise<void>) =>
    (e: any): void => {
      e.preventDefault();
      handler(design);
    };

  const handleSwitchFavoriteClick = handleClick(handleSwitchFavorite);

  const handleCloneClick = handleClick(handleClone);

  return (
    <S.HomeDesignItemHeader>
      <Typography size={'m'} weight={'bold'} className="title" nowrap>
        {design.number}. {design.name}
      </Typography>
      <ButtonContainer>
        <IconButton
          disabled={design.simulationResult === 'IN_PROGRESS'}
          onClick={handleSwitchFavoriteClick}
          Icon={design.isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
          fontColor={favoriteFontColor}
          tooltip={t('favorite')}
          size={'m'}
        />
        <IconButton
          disabled={!canCloneDesign}
          tooltip={t('action:clone')}
          onClick={handleCloneClick}
          Icon={CloneSvg}
          fontColor={theme.v2.icon.primary.default}
        />
      </ButtonContainer>
    </S.HomeDesignItemHeader>
  );
};

export default HomeDesignItemHeader;
