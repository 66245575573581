import React, { useState } from 'react';
import * as S from 'pages/notifications/notifications.styled';
import NotificationsTabsBar from './notifications-tabs-bar/notifications-tabs-bar';
import { useGetNotifications } from 'components/notifications/hooks/use-get-notifications';
import { NotificationTab, notificationTabs } from 'utils/constants/notifications';
import NotificationsTopBar from 'components/notifications/notifications-topbar/notifications-topbar';
import NotificationsWrapper from 'components/notifications/notifications-wrapper/notifications-wrapper';

const Notifications: React.FC = () => {
  const [tabActive, setTabActive] = useState(notificationTabs[0].id),
    { notifications, error, loading, paginationComponent, currentPage } = useGetNotifications(
      tabActive as NotificationTab
    );

  return (
    <>
      <NotificationsTopBar />
      <S.Container>
        <NotificationsTabsBar currentPage={currentPage} tabActive={tabActive} onClickTab={setTabActive} />
        <NotificationsWrapper
          notifications={notifications}
          loading={loading}
          paginationComponent={paginationComponent}
          error={error}
          isNotificationsPanel
        />
      </S.Container>
    </>
  );
};

export default Notifications;
