import React from 'react';
import { views } from 'utils/constants/top-bar';
import { ListSvg, MapViewSvg, MixedViewSvg } from 'assets/icons';
import { StatusSVGWrapper } from '@ratedpower/components';
import NewProjectButton from 'pages/projects/topbar-projects/new-project-button';

const projectsViews = [
  { Icon: ListSvg, value: views.LIST },
  { Icon: MixedViewSvg, value: views.LIST_MAP },
  { Icon: MapViewSvg, value: views.MAP },
];

interface IProps {
  createProject?: () => void;
  changeView: (view: string) => void;
  view: string;
}

const TopbarRightSection: React.FC<IProps> = ({ createProject, changeView, view }) => {
  return (
    <>
      {projectsViews.map((option) => (
        <StatusSVGWrapper
          icon={option.Icon}
          active={option.value === view}
          onClick={() => changeView(option.value)}
          size="m"
          key={option.value}
        />
      ))}
      <NewProjectButton textKey={'project:new-project'} createProject={createProject} />
    </>
  );
};

export default TopbarRightSection;
