import {
  GetCombinedProjectDesignsByUserResponse,
  GetCombinedProjectDesignsResponse,
  IBessDesign,
  IDesign,
  PageInfo,
  SIMULATION_CATEGORY,
} from 'types/design';

export function combineSimulations(response: GetCombinedProjectDesignsResponse): {
  result: { content: (IDesign | IBessDesign)[]; pageInfo: PageInfo };
  project: { name: string; teamId: string; publishedDate?: Date };
} {
  if (!response) {
    return {
      result: { content: [], pageInfo: { totalPages: 0, pageNumber: 0 } },
      project: { name: '', teamId: '' },
    };
  }
  const { projectSimulations, projectStandAloneBessSimulations } = response;

  const pvPageInfo = projectSimulations?.pageInfo;
  const bessPageInfo = projectStandAloneBessSimulations?.pageInfo;
  const totalPages = (pvPageInfo ? pvPageInfo.totalPages : 0) + (bessPageInfo ? bessPageInfo.totalPages : 0);
  const pageInfo: PageInfo = {
    totalPages,
    pageNumber: 0,
  };

  const pvResults = projectSimulations ? projectSimulations.result : [];
  const bessResults = projectStandAloneBessSimulations ? projectStandAloneBessSimulations.result : [];

  const pvResultsWithCategory = pvResults.map((result: IDesign) => ({
    ...result,
    simulationCategory: SIMULATION_CATEGORY.PV,
  }));
  const bessResultsWithCategory = bessResults.map((result: IBessDesign) => ({
    ...result,
    simulationCategory: SIMULATION_CATEGORY.STANDALONE_BATTERY,
  }));

  return {
    result: {
      content: [...pvResultsWithCategory, ...bessResultsWithCategory].sort(
        (a: IDesign | IBessDesign, b: IDesign | IBessDesign) => {
          return new Date(a.publishedDate) > new Date(b.publishedDate) ? -1 : 1;
        }
      ),
      pageInfo,
    },
    project: response.project,
  };
}

export function combineSimulationsByUser(response: GetCombinedProjectDesignsByUserResponse): {
  result: { content: (IDesign | IBessDesign)[]; pageInfo: PageInfo };
  project: { name: string; teamId: string; publishedDate?: Date };
} {
  if (!response) {
    return {
      result: { content: [], pageInfo: { totalPages: 0, pageNumber: 0 } },
      project: { name: '', teamId: '' },
    };
  }
  const { projectSimulationsByUser, projectStandAloneBessSimulationsByUser } = response;
  const pvPageInfo = projectSimulationsByUser?.pageInfo;
  const bessPageInfo = projectStandAloneBessSimulationsByUser?.pageInfo;
  const totalPages = (pvPageInfo ? pvPageInfo.totalPages : 0) + (bessPageInfo ? bessPageInfo.totalPages : 0);
  const pageInfo: PageInfo = {
    totalPages,
    pageNumber: 0,
  };

  const pvResults = response.projectSimulationsByUser ? response.projectSimulationsByUser.result : [];
  const bessResults = response.projectStandAloneBessSimulationsByUser
    ? response.projectStandAloneBessSimulationsByUser.result
    : [];
  const pvResultsWithCategory = pvResults.map((result: IDesign) => ({ ...result, category: SIMULATION_CATEGORY.PV }));
  const bessResultsWithCategory = bessResults.map((result: IBessDesign) => ({
    ...result,
    simulationCategory: SIMULATION_CATEGORY.STANDALONE_BATTERY,
  }));

  return {
    result: {
      content: [...pvResultsWithCategory, ...bessResultsWithCategory].sort(
        (a: IDesign | IBessDesign, b: IDesign | IBessDesign) => {
          return new Date(a.publishedDate) > new Date(b.publishedDate) ? 1 : -1;
        }
      ),
      pageInfo,
    },
    project: response.project,
  };
}
