import styled from 'styled-components';
import { Tag, Typography } from '@ratedpower/components';

export const DesignCardContainer = styled.div`
  background: ${({ theme }) => theme.v2.bg.primary.default};
  border-radius: ${({ theme }) => theme.v2.radius.s};
  box-shadow: 0px 4px 8px 0px ${({ theme }) => theme.v2.elevation.neutral900[16]};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;

  &.selected {
    background: ${({ theme }) => theme.v2.bg.primary.hover};

    .card-image {
      opacity: 1;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.v2.bg.primary.hover};
    transition: all 0.2s ease-out;

    .card-image {
      opacity: 1;
    }
  }

  p {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    color: ${({ theme }) => theme.gray_600};
  }

  .card-image {
    opacity: 0.8;
    height: 200px;

    img {
      border-top-left-radius: ${({ theme }) => theme.v2.radius.s};
      border-top-right-radius: ${({ theme }) => theme.v2.radius.s};
    }
  }

  .card-detail-row {
    padding: 0 12px;
    height: 310px;
    min-height: 300px;
    overflow: hidden;
    transition: height 250ms ease-out, min-height 250ms ease-out, overflow 250ms ease-out, transform 250ms ease-out;

    &.hidden {
      height: 0;
      min-height: 0;
      overflow: hidden;
    }
  }
`;

export const StyledTag = styled(Tag).attrs<{ variant: string }>(({ variant }) => ({
  color: variant,
}))`
  right: ${({ theme }) => theme.v2.spacing.xs};
  top: ${({ theme }) => theme.v2.spacing.xs};
  width: max-content;
  position: absolute;
`;

export const CardHeader = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

export const CardHeaderTitleRow = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.xs};
  display: grid;
  width: 100%;
  grid-template-columns: max-content max-content 1fr max-content;

  & > :nth-child(3) {
    grid-column: 4;
  }
`;

export const CardHeaderMainRow = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
  justify-content: space-around;
  display: flex;
`;

export const ProjectTitle = styled(Typography).attrs({
  size: 'l',
  weight: 'bold',
})`
  text-align: left !important;
  max-width: 200px;
`;

export const MainRowTypography = styled(Typography).attrs({
  size: 's',
})`
  max-width: 25%;
  width: max-content !important;
`;

export const CardHeaderAuthorRow = styled.div`
  color: ${({ theme }) => theme.v2.text.secondary.default};
  display: flex;

  & > :nth-child(2) {
    text-align: right;
  }
`;
