import { IProjectListItem } from 'types/projects';
import { point } from '@turf/helpers';
import { GeoJSONFeatureCollection } from 'utils/maps/geojson';
import { useMemo } from 'react';

export const useHomeProjectsMapBox = (projects: IProjectListItem[]): GeoJSONFeatureCollection | null => {
  return useMemo(() => {
    if (projects.length === 0) {
      return null;
    }
    const points = projects.map((elem: IProjectListItem) => {
      const newPoint: any = point([elem.longitude, elem.latitude], {
        data: {
          content: {
            name: elem.name,
            id: elem.id,
            city: elem.city,
            country: elem.country,
          },
          dataType: 'HOME_PAGE_PROJECT_DATA',
        },
      });
      return newPoint;
    });

    return {
      type: 'FeatureCollection',
      features: points,
    };
  }, [projects]);
};
