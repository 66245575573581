import styled from 'styled-components';

export const SortAttributeLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  white-space: nowrap;
  cursor: pointer;
  padding: ${({ theme }) => theme.v2.spacing.s};
`;
