import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IServerErrorState {
  error?: string;
}

const initialState: IServerErrorState = {};

const serverErrorSlice = createSlice({
  initialState,
  name: 'serverError',
  reducers: {
    setError(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload;
    },
    resetError(state) {
      state.error = undefined;
    },
  },
});

export const { setError, resetError } = serverErrorSlice.actions;

export default serverErrorSlice.reducer;
