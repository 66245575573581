import React from 'react';
import * as S from './comparisson-bar.styled';
import { IconsWrapper } from './comparisson-bar.styled';
import { useTranslation } from 'react-i18next';
import { ArrowDownSvg, ArrowUpSvg, FolderImportSvg, TrashSvg } from 'assets/icons';
import { useTheme } from 'styled-components';
import { IBessDesign, IDesign, SIMULATION_CATEGORY } from 'types/design';
import {
  Button,
  Checkbox,
  IIconButtonOption,
  ISort,
  StatusSVGWrapper,
  Toggle,
  Tooltip,
  Typography,
} from '@ratedpower/components';
import { COMPARISON_DESIGNS_LIMIT } from 'utils/constants/design-comparison';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';

interface IProps {
  selectedDesigns: (IDesign | IBessDesign)[];
  sortBy: ISort;
  viewDetailed: boolean;
  handleArchive: () => void;
  handleCompare: () => void;
  handleDelete: () => void;
  onClickViewDetailed: () => void;
  onSort: () => void;
  toggleAll: () => void;
}

interface ComparissonBarIIconButtonOption extends IIconButtonOption {
  onClick: () => void;
}

const ComparissonBar: React.FC<IProps> = ({
  selectedDesigns,
  sortBy,
  viewDetailed,
  handleArchive,
  handleCompare,
  handleDelete,
  onClickViewDetailed,
  onSort,
  toggleAll,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { canEditDesign } = useCanEditDesigns();
  const numSelectedDesigns = selectedDesigns.length;

  function isAnyDesignWithErrorSelected() {
    return [...selectedDesigns].some((e) => e.status === 'ERROR');
  }

  const numOfSelectedDesignsIsOverTheLimit = numSelectedDesigns > COMPARISON_DESIGNS_LIMIT;

  const selectionIncludesBessDesigns = (selectedDesigns: (IDesign | IBessDesign)[]): boolean => {
    return selectedDesigns.map((design) => design.simulationCategory).includes(SIMULATION_CATEGORY.STANDALONE_BATTERY);
  };

  const compareBtnIsDisabled =
    numSelectedDesigns < 2 ||
    isAnyDesignWithErrorSelected() ||
    numOfSelectedDesignsIsOverTheLimit ||
    selectionIncludesBessDesigns(selectedDesigns);

  const canEditAllDesigns = selectedDesigns.every((design: IDesign | IBessDesign) =>
    canEditDesign(design.createdBy.id, design.projectTeamId)
  );

  const iconsAreDisabled = !numSelectedDesigns || !canEditAllDesigns,
    iconsTooltip = !numSelectedDesigns
      ? t('design:select-1-or-more-designs-to-delete-archive')
      : !canEditAllDesigns
      ? t('design:insufficient-permissions-to-execute-action')
      : undefined,
    comparissonIcons: ComparissonBarIIconButtonOption[] = [
      {
        Icon: TrashSvg,
        value: 'trash',
        disabled: iconsAreDisabled,
        tooltip: iconsTooltip,
        onClick: handleDelete,
      },
      {
        Icon: FolderImportSvg,
        value: 'archive',
        disabled: iconsAreDisabled,
        tooltip: iconsTooltip,
        onClick: handleArchive,
      },
    ],
    selectedDesignsText =
      numSelectedDesigns > 0
        ? t('design:n-designs-selected', { count: numSelectedDesigns })
        : t('design:n-designs-selected_zero'),
    thereAreDesignsSelected = numSelectedDesigns > 0;

  const comparisonTooltipText = selectionIncludesBessDesigns(selectedDesigns)
    ? t('design:bess-comparison-disabled')
    : selectedDesignsText;

  return (
    <S.Container>
      <S.LeftContainer>
        <Checkbox id="comparisson-bar" checked={thereAreDesignsSelected} onChange={() => toggleAll()} />
        {thereAreDesignsSelected && (
          <S.SelectedDesignsWrapper>
            <Typography>{selectedDesignsText}</Typography>
          </S.SelectedDesignsWrapper>
        )}
        <S.VerticalSeparator />
        {thereAreDesignsSelected && (
          <>
            <Tooltip place="bottom" description={comparisonTooltipText}>
              <Button
                disabled={compareBtnIsDisabled}
                variant="accent"
                text={t('action:compare')}
                onClick={() => handleCompare()}
                size="medium"
              />
            </Tooltip>
            <S.VerticalSeparator />
            <IconsWrapper>
              {comparissonIcons.map((icon) => (
                <StatusSVGWrapper
                  active
                  size="m"
                  icon={icon.Icon}
                  onClick={icon.onClick}
                  tooltip={{
                    text: icon.tooltip ?? '',
                    place: 'bottom',
                  }}
                  key={icon.value}
                  disabled={icon.disabled}
                  color={theme.v2.icon.accent.default}
                />
              ))}
            </IconsWrapper>
            <S.VerticalSeparator />
          </>
        )}

        <Toggle
          isOn={viewDetailed}
          label={t('design:designs-details')}
          name="designDetails"
          onToggle={() => onClickViewDetailed()}
        />
      </S.LeftContainer>
      <Button
        variant="ghost"
        text={t('date')}
        Icon={sortBy.ascendent ? ArrowUpSvg : ArrowDownSvg}
        onClick={onSort}
        iconPosition="right"
      />
    </S.Container>
  );
};

export default ComparissonBar;
