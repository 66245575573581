import React from 'react';
import useForm, { IForm } from 'utils/hooks/use-form';
import { useTranslation } from 'react-i18next';
import { jobTitleOptions } from 'utils/constants/users';
import { getOptionForValue } from 'utils/selector';
import ProfileHeader from './profile-header';
import { InputSelect, InputText, LabelWrapper, Separator } from '@ratedpower/components';
import { ProfileSection } from './profile.styled';
import ProfileFooter from 'pages/profile/profile-footer';
import UnsavedChangesModal from 'components/modals/unsaved-changes-modal/unsaved-changes-modal';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { useProfileForm } from './hooks/use-profile-form';

const ProfileForm: React.FC = () => {
  const { t } = useTranslation();
  const { profile } = useSelector((state: RootState) => state.currentUser);
  const { inputs, handleInputChangeManual, handleSubmit, formError } = useForm(handleSaveProfile, profile as IForm);
  const { profileForm, setProfileForm, handleEditProfile } = useProfileForm(profile || undefined);

  const isEditable = !!profileForm;
  const showProfileForm = (show: boolean) => () => setProfileForm(show);

  function handleSaveProfile() {
    const { name, lastname, jobTitle } = inputs;
    const editedUser = { name, lastname, jobTitle };
    handleEditProfile(editedUser);
  }

  const isFormErrorCode = !!formError?.code;
  const jobTitleOption = getOptionForValue(inputs.jobTitle, jobTitleOptions);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ProfileSection>
          <ProfileHeader title={t('user:my-profile')} onEdit={showProfileForm(true)} isEditable={isEditable} />
          <LabelWrapper htmlFor="name" title={t('user:name-person')} size="l">
            <InputText
              helperText={formError?.code ?? ''}
              warning={isFormErrorCode}
              value={inputs.name || ''}
              name="name"
              onChange={(value) => handleInputChangeManual('name', value)}
              readOnly={!isEditable}
              size="l"
            />
          </LabelWrapper>
          <Separator />
          <LabelWrapper htmlFor="lastname" title={t('user:lastname')} size="l">
            <InputText
              helperText={formError?.code ?? ''}
              warning={isFormErrorCode}
              value={inputs.lastname || ''}
              name="lastname"
              onChange={(value) => handleInputChangeManual('lastname', value)}
              readOnly={!isEditable}
              size="l"
            />
          </LabelWrapper>
          <Separator />
          <LabelWrapper htmlFor="jobTitle" title={t('user:job-title')} size="l">
            <InputSelect
              value={jobTitleOption ? [jobTitleOption] : undefined}
              options={jobTitleOptions}
              name="jobTitle"
              onChange={(option) => handleInputChangeManual('jobTitle', option[0].value)}
              disabled={!isEditable}
              size="l"
            />
          </LabelWrapper>
          <Separator />
          <LabelWrapper title={t('user:email')} size="l">
            <InputText
              helperText={formError?.email ?? ''}
              value={inputs.email || ''}
              name="email"
              onChange={(value) => handleInputChangeManual('email', value)}
              readOnly
              size="l"
            />
          </LabelWrapper>
          <Separator />
          {isEditable && <ProfileFooter onDiscard={showProfileForm(false)} handleSaveProfile={handleSaveProfile} />}
        </ProfileSection>
      </form>
      <UnsavedChangesModal hasUnsavedChanges={isEditable} />
    </>
  );
};

export default ProfileForm;
