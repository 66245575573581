import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, Typography } from '@ratedpower/components';

const ErrorRetrievingData: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Notification
      status="error"
      hideCloseButton
      description={<Typography>{t('errors:error-retrieving-data')}</Typography>}
    />
  );
};

export default ErrorRetrievingData;
