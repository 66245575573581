import React from 'react';
import * as S from 'pages/notifications/notifications.styled';
import { INotification } from 'types/notifications';
import UserNotification from 'components/notifications/user-notification/user-notification';
import QueryStatusWrapper from 'components/query-status-wrapper/query-status-wrapper';

interface IProps {
  notifications: INotification[];
  loading: boolean;
  isNotificationsPanel: boolean;
}

const NotificationsList: React.FC<IProps> = ({ notifications, loading, isNotificationsPanel }: IProps) => {
  const notificationsNotReady: boolean = loading || !notifications;

  return (
    <S.NotificationsContainer>
      <QueryStatusWrapper loading={notificationsNotReady}>
        {notifications.map((notification: INotification) => (
          <UserNotification
            key={notification.id}
            notification={notification}
            isNotificationsPanel={isNotificationsPanel}
          />
        ))}
      </QueryStatusWrapper>
    </S.NotificationsContainer>
  );
};

export default NotificationsList;
