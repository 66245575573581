import { TypographyH1 } from '@ratedpower/components';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  ${TypographyH1} {
    margin-bottom: 16px;
  }
`;

export const CardsContainer = styled.div<{ $fullHeight: boolean }>`
  height: ${({ $fullHeight }) => ($fullHeight ? 'calc(100% - 100px)' : 'calc(100% - 120px)')};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px;

  > * {
    margin-bottom: 16px;
  }
`;
