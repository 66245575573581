import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GET_USER_PROJECTS } from 'services/projects-gql';
import { GET_COMPANY_PROJECTS } from 'services/support-gql';
import {
  ADD_PROJECTS_TO_TEAM,
  GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM,
  GET_TEAMS,
  GET_TEAMS_BY_COMPANY,
} from 'services/team-gql';
import { useToasts } from 'utils/hooks/use-toasts';

export const useAddProjectsToTeam = (teamId: string, callback?: () => void) => {
  const { t } = useTranslation();
  const { id: companyId } = useParams<{ id: string }>();
  const { addErrorToast, addSuccessToast } = useToasts();

  const [addProjects] = useMutation(ADD_PROJECTS_TO_TEAM, {
    refetchQueries: companyId
      ? [GET_TEAMS_BY_COMPANY, GET_COMPANY_PROJECTS, GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM]
      : [GET_TEAMS, GET_USER_PROJECTS, GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM],
    onCompleted: () => {
      addSuccessToast(t('user:teams.project-succesfully-added'));
      if (callback) callback();
    },
    onError: () => {
      addErrorToast(t('user:teams.error-adding-project'));
    },
  });

  const addProjectsToTeam = (projectIds: string[]) => {
    addProjects({ variables: { teamId, projectIds } });
  };

  return {
    addProjectsToTeam,
  };
};
