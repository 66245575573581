import React from 'react';
import InfiniteScroll from 'components/infinite-scroll/infinite-scroll';
import CarouselPagination from 'components/carousel-pagination/carousel-pagination';
import { Pagination } from '@ratedpower/components';

export const usePaginationWithQuery = (totalPages: any, currentPage: number, setPagination: any, loading: boolean) => {
  const updateTotalPages = (newTotalPages: number) => {
    setPagination({ currentPage, totalPages: newTotalPages });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage <= totalPages) {
      setPagination({ totalPages, currentPage: newPage });
    }
  };

  const loadMore = () => {
    if (loading) return;
    handlePageChange(currentPage + 1);
  };

  return {
    totalPages,
    currentPage,
    updateTotalPages,
    handlePageChange,
    loadMore,
    paginationComponent:
      totalPages < 1 ? (
        <></>
      ) : (
        <div key="pagination-component" className="pagination">
          <Pagination {...{ currentPage, totalPages }} onChange={handlePageChange} key={totalPages} />
        </div>
      ),
    infiniteScrollComponent:
      totalPages > 1 && currentPage < totalPages ? (
        <div key="infinite-scroll">
          <InfiniteScroll onLoad={loadMore} loading={loading} />
        </div>
      ) : (
        ''
      ),
    // should only be used in a 'relative' container
    carouselPaginationComponent: <CarouselPagination {...{ currentPage, totalPages }} onChange={handlePageChange} />,
  };
};
