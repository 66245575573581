import React from 'react';
import AddProjectsModal from '../../components/add-projects-modal';
import { useTranslation } from 'react-i18next';
import AddMembersModal from '../../components/add-members-modal';
import DeleteTeamModal from '../../components/delete-team-modal';
import { useTeamsProvider } from 'pages/teams/teams-data';
import { useLocation, useParams } from 'react-router-dom';

const TeamModals: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { teamId } = useParams<{ teamId: string }>();
  const routeState = location.state as { teamName: string };

  const { addProjectsModal, addMembersModal, deleteTeamModal } = useTeamsProvider();

  return (
    <>
      {addProjectsModal.isOpen && (
        <AddProjectsModal
          selectedTeamId={addProjectsModal.data?.id || teamId}
          isActive={addProjectsModal.isOpen}
          title={t('user:teams.add-projects-to', { team: addProjectsModal.data?.teamName || routeState?.teamName })}
          disableClickOutside
          onClose={() => addProjectsModal.setModalOpen(false)}
        />
      )}
      {addMembersModal.isOpen && (
        <AddMembersModal
          title={t('user:teams.add-members-to-team', { team: addMembersModal.data?.teamName || routeState?.teamName })}
          isActive={addMembersModal.isOpen}
          currentTeamId={addMembersModal.data?.id || teamId}
          disableClickOutside
          onClose={() => addMembersModal.setModalOpen(false)}
          width="big"
        />
      )}
      {deleteTeamModal.isOpen && (
        <DeleteTeamModal
          title={t('user:teams.delete-team-title', { team: deleteTeamModal.data?.teamName || routeState?.teamName })}
          description={t('user:teams.delete-team-description', {
            team: deleteTeamModal.data?.teamName || routeState?.teamName,
          })}
          isActive={deleteTeamModal.isOpen}
          selectedTeamId={deleteTeamModal.data?.id || teamId}
          disableClickOutside
          status="error"
          onClose={() => deleteTeamModal.setModalOpen(false)}
          width="small"
        />
      )}
    </>
  );
};

export default TeamModals;
