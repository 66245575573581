import React, { useState } from 'react';
import * as S from './company-logo.styled';
import UploadFile from '../upload/upload-file';
import classNames from 'classnames';
import { DefaultLogo, PencilSvg } from 'assets/icons';
import { RemoteImage } from 'utils//hooks/use-remote-image';
import { useTranslation } from 'react-i18next';

export interface ICompanyLogoProps {
  url?: string;
  canSelect: boolean;
  onSelect?: (file: File) => void;
  hideInfo?: boolean;
  customHeight?: string;
  customWidth?: string;
}

const CompanyLogo: React.FC<ICompanyLogoProps> = ({
  url,
  canSelect,
  onSelect,
  hideInfo,
  customHeight,
  customWidth,
}) => {
  const { t } = useTranslation();

  const [logoUrl, setLogoUrl] = useState(url);

  const onEdit = async (file: File) => {
    if (file.type !== 'image/png') {
      return;
    }
    if (file.size > 1024 * 1024 * 3) {
      return;
    } // Size longer than 3MB

    // Show preview (opthimistisc UI)
    const reader = new FileReader();
    reader.onload = (e: any) => {
      setLogoUrl(e.target.result);
    };
    reader.readAsDataURL(file);

    onSelect && onSelect(file);
  };

  return (
    <S.LogoContainer $customHeight={customHeight} $customWidth={customWidth}>
      <div
        className={classNames('logo-img', {
          default: !logoUrl,
        })}
      >
        {logoUrl ? <RemoteImage url={logoUrl} alt="Company Logo" /> : <DefaultLogo className="default-img" />}
        {canSelect && (
          <S.InputFileLabel>
            <UploadFile accept="image/png" onFileChange={(file: File) => onEdit(file)} />
            <PencilSvg />
          </S.InputFileLabel>
        )}
      </div>
      {!hideInfo && (
        <ul className="logo-info">
          <li>{t('company:logo-used-at-documentation')}</li>
          <li>{t('company:accepted-format-png')}</li>
        </ul>
      )}
    </S.LogoContainer>
  );
};

export default CompanyLogo;
