import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, FilterSidebar, FilterSidebarItem } from '@ratedpower/components';
import { IOverlayFilter } from 'utils/hooks/use-filters';

interface IProps {
  filters: IOverlayFilter[];
  isOpen: boolean;
  onClear: () => void;
  onApply: () => void;
  onSelect: (filter: string, value: any, checked: boolean) => void;
  toggleOverlay: () => void;
}

const ProjectFilesFilter = ({ filters, isOpen, onApply, onSelect, onClear, toggleOverlay }: IProps) => {
  const { t } = useTranslation();
  return (
    <FilterSidebar
      onApply={onApply}
      onClear={onClear}
      isOpen={isOpen}
      onClose={toggleOverlay}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <>
        {filters.map((filter, key) => (
          <FilterSidebarItem hasBorderBottom={key !== filters.length - 1} title={filter.title} key={key}>
            <CheckboxGroup
              id={filter.code}
              direction="column"
              options={filter.options.map((option) => ({
                label: t(option.label as string),
                value: option.code,
                checked: option.checked,
              }))}
              onChange={(newOption) => {
                onSelect(filter.code, newOption.value, newOption.checked);
              }}
            />
          </FilterSidebarItem>
        ))}
      </>
    </FilterSidebar>
  );
};

export default ProjectFilesFilter;
