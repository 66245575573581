import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useDesignComparisonData } from './design-comparison-state';
import { Breadcrumb, Button, TopbarWrapper } from '@ratedpower/components';
import { useNavigate } from 'react-router-dom';
import { PlusSvg } from 'assets/icons';

interface IProps {
  onADesign: () => void;
}

const TopbarDesignComparison: React.FC<IProps> = ({ onADesign }) => {
  const { t } = useTranslation(),
    { project } = useDesignComparisonData(),
    navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{t('design:comparison')}</title>
      </Helmet>
      <TopbarWrapper
        type="secondary"
        left={
          project.name && (
            <Breadcrumb
              prevPages={[
                { name: project.name, url: `/projects/${project.id}`, onClick: (url) => url && navigate(url) },
              ]}
              currentPage={{ name: t('design:comparison') }}
            />
          )
        }
        right={<Button text={t('design:add-designs-to-compare')} Icon={PlusSvg} onClick={onADesign} />}
      />
    </>
  );
};

export default TopbarDesignComparison;
