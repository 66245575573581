import styled from 'styled-components';
import { DesignGridItemContainer } from '../design-grid-item.styled';

export const DesignItemLoadingContainer = styled(DesignGridItemContainer)`
  border-radius: ${({ theme }) => theme.v2.radius.s};
  cursor: default;

  .image-space {
    height: 200px;

    span[class*='react-loading-skeleton'] {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      height: 100%;
    }
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const DataWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 16px;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
  padding: ${({ theme }) => theme.v2.spacing.m};
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
