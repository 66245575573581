import styled from 'styled-components';

export const CompanyContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  .banner-description {
    display: flex;
    column-gap: ${({ theme }) => theme.v2.spacing.xxs};
  }
`;

export const BannerContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.v2.spacing.l} ${theme.v2.spacing.l} ${theme.v2.spacing.l}`};
`;
