const hiddenByDefaultLayers = [
  'LV_DC_STRING_CABLES',
  'LV_DC_CABLES',
  'LV_AC_CABLES',
  'LV_TRENCHES',
  'LV_SYSTEM',
  'TRENCHES',
];

export const checkIfLayerIsHidden = (layer: string) => {
  return !hiddenByDefaultLayers.includes(layer);
};
