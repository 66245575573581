import images from 'assets/images';

export const satelliteOptions = [
  {
    label: 'satellite',
    value: 'satellite-v9',
    image: images.SatelliteView,
  },
  {
    label: 'satellite-streets',
    value: 'satellite-streets-v11',
    image: images.SatelliteStreets,
  },
  {
    label: 'streets',
    value: 'streets-v11',
    image: images.StreetsView,
  },
  {
    label: 'outdoors',
    value: 'outdoors-v11',
    image: images.OutdoorsView,
  },
];

export type SatelliteOption = (typeof satelliteOptions)[number];
