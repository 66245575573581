import styled from 'styled-components';
import { Typography } from '@ratedpower/components';
import { RPTable } from '@ratedpower/components';

export const StyledActivityTable = styled(RPTable)`
  tr {
    &.error {
      background-color: ${({ theme }) => theme.v2.error.bg.default};
    }
    &.check,
    &.success {
      background-color: ${({ theme }) => theme.v2.success.bg.default};
    }
    &.warning {
      background-color: ${({ theme }) => theme.v2.warning.bg.default};
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const OptionsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v2.spacing.xs};
  margin-left: ${({ theme }) => theme.v2.spacing.l};
  margin-right: ${({ theme }) => theme.v2.spacing.l};
`;

export const PaginationNumberContainer = styled.div`
  margin: 0.5rem 2.4rem 2rem;
  display: flex;
`;

export const TopMenuActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.v2.spacing.xs};
`;

export const TableContainer = styled.div`
  position: relative;
  height: calc(100% - 106px);
  overflow-y: auto;
  padding: 0 2rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-items: center;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xs};
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    gap: 8px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.gray_dark};
  }
`;

export const CapitalizedContainer = styled(Typography)`
  text-transform: capitalize;
`;

export const ProjectDesign = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const ProjectDesignTeam = styled.div`
  display: flex;
  flex-direction: column;
`;
