import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { Permission } from './permissions';

export const useAuthorization = () => {
  const { profile } = useSelector((state: RootState) => state.currentUser);

  const isAuthorized = (perform: Permission): boolean => {
    return !!(profile && profile.permissions.length && Object.values(profile.permissions).includes(perform));
  };

  const isAuthorizedToPermissionSuffix = (suffix: string) => {
    const permissionsBySuffix = Object.values(Permission).filter(
      (permissionName) => permissionName.split('_')[0] === suffix
    );
    return permissionsBySuffix.some((permission) => isAuthorized(permission));
  };

  const hasAtLeastOneValidAuthorization = (permissions: Permission[]): boolean => {
    if (!profile?.permissions?.length) return false;

    const profilePermissions = new Set(profile.permissions);

    return permissions.some((permission) => profilePermissions.has(permission));
  };

  return { isAuthorized, isAuthorizedToPermissionSuffix, hasAtLeastOneValidAuthorization };
};

interface IProps {
  perform: Permission | Permission[];
  children: ReactNode;
  no?: () => ReactNode;
}

const Can: React.FC<IProps> = (props) => {
  const { isAuthorized } = useAuthorization();

  if (Array.isArray(props.perform)) {
    if (props.perform.some((action) => isAuthorized(action))) {
      return <>{props.children}</>;
    }
  } else if (isAuthorized(props.perform)) {
    return <>{props.children}</>;
  }
  if (!!props.no) return <>{props.no()}</>;
  return null;
};

export default Can;
