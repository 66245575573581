import React, { Fragment } from 'react';
import * as S from './pv-design-plans.styled';
import { getCurrentPlan, getOtherPlans } from './pv-design-plans-constants';
import PvDesignCurrentPlan from './pv-design-current-plan/pv-design-current-plan';
import PvDesignOtherPlan from './pv-design-other-plan/pv-design-other-plan';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { IPvDesignPlan, Plan } from 'types/subscription-plan';
import { Separator } from '@ratedpower/components';

const PvDesignPlans: React.FC<{ isOldCompanyPlan: boolean | null }> = ({ isOldCompanyPlan }) => {
  const { subscriptionPlan, profile } = useSelector((state: RootState) => state.currentUser);

  if (!subscriptionPlan) return <></>;
  const entries = [
    {
      label: 'company:remaining-projects',
      value: subscriptionPlan.unlimitedProjects
        ? 'unlimited'
        : subscriptionPlan.projectsPurchased - subscriptionPlan.projectsDone,
    },
    {
      label: 'company:designs-title',
      value: 'unlimited',
    },
    {
      label: 'company:number-of-users',
      value: profile?.userLimit || 'unlimited',
    },
    {
      label: 'company:starting-date',
      value: subscriptionPlan.startDate,
    },
    {
      label: 'expiration-date',
      value: subscriptionPlan.expirationDate,
    },
  ];

  if (!isOldCompanyPlan) {
    entries.unshift({
      label: 'company:projects-purchased',
      value: subscriptionPlan.unlimitedProjects ? 'unlimited' : subscriptionPlan.projectsPurchased,
    });
    entries.unshift({
      label: 'company:used-projects',
      value: subscriptionPlan.projectsDone,
    });
  }

  const currentPlan: IPvDesignPlan | undefined = getCurrentPlan(subscriptionPlan.plan as Plan);

  return (
    <S.PvPlansContainer>
      {currentPlan && (
        <PvDesignCurrentPlan
          color={currentPlan.color}
          loading={false}
          entries={entries}
          planTitle={currentPlan.title}
        />
      )}
      {getOtherPlans(subscriptionPlan.plan as Plan).map((item, index) => (
        <Fragment key={item.id}>
          <Separator position="vertical" />
          <PvDesignOtherPlan
            hasTopTitle={index === 0}
            color={item.color}
            includedItems={item.includedItems}
            title={item.title}
          />
        </Fragment>
      ))}
    </S.PvPlansContainer>
  );
};

export default PvDesignPlans;
