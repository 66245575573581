import React, { useEffect } from 'react';
import TopbarRightSection from './topbar-right-section';
import { useTranslation } from 'react-i18next';
import { PROJECTS_TABS as tabs } from 'utils/constants/tabs';
import { Helmet } from 'react-helmet';
import InputSearch from 'components/form-components/input-search';
import { Breadcrumb, Button, ITab, Tabs, TopbarWrapper } from '@ratedpower/components';
import { useDebouncedInput } from 'utils/hooks/use-debounced-input';
import { FavouriteSvg, FilterSvg, FolderImportSvg, FolderSvg } from 'assets/icons';
import { useTheme } from 'styled-components';

interface IProps {
  createProject?: () => void;
  openFilters: () => void;
  changeView: (view: string) => void;
  onTabChange: (tab: string) => void;
  onSearch: (search: string) => void;
  search: string;
  view: string;
  selectedTab: string;
  loading: boolean;
}

const TopbarProjects: React.FC<IProps> = ({
  createProject,
  openFilters,
  changeView,
  onTabChange,
  onSearch,
  search,
  selectedTab,
  view,
  loading,
}) => {
  const { t } = useTranslation(),
    theme = useTheme(),
    pageTabs: ITab[] = [
      {
        id: tabs.projects,
        label: t('project:projects'),
        Icon: FolderSvg,
      },
      {
        id: tabs.favorites,
        label: t(tabs.favorites),
        Icon: FavouriteSvg,
      },
      {
        id: tabs.archived,
        label: t(tabs.archived),
        Icon: FolderImportSvg,
      },
    ],
    { value, onChange } = useDebouncedInput(300, onSearch, search);

  useEffect(() => {
    if (!search) {
      onChange(search);
    }
  }, [search]);

  return (
    <>
      <Helmet>
        <title>{t('project:projects')}</title>
      </Helmet>
      <TopbarWrapper
        type="main"
        left={<Breadcrumb loading={loading} currentPage={{ name: t('project:projects') }} />}
        right={<TopbarRightSection view={view} createProject={createProject} changeView={changeView} />}
      />
      <TopbarWrapper
        type="options"
        padding={`${theme.v2.spacing.l} ${theme.v2.spacing.l} 0`}
        noBorder
        left={<Tabs onClickTab={onTabChange} tabs={pageTabs} tabActive={selectedTab} />}
        right={
          <>
            <InputSearch size="s" value={value} onChange={onChange} />
            <Button
              fontColor={theme.v2.text.accent.default}
              fontColorHover={theme.v2.text.accent.hover}
              iconPosition="left"
              size="m"
              variant="ghost"
              onClick={openFilters}
              text={t('action:filter')}
              Icon={FilterSvg}
              textHoverUnderlined={false}
              textUnderlined={false}
            />
          </>
        }
      />
    </>
  );
};

export default TopbarProjects;
