import { GET_NOTIFICATIONS } from 'services/notifications-gql';
import { INotification } from 'types/notifications';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { NotificationsTabDefaultPageSize, NotificationTab } from 'utils/constants/notifications';
import { getSortDirection } from 'utils/sort';

export const useGetNotifications = (notificationReadStatus: NotificationTab) => {
  const { data, error, loading, paginationComponent, currentPage, refetch } = useCustomQuery(GET_NOTIFICATIONS, {
    variables: {
      pagination: {
        page: 0,
        size: NotificationsTabDefaultPageSize,
      },
      notificationReadStatus,
      sorting: {
        property: 'publishedDate',
        direction: getSortDirection(false),
      },
    },
    fetchPolicy: 'network-only',
  });

  const notifications = data?.notifications?.content || ([] as INotification[]);

  return {
    notifications,
    error,
    loading,
    paginationComponent,
    currentPage,
    refetch,
  };
};
