import React from 'react';
import { Typography } from '@ratedpower/components';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { IDesign } from 'types/design';

export const DesignGridItemHeaderDetails: React.FC<{ item: IDesign }> = ({ item }) => {
  const { getValueOf } = useConverter();

  return (
    <div className="card-main-row">
      <Typography size="s">{getValueOf(designAttributes.power.ratedPower, item)}</Typography>
      <Typography size="s">{getValueOf(designAttributes.power.peakPower, item)}</Typography>
      <Typography size="s">{getValueOf(designAttributes.energy.specificProduction, item)}</Typography>
      <Typography size="s">{getValueOf(designAttributes.equipment.gcr, item)}</Typography>
    </div>
  );
};
