import React, { useState } from 'react';
import * as S from './delete-confirmation-modal.styled';
import { InputText, Modal, Typography } from '@ratedpower/components';
import { Trans, useTranslation } from 'react-i18next';
import { IUseModal } from 'utils/hooks/use-modal';

interface IProps {
  confirmationValue?: string;
  confirmationValueLabel: string;
  title: string;
  description: string;
  modal: IUseModal;
  onSubmit: () => void;
}

const DeleteConfirmationModal: React.FC<IProps> = ({
  confirmationValue,
  onSubmit,
  confirmationValueLabel,
  description,
  title,
  modal,
}) => {
  const { t } = useTranslation();
  const [deletedDisabled, setDeleteDisabled] = useState(true);

  const handleSubmit = () => {
    onSubmit();
    modal.setModalOpen(false);
  };

  return (
    <>
      {modal.isOpen && (
        <Modal
          isActive={modal.isOpen}
          title={t(title)}
          status="error"
          size="s"
          onClose={() => modal.setModalOpen(false)}
          buttons={{
            primary: {
              text: t('action:delete'),
              onClick: handleSubmit,
              type: 'button',
              disabled: deletedDisabled,
            },
            secondary: {
              text: t('action:cancel'),
              onClick: () => modal.setModalOpen(false),
            },
          }}
        >
          <S.ModalBody>
            <Typography>
              <Trans i18nKey={description}>
                <b></b>
              </Trans>
            </Typography>
            <InputText
              placeholder={t(confirmationValueLabel)}
              onChange={(newValue: string) => setDeleteDisabled(!(newValue === confirmationValue))}
            />
          </S.ModalBody>
        </Modal>
      )}
    </>
  );
};

export default DeleteConfirmationModal;
