import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DELETE_TEAM,
  GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM,
  GET_TEAMS,
  GET_TEAMS_BY_COMPANY,
  GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM,
} from 'services/team-gql';
import { useToasts } from 'utils/hooks/use-toasts';

export const useDeleteTeam = (selectedTeamId: string, callback: () => void) => {
  const { t } = useTranslation();
  const { id: companyId } = useParams<{ id: string }>();
  const urlParamsNames = Object.keys(useParams());
  const location = useLocation();
  const navigate = useNavigate();

  const { addSuccessToast, addErrorToast } = useToasts();

  const refetchQueries = [GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM, GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM];

  const [deleteTeam] = useMutation(DELETE_TEAM, {
    refetchQueries: companyId ? [...refetchQueries, GET_TEAMS_BY_COMPANY] : [...refetchQueries, GET_TEAMS],
    onCompleted: () => {
      if (urlParamsNames.includes('teamId')) {
        const currentPath = location.pathname;
        const newPath = currentPath.replace(/\/$/, '');
        const newUrl = newPath.split('/').slice(0, -2).join('/');
        navigate(newUrl, { replace: true });
      }
      addSuccessToast(t('user:teams.team-deleted-success'));
      callback();
    },
    onError: () => {
      addErrorToast(t('user:teams.team-deleted-error'));
    },
  });

  const handleDeleteTeam = () => {
    deleteTeam({ variables: { teamId: selectedTeamId } });
  };

  return {
    handleDeleteTeam,
  };
};
