import styled from 'styled-components';

export const PermissionsContainer = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.v2.spacing.l};
  background: ${({ theme }) => theme.v2.bg.secondary.default};
  justify-content: space-between;

  @media (max-width: 1600px) {
    row-gap: 24px;
  }
`;

export const PermissionGroup = styled.div`
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 10px;
  max-width: 250px;
    
  @media (max-width: 1439px) {
    width: 280px;
  }
`;

export const Permission = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RoleNameWrapper = styled.div`
  display: flex;
  column-gap: 14px;
  align-items: center;
`;
