import React, { useEffect, useState } from 'react';
import { GoogleMapContainer } from 'components/maps/map.styled';
import { useMap } from 'utils/maps/use-map';
import { useMapLayers } from 'utils/maps/use-map-layers';
import { GeoJSONFeatureCollection, GeoJSONPoint } from 'utils/maps/geojson';

interface IProps {
  geoJson: GeoJSONFeatureCollection | null;
  componentUpdateDependency?: any;
  updateCenter?: boolean;
}

const GeoPinsMapbox: React.FC<IProps> = ({ componentUpdateDependency, geoJson, updateCenter }) => {
  const mapId = 'geo-pins-map';
  const { mapContainer, map: mapbox, mapLoaded, resizeMap, updateMapCenter } = useMap();
  const { createLayers } = useMapLayers(mapbox);
  const [shouldUpdateCenter, setShouldUpdateCenter] = useState(!!updateCenter);

  useEffect(() => {
    if (mapbox.current && mapLoaded && geoJson) {
      createLayers([
        {
          geoJson,
          prettifiedName: 'geo-pins',
          id: 'GEO_PINS',
        },
      ]);
    }

    if (shouldUpdateCenter && !!geoJson?.features?.length) {
      const { coordinates } = (geoJson.features[0].geometry as GeoJSONPoint) || [];
      updateMapCenter({
        lng: coordinates[0],
        lat: coordinates[1],
        zoom: 2,
      });
      setShouldUpdateCenter(false);
    }

    resizeMap();
  }, [mapbox, mapLoaded, componentUpdateDependency, geoJson]);

  return (
    <GoogleMapContainer>
      <div id={mapId} ref={mapContainer} className="map" />
    </GoogleMapContainer>
  );
};

export default GeoPinsMapbox;
