/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import * as S from './team-cover.styled';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { coverIsColor } from 'utils/teams';
import { Button } from '@ratedpower/components';
import { PencilSvg } from 'assets/icons';

interface IProps {
  cover?: string;
  width: number;
  height: number;
  editable?: boolean;
  onEdit?: () => void;
}

const TeamCover: React.FC<IProps> = ({ cover, editable, width, height, onEdit }) => {
  if (cover && !coverIsColor(cover)) {
    return (
      <S.CardImageContainer>
        {editable && <Button Icon={PencilSvg} shape="round" className="edit-button" onClick={onEdit} />}
        <RemoteImage url={cover} width={width} height={height} alt="Default team image" className="team-image" />
      </S.CardImageContainer>
    );
  }
  return (
    <S.ColorContainer>
      {editable && <Button Icon={PencilSvg} shape="round" className="edit-button" onClick={onEdit} />}
      <S.ColorCover width={`${String(width)}px`} height={`${height}px`} color={cover} />
    </S.ColorContainer>
  );
};

export default TeamCover;
