import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query Roles($pagination: PaginationForm!, $sorting: [SortOrder]!, $search: [String], $filters: [RoleFilterEntry]) {
    result: roles(pagination: $pagination, sorting: $sorting, search: $search, filters: $filters) {
      content {
        id
        hierarchyLevel
        name
        color
        type
        status
        publishedDate
        permissions {
          id
          name
          type
        }
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const GET_ROLES_BY_CURRENT_USER = gql`
  query RolesByCurrentUser {
    result: getRolesByCurrentUser {
      id
      name
      color
      type
      hierarchyLevel
    }
  }
`;

export const GET_USER_ROLE_SUMMARY = gql`
  query GetRoleSummary($filters: [UserFilterEntry], $teamId: String, $companyId: String!) {
    result: roleSummary(filters: $filters, teamId: $teamId, companyId: $companyId) {
      role {
        id
        name
        hierarchyLevel
        color
        type
      }
      userCounter
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation CreateRole($createRoleDTO: CreateRoleDTO!) {
    createRole(createRoleDTO: $createRoleDTO) {
      id
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($updateRoleDTO: UpdateRoleDTO!) {
    updateRole(updateRoleDTO: $updateRoleDTO) {
      id
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`;

export const GET_PERMISSION_IDS = gql`
  query Permissions {
    permissions {
      id
      name
      type
    }
  }
`;
