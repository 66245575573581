import React from 'react';
import * as S from '../read-only-roles-tab/read-only-permissions-list.styled';
import { DefaultTheme } from 'styled-components';
import { BulletPoint, SVGWrapper, Typography } from '@ratedpower/components';
import { getOriginalRolesColors } from 'utils/constants/users';
import { TFunction } from 'i18next';
import { ChevronDownSvg, ChevronUpSvg } from 'assets/icons';
import { IUserRoleSummary } from 'types/users';
import { createColumnHelper } from '@tanstack/react-table';
import { IRole } from 'types/role';

const columnHelper = createColumnHelper<IRole>();

export const getReadOnlyRolesTableColumns = (
  t: TFunction,
  theme: DefaultTheme,
  userRoleSummary: IUserRoleSummary[],
  rolesExpanded: string[]
) => {
  const originalRolesColors = getOriginalRolesColors(theme);
  return [
    columnHelper.accessor('name', {
      header: () => <Typography>{t('user:roles.name', 'name')}</Typography>,
      cell: (info) => (
        <S.RoleNameWrapper>
          <SVGWrapper size="s" icon={rolesExpanded.includes(info.row.original.id) ? ChevronUpSvg : ChevronDownSvg} />
          <BulletPoint size="big" color={info.row.original.color ?? originalRolesColors[info.getValue()]} />
          <Typography>{info.getValue() || '-'}</Typography>
        </S.RoleNameWrapper>
      ),
    }),
    columnHelper.accessor('publishedDate', {
      header: () => <Typography>{t('user:user-plural')}</Typography>,
      cell: (info) => (
        <Typography>
          {userRoleSummary.find(({ role }) => role.id === info.row.original.id)?.userCounter || 0}
        </Typography>
      ),
    }),
    columnHelper.accessor('id', {
      header: () => <Typography>{t('description', 'description')}</Typography>,
      cell: (info) => (
        <Typography>{t(`user:roles.descriptions.${info.row.original.name}`, { defaultValue: '-' })}</Typography>
      ),
    }),
  ];
};
