import React from 'react';
import { DataWrapper, DesignItemLoadingContainer, Header, RowWrapper } from './design-grid-item-loading.styled';
import Skeleton from 'react-loading-skeleton';
import DesignGridItemLoadingDetails from './design-grid-item-loading-details';

interface IProps {
  showDetails?: boolean;
}

const DesignGridItemLoading: React.FC<IProps> = ({ showDetails }) => {
  const headerSkeleton = [
    [{ width: '70%' }, { width: '20%' }],
    [{ width: '22%' }, { width: '22%' }, { width: '22%' }, { width: '22%' }],
  ];

  return (
    <DesignItemLoadingContainer>
      <div className="image-space">
        <Skeleton count={1} />
      </div>
      <Header>
        {headerSkeleton.map((row, rowIndex) => (
          <RowWrapper key={rowIndex}>
            {row.map((data, dataIndex) => (
              <DataWrapper key={dataIndex} width={data.width}>
                <Skeleton count={1} />
              </DataWrapper>
            ))}
          </RowWrapper>
        ))}
      </Header>
      <DesignGridItemLoadingDetails showDetails={showDetails} />
    </DesignItemLoadingContainer>
  );
};

export default DesignGridItemLoading;
