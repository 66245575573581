import { useMutation } from '@apollo/client';
import { UPDATE_USER_BY_PROFILE } from 'services/user-gql';
import { useDispatch } from 'react-redux';
import { IUnitSystemPreferences, isUnitSystem } from 'types/preferences.types';
import { IRadioOption, IOption } from '@ratedpower/components';
import { setUnitSystem } from 'redux/current-user-slice';
import { useRef, useState } from 'react';
import i18n from 'i18n';

interface IUsePreferencesForm {
  isEditable: boolean;
  codeLanguage?: string;
  systemOfUnits?: string;
  temperatureUnits?: string;
  setIsEditable: (editable: boolean) => void;
  handleCancelClick: () => void;
  handleEditLanguage: (option: IOption[]) => void;
  handleEditSystemOfUnits: (option: IRadioOption) => void;
  handleEditTemperatureUnits: (option: IRadioOption) => void;
  handleEditPreferences: () => void;
}

export const usePreferencesForm = (preferences: any): IUsePreferencesForm => {
  const [isEditable, setIsEditable] = useState(false);
  const [temperatureUnits, setTemperatureUnits] = useState<string | undefined>();
  const [codeLanguage, setCodeLanguage] = useState<string | undefined>();
  const [systemOfUnits, setSystemOfUnits] = useState<string | undefined>();
  const newAppPreferences = useRef<IUnitSystemPreferences | undefined>();

  const [updateUser] = useMutation(UPDATE_USER_BY_PROFILE, {
    onCompleted: () => {
      setIsEditable(false);
      if (newAppPreferences.current) {
        i18n.changeLanguage(codeLanguage);
        dispatch(setUnitSystem(newAppPreferences.current));
      }
    },
  });
  const dispatch = useDispatch();

  const handleEditLanguage = (option: IOption[]) => {
    setCodeLanguage(option[0].value);
  };

  const handleEditSystemOfUnits = (option: IRadioOption) => {
    if (!isUnitSystem(option.value)) return;
    setSystemOfUnits(option.value);
  };

  const handleEditTemperatureUnits = (option: IRadioOption) => {
    if (!isUnitSystem(option.value)) return;
    setTemperatureUnits(option.value);
  };

  const handleEditPreferences = () => {
    const { unitsId, temperature, length, surface, language } = preferences;
    const newPreferences: IUnitSystemPreferences = {
      ...preferences,
      id: unitsId,
      length: systemOfUnits || length,
      surface: systemOfUnits || surface,
      temperature: temperatureUnits || temperature,
    };

    newAppPreferences.current = newPreferences;

    const newServerPreferences = {
      codeLanguage: codeLanguage || language,
      interfaceUnits: {
        id: unitsId,
        length: systemOfUnits || length,
        surface: systemOfUnits || surface,
        temperature: temperatureUnits || temperature,
      },
    };

    updateUser({ variables: { userForm: { ...newServerPreferences } } });
  };

  const handleCancelClick = () => {
    setIsEditable(false);
    setCodeLanguage(undefined);
    setSystemOfUnits(undefined);
    setTemperatureUnits(undefined);
  };

  return {
    isEditable,
    codeLanguage,
    systemOfUnits,
    temperatureUnits,
    setIsEditable,
    handleCancelClick,
    handleEditLanguage,
    handleEditPreferences,
    handleEditSystemOfUnits,
    handleEditTemperatureUnits,
  };
};
