import {
  DELETE_SIMULATION_BY_COMPANY_ACTIVITY,
  GET_SIMULATIONS_COMPANY,
  RECOVER_SIMULATION_BY_COMPANY_ACTIVITY,
  SAVE_SIMULATION_BY_COMPANY_ACTIVITY,
} from 'services/support-gql';
import { ISimulationListItemDTO, ISimulationStatus } from 'utils/constants/simulations';
import { IServerFilter, ISort } from 'types/filter';
import { DESIGNS_PER_PAGE, DesignActions } from 'utils/constants/support';
import { getSortDirection } from 'utils/sort';
import { useCompanyActivityState } from './company-activity/company-activity-state';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSearch } from 'utils/hooks/use-search';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { designsColumns } from 'utils/constants/support/designs-columns';
import { useToasts } from 'utils/hooks/use-toasts';
import { dateFilters } from 'utils/constants/filters';
import { getOnCurrentTimezoneDate, toDayEnd } from 'utils/date';
import { useNavigate } from 'react-router-dom';

export const useDesignsTab = () => {
  const theme = useTheme();
  const { isAuthorized } = useAuthorization();
  const { t } = useTranslation();
  const { addErrorToast } = useToasts();
  const { companyId } = useCompanyActivityState();
  const navigate = useNavigate();

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('support:designs-tab.execution-date'),
      code: 'executionDate',
    },
    ascendent: false,
  });
  const [designs, setDesigns] = useState<ISimulationListItemDTO[]>([]);
  const [currentFilters, setCurrentFilters] = useState<IServerFilter[]>([
    { field: 'PUBLISHED_DATE', operator: 'GREATER_OR_EQUAL', values: [new Date(dateFilters[1].value)] },
    {
      field: 'PUBLISHED_DATE',
      operator: 'LESS_OR_EQUAL',
      values: [new Date(getOnCurrentTimezoneDate(toDayEnd(new Date())))],
    },
  ]);
  const [generalStatusSelected, setGeneralStatusSelected] = useState<ISimulationStatus>();

  const { inputSearchProps, getSearchFilter } = useSearch();

  const {
    data: simulations,
    loading,
    error,
    paginationComponent,
  } = useCustomQuery(GET_SIMULATIONS_COMPANY, {
    variables: {
      pagination: {
        page: 0,
        size: DESIGNS_PER_PAGE,
      },
      filters: currentFilters,
      sorting: {
        property: sortedBy?.selector.code,
        direction: getSortDirection(sortedBy?.ascendent),
      },
      search: getSearchFilter(),
      companyId: companyId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (simulations && simulations.result && simulations.result.content) {
      const newData: ISimulationListItemDTO[] = simulations.result.content
        .filter((item: any) => !!item)
        .map((item: any) => {
          return { ...item, active: false };
        });
      setDesigns(newData);
    }
  }, [simulations]);

  const [deleteSimulation] = useMutation(DELETE_SIMULATION_BY_COMPANY_ACTIVITY, {
    refetchQueries: [GET_SIMULATIONS_COMPANY],
    onError: onErrorPerformingAction,
  });

  const [recoverSimulation] = useMutation(RECOVER_SIMULATION_BY_COMPANY_ACTIVITY, {
    refetchQueries: [GET_SIMULATIONS_COMPANY],
    onError: onErrorPerformingAction,
  });

  const [saveSimulation] = useMutation(SAVE_SIMULATION_BY_COMPANY_ACTIVITY, {
    refetchQueries: [GET_SIMULATIONS_COMPANY],
    onError: onErrorPerformingAction,
  });

  function onErrorPerformingAction() {
    addErrorToast(t('support:companies-error'));
  }

  const onPerformAction = (id: string, action: DesignActions) => {
    switch (action) {
      case 'DELETE':
        deleteSimulation({ variables: { id } });
        break;
      case 'RECOVER':
        recoverSimulation({ variables: { id } });
        break;
      case 'SAVE':
        saveSimulation({ variables: { id } });
        break;
    }
  };

  const table = useReactTable({
    data: designs,
    columns: designsColumns(t, theme, {
      setSortedBy,
      onPerformAction,
      sortedBy,
      hasWritePermission: isAuthorized(Permission.SIMULATION_SUPPORT_WRITE),
      navigate,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    data: designs,
    loading,
    error,
    table,
    inputSearchProps,
    generalStatusSelected,
    paginationComponent,
    currentFilters,
    setGeneralStatusSelected,
    setCurrentFilters,
  };
};
