import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM } from 'services/team-gql';

export const useListTeamCountries = (): string[] => {
  const { id: companyIdFromUrl } = useParams<{ id: string }>();
  const {
    currentUser: { profile },
  } = useSelector((state: RootState) => state);

  const { data: countries } = useQuery(GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM, {
    fetchPolicy: 'network-only',
    variables: { companyId: companyIdFromUrl || profile?.companyId },
  });

  return countries?.result;
};
