import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { setShowPopup, setVersion } from 'redux/version-slice';

export const useVersionControl = () => {
  const showPopup = useSelector((state: RootState) => state.version.showPopup);
  const currentVersion = useSelector((state: RootState) => state.version.currentVersion);
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(setShowPopup(false));
  };

  const onAccept = () => {
    window.location.reload();
    dispatch(setShowPopup(true));
  };

  useEffect(() => {
    const fetchVersion = async () => {
      const response = await fetch('./index.html');
      const lastModified = response.headers.get('last-modified');
      if (lastModified) {
        const dateFromLastModified = new Date(lastModified);

        if (!currentVersion) {
          dispatch(setVersion({ currentVersion: dateFromLastModified }));
        }
        if (currentVersion && currentVersion < dateFromLastModified) {
          dispatch(setShowPopup(true));
        }
      }
    };
    const interval = setInterval(fetchVersion, 120000);

    return () => clearInterval(interval);
  }, [currentVersion]);

  return { showPopup, onCancel, onAccept };
};
