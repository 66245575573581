import React, { useState } from 'react';
import * as S from './custom-dates.styled';
import { BaseTemplateInput } from '@ratedpower/components';
import { DateRange } from 'types/filter';
import { dateFilters } from 'utils/constants/filters';
import { getShortDate } from 'utils/date';

export interface IProps {
  onChangeDateRange: (newDateRange: DateRange) => void;
  currentDateRange: DateRange;
}

const CustomDates: React.FC<IProps> = ({ currentDateRange, onChangeDateRange }) => {
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const isValidDateFrom = (date: string): boolean =>
    new Date(date).getTime() <= new Date(currentDateRange.to).getTime();

  const isValidDateTo = (date: string): boolean =>
    !(
      new Date(date).getTime() > new Date(dateFilters[0].value).getTime() ||
      new Date(date).getTime() < new Date(currentDateRange.from).getTime()
    );

  return (
    <S.CustomDatesContainer>
      <BaseTemplateInput
        type="date"
        value={dateFrom || getShortDate(currentDateRange.from)}
        name="date-from"
        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
          const validDate = isValidDateFrom(e.target.value) ? e.target.value : getShortDate(currentDateRange.from);
          onChangeDateRange({
            from: new Date(validDate).toISOString(),
            to: currentDateRange.to,
          });
          setDateFrom(validDate);
        }}
        onChange={(value: string) => setDateFrom(value)}
      />
      <BaseTemplateInput
        type="date"
        value={dateTo || getShortDate(currentDateRange.to)}
        name="date-to"
        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
          const validDate = isValidDateTo(e.target.value) ? e.target.value : getShortDate(dateFilters[0].value);
          onChangeDateRange({
            from: currentDateRange.from,
            to: new Date(validDate).toISOString(),
          });
          setDateTo(validDate);
        }}
        onChange={(value: string) => setDateTo(value)}
      />
    </S.CustomDatesContainer>
  );
};

export default CustomDates;
