import { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { GET_CAPEX_CREATORS, SAVE_CAPEX_TEMPLATE, UPDATE_CAPEX_TEMPLATE } from 'services/capex-gql';
import { ICapexTemplate, ICapexTemplateCreate, IUseCapexFormObject, IUseCapexTemplateEditorObject } from 'types/capex';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useCapexForm } from 'utils/hooks/capex/use-capex-data';
import { useHistory as useStateHistory } from 'utils/hooks/use-history';
import { useKeyboardShortcut } from 'utils/hooks/use-keyboard-shortcut';
import { useToasts } from 'utils/hooks/use-toasts';
import { t } from 'i18next';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { dataToCapexTemplate } from 'utils/constants/capex-constants';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';
import { useNavigate, useLocation, useBlocker } from 'react-router-dom';

export function useCapexTemplateEditor(templateData?: ICapexTemplate, skip?: boolean): IUseCapexTemplateEditorObject {
  const modalExitWarning: IUseModal = useModal(false);
  const { addSuccessToast } = useToasts();
  const { isAuthorized } = useAuthorization();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { pathname } = useLocation();
  const letMeOut = useRef<boolean>(true);
  const redirectToCapexAfterCreate = useRef<boolean>(false);
  const userIsCreating = useRef<boolean>(!pathname.includes('edit'));
  const { currencyOptions } = useSelector((currencyState: RootState) => currencyState.currencies);

  const templateCreatedId = useRef<string>();
  const templateModified = useRef<ICapexTemplate>();

  const {
    state: editableTemplate,
    set: setEditableTemplate,
    undo,
    redo,
    clear,
    canUndo,
  } = useStateHistory(templateData, 10);

  useEffect(() => {
    if (templateData) {
      clear(templateData);
    }
  }, [templateData?.id]);

  const useCapexFormObject: IUseCapexFormObject = useCapexForm(
    editableTemplate?.entries || templateData?.entries,
    skip
  );
  const { getEntries } = useCapexFormObject;
  const user = useSelector((userState: RootState) => userState.currentUser.profile);
  const companyIdQueryParam = useQuerySearchParams().get('companyId');
  const { companyId: userCompanyId } = { ...user };
  const companyId = companyIdQueryParam || userCompanyId;

  useKeyboardShortcut(['z'], () => editableTemplate !== undefined && undo(), null, { ctrlKey: true });
  useKeyboardShortcut(['z'], redo, null, { ctrlKey: true, shiftKey: true });
  useKeyboardShortcut(['y'], redo, null, { ctrlKey: true });

  const [updateCapexTemplate] = useMutation(UPDATE_CAPEX_TEMPLATE, {
    onCompleted: (data) => {
      showToastSuccess(false, data.updateCapexTemplate);
      resetTemplate(dataToCapexTemplate(data.updateCapexTemplate, currencyOptions));
      if (redirectToCapexAfterCreate.current) {
        navigate(-1);
      }
    },
  });

  const [saveTemplate, { data: templateCreated, loading: loadingCreation }] = useMutation(SAVE_CAPEX_TEMPLATE, {
    refetchQueries: [GET_CAPEX_CREATORS],
    onCompleted: (data) => {
      showToastSuccess(true, data.createCapexTemplate);
      templateCreatedId.current = data.createCapexTemplate.id;
      userIsCreating.current = false;
      resetTemplate(dataToCapexTemplate(data.createCapexTemplate, currencyOptions));
      if (redirectToCapexAfterCreate.current) {
        const historyState = state as { backToCapexHome?: boolean };
        historyState.backToCapexHome ? navigate('/capex') : navigate(-1);
      }
    },
  });

  useEffect(() => {
    letMeOut.current = !canUndo;
  }, [canUndo]);

  useEffect(() => {
    if (!!templateData && templateData.status === 'IMMUTABLE') {
      templateModified.current = templateData;
    }
  }, [templateData]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && canUndo && !letMeOut.current
  );

  useEffect(() => {
    if (blocker.state !== 'blocked') return;
    modalExitWarning.setModalOpen(true);
  }, [blocker.state]);

  const handleAcceptUnsavedChanges = () => {
    if (blocker && blocker.proceed && blocker.location) {
      blocker.proceed();
      navigate(blocker.location.pathname);
    }
    modalExitWarning.setModalOpen(false);
  };

  const handleCancelUnsavedChanges = () => {
    if (blocker && blocker.reset) blocker.reset();
    modalExitWarning.setModalOpen(false);
  };

  const showToastSuccess = (creation: boolean, newTemplate: any) => {
    const defaultName = newTemplate?.company ? t('capex:untitled') : t('capex:rest-of-the-world');
    const templateName = newTemplate?.name || newTemplate?.country?.translation || defaultName;

    addSuccessToast(
      t(`capex:${!creation ? 'saved-changes-template' : 'template-created-succesfully'}`, {
        templateName,
      })
    );
  };

  const updateTemplateChanges = (redirectToCapex: boolean) => {
    if (editableTemplate) {
      const form: ICapexTemplateCreate = {
        name: editableTemplate.countryCode ? null : editableTemplate.name,
        countryCode: editableTemplate.countryCode,
        currency: editableTemplate.currency.value,
        entries: getEntries(false),
        countryDefault: editableTemplate?.countryDefault,
        folder: editableTemplate?.folder?.name,
      };
      letMeOut.current = true;
      redirectToCapexAfterCreate.current = redirectToCapex;
      updateCapexTemplate({ variables: { form: { ...form, id: templateCreatedId.current || editableTemplate.id } } });
    }
  };

  const resetTemplate = (newState: ICapexTemplate | undefined) => {
    clear(newState);
  };

  const changeTemplateProperty = (newProperty: keyof ICapexTemplate, value: any) => {
    const setTemplate = (template?: ICapexTemplate) => {
      newProperty === 'entries'
        ? setEditableTemplate(JSON.parse(JSON.stringify({ ...template, [newProperty]: value })))
        : setEditableTemplate({ ...template, [newProperty]: value });
    };
    if (!editableTemplate) {
      setTemplate(templateData);
      return;
    }
    setTemplate(editableTemplate);
  };

  const changeTemplateProperties = (newProperties: string[], newValues: any[]) => {
    let newTemplate: ICapexTemplate = { ...editableTemplate };
    newProperties.forEach((property, index) => {
      const templateProperty = property as keyof ICapexTemplate;
      newTemplate = { ...newTemplate, [templateProperty]: newValues[index] };
    });
    return setEditableTemplate(newTemplate);
  };

  const handleSaveChanges = (redirectToCapex: boolean) => {
    if (!userIsCreating.current) {
      updateTemplateChanges(redirectToCapex);
      return;
    }
    letMeOut.current = true;
    redirectToCapexAfterCreate.current = redirectToCapex;
    saveTemplate({
      variables: {
        form: {
          name: editableTemplate.countryCode ? null : editableTemplate.name,
          currency: editableTemplate.currency?.value,
          folder: editableTemplate.folder?.name,
          companyId:
            editableTemplate?.type === 'public' && isAuthorized(Permission.FINANCIAL_SUPPORT_WRITE)
              ? undefined
              : companyId,
          countryDefault: editableTemplate.countryDefault,
          entries: getEntries(true),
          countryCode: editableTemplate.countryCode,
        },
      },
    });
  };

  const createFromDesignProcess = (newName?: string, newCurrency?: string) => {
    saveTemplate({
      variables: {
        form: {
          name: newName ?? (editableTemplate?.name || templateData?.name),
          currency: newCurrency ?? (editableTemplate?.currency?.value || templateData?.currency.value),
          folder: editableTemplate?.folder?.name || templateData?.folder?.name,
          companyId: user?.companyId,
          entries: getEntries(true),
          countryCode: editableTemplate?.countryCode || templateData?.countryCode,
        },
      },
    });
  };

  return {
    handleSaveChanges,
    createFromDesignProcess,
    resetTemplate,
    setEditableTemplate,
    updateTemplateChanges,
    changeTemplateProperty,
    changeTemplateProperties,
    handleAcceptUnsavedChanges,
    handleCancelUnsavedChanges,
    loadingCreation,
    templateModified: templateModified?.current,
    modalExitWarning,
    editableTemplate: editableTemplate || templateData,
    useCapexFormObject,
    canUndo: canUndo && editableTemplate !== undefined,
    templateCreated: templateCreated ? templateCreated?.createCapexTemplate : undefined,
  };
}
