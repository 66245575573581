import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GET_USER_ROLE_SUMMARY } from 'services/roles-gql';
import {
  GET_COMPANY_USERS,
  GET_TEAMS,
  GET_TEAMS_BY_COMPANY,
  GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM,
  GET_TEAM_MANAGER_TEAMS_MEMBERS,
  REMOVE_MEMBER_FROM_TEAM,
} from 'services/team-gql';
import { ITeam } from 'types/teams';
import { useToasts } from 'utils/hooks/use-toasts';

export const useRemoveMemberFromTeam = (teamId: string, callback?: () => void) => {
  const { t } = useTranslation();
  const { addSuccessToast, addErrorToast } = useToasts();
  const { id: companyId } = useParams<{ id: string }>();

  const refetchQueries = [GET_COMPANY_USERS, GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM, GET_TEAM_MANAGER_TEAMS_MEMBERS];

  const [removeMember, { data, loading }] = useMutation(REMOVE_MEMBER_FROM_TEAM, {
    refetchQueries: companyId
      ? [...refetchQueries, GET_TEAMS_BY_COMPANY]
      : [...refetchQueries, GET_TEAMS, GET_USER_ROLE_SUMMARY],
    onCompleted: () => {
      addSuccessToast(t('user:teams.member-succesfully-removed'));
      if (callback) callback();
    },
    onError: () => {
      addErrorToast(t('user:teams.error-removing-member'));
    },
  });

  const onRemoveMember = (userId: string) => {
    removeMember({ variables: { teamId, memberId: userId } });
  };

  return {
    loading,
    onRemoveMember,
    updatedTeam: data?.removeMemberFromTeam as ITeam | undefined,
  };
};
