import React from 'react';
import * as S from './users-tab-role-switch.styled';
import SubmitLoader from 'components/loaders/submit-loader';
import { getOriginalRolesColors } from 'utils/constants/users';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  BulletPoint,
  ISegmentedControlItem,
  ScrollingCarousel,
  SegmentedControl,
  Typography,
} from '@ratedpower/components';
import { IUserRoleSummary } from 'types/users';
import { IServerFilter } from 'types/filter';
import { ButtonSwitchContainer } from 'components/button-switch-containers/button-switch-containers.styled';

interface IProps {
  loading: boolean;
  userRoleSummary: IUserRoleSummary[];
  roleFilterSelected?: IServerFilter;
  onClickRole: (roleSelected: string, roleSelectedHierarchy: number) => void;
  onClickAll: () => void;
}

const UsersTabRoleSwitch: React.FC<IProps> = ({
  loading,
  userRoleSummary,
  roleFilterSelected,
  onClickRole,
  onClickAll,
}) => {
  const theme = useTheme(),
    { t } = useTranslation(),
    allRolesOption = {
      label: t('all'),
      value: userRoleSummary.reduce((accumulator, current) => current.userCounter + accumulator, 0),
    },
    adaptedUserRoleSummary = [
      allRolesOption,
      ...userRoleSummary.map((role: IUserRoleSummary) => ({
        label: `${role.role.name} (${role.userCounter})`,
        value: role.role.id,
        hierarchyLevel: role.role.hierarchyLevel,
      })),
    ];

  if (loading) {
    return <SubmitLoader width={25} height={25} color={theme.secondary} />;
  }

  const handleOnChange = (selectedItem: ISegmentedControlItem, selectedItemIndex: number): void => {
    if (selectedItemIndex === 0) {
      onClickAll();
    } else {
      onClickRole(selectedItem.value, selectedItem.hierarchyLevel);
    }
  };

  const originalRolesColors = getOriginalRolesColors(theme);

  return (
    <S.UsersSummaryContainer>
      <ButtonSwitchContainer className="scrollable-items" $fullWidth={false}>
        <ScrollingCarousel>
          <></>
          <SegmentedControl
            items={adaptedUserRoleSummary}
            selectedValue={roleFilterSelected?.values[0] || allRolesOption.value}
            onChange={handleOnChange}
          />
        </ScrollingCarousel>
      </ButtonSwitchContainer>
      {userRoleSummary.map(
        ({ role }: IUserRoleSummary) =>
          roleFilterSelected?.values.includes(role.id) && (
            <S.RoleDescription key={role.id}>
              <BulletPoint color={role.color ?? String(originalRolesColors[role.name])}>
                {t([`user:${role.name.toLowerCase()}`, role.name])}:
              </BulletPoint>
              <Typography>{t(`user:roles.descriptions.${role.name}`, { defaultValue: '-' })}</Typography>
            </S.RoleDescription>
          )
      )}
    </S.UsersSummaryContainer>
  );
};

export default UsersTabRoleSwitch;
