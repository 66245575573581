export enum RudderstackEvent {
  AA_ADDED = 'available_area_added',
  APPLY_CAPEX_FILTERS = 'capex_filters_applied',
  APPLY_DESIGNS_FILTERS = 'designs_filters_applied',
  APPLY_DP_FILTERS = 'dp_filters_applied',
  APPLY_EQUIPMENT_FILTERS = 'equipment_filters_applied',
  APPLY_PROJECTS_FILTERS = 'projects_filters_applied',
  APPLY_STATISTICS_AND_KPIS_FILTERS = 'statistics_and_kpis_filters_applied',
  APPLY_USERS_FILTERS = 'users_filters_applied',
  COMPARISON_EXPORTED = 'comparison_exported',
  CREATE_PROJECT = 'project_created',
  CREATE_STRUCTURE_START = 'structure_saved',
  DESIGN_CLONE = 'design_cloned',
  DESIGN_MARKED_FAVORITE = 'design_marked_favorite',
  DOWNLOAD_DOCUMENTS = 'documents_downloaded',
  EXPORT_STATISTICS_AND_KPIS = 'statistics_and_kpis_exported',
  IMPORT_SITE_ELEMENTS = 'site_elements_imported',
  LOGIN = 'user_logged_in',
  PARCELS_ACTIVATED = 'parcels_activated',
  RUN_BATCH = 'batch_run',
  RUN_COMPARISON = 'comparison_run',
  RUN_DESIGN = 'design_run',
  SAVE_DESIGN = 'design_saved',
  SHAPE_TOOL_CLICKED = 'shape_tool_clicked',
}

export enum ComparisonExportType {
  CHART = 'chart',
  TABLE = 'table',
}

export enum DPFilterType {
  MY_DESIGNS = 'my_designs',
  ALL_DESIGNS = 'all_designs',
}

export enum BatchType {
  PITCH = 'pitch',
  DC_AC_RATIO = 'dc_ac_ratio',
  TILT = 'tilt',
}

export enum ShapeType {
  POLYGON = 'polygon',
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
}

export enum SourceArea {
  US_PARCELS = 'us_parcels',
}

export enum SiteSource {
  CAD = 'cad',
  KML = 'kml',
  SHP = 'shp',
}

export enum DesignFilters {
  METEO_SOURCE = 'meteo_source',
  TYPE_OF_DESIGN = 'type_of_design',
  CREATOR = 'creator',
}

export enum EquipmentFilters {
  DATABASE = 'database',
}

export enum UsersFilters {
  JOB_TITLE = 'job_title',
  STATUS = 'status',
}

export enum CapexFilters {
  CURRENCY = 'currency',
  CREATOR = 'creator',
  CREATION_DATE_RANGE = 'creation_date_range',
}
