import styled from 'styled-components';

export const CreateProjectFormInputsWrapper = styled.div`
  padding: ${({ theme }) => `${theme.v2.spacing.m} 0`};
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;

  //TODO re-think this and find a way to adapt the wrappers or the modal to have a max-width, or designers to stick to the sizes.

  & > div {
    width: 100%;
  }
`;
