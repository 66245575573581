import { IUserMinInfoDTO } from './users';

export enum Plan {
  DEMO = 'DEMO',
  CUSTOM = 'CUSTOM',
  ESSENTIALS = 'ESSENTIALS',
  PROFESSIONAL = 'PROFESSIONAL',
  ENTERPRISE = 'ENTERPRISE',
}

export enum PlanStage {
  PLAN_VALID = 'PLAN_VALID',
  PROJECTS_LIMIT = 'PROJECTS_LIMIT',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  OVERDUE_PAYMENT = 'OVERDUE_PAYMENT',
}

export enum CustomPlanType {
  PAID_TRIAL = 'PAID_TRIAL',
  PAID_TRIAL_EXTENSION = 'PAID_TRIAL_EXTENSION',
  CONSULTANCY_SERVICES = 'CONSULTANCY_SERVICES',
  ISOLATED_PROJECTS = 'ISOLATED_PROJECTS',
  PAID_PILOTS = 'PAID_PILOTS',
  PAID_TRAININGS = 'PAID_TRAININGS',
  NONE = 'NONE',
}

export enum PlanStatus {
  VALID = 'VALID',
  HIDDEN = 'HIDDEN',
}

export enum ModificationType {
  RENEW = 'RENEW',
  UPGRADE = 'UPGRADE',
  NONE = 'NONE',
}

export interface ISubscriptionPlanSimpleDTO {
  id: string;
  plan: Plan;
  extensions: ISubscriptionPlanExtensionDTO[];
  projectsPurchased: number;
  projectsDone: number;
  designsDone: number;
  startDate: string;
  expirationDate: string;
  publishedDate: string;
  originalExpirationDate: string;
  activeUsers: number;
  usersCreated: number;
  unlimitedProjects: boolean;
  modificationType?: ModificationType;
}

export interface ISubscriptionPlanExtensionDTO {
  id: string;
  createdBy?: IUserMinInfoDTO;
  expirationDate: string;
  publishedDate: string;
  price?: number;
  projectsPurchased?: number;
  projectsDone?: number;
  designsDone?: number;
}

export interface ICurrentSubscriptionPlan {
  hasFuturePlan?: boolean;
  publishedDate: string;
  projectsDone: number;
  projectsPurchased: number;
  startDate: string;
  plan: string;
  expirationDate: string;
  unlimitedProjects: boolean;
  activeUsers: number;
  usersCreated: number;
  modificationType?: ModificationType;
}

export interface ISubscriptionPlanDTO extends ISubscriptionPlanSimpleDTO {
  createdBy?: IUserMinInfoDTO;
  notes?: string;
  price: number;
  status: PlanStatus;
  originalExpirationDate: string;
  customPlanType?: CustomPlanType;
  currency: string;
  title?: string;
}

export interface ISubscriptionPlanCreate {
  plan: Plan;
  customPlanType?: CustomPlanType;
  notes?: string;
  price: number;
  projectsPurchased: number;
  startDate: string;
  expirationDate: string;
  currency: string;
}

export type IPvDesignPlan = {
  id: Plan;
  title: string;
  planDescription: string;
  color: 'primary' | 'secondary' | 'yellow_dark' | 'purple' | 'red';
  includedItems: string[];
};
