import { useMutation } from '@apollo/client';
import useGetNotificationToast, { NotificationsOperation } from 'pages/communication/hooks/use-get-notifications-toast';
import {
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
} from 'services/notifications-gql';
import { NotificationsTabDefaultPageSize } from 'utils/constants/notifications';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';

const useMarkAllNotificationsAsRead = (currentPage: number) => {
  const [markAllAsRead, { error, data }] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ, {
    variables: {
      pagination: {
        page: currentPage - 1,
        size: NotificationsTabDefaultPageSize,
      },
    },
    refetchQueries: [GET_UNREAD_NOTIFICATIONS, GET_NOTIFICATIONS],
    onCompleted: () => showToast(ToastType.SUCCESS),
    onError: () => showToast(ToastType.ERROR),
  });

  const getNotificationToast = useGetNotificationToast(),
    { addToast } = useToasts();

  const showToast = (statusType: ToastType): void => {
    const { location, message, type, duration } = getNotificationToast(NotificationsOperation.READ_ALL, statusType);

    addToast(location, message, type, duration);
  };

  return { markAllAsRead, error: error || data?.errors };
};

export default useMarkAllNotificationsAsRead;
