import styled from 'styled-components';

export const HomeNoDesignsContainer = styled.div`
    height: fit-content;
    justify-content: center;
    display: flex;
`;

export const HomeNoDesignsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;