export enum MeteoSources {
  API_SOLARANYWHERE = 'API_SOLARANYWHERE',
  API_SOLCAST = 'API_SOLCAST',
  API_SOLARGIS = 'API_SOLARGIS',
  API_SOLARGIS_15M = 'API_SOLARGIS_15M',
  VAISALA = 'VAISALA',
}

const meteoDataSourceNames: { [key in MeteoSources]: string } = {
  API_SOLARANYWHERE: 'SolarAnywhere',
  API_SOLCAST: 'Solcast',
  VAISALA: 'Vaisala',
  API_SOLARGIS: 'Solargis 60M',
  API_SOLARGIS_15M: 'Solargis 15M',
};

export const meteoDataSourceLinks: { [key in MeteoSources]: string } = {
  API_SOLARANYWHERE: 'https://data.solaranywhere.com/Account/Register?isPurchaseContext=False',
  API_SOLCAST: 'https://toolkit.solcast.com.au/register/?utm_source=Referral&utm_medium=ratedpower',
  VAISALA: 'Vaisala',
  API_SOLARGIS:
    'https://solargis.com/products/api/from-external?utm_source=pvdesign&utm_medium=integrator&utm_campaign=tmy-api',
  API_SOLARGIS_15M:
    'https://solargis.com/products/api/from-external?utm_source=pvdesign&utm_medium=integrator&utm_campaign=tmy-api',
};

export default meteoDataSourceNames;
