import styled from 'styled-components';

export const DesignItemDetailsContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 14px 0;

  .section-header {
    width: 100%;
    text-align: left;
    text-transform: capitalize;
  }
`;

export const DesignItemDetailsRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const DesignItemSectionRow = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;

  .body-col-2 {
    width: 30%;
  }

  .body-col-1 {
    width: 30%;
  }

  .body-col {
    text-align: left;

    span {
      color: ${({ theme }) => theme.gray_dark};
    }
  }
`;
