import React, { useEffect } from 'react';
import { GET_ENTITY_INFO } from 'services/support-gql';
import { CrossSvg } from 'assets/icons';
import { SCREEN_LOCATION } from 'utils/constants/toasts';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';
import { useQuery } from '@apollo/client';
import { ILinkedEntityInfoDTO } from 'types/support.types';
import { getMappedErrorType, ILocationError } from 'utils/constants/kml-errors';

export interface IUseErrorInfoModal {
  errorInfo: ILinkedEntityInfoDTO;
  KMLinfo: ILocationError;
}

export const useErrorInfoModal = (entityType: string, entityId: string): IUseErrorInfoModal => {
  const { addToast } = useToasts();

  const { data, error, loading } = useQuery(GET_ENTITY_INFO, {
    variables: {
      linkedEntityType: entityType,
      linkedEntityId: entityId,
    },
    fetchPolicy: 'cache-first',
    skip: !entityType,
  });

  useEffect(() => {
    if (!loading && error) {
      addToast(
        SCREEN_LOCATION.topCenter,
        <>
          <CrossSvg />
          {error.message}
        </>,
        ToastType.ERROR,
        4000
      );
    }
  }, [data, error, loading]);

  return (
    !loading &&
    data && {
      errorInfo: data.linkedEntityInfo,
      KMLinfo: getMappedErrorType({
        errorType: data.linkedEntityInfo.error,
        errorComponents: data.linkedEntityInfo.message,
      }),
    }
  );
};
