import { gql } from '@apollo/client';

const fragments = {
  profile: gql`
    fragment Profile on UserDTO {
      name
      authId
      azId
      lastname: surname
      email
      jobTitle
      company {
        userLimit
        usersCount
        logo {
          low: lowResUrl
        }
      }
    }
  `,
  preferences: gql`
    fragment Preferences on UserDTO {
      lng: codeLanguage
      units: interfaceUnits {
        id
        length
        surface
        temperature
      }
    }
  `,
  companyUser: gql`
    fragment CompanyUser on UserDTO {
      name
      lastname: surname
      email
      jobTitle
      lastLogin
    }
  `,
  currentPlan: gql`
    fragment CurrentPlan on UserDTO {
      company {
        currentPlan {
          plan
          expirationDate
          projectsDone
          projectsPurchased
          usersCreated
          activeUsers
          startDate
          unlimitedProjects
          publishedDate
        }
      }
    }
  `,
  role: gql`
    fragment Role on UserDTO {
      role {
        id
        name
        color
        status
        publishedDate
        type
        hierarchyLevel
        permissions {
          id
          name
          publishedDate
        }
      }
    }
  `,
};

export const GET_USER = gql`
  query User {
    user {
      id
      ...Role
      ...Profile
      ...Preferences
      ...CurrentPlan
      company {
        id
        name
        hasFuturePlan
        country {
          id
        }
        organizationId
        enverusId
        companyWarningMessage
      }
    }
  }
  ${fragments.role}
  ${fragments.profile}
  ${fragments.preferences}
  ${fragments.currentPlan}
`;

export const GET_PLAN_STAGES = gql`
  query PlanStages {
    planStages
  }
`;

export const GET_USER_ID = gql`
  query UserID {
    user {
      id
    }
  }
`;

export const GET_COMPANY_ACCOUNT_INFO = gql`
  query CompanyAccountInfo {
    companyAccountInfo: user {
      company {
        id
        country {
          id
          countryCode
          translation
        }
        name
        email
        logo {
          high: highResUrl
        }
      }
    }
  }
`;

export const GET_ALL_COMPANY_SECRETS = gql`
  query CompanySecretsByCompanyId($companyId: ID!) {
    companySavedSecrets: getAllCompanySecretInfoByCompany(companyId: $companyId) {
      secret
      status
      mode
    }
  }
`;

export const GET_ALL_AVAILABLE_COMPANY_SECRETS = gql`
  query CompanySecretsAvailable {
    companyAvailableSecrets: getAllAvailableCompanySecrets {
      publicName
      secret
    }
  }
`;

export const GET_JOB_TITLES = gql`
  query UserJobTitle {
    result: jobTitles
  }
`;

export const UPDATE_SECRET = gql`
  mutation UpdateSecret($companyId: ID!, $form: CompanySecretMeteoSourceDTO) {
    updatedSecretIntegration: saveConfigurationMeteoSource(companyId: $companyId, form: $form) {
      secret
      status
      mode
    }
  }
`;

export const UPDATE_COMPANY_EXTERNAL = gql`
  mutation UpdateCompany($companyUpdateSuperUserDTO: CompanyUpdateSuperUserDTO!, $image: Upload) {
    companyUpdateSuperUser(companyUpdateSuperUserDTO: $companyUpdateSuperUserDTO, image: $image) {
      id
      name
      email
      country {
        id
      }
      logo {
        highResUrl
        lowResUrl
      }
    }
  }
`;

export const GET_USERS = gql`
  query Users(
    $pagination: PaginationForm!
    $sorting: [SortOrder]!
    $search: [String]
    $filters: [UserFilterEntry]
    $teamId: String
  ) {
    result: users(pagination: $pagination, sorting: $sorting, search: $search, filters: $filters, teamId: $teamId) {
      content {
        id
        status
        ...CompanyUser
        ...Role
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.role}
  ${fragments.companyUser}
`;

export const CREATE_USER_BY_SUPERUSER = gql`
  mutation CreateUserBySuperUser($userForm: CreateUserSuperUserDTO!) {
    createUserBySuperUser(createUserSuperUserDTO: $userForm) {
      id
      status
      ...Role
      ...Profile
    }
  }
  ${fragments.role}
  ${fragments.profile}
`;

export const CREATE_USER_BY_SUPPORT = gql`
  mutation CreateUserBySupport($userForm: CreateUserSupportDTO!) {
    createUserBySupport(createUserSupportDTO: $userForm) {
      id
      status
      ...Role
      ...Profile
    }
  }
  ${fragments.role}
  ${fragments.profile}
`;

export const UPDATE_USER_BY_SUPERUSER = gql`
  mutation UpdateUserBySuperUser($userForm: UpdateUserSuperUserDTO!) {
    updateUserBySuperUser(updateUserSuperUserDTO: $userForm) {
      id
    }
  }
`;

export const UPDATE_USER_BY_SUPPORT = gql`
  mutation UpdateUserBySupport($userForm: UpdateUserSupportDTO!) {
    updateUserBySupport(updateUserSupportDTO: $userForm) {
      id
    }
  }
`;

export const UPDATE_USER_BY_PROFILE = gql`
  mutation UpdateUserByProfile($userForm: UpdateUserProfileDTO!) {
    updateUserByProfile(updateUserProfileDTO: $userForm) {
      id
    }
  }
`;

export const UPDATE_TEMPORARY_PASSWORD = gql`
  mutation UpdateTemporaryPassword($email: String!) {
    updateTemporaryPassword(email: $email) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const UPDATE_LAST_LOGIN = gql`
  mutation UpdateLastLogin {
    updateLastLogin {
      id
    }
  }
`;

export const GET_COMPANY_LOGO = gql`
  query GetCompanyLogo($companyId: ID!) {
    result: getCompanyLogo(companyId: $companyId) {
      lowResUrl
    }
  }
`;
