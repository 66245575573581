/* eslint-disable max-len */
import React from 'react';
import { DesignItemDetailsContainer, DesignItemDetailsRowWrapper } from './design-item-details.styled';
import { IDesign } from 'types/design';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';

interface IProps {
  item: IDesign;
  hidden?: boolean;
}

const DesignItemDetails: React.FC<IProps> = ({ item, hidden }) => {
  const { inverter1, structure, module } = item.inputs || {};
  const { getValueOf } = useConverter();
  const { t } = useTranslation();

  if (!item.outputs) return null;

  return (
    <DesignItemDetailsContainer>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('design:power')}
        </Typography>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {getValueOf(designAttributes.power.ratedPower, item)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.power.peakPower, item)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.power.ratioDcAc, item)} <span>DC/AC</span>
          </Typography>
        </div>
      </DesignItemDetailsRowWrapper>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('design:energy')}
        </Typography>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {getValueOf(designAttributes.energy.specificProduction, item)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.energy.eneryYield, item)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.energy.plantPR, item)}
          </Typography>
        </div>
      </DesignItemDetailsRowWrapper>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('design-process:site')}
        </Typography>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {t(`design:${designAttributes.site.installedArea.label}`)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {getValueOf(designAttributes.site.installedArea, item)}
          </Typography>
        </div>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {getValueOf(designAttributes.meteo.source, item)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.meteo.yearGhi, item)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.meteo.avgTemp, item)}
          </Typography>
        </div>
      </DesignItemDetailsRowWrapper>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('equipment')}
        </Typography>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-2">{`${module.manufacturer}`}</Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.equipment.modulePower, item)}
          </Typography>
          <p className="body-col body-col-1" />
        </div>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {`${inverter1.manufacturer} ${module.model}`}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.equipment.inverter1Power, item)}
          </Typography>
          <p className="body-col body-col-1" />
        </div>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {`${structure.manufacturer} ${structure.model} - ${structure.positionNumber}${structure.positionLetter}`}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.equipment.modulesPerString, item)} <span>modules/string</span>
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.equipment.tiltAngle, item)} <span>Tilt</span>
          </Typography>
        </div>
        <div className="section-row">
          <p className="body-col body-col-2" />
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.equipment.pitchDistance, item)} <span>Pitch</span>
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.equipment.gcr, item)} <span>GCR</span>
          </Typography>
        </div>
        <div className="section-row">
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {t('design:lvConfig')}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(designAttributes.equipment.lvConfig, item)}
          </Typography>
          <p className="body-col body-col-1" />
        </div>
      </DesignItemDetailsRowWrapper>
    </DesignItemDetailsContainer>
  );
};

export default DesignItemDetails;
