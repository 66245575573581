import React from 'react';
import { ChevronDownSvg, ChevronUpSvg } from 'assets/icons';
import { useTheme } from 'styled-components';
import { SVGWrapper } from '@ratedpower/components';

interface CollapseIconProps {
  isCollapsed: boolean;
}

const NotificationsCollapseIcon: React.FC<CollapseIconProps> = ({ isCollapsed }) => {
  const theme = useTheme();

  return (
    <SVGWrapper color={theme.v2.icon.primary.default} icon={isCollapsed ? ChevronDownSvg : ChevronUpSvg} size="m" />
  );
};

export default NotificationsCollapseIcon;
