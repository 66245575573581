import { Typography } from '@ratedpower/components';
import styled from 'styled-components';

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.v2.spacing.m};
  row-gap: ${({ theme }) => theme.v2.spacing.m};
`;

export const FormError = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.v2.spacing.m};
`;
