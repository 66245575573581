import React from 'react';
import * as S from 'components/error-components/zero-created-2/zero-created-2.styled';
import { ZeroCreatedSubtitleContainer } from 'components/error-components/zero-created-2/zero-created-2.styled';
import { Typography } from '@ratedpower/components';

interface IProps {
  Icon: React.FunctionComponent;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const ZeroCreated2: React.FC<IProps> = ({ Icon, title, subtitle }) => {
  return (
    <S.ZeroCreatedContainer>
      <Icon />
      {title && (
        <Typography weight={'bold'} size={'m'}>
          {title}
        </Typography>
      )}
      {subtitle && <ZeroCreatedSubtitleContainer size={'s'}>{subtitle}</ZeroCreatedSubtitleContainer>}
    </S.ZeroCreatedContainer>
  );
};

export default ZeroCreated2;
