import React from 'react';
import { RemoteImage } from '../hooks/use-remote-image';
import { Converter, IConvertToShowOptions } from 'types/preferences.types';
import { t } from 'i18next';

const DataDisplay = (value: any, unit: string, suffix = '') => {
  return (
    <>
      {value}
      <span className="unit">{` ${unit}${suffix}`}</span>
    </>
  );
};

export interface IAttribute {
  key: string | string[];
  label: string;
  sortKeys?: string[];
  className?: string;
  collapsible?: boolean;
  converter?: {
    name: Converter;
    options?: IConvertToShowOptions;
  };
  render: (data: any, currency?: string, unit?: string) => any;
}

export interface IBessDesignAttributes {
  simulationType: IAttribute;
  notes: IAttribute;
  satellitePicture: {
    high: IAttribute;
    thumbnail: IAttribute;
    low: IAttribute;
  };
  bess: {
    activePowerAc: IAttribute;
    energyCapacity: IAttribute;
    operationHours: IAttribute;
  };
  equipment: {
    storageInverterRatedPower: IAttribute;
    bessContainerCapacity: IAttribute;
  };
  site: {
    installedArea: IAttribute;
  };
}

export const bessDesignAttributes: IBessDesignAttributes = {
  simulationType: {
    key: 'simulationType',
    label: 'type-of-design',
    render: () => t('design-process:batch.single-bess'),
  },
  notes: {
    key: 'notes',
    label: 'notes',
    render: (data) => data || '-',
  },
  satellitePicture: {
    low: {
      key: 'satellitePicture.low',
      label: 'layout-view',
      className: 'img-layout',
      render: (data) => <RemoteImage url={data} className="img-design-layout" alt="Layout view" />,
    },
    high: {
      key: 'satellitePicture.high',
      label: 'layout-view',
      className: 'img-layout',
      render: (data) => <RemoteImage url={data} className="img-design-layout" alt="Layout view" />,
    },
    thumbnail: {
      key: 'satellitePicture.thumbnail',
      label: 'layout-view',
      className: 'img-layout',
      render: (data) => <RemoteImage url={data} className="img-design-layout" alt="Layout view" />,
    },
  },
  bess: {
    activePowerAc: {
      key: 'standaloneBatterySimulationOutputs.activePowerAc',
      label: 'activePowerAc',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'W'),
    },
    energyCapacity: {
      key: 'standaloneBatterySimulationOutputs.energyCapacity',
      label: 'energyCapacity',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
    },
    operationHours: {
      key: 'standaloneBatterySimulationOutputs.operationHours',
      label: 'operationHours',
      render: (data) => `${data.toFixed(1)}`,
    },
  },
  equipment: {
    bessContainerCapacity: {
      key: 'standaloneBatterySimulationInputs.capacityPerContainer',
      label: 'capacityPerContainer',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Wh'),
    },
    storageInverterRatedPower: {
      key: 'standaloneBatterySimulationInputs.inverter.ratedPower',
      label: 'ratedPower',
      converter: {
        name: 'dimension',
        options: {
          decimals: 3,
        },
      },
      render: (data) => DataDisplay(data.value, data.unit, 'Va'),
    },
  },
  site: {
    installedArea: {
      key: 'site.siteBatterySummary.area',
      label: 'installed',
      converter: {
        name: 'surface',
      },
      render: (data) => DataDisplay(data.value, data.unit),
    },
  },
};
