import styled from 'styled-components';

export const TopbarWrapper = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.xl};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightTopbarWrapper = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
`;

export const ErrorWrapper = styled.div`
  margin-top: ${({ theme }) => theme.v2.spacing.xs};
`;
