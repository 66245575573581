import { IUnits } from 'types/preferences.types';

export const volumeUnits: IUnits = {
  INTERNATIONAL: {
    L: {
      unit: 'km³',
      toShow: (value) => value * 1e-9,
      toSend: (value) => value * 1e9,
    },
    M: {
      unit: 'm³',
      toShow: (value) => value,
      toSend: (value) => value,
    },
    S: {
      unit: 'cm³',
      toShow: (value) => value * 1e6,
      toSend: (value) => value * 1e-6,
    },
    XS: {
      unit: 'mm²',
      toShow: (value) => value * 1e9,
      toSend: (value) => value * 1e-9,
    },
  },
  IMPERIAL: {
    L: {
      unit: 'mi³',
      toShow: (value) => value / 4.168e9,
      toSend: (value) => value * 4.168e9,
    },
    M: {
      unit: 'ft³',
      toShow: (value) => value * 35.315,
      toSend: (value) => value / 35.315,
    },
    XS: {
      unit: 'in³',
      toShow: (value) => value * 61020,
      toSend: (value) => value / 61020,
    },
  },
};
