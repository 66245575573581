import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Checkbox } from '@ratedpower/components';

interface IProps {
  id: string;
  publicName: string;
  disabled: boolean;
  checkAutoGenerate: boolean;
  toggleCheckAutogenerate?: () => void;
  hidden?: boolean;
}

const IntegrationCheckbox: React.FC<IProps> = ({
  id,
  publicName,
  checkAutoGenerate,
  disabled,
  hidden,
  toggleCheckAutogenerate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={hidden ? 'visibility-hidden' : ''}>
      <Checkbox
        id={`auto-generate-${id}`}
        checked={checkAutoGenerate}
        onChange={() => {
          toggleCheckAutogenerate && toggleCheckAutogenerate();
        }}
        label={t('company:auto-generate-meteo-integration', { integrationName: publicName })}
        color={theme.primary}
        disabled={disabled}
      />
    </div>
  );
};

export default IntegrationCheckbox;
