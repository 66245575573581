import React, { useMemo } from 'react';
import { IAttribute } from 'utils/constants/design-attributes';
import useDesignComparisonScatterChart, { IChartDimensionUnits } from './use-design-comparison-scatter-chart';
import DesignComparisonScatterChartContainer from './design-comparison-scatter-chart-container';

interface IChartAxisData {
  x: string;
  y: string;
  [key: string]: string;
}

export interface IPropsDesignComparisonScatterChart {
  title: string;
  data: any[];
  xAttr: IAttribute;
  yAttr: IAttribute;

  formatUnits?: (dimensionUnits: IChartDimensionUnits) => IChartAxisData;
  formatAxisLabels?: (dimensionUnits: IChartDimensionUnits) => IChartAxisData;
  tooltipLabels: IChartAxisData;
}
const DesignComparisonScatterChart: React.FC<IPropsDesignComparisonScatterChart> = ({
  title,
  data,
  xAttr,
  yAttr,
  tooltipLabels,
  ...optionalProps
}) => {
  const { formattedData, dimensionUnits } = useDesignComparisonScatterChart({ data, xAttr, yAttr });

  const units = useMemo<IChartAxisData>(() => {
    if (!optionalProps.formatUnits) return { x: '', y: '' };
    return optionalProps.formatUnits(dimensionUnits);
  }, [dimensionUnits, optionalProps]);

  const axisLabels = useMemo<IChartAxisData>(() => {
    if (!optionalProps.formatAxisLabels) return tooltipLabels;
    return optionalProps.formatAxisLabels(dimensionUnits);
  }, [dimensionUnits, optionalProps, tooltipLabels]);

  return (
    <DesignComparisonScatterChartContainer
      title={title}
      data={data}
      formattedData={formattedData}
      units={units}
      axisLabels={axisLabels}
      tooltipLabels={tooltipLabels}
    />
  );
};

export default DesignComparisonScatterChart;
