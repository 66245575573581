import { ITab } from '@ratedpower/components';
import { AccountSvg, ConfigSvg, SubscriptionSvg } from 'assets/icons';

export const PROFILE_TABS: ITab[] = [
  {
    id: 'profile',
    label: 'user:my-profile',
    Icon: AccountSvg,
  },
  {
    id: 'preferences',
    label: 'user:my-preferences',
    Icon: ConfigSvg,
  },
  {
    id: 'plan',
    label: 'user:my-plan',
    Icon: SubscriptionSvg,
  },
];

export const PROFILE_TABS_URLS: { [key: string]: string } = {
  profile: '/profile',
  preferences: '/profile/preferences',
  plan: '/profile/plan',
};
