import React, { useEffect, useState } from 'react';
import { InputText, Typography } from '@ratedpower/components';

const EditableInputTextCell: React.FC<{ info: any; error?: boolean }> = ({ info, error }) => {
  const {
    getValue,
    row: { index, original },
    column: { id },
    table,
  } = info;
  const initialValue = getValue();
  const [cellValue, setCellValue] = useState(initialValue);

  useEffect(() => {
    setCellValue(initialValue);
  }, [initialValue]);

  return !original.editable ? (
    <Typography>{getValue()}</Typography>
  ) : (
    <InputText
      type="text"
      value={cellValue}
      error={error}
      onChange={(value: string) => setCellValue(value)}
      onBlur={() => table.options.meta.updateData(index, id, cellValue)}
    />
  );
};

export default EditableInputTextCell;
