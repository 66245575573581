import { t } from 'i18next';
import { DESIGN_COMPARISON_CHARTS } from 'utils/constants/design-comparison';
import { IComparisonFilter } from 'types/design-comparison.types';
import { IDesignAttributes } from 'utils/constants/design-attributes';
import { IPropsDesignComparisonScatterChart } from '../design-comparison-scatter-chart/design-comparison-scatter-chart';
import { ChartFunction } from './types';
import { IDesign } from 'types/design';
import { CalculationResult, FinancialResultsDTO } from 'pages/design-process/outputs/financial/types';

function updateFinancialResultDTO(designs: IDesign[], key: keyof FinancialResultsDTO) {
  const updatedDesigns = designs.map((design) => {
    const value = design?.outputs?.financialResultsDTO?.[key];
    if (value !== undefined && value !== null && typeof value === 'number') {
      return {
        ...design,
        outputs: {
          ...design.outputs,
          financialResultsDTO: {
            ...design?.outputs?.financialResultsDTO,
            [key]: value * 100,
          },
        },
      };
    }
    return design;
  });
  return updatedDesigns;
}

export const graphicScatterAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes,
  activeAttribute: IComparisonFilter
): IPropsDesignComparisonScatterChart | null => {
  const chartFunction = chartFunctions[activeAttribute.label];
  if (!chartFunction) return null;
  return chartFunction(designs, designAttributes);
};

const getSpecificProductionVsDcAcRatioAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  return {
    title: t('design:comparison-charts.specific-production-vs-dcac-ratio'),
    data: designs,
    xAttr: designAttributes.power.ratioDcAc,
    yAttr: designAttributes.energy.specificProduction,
    tooltipLabels: {
      x: t('design:ratioDcAc'),
      y: t('design:specificProduction'),
    },
    formatUnits: () => ({
      x: '',
      y: 'kWh/kWp',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:ratioDcAc')}`,
      y: `${t('design:specificProduction')} (kWh/kWp)`,
    }),
  };
};

const getNpvVsDCACRatioAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => ({
  title: t('design:comparison-charts.npv-vs-dc-ac-ratio'),
  data: designs,
  xAttr: designAttributes.power.ratioDcAc,
  yAttr: designAttributes.financial.npv,
  tooltipLabels: {
    x: t('design:ratioDcAc'),
    y: t('design:net-present-value'),
  },
  formatUnits: () => ({
    x: '',
    y: '',
  }),
  formatAxisLabels: () => ({
    x: `${t('design:ratioDcAc')}`,
    y: `${t('design:net-present-value')} [m.u.]`,
  }),
});

const getIrrVsDCACRatioAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.irrCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.irr-vs-dc-ac-ratio'),
    data: updateFinancialResultDTO(filteredDesigns, 'irr'),
    xAttr: designAttributes.power.ratioDcAc,
    yAttr: designAttributes.financial.irr,
    tooltipLabels: {
      x: t('design:ratioDcAc'),
      y: t('design:internal-rate-return'),
    },
    formatUnits: () => ({
      x: '',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:ratioDcAc')}`,
      y: `${t('design:internal-rate-return')}`,
    }),
  };
};

const getPaybackVsTiltAnglesAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) =>
      design?.outputs?.financialResultsDTO.paybackCalculationResult === CalculationResult.CORRECT &&
      !design?.inputs?.structure?.isTracker
  );
  return {
    title: t('design:comparison-charts.payback-vs-tilt-angles'),
    data: filteredDesigns,
    xAttr: designAttributes.equipment.tiltAngle,
    yAttr: designAttributes.financial.payback,
    tooltipLabels: {
      x: t('design:tilt-angles'),
      y: t('design:payback'),
    },
    formatUnits: () => ({
      x: '°',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:tilt-angles')} [°]`,
      y: `${t('design:payback')} [years]`,
    }),
  };
};

const getRoiVsGCRAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.roiCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.roi-vs-gcr'),
    data: updateFinancialResultDTO(filteredDesigns, 'roi'),
    xAttr: designAttributes.equipment.gcr,
    yAttr: designAttributes.financial.roi,
    tooltipLabels: {
      x: t('design:gcr'),
      y: t('design:net-return-of-investment'),
    },
    formatUnits: () => ({
      x: '%',
      y: '%',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:gcr')} %`,
      y: `${t('design:net-return-of-investment')} [%]`,
    }),
  };
};

const getNpvVsGCRAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => ({
  title: t('design:comparison-charts.npv-vs-gcr'),
  data: designs,
  xAttr: designAttributes.equipment.gcr,
  yAttr: designAttributes.financial.npv,
  tooltipLabels: {
    x: t('design:gcr'),
    y: t('design:net-present-value'),
  },
  formatUnits: () => ({
    x: '%',
    y: '',
  }),
  formatAxisLabels: () => ({
    x: `${t('design:gcr')} %`,
    y: `${t('design:net-present-value')} [m.u.]`,
  }),
});

const getNpvVsTiltAngleAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter((design) => !design?.inputs?.structure?.isTracker);
  return {
    title: t('design:comparison-charts.npv-vs-tilt-angle'),
    data: filteredDesigns,
    xAttr: designAttributes.equipment.tiltAngle,
    yAttr: designAttributes.financial.npv,
    tooltipLabels: {
      x: t('design:tilt-angles'),
      y: t('design:net-present-value'),
    },
    formatUnits: () => ({
      x: '°',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:tilt-angles')} [°]`,
      y: `${t('design:net-present-value')} [m.u.]`,
    }),
  };
};

const getIrrVsGCRAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.irrCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.irr-vs-gcr'),
    data: updateFinancialResultDTO(filteredDesigns, 'irr'),
    xAttr: designAttributes.equipment.gcr,
    yAttr: designAttributes.financial.irr,
    tooltipLabels: {
      x: t('design:gcr'),
      y: t('design:internal-rate-return'),
    },
    formatUnits: () => ({
      x: '%',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:gcr')} %`,
      y: `${t('design:internal-rate-return')}`,
    }),
  };
};

const getIrrVsTiltAngleAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) =>
      design?.outputs?.financialResultsDTO.irrCalculationResult === CalculationResult.CORRECT &&
      !design?.inputs?.structure?.isTracker
  );
  return {
    title: t('design:comparison-charts.irr-vs-tilt-angle'),
    data: updateFinancialResultDTO(filteredDesigns, 'irr'),
    xAttr: designAttributes.equipment.tiltAngle,
    yAttr: designAttributes.financial.irr,
    tooltipLabels: {
      x: t('design:tilt-angles'),
      y: t('design:internal-rate-return'),
    },
    formatUnits: () => ({
      x: '°',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:tilt-angles')} [°]`,
      y: `${t('design:internal-rate-return')}`,
    }),
  };
};

const getPaybackVsDcAcRatioAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.paybackCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.payback-vs-dc-ac-ratio'),
    data: filteredDesigns,
    xAttr: designAttributes.power.ratioDcAc,
    yAttr: designAttributes.financial.payback,
    tooltipLabels: {
      x: t('design:ratioDcAc'),
      y: t('design:payback'),
    },
    formatUnits: () => ({
      x: '',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: t('design:ratioDcAc'),
      y: `${t('design:payback')} [years]`,
    }),
  };
};

const getPaybackVsGCRAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.paybackCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.payback-vs-gcr'),
    data: filteredDesigns,
    xAttr: designAttributes.equipment.gcr,
    yAttr: designAttributes.financial.payback,
    tooltipLabels: {
      x: t('design:gcr'),
      y: t('design:payback'),
    },
    formatUnits: () => ({
      x: '%',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:gcr')} %`,
      y: `${t('design:payback')} [years]`,
    }),
  };
};

const getDiscountedPaybackVsDcAcRatioAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.discountedPaybackCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.discounted-payback-vs-dc-ac-ratio'),
    data: filteredDesigns,
    xAttr: designAttributes.power.ratioDcAc,
    yAttr: designAttributes.financial.discountedPayback,
    tooltipLabels: {
      x: t('design:ratioDcAc'),
      y: t('design:discountedPayback'),
    },
    formatUnits: () => ({
      x: '',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:ratioDcAc')}`,
      y: `${t('design:discountedPayback')} [years]`,
    }),
  };
};

const getDiscountedPaybackVsGCRAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.discountedPaybackCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.discounted-payback-vs-gcr'),
    data: filteredDesigns,
    xAttr: designAttributes.equipment.gcr,
    yAttr: designAttributes.financial.discountedPayback,
    tooltipLabels: {
      x: t('design:gcr'),
      y: t('design:discountedPayback'),
    },
    formatUnits: () => ({
      x: '%',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:gcr')} %`,
      y: `${t('design:discountedPayback')} [years]`,
    }),
  };
};

const getDiscountedPaybackVsTiltAngleAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) =>
      design?.outputs?.financialResultsDTO.discountedPaybackCalculationResult === CalculationResult.CORRECT &&
      !design?.inputs?.structure?.isTracker
  );
  return {
    title: t('design:comparison-charts.discounted-payback-vs-tilt-angle'),
    data: filteredDesigns,
    xAttr: designAttributes.equipment.tiltAngle,
    yAttr: designAttributes.financial.discountedPayback,
    tooltipLabels: {
      x: t('design:tilt-angles'),
      y: t('design:discountedPayback'),
    },
    formatUnits: () => ({
      x: '°',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:tilt-angles')} [°]`,
      y: `${t('design:discountedPayback')} [years]`,
    }),
  };
};

const getRoIVsDcAcRatioAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.roiCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.roi-vs-dc-ac-ratio'),
    data: updateFinancialResultDTO(filteredDesigns, 'roi'),
    xAttr: designAttributes.power.ratioDcAc,
    yAttr: designAttributes.financial.roi,
    tooltipLabels: {
      x: t('design:ratioDcAc'),
      y: t('design:net-return-of-investment'),
    },
    formatUnits: () => ({
      x: '',
      y: '%',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:ratioDcAc')}`,
      y: `${t('design:net-return-of-investment')} [%]`,
    }),
  };
};

const getRoIVsTiltAngleAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) =>
      design?.outputs?.financialResultsDTO.roiCalculationResult === CalculationResult.CORRECT &&
      !design?.inputs?.structure?.isTracker
  );
  return {
    title: t('design:comparison-charts.roi-vs-tilt-angle'),
    data: updateFinancialResultDTO(filteredDesigns, 'roi'),
    xAttr: designAttributes.equipment.tiltAngle,
    yAttr: designAttributes.financial.roi,
    tooltipLabels: {
      x: t('design:tilt-angles'),
      y: t('design:net-return-of-investment'),
    },
    formatUnits: () => ({
      x: '°',
      y: '%',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:tilt-angles')} [°]`,
      y: `${t('design:net-return-of-investment')} [%]`,
    }),
  };
};

const getLcoeVsDcAcRatioAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => ({
  title: t('design:comparison-charts.lcoe-vs-dc-ac-ratio'),
  data: designs,
  xAttr: designAttributes.power.ratioDcAc,
  yAttr: designAttributes.financial.lcoe,
  tooltipLabels: {
    x: t('design:ratioDcAc'),
    y: t('design:lcoe'),
  },
  formatUnits: () => ({
    x: '',
    y: '',
  }),
  formatAxisLabels: () => ({
    x: `${t('design:ratioDcAc')}`,
    y: `${t('design:lcoe')} [m.u./MWh]`,
  }),
});

const getLcoeVsGCRAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) => design?.outputs?.financialResultsDTO.lcoeCalculationResult === CalculationResult.CORRECT
  );
  return {
    title: t('design:comparison-charts.lcoe-vs-gcr'),
    data: filteredDesigns,
    xAttr: designAttributes.equipment.gcr,
    yAttr: designAttributes.financial.lcoe,
    tooltipLabels: {
      x: t('design:gcr'),
      y: t('design:lcoe'),
    },
    formatUnits: () => ({
      x: '%',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:gcr')} %`,
      y: `${t('design:lcoe')} [m.u./MWh]`,
    }),
  };
};

const getLcoeVsTiltAngleAttributes = (
  designs: IDesign[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonScatterChart => {
  const filteredDesigns = designs.filter(
    (design) =>
      design?.outputs?.financialResultsDTO.lcoeCalculationResult === CalculationResult.CORRECT &&
      !design?.inputs?.structure?.isTracker
  );
  return {
    title: t('design:comparison-charts.lcoe-vs-tilt-angle'),
    data: filteredDesigns,
    xAttr: designAttributes.equipment.tiltAngle,
    yAttr: designAttributes.financial.lcoe,
    tooltipLabels: {
      x: t('design:tilt-angles'),
      y: t('design:lcoe'),
    },
    formatUnits: () => ({
      x: '°',
      y: '',
    }),
    formatAxisLabels: () => ({
      x: `${t('design:tilt-angles')} [°]`,
      y: `${t('design:lcoe')} [m.u./MWh]`,
    }),
  };
};

const chartFunctions: {
  [key: string]: ChartFunction<IPropsDesignComparisonScatterChart>;
} = {
  [DESIGN_COMPARISON_CHARTS.specificProductionVsDcAcRatio]: getSpecificProductionVsDcAcRatioAttributes,
  [DESIGN_COMPARISON_CHARTS.npvVsDCACRatio]: getNpvVsDCACRatioAttributes,
  [DESIGN_COMPARISON_CHARTS.paybackVsTiltAngles]: getPaybackVsTiltAnglesAttributes,
  [DESIGN_COMPARISON_CHARTS.irrVsDCACRatio]: getIrrVsDCACRatioAttributes,
  [DESIGN_COMPARISON_CHARTS.roiVsGCR]: getRoiVsGCRAttributes,
  [DESIGN_COMPARISON_CHARTS.npvVsGCR]: getNpvVsGCRAttributes,
  [DESIGN_COMPARISON_CHARTS.npvVsTiltAngle]: getNpvVsTiltAngleAttributes,
  [DESIGN_COMPARISON_CHARTS.irrVsGCR]: getIrrVsGCRAttributes,
  [DESIGN_COMPARISON_CHARTS.irrVsTiltAngle]: getIrrVsTiltAngleAttributes,
  [DESIGN_COMPARISON_CHARTS.paybackVsDCACRatio]: getPaybackVsDcAcRatioAttributes,
  [DESIGN_COMPARISON_CHARTS.paybackVsGCR]: getPaybackVsGCRAttributes,
  [DESIGN_COMPARISON_CHARTS.discountedPaybackVsDCACRatio]: getDiscountedPaybackVsDcAcRatioAttributes,
  [DESIGN_COMPARISON_CHARTS.discountedPaybackVsGCR]: getDiscountedPaybackVsGCRAttributes,
  [DESIGN_COMPARISON_CHARTS.discountedPaybackVsTiltAngle]: getDiscountedPaybackVsTiltAngleAttributes,
  [DESIGN_COMPARISON_CHARTS.roiVsDCACRatio]: getRoIVsDcAcRatioAttributes,
  [DESIGN_COMPARISON_CHARTS.roiVsTiltAngle]: getRoIVsTiltAngleAttributes,
  [DESIGN_COMPARISON_CHARTS.lcoeVsDCACRatio]: getLcoeVsDcAcRatioAttributes,
  [DESIGN_COMPARISON_CHARTS.lcoeVsGCR]: getLcoeVsGCRAttributes,
  [DESIGN_COMPARISON_CHARTS.lcoeVsTiltAngle]: getLcoeVsTiltAngleAttributes,
};
