export const PROJECT_DESIGN_UNALLOWED_CHARS = ['+', '/', ':', '*', '?', '<', '>', '|', '“', '\\', '"'];

export const isDate = (date: string) => {
  const re = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])@(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/;
  return re.test(date);
};

export const hasNumber = (value: string) => /\d/.test(value);

export const hasMin12Characters = (value: string) => value.length >= 12;

export const hasSymbol = (value: string) => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);

export const hasLowerCase = (value: string) => /[a-z]/.test(value);

export const hasUpperCase = (value: string) => /[A-Z]/.test(value);

export const isNumeric = (value: string) => !isNaN(+value);

export const hasValidCharacters = (unallowedChars: string[]) => (value: string) =>
  !value.split('').some((char) => unallowedChars.includes(char));

export const hasLessThanNChars = (n: number) => (value: string) => value.length < n;

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
