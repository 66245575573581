import { IUnits } from 'types/preferences.types';

export const invertedSurfaceUnits: IUnits = {
  INTERNATIONAL: {
    M: {
      unit: '1/ha',
      toShow: (value) => value,
      toSend: (value) => value,
    },
  },
  IMPERIAL: {
    M: {
      unit: '1/acre',
      toShow: (value) => value * 0.4046856,
      toSend: (value) => value / 0.4046856,
    },
  },
};
