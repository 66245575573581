import { IUserMinInfoDTO } from './users';

export interface ITeam {
  id: string;
  teamManagersList?: IUserMinInfoDTO[];
  coverImage?: {
    lowResUrl: string;
    highResUrl: string;
  };
  coverColor?: string;
  name: string;
  users?: IUserMinInfoDTO[];
  numMembers: number;
  numProjects: number;
  description: string;
}

export enum TeamActions {
  ADD_PROJECT = 'ADD_PROJECT',
  ADD_MEMBER = 'ADD_MEMBER',
  TEAM_SETTINGS = 'TEAM_SETTINGS',
  DELETE_TEAM = 'DELETE_TEAM',
}

export enum TeamContentType {
  PROJECTS = 'PROJECTS',
  MEMBERS = 'MEMBERS',
  SETTINGS = 'SETTINGS',
  TEAMS = 'TEAMS',
}

export interface ITeamsFilters {
  countProjects: string[];
  countMembers: string[];
  teamManagers: string[];
  countries: string[];
}

export type CreatedTeam = { id: string; name: string; coverImage?: { highResUrl: string }; coverColor?: string };

export interface ICreateTeamDTO {
  name: string;
  description?: string;
  image?: File;
  coverColor?: string;
  companyId?: string;
}

export type IUpdateTeamDTO = ICreateTeamDTO & {
  id: string;
};

export interface IUserTeamsAssigned {
  name: string;
  numProjects: number;
}

export interface IMemberDTO {
  id: string;
  name: string;
  lastname: string;
  email: string;
  codeLanguage: string;
  jobTitle: string;
  roleName: string;
}
