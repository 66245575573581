import React from 'react';
import * as serviceWorker from './serviceWorker';
import Router from 'layout/app-router';

// redux
import { Provider } from 'react-redux';
import store from './redux/store';

// external CSS
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'rsuite/dist/rsuite.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '@reactour/popover/dist/index.css';

import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from '@ratedpower/components';

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  });
}

// UX monitoring
// import datadog from './services/datadog';
// datadog();

// tslint:disable-next-line: no-var-requires

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Provider>
  );
} else {
  console.error('Root container not found');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
