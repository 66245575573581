import React from 'react';
import { DesignItemLoadingContainer } from '../loading/design-item-loading.styled';
import { DesignColumn } from '../../../design-column.styled';
import BtnFavorite from 'components/buttons/btn-favorite/btn-favorite';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { DESIGN_ACTIONS as action } from 'utils/constants/designs';
import { IBessDesign, IDesign, SIMULATION_TYPE } from 'types/design';
import { getDynamicDate } from 'utils/date';
import { IconButton, Tag, Tooltip, Typography, ConditionalWrapper } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { CloneSvg } from 'assets/icons';

interface IProps {
  item: IDesign | IBessDesign;
  onPerform: (action: string) => void;
}

const DesignItemLoading: React.FC<IProps> = ({ item, onPerform }) => {
  const {
    createdBy: { lastname, name },
    name: title,
    publishedDate,
  } = item;
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClone = (e: any) => {
    e.stopPropagation();
    onPerform(action.clone);
  };

  const canCloneDesigns = useCanCloneDesigns();

  const isSingle = item.simulationType === SIMULATION_TYPE.SINGLE;
  return (
    <DesignItemLoadingContainer>
      <div className="main-row">
        <DesignColumn className="favorite" onClick={(e) => e.stopPropagation()}>
          <BtnFavorite isFav={false} />
        </DesignColumn>
        <DesignColumn className="first-column">
          <ConditionalWrapper
            condition={title.length > 20}
            wrapper={(children) => <Tooltip description={title}>{children}</Tooltip>}
          >
            <Typography color={theme.gray_darker} variant="h3" className="name" weight={700} nowrap>
              {item.name}
            </Typography>
          </ConditionalWrapper>
          <Typography className="author" variant="small-text">
            {`${name} ${lastname}`}
          </Typography>
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="data tags">
          <Tag color={isSingle ? 'green' : 'purple'}>
            {isSingle ? t('design-process:batch.single-design') : t('design-process:batch.multiple-pv-designs')}
          </Tag>
        </DesignColumn>

        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="skeleton">
          <Skeleton count={1} />
        </DesignColumn>
        <DesignColumn className="createdAt">
          <p>
            {t('design:design-date', {
              dateFormat: getDynamicDate(publishedDate),
            })}
          </p>
        </DesignColumn>
        <DesignColumn className="more collapsed">
          <IconButton
            Icon={() => <CloneSvg />}
            tooltip={t('action:clone')}
            onClick={handleClone}
            disabled={!canCloneDesigns}
          />
        </DesignColumn>
      </div>
    </DesignItemLoadingContainer>
  );
};

export default DesignItemLoading;
