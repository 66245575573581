import { Permission } from 'access-control/permissions';

export interface IPermission {
  id: string;
  name: string;
  type?: string;
  publishedDate?: Date;
}

export type PermissionType =
  | 'READ'
  | 'WRITE'
  | 'DISABLE'
  | 'VIEW'
  | 'DOWNLOAD'
  | 'SAML'
  | 'DELETE'
  | 'SCORE'
  | 'CREATE'
  | 'RESEND';

export enum ROLE_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum ROLE_TYPE {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface IRole {
  id: string;
  name: string;
  color?: string;
  hierarchyLevel?: number;
  status?: ROLE_STATUS;
  type: ROLE_TYPE;
  publishedDate?: Date;
  permissions: IPermission[];
}

export interface IRoleListItem {
  id?: string;
  color?: string;
  hierarchyLevel?: number;
  name: string;
  description: string;
  type: ROLE_TYPE;
  status?: ROLE_STATUS;
  editable?: boolean;
  permissions: IPermission[];
}

export interface IPermissionType {
  text: string;
  value: Permission;
}
export interface IPermissionToShow {
  group: string;
  permissionType: IPermissionType[];
}

export interface IReadOnlyPermissionSection {
  sectionTitle: string;
  permissions: {
    title: string;
    titleColor?: string;
    points?: string[];
  }[];
}
