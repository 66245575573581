import React from 'react';
import Can from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { Button } from '@ratedpower/components';
import { PlusSvg } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

interface INewProjectButton {
  createProject?: () => void;
  textKey: string;
}

const NewProjectButton = ({ createProject, textKey }: INewProjectButton) => {
  const { subscriptionPlan } = useSelector((state: RootState) => state.currentUser);
  const canCreateProjects =
    !!subscriptionPlan &&
    (subscriptionPlan.projectsPurchased - subscriptionPlan.projectsDone > 0 || subscriptionPlan?.unlimitedProjects) &&
    !subscriptionPlan.subscriptionPlanExpired &&
    createProject;
  const { t } = useTranslation();
  return (
    <Can
      perform={[
        Permission.PROJECT_SUPPORT_CREATE,
        Permission.PROJECT_CREATE_IN_USER_COMPANY,
        Permission.PROJECT_CREATE_IN_USER_TEAM_AND_PUBLIC,
      ]}
    >
      <Button
        size="medium"
        disabled={!canCreateProjects}
        onClick={() => {
          if (canCreateProjects) createProject();
        }}
        Icon={PlusSvg}
        text={t(textKey)}
        data-test="topbar-main-button"
      ></Button>
    </Can>
  );
};

export default NewProjectButton;
