import { useConverter } from '.';
import { Converter, IConvertToShowOptions, UnitSystem } from 'types/preferences.types';
import { useState, useRef, useEffect } from 'react';

export interface ISupportedInputAttributes {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IRange {
  min?: string;
  max?: string;
}

function restrictValueToRange(value: string, range: IRange): string {
  if (!!range.min && Number(value) < Number(range.min)) return range.min;
  if (!!range.max && Number(value) > Number(range.max)) return range.max;
  return value;
}

export const useConvertedInputNumber = (
  baseValue: any,
  updateForm: (newValue: any) => void,
  converterTo?: {
    type: Converter;
    options?: IConvertToShowOptions;
  },
  unitSystem?: UnitSystem
): ISupportedInputAttributes => {
  const { converters } = useConverter({ length: unitSystem });
  const prevBaseValue = useRef();
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    prevBaseValue.current = baseValue;
    setCurrentValue(getDisplayedValue());
  }, [baseValue, converters]);

  function getDisplayedValue() {
    if (isNaN(Number(baseValue))) return '';

    if (!!converterTo && !!converters) {
      const { type, options } = converterTo;
      return converters[type].toShow(baseValue, options).value;
    }
    return baseValue;
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, min, max } = e.target;
    const newValue = restrictValueToRange(value, { min, max });
    if (newValue !== value) {
      setCurrentValue(newValue.toString());
    }

    if (!!converterTo && !!converters) {
      const { type, options } = converterTo;
      const convertedValue = converters[type].toSend(Number(newValue), options);
      updateForm(convertedValue ?? 0);
    } else {
      updateForm(typeof baseValue === 'number' ? Number(newValue) : newValue);
    }
  };

  return {
    onChange,
    onBlur,
    value: currentValue,
  };
};
