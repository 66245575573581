import React, { useMemo } from 'react';
import { IProjectListItem } from 'types/projects';
import { useProjectsMapBox } from './use-projects-mapbox';
import GeoPinsMapbox from 'components/maps/geo-pins-map/geo-pins-mapbox';

interface IProps {
  projects: IProjectListItem[];
  viewMapStatus?: string;
}

const ProjectsMapBox: React.FC<IProps> = ({ projects, viewMapStatus }) => {
  const { convertListItemToGeoJSON, createFeatureCollection } = useProjectsMapBox();

  const featureCollection = useMemo(() => {
    const listItems = convertListItemToGeoJSON(projects);
    return createFeatureCollection(listItems);
  }, [projects]);

  return <GeoPinsMapbox componentUpdateDependency={viewMapStatus} geoJson={featureCollection} />;
};

export default ProjectsMapBox;
