import styled, { css } from 'styled-components';

export interface IButtonSwitchItem {
  label: string;
  value: string;
  info?: string;
  disabled?: boolean;
}

const OptionNonSelected = css`
  background: none;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.gray_lighter};
  label {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    transition: all 0.1s linear;
    background-color: ${({ theme }) => theme.primary_5};
  }
`;

const OptionSelected = css`
  background-color: ${({ theme }) => theme.primary_5};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primary};
  cursor: default;
`;

const OptionDisabled = css`
  background-color: ${({ theme }) => theme.gray_lighter};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.gray_light};
  cursor: default;
`;

const getStatusStyles = ({ selected, disabled }: { selected?: boolean; disabled?: boolean }) => {
  if (disabled) return OptionDisabled;
  if (selected) return OptionSelected;
  return OptionNonSelected;
};

export const ButtonSwitchItemContainer = styled.button<{ selected?: boolean; disabled?: boolean }>`
  align-items: center;
  color: ${({ theme }) => theme.gray_dark};
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 0.6rem 3rem;

  &:first-child {
    border-radius: 2px 0 0 2px;
  }

  &:last-child {
    border-radius: 0 2px 2px 0;
  }

  ${({ selected, disabled }) => getStatusStyles({ selected, disabled })}
`;

export const ButtonSwitchContainer = styled.div<{ $fullWidth: boolean }>`
  background: ${({ theme }) => theme.white};
  border-radius: 4px;

  ${({ $fullWidth }) =>
    $fullWidth
      ? css`
          display: grid;
          grid-auto-columns: 1fr;
          grid-auto-flow: column;

          ${ButtonSwitchItemContainer} {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            min-width: 96px;
            overflow: hidden;
            white-space: nowrap;
          }
        `
      : css`
          display: inline-flex;
        `}

  &.scrollable-items {
    display: initial;
  }
`;
