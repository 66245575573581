import { useQuery } from '@apollo/client';
import { GET_PROJECT_CREATORS } from 'services/projects-gql';

export interface IUseGetProjectCreatos {
  fetchedCreators: any;
  unknownCreators: IProjectCreator[];
  loadingCreators: boolean;
}

export interface IProjectCreator {
  name: string;
  id: string;
  lastname: string;
}

export const useGetProjectCreatos = (): IUseGetProjectCreatos => {
  const { loading, data: fetchedCreators } = useQuery(GET_PROJECT_CREATORS, {
    variables: {
      pagination: {
        page: 0,
        size: 50,
      },
    },
    fetchPolicy: 'cache-first',
  });

  function getCreators() {
    if (fetchedCreators) {
      return fetchedCreators.result.content
        .filter((user: IProjectCreator) => user.name === '-' && user.lastname === '-')
        .map((user: IProjectCreator) => user.id);
    }
    return [''];
  }

  return {
    fetchedCreators,
    unknownCreators: getCreators(),
    loadingCreators: loading,
  };
};
