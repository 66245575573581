import styled, { css } from 'styled-components';

export const CardContainer = styled.div<{ $highlightOnHover: boolean }>`
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.m};
  border-radius: ${({ theme }) => theme.v2.radius.s};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 345px;
  width: 328px;

  ${({ $highlightOnHover }) =>
    $highlightOnHover &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const CardImageContainer = styled.div`
  display: flex;
  width: 328px;
  height: 200px;
  border-bottom: 1px solid ${({ theme }) => theme.gray_lighter};

  .team-image {
    object-fit: contain;
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardContent = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  align-items: baseline;
  row-gap: 8px;
`;

export default { CardContent, CardTitleContainer, CardImageContainer, CardContainer };
