import styled, { css } from 'styled-components';
import { Typography } from '@ratedpower/components';

export const NotificationContainer = styled.div<{ $isRead: boolean }>`
  background-color: ${({ theme, $isRead }) => ($isRead ? theme.v2.bg.secondary.default : theme.v2.bg.primary.default)};
  padding: ${({ theme }) => theme.v2.spacing.s};
  border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  align-items: center;
`;

export const RightTopContainer = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  align-items: center;
`;

export const CollapseWrapper = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  flex-direction: column;
  display: flex;
`;

export const DescriptionCollapsed = styled.div`
  padding: ${({ theme }) => `${theme.v2.spacing.s} ${theme.v2.spacing.xxl}`};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DescriptionWrapper = styled(Typography)<{ $isCollapsed: boolean }>`
  margin-bottom: ${({ theme }) => theme.v2.spacing.m};

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const KnowledgeBaseArticleContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
`;

export const KnowledgebaseArticle = styled.div`
  display: flex;
`;
