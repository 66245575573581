import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_SUPPORT_PLANS, UPGRADE_CHANGE_PLAN, UPGRADE_CURRENT_PLAN } from 'services/subscription-plan-gql';
import { CustomPlanType, ISubscriptionPlanDTO } from 'types/subscription-plan';
import { IForm } from 'utils/hooks/use-form';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useNotificationToast } from './use-notification-toast';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

export interface IUseUpgradePlan {
  upgradePlanModal: IUseModal;
  handleUpgradePlan: (inputs: IForm) => void;
  handleUpgradeChangePlan: (inputs: IForm) => void;
  handleOpenUpgradeModal: (id: string) => void;
}

export const useUpgradePlan = (currentPlan: ISubscriptionPlanDTO): IUseUpgradePlan => {
  const { id: supportPagesCompanyId } = useParams<{ id: string }>();
  const { errorNotification, successNotification } = useNotificationToast();
  const currencies = useSelector((state: RootState) => state.currencies.currencyOptions);

  const upgradePlanModal = useModal();

  const handleOpenUpgradeModal = () => {
    upgradePlanModal.setData({ currentPlan });
    upgradePlanModal.setModalOpen(true);
  };
  const handleUpgradePlan = (inputs: IForm) => {
    const variables = {
      companyId: supportPagesCompanyId,
      subscriptionPlanUpdateDTO: {
        id: currentPlan.id,
        plan: inputs.plan,
        customPlanType: inputs.customPlanType || CustomPlanType.NONE,
        notes: inputs.notes,
        price: Number(inputs.price) || 0,
        projectsPurchased: Number(inputs.projectsPurchased) || 0,
        startDate: currentPlan.startDate,
        expirationDate: inputs.expirationDate || currentPlan.expirationDate,
        currency: currentPlan.currency || currencies[0],
      },
      sendWelcomeEmail: inputs.sendWelcomeEmail,
    };
    upgradeCurrentPlan({ variables });
  };
  const handleUpgradeChangePlan = (inputs: IForm) => {
    const variables = {
      companyId: supportPagesCompanyId,
      subscriptionPlanCreateDTO: {
        plan: inputs.plan,
        price: Number(inputs.price) || 0,
        currency: inputs.currency,
        startDate: inputs.startDate,
        projectsPurchased: Number(inputs.projectsPurchased) || 0,
        expirationDate: inputs.expirationDate,
        customPlanType: inputs.customPlanType,
        notes: inputs.notes,
      },
      sendWelcomeEmail: inputs.sendWelcomeEmail,
    };
    upgradeChangePlan({ variables });
  };

  const [upgradeCurrentPlan] = useMutation(UPGRADE_CURRENT_PLAN, {
    onCompleted: () => {
      upgradePlanModal.setModalOpen(false);
      successNotification('support:update-company-success');
    },
    onError: (apolloError: any) => errorNotification(apolloError),
    refetchQueries: [GET_SUPPORT_PLANS],
  });
  const [upgradeChangePlan] = useMutation(UPGRADE_CHANGE_PLAN, {
    onCompleted: () => {
      upgradePlanModal.setModalOpen(false);
      successNotification('support:update-company-success');
    },
    onError: (apolloError: any) => errorNotification(apolloError),
    refetchQueries: [GET_SUPPORT_PLANS],
  });

  return {
    upgradePlanModal,
    handleUpgradePlan,
    handleUpgradeChangePlan,
    handleOpenUpgradeModal,
  };
};
