import React from 'react';
import classNames from 'classnames';
import { DotsSvg } from 'assets/icons';
import { ISort } from 'types/filter';
import { PopupWrapper, SortableHeader, Tag, Typography, Button } from '@ratedpower/components';
import { TFunction } from 'i18next';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { getDateShortMonthDDYYYY, isDateBetweenDates } from 'utils/date';
import {
  ISubscriptionPlanExtensionDTO,
  ISubscriptionPlanDTO,
  ModificationType,
  PlanStatus,
} from 'types/subscription-plan';

const columnHelper = createColumnHelper<ISubscriptionPlanDTO>();

const getSimpleCell = (value: React.ReactNode, isCurrentPlan: boolean, isHidden: boolean) => {
  return (
    <Typography as="span" className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}>
      {value ?? ''}
    </Typography>
  );
};

const getProjectsRemaining = (
  value: React.ReactNode,
  extensions: ISubscriptionPlanExtensionDTO[],
  isCurrentPlan: boolean,
  isHidden: boolean,
  unlimitedProjects: boolean,
  t: TFunction
) => {
  let remainingValue = value ?? '-';
  remainingValue = unlimitedProjects ? t('company:unlimited') : remainingValue;
  if (extensions.length === 1)
    return (
      <Typography as="span" className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}>
        {remainingValue}
      </Typography>
    );
  return (
    <span className="timelapse-cell">
      {extensions.map((extension) => {
        return (
          <Typography
            as="span"
            key={extension.id}
            className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}
          >
            {`${Number(extension['projectsPurchased']) - Number(extension['projectsDone'])}`}
          </Typography>
        );
      })}
    </span>
  );
};

const getModificationCell = (value: React.ReactNode, isCurrentPlan: boolean, isHidden: boolean, isNone: boolean) => {
  let tagColor = value === ModificationType.UPGRADE ? 'orange' : 'blue';
  if (isHidden) tagColor = 'hidden';

  return (
    <>
      {value && !isNone && (
        <Tag
          color={tagColor}
          ellipsis={false}
          className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}
        >
          {value}
        </Tag>
      )}
    </>
  );
};

const getOptions = (
  t: TFunction,
  planHistoryActions: { [x: string]: (id: string, inputs?: any) => void },
  isCurrentPlan: boolean,
  info: CellContext<ISubscriptionPlanDTO, string>
) => {
  return (
    <span className={classNames('timelapse-cell')}>
      {info.row.original.extensions.map((extension, index) => (
        <PopupWrapper
          key={extension.id}
          dataTest="plan-actions-id"
          popupPosition="leftCenter"
          onClickOption={(option) =>
            planHistoryActions[option.value](info.getValue(), index !== 0 ? extension.id : undefined)
          }
          options={[
            { value: 'edit', label: t('action:edit') },
            ...(!isCurrentPlan && index === 0 ? [{ value: 'delete', label: t('action:delete') }] : []),
            ...(isCurrentPlan &&
            index === 0 &&
            isDateBetweenDates(
              new Date(),
              new Date(info.row.original.startDate),
              new Date(info.row.original.expirationDate)
            )
              ? [
                  { value: 'upgrade', label: t('action:upgrade') },
                  { value: 'renew', label: t('action:renew') },
                ]
              : []),
            ...(!isCurrentPlan &&
            !isDateBetweenDates(
              new Date(),
              new Date(info.row.original.startDate),
              new Date(info.row.original.expirationDate)
            )
              ? [
                  {
                    value: `${info.row.original.status === PlanStatus.HIDDEN ? 'show' : 'hide'}`,
                    label: t(`action:${info.row.original.status === PlanStatus.HIDDEN ? 'show' : 'hide'}`),
                  },
                ]
              : []),
          ]}
        >
          <Button Icon={DotsSvg} variant="ghost" />
        </PopupWrapper>
      ))}
    </span>
  );
};

const getMultiCell = (
  value: React.ReactNode,
  column: string,
  extensions: ISubscriptionPlanExtensionDTO[],
  isCurrentPlan: boolean,
  isHidden: boolean,
  currency?: string
) => {
  if (extensions.length === 1)
    return (
      <Typography as="span" className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}>
        {`${value} ${currency ? currency : ''}` ?? '-'}
      </Typography>
    );
  return (
    <span className={classNames('timelapse-cell')}>
      {extensions.map((extension) => {
        return (
          <Typography
            as="span"
            key={extension.id}
            className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}
          >
            {`${extension[column as keyof ISubscriptionPlanExtensionDTO] ?? value} ${currency ? currency : ''}` ?? '-'}
          </Typography>
        );
      })}
    </span>
  );
};

const getExpirationDate = (
  expDate: string,
  extensions: ISubscriptionPlanExtensionDTO[],
  isCurrentPlan: boolean,
  isHidden: boolean,
  locale: string
) => {
  if (extensions.length === 1)
    return (
      <Typography as="span" className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}>
        {getDateShortMonthDDYYYY(new Date(expDate), locale) || '-'}
      </Typography>
    );
  return (
    <span className={classNames('timelapse-cell')}>
      {extensions.map((extension) => {
        return (
          <Typography
            as="span"
            key={extension.id}
            className={classNames({ 'current-plan': isCurrentPlan, 'is-hidden': isHidden })}
          >
            {getDateShortMonthDDYYYY(new Date(extension.expirationDate), locale)}
          </Typography>
        );
      })}
    </span>
  );
};

export const planHistoryColumns = (
  t: TFunction,
  currentPlanId: string,
  options: {
    sortedBy: ISort;
    setSortedBy: React.Dispatch<React.SetStateAction<ISort>>;
    locale: string;
  },
  fromSupportView?: boolean
) => [
  columnHelper.accessor((row) => row.title, {
    id: 'title',
    cell: (info) =>
      getSimpleCell(
        info.getValue(),
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN
      ),
    header: () => (fromSupportView ? t('company:purchased-plan') : t('company:plan')),
  }),
  columnHelper.accessor((row) => row.modificationType, {
    id: 'modificationType',
    cell: (info) =>
      getModificationCell(
        info.getValue(),
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN,
        info.getValue() === ModificationType.NONE
      ),
    header: () => t('company:modification'),
  }),
  columnHelper.accessor((row) => row.startDate, {
    id: 'startDate',
    cell: (info) =>
      getSimpleCell(
        getDateShortMonthDDYYYY(new Date(info.getValue()), options.locale),
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN
      ),
    header: () => (
      <SortableHeader
        selector={{ label: fromSupportView ? t('company:starting-date') : t('start-date'), code: 'startDate' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  columnHelper.accessor((row) => row.expirationDate, {
    id: 'expirationDate',
    cell: (info) =>
      getExpirationDate(
        info.getValue(),
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN,
        options.locale
      ),
    header: () => (
      <SortableHeader
        selector={{ label: t('expiration-date'), code: 'expirationDate' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  columnHelper.accessor((row) => row.projectsPurchased, {
    id: 'projectsPurchased',
    cell: (info) =>
      getMultiCell(
        info.row.original.unlimitedProjects ? t('company:unlimited') : info.getValue(),
        info.column.id,
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN
      ),
    header: () => t('company:projects-purchased'),
  }),
  columnHelper.accessor((row) => row.projectsDone, {
    id: 'projectsDone',
    cell: (info) =>
      getMultiCell(
        info.getValue(),
        info.column.id,
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN
      ),
    header: () => t('company:used-projects'),
  }),
  columnHelper.accessor((row) => row.projectsDone, {
    id: 'projectsRemaining',
    cell: (info) =>
      getProjectsRemaining(
        Number(info.row.original.projectsPurchased) - Number(info.getValue()),
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN,
        info.row.original.unlimitedProjects,
        t
      ),
    header: () => t('company:remaining-projects'),
  }),
  columnHelper.accessor((row) => row.designsDone, {
    id: 'designsDone',
    cell: (info) =>
      getMultiCell(
        info.getValue(),
        info.column.id,
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN
      ),
    header: () => t('company:created-designs'),
  }),
  columnHelper.accessor((row) => row.usersCreated, {
    id: 'usersCreated',
    cell: (info) =>
      getMultiCell(
        info.getValue(),
        info.column.id,
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN
      ),
    header: () => t('company:users-created'),
  }),
];

export const planHistorySupportColumns = (
  t: TFunction,
  currentPlanId: string,
  options: {
    sortedBy: ISort;
    locale: string;
    setSortedBy: React.Dispatch<React.SetStateAction<ISort>>;
    planHistoryActions: { [x: string]: (id: string, inputs?: any) => void };
    currencies: { [key: string]: string };
  }
) => [
  ...planHistoryColumns(t, currentPlanId, options, true),
  columnHelper.accessor((row) => row.activeUsers, {
    id: 'activeUsers',
    cell: (info) =>
      getMultiCell(
        info.getValue(),
        info.column.id,
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN
      ),
    header: () => t('company:active-users'),
  }),
  columnHelper.accessor((row) => row.price, {
    id: 'price',
    cell: (info) =>
      getMultiCell(
        info.getValue(),
        info.column.id,
        info.row.original.extensions,
        isDateBetweenDates(
          new Date(),
          new Date(info.row.original.startDate),
          new Date(info.row.original.expirationDate)
        ) && info.row.original.id === currentPlanId,
        info.row.original.status === PlanStatus.HIDDEN,
        options.currencies[info.row.original.currency]
      ),
    header: () => t('price'),
  }),
  columnHelper.accessor('id', {
    meta: {
      cellClassName: 'sticky-column',
    },
    cell: (info) => {
      return getOptions(t, options.planHistoryActions, info.row.original.id === currentPlanId, info);
    },
    header: () => <></>,
  }),
];
