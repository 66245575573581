import styled from 'styled-components';

export const CompanyProfileSection = styled.div`
  & > * {
    margin-bottom: ${({ theme }) => theme.v2.spacing.m};
  }
`;

export const CompanyAccountInfoContainer = styled.form`
  background: ${({ theme }) => theme.v2.bg.secondary.default};
  padding: ${({ theme }) => theme.v2.spacing.m};
  margin: 0 ${({ theme }) => theme.v2.spacing.l};
  width: max-content;
`;

export const ButtonsGroup = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
`;
