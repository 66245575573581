import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from 'utils/maps/map-popups.styled';
import { IConverters } from 'types/preferences.types';
import { Separator, Typography } from '@ratedpower/components';

interface IPlotAreaPopupProps {
  data: {
    name: string;
    area: number;
    exteriorRingPerimeter: number;
  };
  converters: IConverters | undefined;
}

const PlotAreaPopup: React.FC<IPlotAreaPopupProps> = ({ data, converters }) => {
  const { t } = useTranslation();
  return (
    <>
      <S.MapPopupRow>
        <Typography category="title" weight="bold">
          {data.name}
        </Typography>
      </S.MapPopupRow>
      <Separator />
      {converters && (
        <>
          <S.MapPopupRow>
            <Typography category="display" size="s" weight="bold">
              {t('design-process:interactive-site.area')}:
            </Typography>
            <Typography dataTest={'area-testid'} category="display" size="s">
              {` ${converters.surface.toShow(data.area, { decimals: 2, size: 'M' }).value} `}
              {converters.surface.toShow(data.area, { decimals: 2, size: 'M' }).unit}
            </Typography>
          </S.MapPopupRow>
          <S.MapPopupRow>
            <Typography category="display" size="s" weight="bold">
              {t('design-process:interactive-site.perimeter')}:
            </Typography>
            <Typography dataTest="perymeter-testid" category="display" size="s">
              {` ${converters.length.toShow(data.exteriorRingPerimeter, { decimals: 2, size: 'L' }).value} `}
              {converters.length.toShow(data.exteriorRingPerimeter, { decimals: 2, size: 'L' }).unit}
            </Typography>
          </S.MapPopupRow>
        </>
      )}
    </>
  );
};

export default PlotAreaPopup;
