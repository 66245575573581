
export const arrayDeepCopy = (inObject: any) => {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject;
  }
  const outObject: any = Array.isArray(inObject) ? [] : {};

  for (const [key, value] of Object.entries(inObject)) {
    outObject[key] = arrayDeepCopy(value);
  }

  return outObject;
};
