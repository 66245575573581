import { useMemo } from 'react';
import { designAttributes, IAttribute } from 'utils/constants/design-attributes';
import { useConverter } from 'utils/conversor';
import { DimensionUnit, getDimensionUnitForArrayConvertion } from 'utils/conversor/dimension';
import { resolveKey } from 'utils/objects';

interface ICurrency {
  symbol: string;
  colorIndex: number;
}
const currencies: { [key: string]: ICurrency } = {
  EUR: {
    symbol: '€',
    colorIndex: 0,
  },
  USD: {
    symbol: '$',
    colorIndex: 1,
  },
};
export interface IChartDimensionUnits {
  x: DimensionUnit | null;
  y: DimensionUnit | null;
}

interface IProps {
  data: any;
  xAttr: IAttribute;
  yAttr: IAttribute;
}

const useDesignComparisonMoneyBarChart = ({ data, xAttr, yAttr }: IProps) => {
  const { converters, getValueOf } = useConverter();

  const dimensionUnits = useMemo<IChartDimensionUnits>(() => {
    const initialState: IChartDimensionUnits = { x: null, y: null };
    if (!data || !xAttr || !yAttr) return initialState;
    const state = { ...initialState };

    const yKey = yAttr.key;

    if (typeof yKey === 'string' && !Array.isArray(yKey)) {
      if (yAttr.converter && yAttr.converter.name === 'dimension') {
        // const yValues = data.map((e: any) => {
        //   const subArray = getValueOf(complexAttr, e);
        //   return subArray.map((e: any) => e[yKey]);
        // }).flat();
        // const subArray = getValueOf(complexAttr, e);
        const yValues = data.map((e: any) => e[yKey]);

        state.y = getDimensionUnitForArrayConvertion(yValues);
      }
    }

    return state;
  }, [data, xAttr, yAttr]);

  const formattedData = useMemo<any[]>(() => {
    if (!data) return [];

    const result = data.map((item: any) => {
      const x = getValueOf(xAttr, item);
      // const y = convertValueOf(yAttr, item, { decimals: 2, ...(!!dimensionUnits.y && { dimension: dimensionUnits.y }) });
      const yKey = yAttr.key;
      const currency = getValueOf(designAttributes.financial.currency, item);
      const currencyData = currency in currencies ? currencies[currency] : null;
      const extraData = currencyData ? { fillColorIndex: currencyData.colorIndex, symbol: currencyData.symbol } : {};
      const fillColor = item.fillColor;
      const publishedDate = item.publishedDate;
      if (typeof yKey === 'string' && !Array.isArray(yKey)) {
        const y = resolveKey(item, yKey);
        const yFormatted = !!y && typeof y === 'number' ? parseFloat(y.toFixed(2)) : 0;

        return { x, y: yFormatted, ...extraData, fillColor, publishedDate };
      }
      return { x, y: undefined, ...extraData, fillColor, publishedDate };
    });

    return result;
  }, [data, dimensionUnits, converters]);

  return {
    dimensionUnits,
    formattedData,
  };
};

export default useDesignComparisonMoneyBarChart;
