import { useDispatch } from 'react-redux';
import { setError } from 'redux/server-error-slice';

export enum ErrorType {
  FORBIDDEN = 'FORBIDDEN',
  GENERAL = 'GENERAL',
  NO_INTERNET = 'NO_INTERNET',
  UNKNOWN_NETWORK_ERROR = 'NETWORK_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export const useErrorNotifier = () => {
  const dispatch = useDispatch();

  return (error?: string) => {
    dispatch(setError(error));
  };
};
