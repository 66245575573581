import React from 'react';
import { WarningSvg } from 'assets/icons';
import { IConverters } from 'types/preferences.types';
import { useTranslation } from 'react-i18next';
import { MapPopupRow, MapPopupWarningRow } from 'utils/maps/map-popups.styled';
import { Separator, SVGWrapper, Typography } from '@ratedpower/components';

interface IOhlTowerData {
  data: {
    type: string;
    number: number;
    ohlName?: string;
    angle: number;
    towerHeight: number;
    towerWarnings?: TowerWarning[];
    previousSpanLength?: number;
    nextSpanLength?: number;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  converters: IConverters | undefined;
}

type TowerWarning =
  | 'could-not-spot'
  | 'intersects-with-ra'
  | 'prev-long-span'
  | 'next-long-span'
  | 'prev-short-span'
  | 'next-short-span';

const OhlTowerPopup: React.FC<IOhlTowerData> = ({ data, converters }) => {
  const { t } = useTranslation();

  const warningsToDraw =
    data.towerWarnings &&
    new Set(
      data.towerWarnings.map((warning) => {
        if (warning !== 'could-not-spot' && warning !== 'intersects-with-ra') {
          return 'span-warning';
        } else {
          return warning;
        }
      })
    );

  return (
    <>
      <MapPopupRow>
        <Typography category="title" weight="bold" size="m">
          {t('design-process:ohl.tower-info-popup.tower')}
          {' ' + data.number + ' '}
          {data.ohlName ? '(' + data.ohlName + ') ' : ''}
          {'- '}
          {t('design-process:ohl.tower-info-popup.tower-type.' + data.type)}
        </Typography>
      </MapPopupRow>
      <Separator />
      {converters && (
        <>
          {warningsToDraw &&
            Array.from(warningsToDraw).map((warning) => {
              return (
                <MapPopupWarningRow key={warning}>
                  <SVGWrapper icon={WarningSvg} size="s" />
                  <div>
                    <Typography category="display" size="s" weight="bold" as="div">
                      {t('design-process:ohl.tower-info-popup.warning.' + warning)}
                    </Typography>
                    <Typography category="display" size="s" as="div">
                      {warning === 'span-warning'
                        ? t('design-process:ohl.tower-info-popup.warning.span-warning-info')
                        : ''}
                    </Typography>
                  </div>
                </MapPopupWarningRow>
              );
            })}
          {data.type === 'angle' && (
            <MapPopupRow>
              <Typography category="display" size="s">
                <b> {t('design-process:ohl.tower-info-popup.angle') + ': '}</b>
                {converters.decimal.toShow(data.angle, { decimals: 1, size: 'M' }).value}
                {' º'}
              </Typography>
            </MapPopupRow>
          )}
          <>
            <MapPopupRow>
              <Typography category="display" size="s">
                <b>{t('design-process:ohl.tower-info-popup.position') + ': '}</b>
                {converters.decimal.toShow(data.coordinates.lat, { decimals: 4, size: 'M' }).value}
                {', '}
                {converters.decimal.toShow(data.coordinates.lng, { decimals: 4, size: 'M' }).value}
              </Typography>
            </MapPopupRow>
            <MapPopupRow>
              <Typography category="display" size="s">
                <b> {t('design-process:ohl.tower-info-popup.height') + ': '}</b>
                {` ${converters.length.toShow(data.towerHeight, { decimals: 2, size: 'M' }).value} `}
                {converters.length.toShow(data.towerHeight, { decimals: 2, size: 'M' }).unit}
              </Typography>
            </MapPopupRow>

            {data.previousSpanLength && data.previousSpanLength > 0 && (
              <MapPopupRow>
                <Typography category="display" size="s">
                  <b>{t('design-process:ohl.tower-info-popup.span-previous') + ': '}</b>
                  {` ${
                    converters.length.toShow(data.previousSpanLength, {
                      decimals: 2,
                      size: 'M',
                    }).value
                  } `}
                  {
                    converters.length.toShow(data.previousSpanLength, {
                      decimals: 2,
                      size: 'M',
                    }).unit
                  }
                </Typography>
              </MapPopupRow>
            )}
            {data.nextSpanLength && data.nextSpanLength > 0 && (
              <MapPopupRow>
                <Typography category="display" size="s">
                  <b>{t('design-process:ohl.tower-info-popup.span-next') + ': '}</b>
                  {` ${
                    converters.length.toShow(data.nextSpanLength, {
                      decimals: 2,
                      size: 'M',
                    }).value
                  } `}
                  {converters.length.toShow(data.nextSpanLength, { decimals: 2, size: 'M' }).unit}
                </Typography>
              </MapPopupRow>
            )}
          </>
        </>
      )}
    </>
  );
};

export default OhlTowerPopup;
