/* eslint-disable max-len */
import styled from 'styled-components';

export const GoogleMapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  .geocoder {
    position: absolute;
    left: 8px;
    top: 10px;
  }

  #map-style-selector {
    position: absolute;
    left: 24px;
    bottom: 70px;
    z-index: 1 !important;
  }

  #map-options {
    position: absolute;
    bottom: 124px;
    left: 24px;
    z-index: 1 !important;

    .ruler-btn {
      margin-bottom: ${({ theme }) => theme.v2.spacing.s};
    }
  }

  .mapboxgl-ctrl-bottom-left {
    left: 13px;
    z-index: 1 !important;
  }

  .mapboxgl-ctrl-group {
    visibility: hidden;
  }

  .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    margin: ${({ theme }) => theme.v2.spacing.m};
  }

  #click-position {
    position: absolute;
    bottom: 20px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 6px 8px;
  }

  #drawing-info {
    height: 75px;
    width: 150px;
    position: absolute;
    bottom: 80px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
  }

  #featurePopup {
    min-height: 75px;
    min-width: 150px;
    position: absolute;
    bottom: 114px;
    right: 10px;
  }

  .marker {
    // eslint-disable-next-line max-len
    background-image: url('https://thumbs.dreamstime.com/b/vector-push-pin-icon-pushpin-illustration-graphic-design-isolated-eps-symbol-195158845.jpg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }

  #draw-options {
    position: absolute;
    top: 0;
    left: 0;
  }

  .map {
    border-radius: 2px;
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  .map-detail {
    height: 100%;
    width: 100%;
  }

  #layout-preview-map {
    position: absolute;
    width: 100%;
  }

  .mapboxgl-popup-content {
    width: fit-content;
    padding: 0;
  }
`;
