import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, FilterSidebarItem, RadioGroup, FilterSidebar, IRadioOption } from '@ratedpower/components';
import { projectFiltersTypes } from 'utils/constants/projects';
import { IOverlayFilter } from 'utils/hooks/use-filters';

interface IProps {
  id?: string;
  onApply: () => void;
  onClear: () => void;
  onClose: () => void;
  onSelect: (filterCode: string, value: any, checked: boolean) => void;
  isOpen: boolean;
  filters: IOverlayFilter[];
}

const ProjectsFilterPanel: React.FC<IProps> = ({ onApply, onClear, onClose, onSelect, isOpen, filters, id }) => {
  const { t } = useTranslation();
  if (!filters?.length) return <></>;
  return (
    <FilterSidebar
      onApply={onApply}
      onClear={onClear}
      isOpen={isOpen}
      onClose={onClose}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <>
        <FilterSidebarItem title={filters[0].title} hasBorderBottom>
          <RadioGroup
            id={id + projectFiltersTypes.numDesigns}
            direction="column"
            selectedValue={filters[0].options.find((option) => option.checked)?.code}
            options={filters[0].options.map(
              (option) =>
                ({
                  label: t(option.label as string),
                  value: option.code,
                } as IRadioOption)
            )}
            onChange={(newOption) => {
              onSelect(filters[0].code, newOption.value, true);
            }}
          />
        </FilterSidebarItem>
        {filters.slice(1).map(
          (filter, key) =>
            !!filter.options.length && (
              <FilterSidebarItem hasBorderBottom={key !== filters.length - 1} title={filter.title} key={key}>
                <CheckboxGroup
                  id={id + filter.code}
                  direction="column"
                  options={filter.options.map((option) => ({
                    label: option.label,
                    value: option.code,
                    checked: option.checked,
                  }))}
                  onChange={(newOption) => {
                    onSelect(filter.code, newOption.value, newOption.checked);
                  }}
                />
              </FilterSidebarItem>
            )
        )}
      </>
    </FilterSidebar>
  );
};

export default ProjectsFilterPanel;
