import React, { useState } from 'react';
import * as S from '../plan-modal.styled';
import {
  IModalProps,
  Modal,
  Checkbox,
  InputNumber,
  Dropdown,
  InputTextArea,
  LabelWrapper,
  Typography,
  BaseTemplateInput,
} from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { CustomPlanType, ISubscriptionPlanCreate, Plan } from 'types/subscription-plan';
import { plansOptions, customPlanTypeOptions } from 'utils/constants/support';
import { IOption } from 'types/common';
import { getOptionForValue } from 'utils/selector';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useForm from 'utils/hooks/use-form';
import { getDateYYYYMMDD, addDays } from 'utils/date';
import { useChangePlanOrDates } from 'utils/hooks/use-change-plan-or-dates';

export interface IAddPlanModalProps extends IModalProps {
  handleCreateNewPlan: (plan: ISubscriptionPlanCreate, sendWelcomeEmail: boolean) => void;
}

const AddPlanModal: React.FC<IAddPlanModalProps> = (props) => {
  const { t } = useTranslation();
  const [sendWelcomeEmailChecked, setSendWelcomeEmailChecked] = useState(false);
  const currencies = useSelector((state: RootState) => state.currencies.currencyOptions);

  const { inputs, handleInputChangeManual, formError, setFormError } = useForm(null, {
    plan: Plan.DEMO,
    startDate: getDateYYYYMMDD(new Date()),
    expirationDate: getDateYYYYMMDD(addDays(new Date(), 14)),
    customPlanType: CustomPlanType.PAID_TRIAL,
    notes: '',
    currency: 'EUR',
    price: 0,
    projectsPurchased: 50,
  });

  const planOptions = plansOptions.map((plan: IOption) => ({
    label: t(`support:${plan.label}`),
    value: plan.value,
  }));

  const customPlanOptions = customPlanTypeOptions.map((planType: IOption) => ({
    label: t(`company:custom-plan-types.${planType.label}`),
    value: planType.value,
  }));

  const { onChangeExpirationDate, onChangeStartDate, autoChangeExpirationDate } = useChangePlanOrDates({
    plan: inputs.plan,
    handleSetExpirationDate: (newDate: string) => handleInputChangeManual('expirationDate', newDate),
    handleSetStartDate: (newDate: string) => handleInputChangeManual('startDate', newDate),
  });

  const isFormValid = () => {
    const mandatoryNumberInputsKeys = ['price', 'projectsPurchased'];

    const mandatoryNumberFieldErrors = Object.entries(inputs).reduce((acc: Record<string, string>[], [key, value]) => {
      if (mandatoryNumberInputsKeys.includes(key) && value < 0) return { ...acc, [key]: 'field-empty-plural' };
      return acc;
    }, []);

    const { expirationDate, startDate } = inputs;

    const wrongDateFields =
      new Date(startDate?.value).getTime() > new Date(expirationDate?.value).getTime()
        ? {
            startDate: 'start-date-past-expiration',
            expirationDate: 'start-date-past-expiration',
          }
        : {};

    const errors = { ...wrongDateFields, ...mandatoryNumberFieldErrors };
    if (Object.keys(errors).length > 0) {
      setFormError(errors);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!isFormValid()) return;

    const newPlan: ISubscriptionPlanCreate = {
      plan: inputs.plan,
      customPlanType: inputs.plan === Plan.CUSTOM ? inputs.customPlanType : undefined,
      notes: inputs.plan === Plan.CUSTOM ? inputs.notes : undefined,
      currency: inputs.currency,
      price: inputs.price,
      startDate: inputs.startDate,
      expirationDate: inputs.expirationDate,
      projectsPurchased: inputs.plan === Plan.ENTERPRISE ? 0 : inputs.projectsPurchased,
    };

    props.handleCreateNewPlan(newPlan, sendWelcomeEmailChecked);
  };

  const displayedFormErrors = [...new Set(Object.values(formError ?? {}))];

  return (
    <Modal
      {...props}
      title={t('company:new-plan')}
      disableClickOutside
      buttons={{
        primary: { text: t('action:save'), type: 'submit', onClick: handleSubmit },
        secondary: {
          text: t('action:cancel'),
          onClick: props.onClose,
        },
      }}
    >
      <Typography weight={700}>{t('company:plan-details')}</Typography>
      <S.ModalPlanForm onSubmit={handleSubmit}>
        <LabelWrapper title={t('company:plan')} htmlFor="plan">
          <Dropdown
            options={planOptions}
            name="plan"
            error={!!formError?.['plan']}
            value={[getOptionForValue(inputs.plan, planOptions)]}
            onChange={([selectedItem]) => {
              autoChangeExpirationDate(inputs.startDate, selectedItem.value);
              handleInputChangeManual('plan', selectedItem.value);
            }}
          />
        </LabelWrapper>
        {inputs.plan === Plan.CUSTOM && (
          <>
            <LabelWrapper title={t('type')} htmlFor="customPlanType">
              <Dropdown
                error={!!formError?.['customPlanType']}
                options={customPlanOptions}
                value={[getOptionForValue(inputs.customPlanType, customPlanOptions)]}
                onChange={([selectedItem]) => handleInputChangeManual('customPlanType', selectedItem.value)}
                name="type"
              />
            </LabelWrapper>
          </>
        )}

        <LabelWrapper title={t('capex:currency')} htmlFor="currency">
          <Dropdown
            name="currency"
            options={currencies}
            value={[getOptionForValue(inputs.currency, currencies)]}
            onChange={([selectedItem]) => handleInputChangeManual('currency', selectedItem.value)}
          />
        </LabelWrapper>
        <LabelWrapper title={t('price')} htmlFor="price">
          <InputNumber
            warning={!!formError?.['price']}
            name="price"
            min={0}
            value={inputs.price}
            onChange={(newPrice) => handleInputChangeManual('price', parseInt(newPrice))}
          />
        </LabelWrapper>
        {inputs.plan === Plan.CUSTOM && (
          <LabelWrapper title={t('notes')} htmlFor="notes">
            <InputTextArea
              placeholder={t('notes')}
              name="notes"
              size="m"
              value={inputs.notes}
              onChange={(e) => handleInputChangeManual('notes', e.target.value)}
            />
          </LabelWrapper>
        )}
        <LabelWrapper alignItems="baseline" title={t('company:projects-purchased')} htmlFor="projectsPurchased">
          {inputs.plan === Plan.ENTERPRISE ? (
            <S.Unlimited>{t('company:unlimited')}</S.Unlimited>
          ) : (
            <InputNumber
              warning={!!formError?.['projectsPurchased']}
              name="projectsPurchased"
              min={0}
              value={inputs.projectsPurchased}
              onChange={(newValue) => handleInputChangeManual('projectsPurchased', parseInt(newValue))}
            />
          )}
        </LabelWrapper>
        <LabelWrapper title={t('start-date')} htmlFor="startDate">
          <BaseTemplateInput
            warning={!!formError?.['startDate']}
            type="date"
            name="startDate"
            value={inputs.startDate}
            onChange={onChangeStartDate}
            max="2999-12-31"
          />
        </LabelWrapper>
        <LabelWrapper title={t('expiration-date')} htmlFor="expirationDate">
          <BaseTemplateInput
            warning={!!formError?.['expirationDate']}
            type="date"
            name="expirationDate"
            value={inputs.expirationDate}
            onChange={onChangeExpirationDate}
            max="2999-12-31"
          />
        </LabelWrapper>
        <LabelWrapper title={t('support:send-welcome-mail')} htmlFor="sendWelcomeEmail" maxWidth="210px">
          <Checkbox
            id="sendWelcomeEmail"
            name="sendWelcomeEmail"
            checked={sendWelcomeEmailChecked}
            onChange={(e) => setSendWelcomeEmailChecked(e.target.checked)}
          />
        </LabelWrapper>
        <div className="errors">
          {displayedFormErrors.map((error, index) => (
            <S.FormError key={`${error}-${index}`} size="s">
              {t(`errors:${error}`)}
            </S.FormError>
          ))}
        </div>
      </S.ModalPlanForm>
    </Modal>
  );
};

export { AddPlanModal };
