import React from 'react';
import ZeroCreated2 from 'components/error-components/zero-created-2/zero-created-2';
import { Trans, useTranslation } from 'react-i18next';
import { ZeroDesignsSvg } from 'assets/icons';

interface IProps {
  selectedTab: string;
  hasRunDesignPermissions?: boolean;
}

const ZeroDesignsCreated = ({ selectedTab, hasRunDesignPermissions }: IProps) => {
  const { t } = useTranslation(),
    messageKey = hasRunDesignPermissions ? `design:zero-${selectedTab}` : 'design:zero-designs-no-permissions';

  return (
    <ZeroCreated2
      Icon={ZeroDesignsSvg}
      title={t(`${messageKey}.title`)}
      subtitle={<Trans i18nKey={`${messageKey}.subtitle`} />}
    />
  );
};

export default ZeroDesignsCreated;
