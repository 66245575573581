import { gql } from '@apollo/client';

const fragments = {
  team: gql`
    fragment Team on TeamListItemDTO {
      id
      name
      description
      coverColor
      coverImage {
        highResUrl
        lowResUrl
      }
      teamManagersList {
        name
        surname
      }
      publishedDate
      numMembers
      numProjects
    }
  `,
};
export const GET_TEAMS_BY_COMPANY = gql`
  query GetTeamsListByCompany(
    $companyId: String!
    $pagination: PaginationForm
    $sorting: [SortOrder]
    $filters: [TeamFilterEntry]
    $search: [String]
  ) {
    result: getTeamsListByCompany(
      companyId: $companyId
      pagination: $pagination
      sorting: $sorting
      filters: $filters
      search: $search
    ) {
      content {
        ...Team
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.team}
`;

export const GET_TEAMS = gql`
  query GetTeamsList(
    $pagination: PaginationForm
    $sorting: [SortOrder]
    $filters: [TeamFilterEntry]
    $search: [String]
  ) {
    result: getTeamsList(pagination: $pagination, sorting: $sorting, filters: $filters, search: $search) {
      content {
        ...Team
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.team}
`;

export const GET_TEAM_BY_ID = gql`
  query GetTeamById($teamId: ID!) {
    getTeamById(teamId: $teamId) {
      name
      description
      coverColor
      coverImage {
        highResUrl
        lowResUrl
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation CreateTeam($createTeamDTO: CreateTeamDTO!, $coverImage: Upload) {
    createTeam(createTeamDTO: $createTeamDTO, coverImage: $coverImage) {
      id
      name
      coverColor
      coverImage {
        highResUrl
        lowResUrl
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($updateTeamDTO: UpdateTeamDTO!, $coverImage: Upload) {
    updateTeam(updateTeamDTO: $updateTeamDTO, coverImage: $coverImage) {
      id
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  query GetCompanyUsers(
    $companyId: String!
    $currentTeamId: String!
    $filterTeamId: String
    $showCurrentTeamMembers: Boolean!
    $search: [String]
    $sorting: [SortOrder]
    $filters: [UserFilterEntry]
    $pagination: PaginationForm
  ) {
    getCompanyUsers(
      companyId: $companyId
      currentTeamId: $currentTeamId
      filterTeamId: $filterTeamId
      showCurrentTeamMembers: $showCurrentTeamMembers
      search: $search
      sorting: $sorting
      filters: $filters
      pagination: $pagination
    ) {
      content {
        id
        name
        lastname: surname
        email
        codeLanguage
        jobTitle
        roleName
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const GET_USER_TEAMS_ASSIGNED = gql`
  query GetUserTeamsAssigned($userId: String!) {
    getUserTeamsAssigned(userId: $userId) {
      name
      numProjects
    }
  }
`;

export const ADD_PROJECTS_TO_TEAM = gql`
  mutation AddProjectsToTeam($teamId: ID!, $projectIds: [ID]!) {
    addProjectsToTeam(teamId: $teamId, projectIds: $projectIds) {
      id
    }
  }
`;

export const REMOVE_PROJECT_FROM_TEAM = gql`
  mutation RemoveProjectFromTeam($teamId: ID!, $projectId: ID!) {
    removeProjectFromTeam(teamId: $teamId, projectId: $projectId) {
      id
    }
  }
`;

export const ADD_MEMBERS_TO_TEAM = gql`
  mutation AddMembersToTeam($teamId: ID!, $memberIds: [ID]!) {
    addMembersToTeam(teamId: $teamId, memberIds: $memberIds) {
      id
      users {
        id
        name
        surname
      }
    }
  }
`;

export const REMOVE_MEMBER_FROM_TEAM = gql`
  mutation RemoveMemberFromTeam($teamId: ID!, $memberId: ID!) {
    removeMemberFromTeam(teamId: $teamId, memberId: $memberId) {
      id
      users {
        id
        name
        surname
      }
    }
  }
`;

export const GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM = gql`
  query GetTeamManagersThatBelongsToTeams($companyId: String!) {
    result: getTeamManagersThatBelongsToTeams(companyId: $companyId) {
      id
      name
      surname
      email
    }
  }
`;

export const GET_COUNTRIES_OF_PROJECTS_THAT_BELONGS_TO_TEAM = gql`
  query GetCountriesOfProjectsThatBelongsToTeams($companyId: String!) {
    result: getCountriesOfProjectsThatBelongsToTeams(companyId: $companyId)
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteTeam($teamId: String!) {
    deleteTeam(teamId: $teamId)
  }
`;

export const GET_TEAM_MANAGER_TEAMS_MEMBERS = gql`
  query GetTeamManagerTeamsMembers {
    getTeamManagerTeamsMembers {
      id
    }
  }
`;

export const CREATE_TEAM_BY_COMPANY = gql`
  mutation CreateTeamByCompany($createTeamByCompanyDTO: CreateTeamByCompanyDTO!, $coverImage: Upload) {
    createTeamByCompany(createTeamByCompanyDTO: $createTeamByCompanyDTO, coverImage: $coverImage) {
      id
      name
      coverColor
      coverImage {
        highResUrl
        lowResUrl
      }
    }
  }
`;
