import styled from 'styled-components';
import { Tag } from '@ratedpower/components';

export const ThumbnailContainer = styled.div`
  border-top-left-radius: ${({ theme }) => theme.v2.radius.s};
  border-top-right-radius: ${({ theme }) => theme.v2.radius.s};
  position: relative;
  padding: 0;
`;

export const PositionedTag = styled(Tag)`
  top: ${({ theme }) => theme.v2.spacing.xs};
  right: ${({ theme }) => theme.v2.spacing.xs};
  position: absolute;
`;
