import styled, { css } from 'styled-components';

const CardContainerSelected = css`
  outline: 1px solid ${({ theme }) => theme.v2.stroke.accent.default};
  cursor: pointer;
`;

export const CardContainer = styled.div<{ selected?: boolean }>`
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.s};
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  border-radius: ${({ theme }) => theme.v2.radius.xs};
  display: flex;
  overflow: hidden;
  height: 88px;
  width: 276px;
  cursor: pointer;

  ${({ selected }) => selected && CardContainerSelected}
`;

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardContent = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  gap: ${({ theme }) => theme.v2.spacing.xs};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
