import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { ITab, Tabs } from '@ratedpower/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { PROFILE_TABS, PROFILE_TABS_URLS } from 'pages/profile/utils/constants';

const ProfileTabs: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(pathname.split('/')[2]);

  useEffect(() => {
    setTabActive(pathname.split('/')[2] || 'profile');
  }, [pathname]);

  const onClickTab = (tabName: string) => {
    navigate(PROFILE_TABS_URLS[tabName]);
    setTabActive(tabName);
  };

  const tabs: ITab[] = React.useMemo(() => PROFILE_TABS.map((tab) => ({ ...tab, label: t(tab.label) })), [t]);

  return (
    <>
      <Helmet>
        <title>{t(`user:${tabActive}`)}</title>
      </Helmet>
      <Tabs onClickTab={onClickTab} tabs={tabs} tabActive={tabActive} />
    </>
  );
};

export default ProfileTabs;
