import React from 'react';
import { TFunction } from 'i18next';
import { Button, PopupWrapper, SortableHeader, Tag, Tooltip, Typography } from '@ratedpower/components';
import { DefaultTheme } from 'styled-components';
import { ISort } from 'types/filter';
import { getDateMonthDDYYYY, getHourAndMinutesHHmm, getOnCurrentTimezoneDateYYYYMMDD } from 'utils/date';
import { ISimulationListItemDTO } from '../simulations';
import { STATUS_COLORS } from '../status';
import { createColumnHelper } from '@tanstack/table-core';
import { DotsSvg, InfoSvg } from 'assets/icons';
import {
  ProjectContainer,
  SimulationStatusContainer,
} from 'pages/support/company-activity/designs-tab/designs-tab.styled';
import { DesignActions, getDesignActions } from '../support';
import { NavigateFunction } from 'react-router-dom';
import { openExternalLink } from 'utils/navigation';
import { buildDesignProcessUrl, buildProjectUrl } from 'utils/navigation/navigation';

interface IDesignsColumnsOptions {
  hasWritePermission: boolean;
  onPerformAction: (id: string, value: DesignActions) => void;
  setSortedBy: (sort: ISort) => void;
  sortedBy: ISort;
  navigate: NavigateFunction;
}

const columnHelper = createColumnHelper<ISimulationListItemDTO>();

const defaultSelector: ISort = {
  ascendent: false,
  selector: {
    code: '',
    label: '',
  },
};

export const designsColumns = (t: TFunction, theme: DefaultTheme, options: IDesignsColumnsOptions) => {
  const handleSorting = (filter: ISort) => {
    if (options && filter) options.setSortedBy(filter);
  };

  return [
    columnHelper.accessor('name', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:designs-tab.designs-name'),
            code: 'name',
          }}
          sortedBy={options ? options.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => (
        <Button
          onClick={() => {
            openExternalLink(
              buildDesignProcessUrl(
                info.row.original.projectId,
                info.row.original.id,
                info.row.original.simulationCategory,
                true
              )
            );
          }}
          text={info.row.original.name}
          textUnderlined={true}
          noPadding
          variant="link"
        />
      ),
    }),
    columnHelper.accessor('projectName', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:designs-tab.project-name'),
            code: 'project',
          }}
          sortedBy={options ? options.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => (
        <ProjectContainer>
          <Button
            onClick={() => openExternalLink(buildProjectUrl(info.row.original.projectId))}
            textUnderlined={true}
            noPadding
            text={info.row.original.projectName}
            variant="link"
          />
          <Typography>{info.row.original.teamName ?? t('user:teams.public-teams')}</Typography>
        </ProjectContainer>
      ),
    }),
    columnHelper.accessor('executionDate', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:designs-tab.execution-date'),
            code: 'executionDate',
          }}
          sortedBy={options ? options.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => (
        <Typography>
          {info.row.original.executionDate
            ? `${getDateMonthDDYYYY(info.row.original.executionDate)} ${getHourAndMinutesHHmm(
                info.row.original.executionDate
              )}`
            : '-'}
        </Typography>
      ),
    }),
    columnHelper.accessor('duration', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:designs-tab.duration'),
            code: 'duration',
          }}
          sortedBy={options ? options.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{info.row.original.duration}</Typography>,
    }),
    columnHelper.accessor('userEmail', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:designs-tab.user-email'),
            code: 'createdBy',
          }}
          sortedBy={options ? options.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{info.row.original.userEmail}</Typography>,
    }),
    columnHelper.accessor('companiesActivitySimulationResult', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:designs-tab.simulation-result'),
            code: 'status',
          }}
          sortedBy={options ? options.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => {
        return (
          <Tag color={STATUS_COLORS[info.row.original.companiesActivitySimulationResult]}>
            {info.row.original.companiesActivitySimulationResult}
          </Tag>
        );
      },
    }),
    columnHelper.accessor('companiesActivityStatus', {
      header: () => t('support:designs-tab.status'),
      cell: (info) => {
        const { message, supportExpirationDate, userExpirationDate } = info.row.original.companiesActivityStatus;

        const formatDate = (date?: string) => {
          if (!date) return;
          return getOnCurrentTimezoneDateYYYYMMDD(new Date(date)) + ' ' + getHourAndMinutesHHmm(new Date(date));
        };

        return (
          <SimulationStatusContainer>
            <Typography>{message}</Typography>
            {supportExpirationDate &&
              userExpirationDate &&
              (message === 'Not saved (< 1 day)' ? (
                <Tooltip
                  description={t('support:expires-on', {
                    expirationDate: formatDate(supportExpirationDate),
                    userExpirationDate: formatDate(userExpirationDate),
                  })}
                >
                  <InfoSvg />
                </Tooltip>
              ) : (
                <Tooltip
                  description={t('support:expires-on-hidden', {
                    expirationDate: formatDate(supportExpirationDate),
                  })}
                >
                  <InfoSvg />
                </Tooltip>
              ))}
          </SimulationStatusContainer>
        );
      },
    }),
    columnHelper.accessor('companiesActivityStatus.errorCode', {
      header: () => t('errors:error-code'),
      cell: (info) => <Typography>{info.getValue() ?? '-'}</Typography>,
    }),
    columnHelper.accessor('allowedActions', {
      header: undefined,
      cell: (info) => {
        if (!options.hasWritePermission) return <></>;
        return (
          !!info.row.original.allowedActions.length && (
            <PopupWrapper
              options={getDesignActions(t).filter((action) => info.row.original.allowedActions.includes(action.value))}
              onClickOption={(option) => options.onPerformAction(info.row.original.id, option.value as DesignActions)}
            >
              <Button variant="ghost" Icon={DotsSvg} />
            </PopupWrapper>
          )
        );
      },
    }),
  ];
};
