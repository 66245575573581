import gql from 'graphql-tag';

const fragments = {
  companies: gql`
    fragment Company on CompanyDTO {
      country {
        id
        countryCode
        translation
      }
      email
      errors: numErrorSupportAlert
      id
      name
      publishedDate
      logo {
        lowResUrl
        highResUrl
      }
      currentPlan {
        plan
        expirationDate
        projectsDone
        projectsPurchased
        startDate
      }
      suppAlert: numCheckSupportAlert
      warnings: numWarningSupportAlert
      supportedBy {
        id
        name
      }
      businessManager {
        id
        name
      }
      salesDevRepresentative {
        id
        name
      }
      companyWarningMessage
      salesforceId
      externalProviderName
      haveFederatedLogin
      equipmentSettings {
        id
        publishedDate
      }
      showLogoInDocuments
      userLimit
      usersCount
      unlimitedUsers
      maxSiteToProjectDistance
    }
  `,
  supportUser: gql`
    fragment SupportUser on UserDTO {
      id
      name
      lastLogin
      lastname: surname
      numManagedCompanies
      type
      lastLogin
      email
      publishedDate
      status
      role {
        id
        name
        type
      }
      language: codeLanguage
      jobTitle
    }
  `,

  supportAlert: gql`
    fragment SupportAlert on SupportAlertDTO {
      id
      description
      projectName
      projectId
      archived
      alertSeverity
      entityName
      entityId
      linkedEntityType
      statusSupport
      teamName
      publishedDate
      alertType
      linkedEntityOwner {
        id
        name
        surname
        email
        codeLanguage
      }
      createdBy {
        id
        name
        surname
        email
        codeLanguage
      }
      company {
        id
        name
      }
    }
  `,
  userMinInfo: gql`
    fragment UserMinInfo on UserMinInfoDTO {
      id
      name
      surname
      email
      codeLanguage
    }
  `,
  project: gql`
    fragment Project on ProjectDTO {
      teamName
      name
      country
      region
      city
      id
      numDesigns
      country
      region
      city
      publishedDate
      maxSiteToProjectDistance
      status
      numDesigns
      createdBy {
        ...UserMinInfo
      }
    }
  `,
  simulations: gql`
    fragment Simulations on SimulationListItemDTO {
      id
      name
      projectId
      projectName
      executionDate
      duration
      userEmail
      teamName
      simulationCategory
      companiesActivityStatus {
        message
        errorCode
        supportExpirationDate
        userExpirationDate
      }
      companiesActivitySimulationResult
      allowedActions
    }
  `,
};

export const GET_ALL_COMPANIES = gql`
  query AllCompanies(
    $pagination: PaginationForm!
    $filters: [CompanyFilterEntry]
    $search: [String]
    $sorting: [SortOrder]
  ) {
    result: companies(pagination: $pagination, filters: $filters, search: $search, sorting: $sorting) {
      content {
        ...Company
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.companies}
`;

export const GET_COMPANIES_NAMES = gql`
  query CompaniesNames(
    $pagination: PaginationForm!
    $filters: [CompanyFilterEntry]
    $search: [String]
    $sorting: [SortOrder]
  ) {
    result: companies(pagination: $pagination, filters: $filters, search: $search, sorting: $sorting) {
      content {
        id
        name
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const GET_SUPPORT_COMPANY = gql`
  query CompanyById($id: ID!) {
    result: companyById(id: $id) {
      ...Company
    }
  }
  ${fragments.companies}
`;

export const GET_CS_MANAGERS = gql`
  query GetCSManagers($type: UserType!, $search: [String]) {
    result: usersByType(type: $type, search: $search) {
      ...SupportUser
    }
  }
  ${fragments.supportUser}
`;

export const GET_BIS_MANAGERS = gql`
  query GetBisManagers($type: UserType!, $search: [String]) {
    result: usersByType(type: $type, search: $search) {
      ...SupportUser
    }
  }
  ${fragments.supportUser}
`;

export const GET_SDR_MANAGERS = gql`
  query GetSDRManagers($type: UserType!, $search: [String]) {
    result: usersByType(type: $type, search: $search) {
      ...SupportUser
    }
  }
  ${fragments.supportUser}
`;

export const GET_ACTIVITY_COMPANY = gql`
  query SupportAlerts(
    $pagination: PaginationForm!
    $sorting: [SortOrder]
    $filters: [SupportAlertFilterEntry]
    $search: [String]
  ) {
    result: supportAlerts(pagination: $pagination, filters: $filters, sorting: $sorting, search: $search) {
      content {
        ...SupportAlert
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.supportAlert}
`;

export const GET_SIMULATIONS_COMPANY = gql`
  query SimulationsList(
    $companyId: ID!
    $pagination: PaginationForm!
    $sorting: [SortOrder]
    $search: [String]
    $filters: [SimulationFilterEntry]
  ) {
    result: simulationsList(
      companyId: $companyId
      pagination: $pagination
      sorting: $sorting
      search: $search
      filters: $filters
    ) {
      content {
        ...Simulations
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.simulations}
`;

export const GET_COMPANY_PROJECTS = gql`
  query ProjectsByCompany(
    $pagination: PaginationForm!
    $sorting: [SortOrder]
    $filters: [ProjectFilterEntry]
    $teamId: String
    $search: [String]
    $companyId: String
  ) {
    projectsByCompany(
      pagination: $pagination
      filters: $filters
      sorting: $sorting
      search: $search
      teamId: $teamId
      companyId: $companyId
    ) {
      content {
        ...Project
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.project}
  ${fragments.userMinInfo}
`;

export const GET_USERS_BY_COMPANY = gql`
  query UsersByCompany(
    $pagination: PaginationForm!
    $companyIds: [String]!
    $sorting: [SortOrder]
    $search: [String]
    $filters: [UserFilterEntry]
  ) {
    result: usersByCompany(
      pagination: $pagination
      sorting: $sorting
      search: $search
      companyIds: $companyIds
      filters: $filters
    ) {
      content {
        ...SupportUser
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.supportUser}
`;

export const GET_ENTITY_INFO = gql`
  query LinkedEntityInfo($linkedEntityType: LinkedEntityType!, $linkedEntityId: String) {
    linkedEntityInfo(linkedEntityType: $linkedEntityType, linkedEntityId: $linkedEntityId) {
      error
      message
    }
  }
`;

export const UPDATE_COMPANIES = gql`
  mutation UpdateSupportCompanies($companyUpdateSupportDTOList: [CompanyUpdateSupportDTO]!) {
    companyUpdateSupport(companyUpdateSupportDTOList: $companyUpdateSupportDTOList) {
      id
      name
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CompanyCreate($companyForm: CompanyCreateDTO!, $image: Upload) {
    companyCreate(companyCreateDTO: $companyForm, image: $image) {
      id
    }
  }
`;

export const UPDATE_SUPPORT_COMPANY = gql`
  mutation UpdateSupportCompany($companyForm: [CompanyUpdateSupportDTO]!, $image: Upload) {
    companyUpdateSupport(companyUpdateSupportDTOList: $companyForm, image: $image) {
      id
    }
  }
`;

export const UPDATE_SAML_CONFIGURATION = gql`
  mutation CompanyUpdateFederatedLogin($companyUpdateFederatedLoginDTO: CompanyUpdateFederatedLoginDTO!) {
    companyUpdateFederatedLogin(companyUpdateFederatedLoginDTO: $companyUpdateFederatedLoginDTO) {
      id
    }
  }
`;

export const COMPANY_UPDATE_ALERT = gql`
  mutation CompanyUpdateAlert($companyUpdateAlertDTO: CompanyUpdateAlertDTO) {
    companyUpdateAlert(companyUpdateAlertDTO: $companyUpdateAlertDTO) {
      id
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateSupportAlert($supportAlertUpdateDTOList: [SupportAlertUpdateDTO]!) {
    updateSupportAlert(supportAlertUpdateDTOList: $supportAlertUpdateDTOList) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProjectSupport($projectUpdateSupportDTO: ProjectUpdateSupportDTO!) {
    updateProjectSupport(projectUpdateSupportDTO: $projectUpdateSupportDTO) {
      id
    }
  }
`;

export const UPDATE_SIMULATIONS = gql`
  mutation UpdateSimulations($simulationForms: [UpdateSimulationForm]!) {
    updateSimulations(simulationForms: $simulationForms) {
      id
    }
  }
`;

export const CREATE_PROJECT_BY_SUPPORT = gql`
  mutation CreateProjectBySupport($projectCreateSupportDTO: ProjectCreateSupportDTO!) {
    createProjectBySupport(projectCreateSupportDTO: $projectCreateSupportDTO) {
      id
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: ID!) {
    companyDelete(id: $id) {
      id
    }
  }
`;

export const DELETE_SIMULATION_BY_COMPANY_ACTIVITY = gql`
  mutation DeleteSimulationThroughCompaniesActivity($id: ID!) {
    deleteSimulationThroughCompaniesActivity(id: $id) {
      id
    }
  }
`;

export const RECOVER_SIMULATION_BY_COMPANY_ACTIVITY = gql`
  mutation RecoverSimulationThroughCompaniesActivity($id: ID!) {
    recoverSimulationThroughCompaniesActivity(id: $id) {
      id
    }
  }
`;

export const SAVE_SIMULATION_BY_COMPANY_ACTIVITY = gql`
  mutation SaveSimulationThroughCompaniesActivity($id: ID!) {
    saveSimulationThroughCompaniesActivity(id: $id) {
      id
    }
  }
`;

export const SEARCH_ORGANIZATION = gql`
  query SearchOrganization($organizationSearch: OrganizationSearchDTO!) {
    searchOrganization(organizationSearch: $organizationSearch) {
      id
      name
      domain
      salesforceId
      companyCreated
      enverusId
    }
  }
`;
