import styled from 'styled-components';

export const MainView = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  flex-grow: 1;

  th {
    width: 10%;
  }

  th:nth-child(1) {
    width: 5%;
  }

  th:nth-child(3) {
    width: 15%;
  }

  th:nth-child(5) {
    width: 50%;
  }

  td {
    white-space: normal;
    height: 50px;
  }

  .design-process-input {
    display: block;
  }

  .pagination-container {
    margin-top: 1.5rem;
  }

  /* Temporal patch solution until the web gets responsive */
  @media screen and (min-width: 1400px) {
    .table-container {
      tr {
        &:nth-last-child(-n + 3) {
          td .option-list ul,
          td .option-list {
            position: absolute;
          }
        }
      }
    }
  }
`;

export const RolesTabWrapper = styled.div`
  flex-grow: 1;
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  &.disabled {
    .svg {
      cursor: pointer;
      color: ${({ theme }) => theme.gray_dark};
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;

  .flat {
    margin-left: 1rem;
    overflow: inherit;

    svg {
      margin-left: 1rem;
    }
  }
`;

export const TopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.v2.spacing.l};
  padding-left: ${({ theme }) => theme.v2.spacing.l};
  padding-right: ${({ theme }) => theme.v2.spacing.l};
`;
