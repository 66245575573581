import { useEffect, useRef, useState } from 'react';
import { DELETE_USER, UPDATE_TEMPORARY_PASSWORD, UPDATE_USER_BY_SUPPORT } from 'services/user-gql';
import { GET_USERS_BY_COMPANY } from 'services/support-gql';
import { IServerFilter, ISort } from 'types/filter';
import { INewSupportUser, ISupportUserListItem } from 'types/support.types';
import { USERS_PER_PAGE } from 'utils/constants/support';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { getSortDirection } from 'utils/sort';
import { useCompanyActivityState } from './company-activity/company-activity-state';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useModal } from 'utils/hooks/use-modal';
import { useMutation } from '@apollo/client';
import { useSearch } from 'utils/hooks/use-search';
import { useToasts } from 'utils/hooks/use-toasts';
import { useTranslation } from 'react-i18next';
import { useRolesByCurrentUser } from 'utils/hooks/use-roles-by-current-user';
import { useUserRoleSummary } from 'utils/hooks/use-get-role-summary';
import { GET_USER_ROLE_SUMMARY } from 'services/roles-gql';
import { useUsersColumns } from './company-activity/users-tab/use-users-columns';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ADMIN_EMAIL } from 'utils/constants/contact';

export const useUsersTab = () => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const { addSuccessToast, addErrorToast } = useToasts();
  const { companyId, contextCompany } = useCompanyActivityState();
  const { inputSearchProps, getSearchFilter } = useSearch();
  const editUserFormModal = useModal();
  const deleteModal = useModal();
  const teamsAssignedModal = useModal();
  const [users, setUsers] = useState<ISupportUserListItem[]>([]);
  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('support:company-name'),
      code: 'name',
    },
    ascendent: true,
  });
  const [currentFilters, setCurrentFilters] = useState<IServerFilter[]>([]);
  const isNewCompany = useRef(false);
  const { rolesByCurrentUser } = useRolesByCurrentUser();
  const { userRoleSummary, userSummaryLoading } = useUserRoleSummary(currentFilters, null);

  const usersTableColumns = useUsersColumns({
    canDeleteUsers:
      isAuthorized(Permission.USER_SUPPORT_DELETE) ||
      (isAuthorized(Permission.USER_SUPPORT_DELETE_EXTERNAL) && contextCompany?.email !== ADMIN_EMAIL),
    canChangePermissions: isAuthorized(Permission.ASSIGN_SUPPORT_ROLE_WRITE),
    canResendPassword: isAuthorized(Permission.USER_SUPPORT_RESEND_PASSWORD),
    onSort: setSortedBy,
    sortedBy,
    onSelectRole,
    handleAction,
    roles: rolesByCurrentUser,
    companyEmail: contextCompany?.email,
  });

  const {
    data,
    loading: loadingUsers,
    error,
    paginationComponent,
  } = useCustomQuery(GET_USERS_BY_COMPANY, {
    variables: {
      pagination: {
        page: 0,
        size: USERS_PER_PAGE,
      },
      sorting: {
        property: sortedBy?.selector.code,
        direction: getSortDirection(sortedBy?.ascendent),
      },
      search: getSearchFilter(),
      companyIds: [companyId],
      filters: currentFilters,
    },
    fetchPolicy: 'network-only',
  });

  const [deleteUser, { loading: loadingDelete }] = useMutation(DELETE_USER, {
    refetchQueries: [GET_USERS_BY_COMPANY, GET_USER_ROLE_SUMMARY],
    onCompleted: () => {
      deleteModal.setModalOpen(false);
      addSuccessToast(t('user:user-deleted-successfully'));
    },
    onError: () => {
      addErrorToast(t('user:user-deleted-error'));
    },
  });
  const [updateUser, { loading: loadingUpdate }] = useMutation(UPDATE_USER_BY_SUPPORT, {
    refetchQueries: [GET_USERS_BY_COMPANY, GET_USER_ROLE_SUMMARY],
    onCompleted: () => {
      editUserFormModal.setModalOpen(false);
      addSuccessToast(t('user:user-updated-successfully'));
    },
    onError: () => {
      addErrorToast(t('user:user-update-error'));
    },
  });
  const [updateTemporaryPassword] = useMutation(UPDATE_TEMPORARY_PASSWORD);

  function handleEditUser(user: INewSupportUser) {
    const userForm = {
      id: user.id,
      name: user.name,
      surname: user.lastname,
      email: user.email,
      roleId: user.roleId,
      jobTitle: user.jobTitle,
      codeLanguage: user.language,
      type: user.type,
      status: user.status,
    };
    updateUser({ variables: { userForm } });
  }

  function onSelectRole(option: string, id: string) {
    const user: INewSupportUser | undefined = users.find((user) => user.id === id);
    if (user) {
      handleEditUser({ ...user, roleId: option });
    }
  }

  function handleAction(id: string, action: string) {
    const user = users.find((item) => item.id === id);
    switch (action) {
      case 'resend-pass':
        if (user && user.email) resendPassword(user.email);
        break;
      case 'edit':
        editUserFormModal.setData(user);
        editUserFormModal.toggle();
        break;
      case 'enable':
        onChangeStatus(id, 'ENABLED');
        break;
      case 'disable':
        onChangeStatus(id, 'DISABLED');
        break;
      case 'delete-user':
        if (user) {
          deleteModal.setData({ id: user.id, name: user.name });
          deleteModal.toggle();
        }
        break;
      case 'show-teams-assigned':
        teamsAssignedModal.setData(id);
        teamsAssignedModal.setModalOpen(true);
        break;
    }
  }

  function onChangeStatus(id: string, status: string) {
    const user: ISupportUserListItem | undefined = users.find((item) => item.id === id);
    if (user) {
      handleEditUser({ ...user, status });
    }
  }

  async function resendPassword(email: string) {
    const { data: resentData, errors } = await updateTemporaryPassword({ variables: { email } });
    if (errors) {
      addErrorToast(t('support:users-tab.password-resent-error'));
      return;
    }
    if (resentData) {
      addSuccessToast(t('support:users-tab.password-resent-success'));
    }
  }

  async function handleDeleteUser(id: string) {
    const response = await deleteUser({ variables: { id: id } });
    if (response.data && response.data.deleteUser) {
      addSuccessToast(t('support:users-tab.user-deleted-succes'));
    } else {
      addErrorToast(t('support:users-tab.user-deleted-error'));
    }
  }

  useEffect(() => {
    if (!data || !data.result || loadingUsers) return;
    if (!data.result.content.length && !inputSearchProps.value && !currentFilters.length) {
      isNewCompany.current = true;
      return;
    }
    isNewCompany.current = false;
    setUsers(data.result.content);
  }, [data, loadingUsers]);

  const table = useReactTable({ columns: usersTableColumns, data: users, getCoreRowModel: getCoreRowModel() });

  return {
    query: {
      users,
      paginationComponent,
      loading: loadingUsers || loadingUpdate || loadingDelete,
      error,
    },
    loadingUsers,
    deleteModal,
    editUserFormModal,
    inputSearchProps,
    table,
    isNewCompany: isNewCompany.current,
    userRoleSummary: userRoleSummary || [],
    userSummaryLoading,
    currentFilters,
    teamsAssignedModal,
    handleEditUser,
    handleDeleteUser,
    setCurrentFilters,
  };
};
