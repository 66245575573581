import React from 'react';
import DesignGridItem from './item/design-grid-item';
import { DesignsGridContainer } from './designs-grid.styled';
import { IBessDesign, IDesign } from 'types/design';

interface IProps {
  designs: (IDesign | IBessDesign)[];
  viewDetailed: boolean;
  rowsSelected: (IDesign | IBessDesign)[];
  zeroDesignsCreated: boolean;
  onPerform: (action: string, item: IDesign | IBessDesign) => void;
  onEditDesignName: (id: string, newName: string) => void;
}

const DesignsGrid: React.FC<IProps> = (props) => {
  const { designs, viewDetailed, rowsSelected, onPerform, onEditDesignName } = props;

  const handleEditDesignName = (id: string) => (newName: string) => {
    onEditDesignName(id, newName);
  };

  return (
    <DesignsGridContainer data-testid="designs-grid">
      {designs.map((item) => (
        <DesignGridItem
          key={item.id}
          item={item}
          showDetails={viewDetailed}
          selected={rowsSelected.some((element) => element.id === item.id)}
          onPerform={onPerform}
          onEditName={handleEditDesignName(item.id)}
        />
      ))}
    </DesignsGridContainer>
  );
};

export default DesignsGrid;
