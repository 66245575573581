import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuerySearchParams() {
  const { search } = useLocation();
  const querySearchParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  return querySearchParams;
}
