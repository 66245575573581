import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Label, ResponsiveContainer, Bar, BarChart, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import { IOption } from 'types/common';
import { IAttribute } from 'utils/constants/design-attributes';
import { sortByKey } from 'utils/sort';
import {
  ChartTitle,
  DesignComparisonGenericChartContainer,
  DesignComparisonSortSelector,
} from '../design-comparison-generic-chart.styled';
import useDesignComparisonMoneyBarChart, { IChartDimensionUnits } from './use-design-comparison-money-bar-chart';
import { Typography, Tooltip as RPTooltip, Dropdown, SVGWrapper } from '@ratedpower/components';
import { InfoSvg } from 'assets/icons';
import { useTheme } from 'styled-components';

interface IChartAxisData {
  y: string;
  [key: string]: string;
}

const sortOptions = (t: any): IOption[] => [
  {
    label: t('design:date'),
    value: 'publishedDate',
  },
  {
    label: t('design:name'),
    value: 'name',
  },
  {
    label: t('design:sort.price-low-to-high'),
    value: 'priceLowToHigh',
  },
  {
    label: t('design:sort.price-high-to-low'),
    value: 'priceHighToLow',
  },
];

const KEY_SORT_OPTIONS: { [key: string]: any } = {
  publishedDate: { selector: 'publishedDate', ascendent: false },
  name: { selector: 'x', ascendent: false },
  priceLowToHigh: { selector: 'y', ascendent: false },
  priceHighToLow: { selector: 'y', ascendent: true },
};

export interface IPropsDesignComparisonMoneyBarChart {
  title: string;
  data: any[];
  xAttr: IAttribute;
  yAttr: IAttribute;
  tickFormatterX?: any;
  tickFormatterY?: any;
  formatUnits?: (dimensionUnits: IChartDimensionUnits) => IChartAxisData;
  formatAxisLabels?: (dimensionUnits: IChartDimensionUnits) => IChartAxisData;
  tooltipLabels: IChartAxisData;
}
const DesignComparisonMoneyBarChart: React.FC<IPropsDesignComparisonMoneyBarChart> = ({
  title,
  data,
  xAttr,
  yAttr,
  tooltipLabels,
  tickFormatterX,
  tickFormatterY,
  ...optionalProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { formattedData, dimensionUnits } = useDesignComparisonMoneyBarChart({ data, xAttr, yAttr });
  const [selectedSortMethod, setSelectedSortMethod] = useState<IOption | undefined>(undefined);

  const chartData = useMemo(() => {
    if (!selectedSortMethod) return formattedData;
    return sortByKey(formattedData, KEY_SORT_OPTIONS[selectedSortMethod.value]);
  }, [selectedSortMethod, formattedData]);

  const handleSort = (option: IOption[]) => {
    setSelectedSortMethod(option[0]);
  };

  const units = useMemo<IChartAxisData>(() => {
    if (!optionalProps.formatUnits) return { x: '', y: '' };
    return optionalProps.formatUnits(dimensionUnits);
  }, [dimensionUnits, optionalProps]);

  const axisLabels = useMemo<IChartAxisData>(() => {
    if (!optionalProps.formatAxisLabels) return tooltipLabels;
    return optionalProps.formatAxisLabels(dimensionUnits);
  }, [dimensionUnits, optionalProps, tooltipLabels]);

  return (
    <DesignComparisonGenericChartContainer>
      <ChartTitle>
        <Typography weight="bold" color={theme.v2.text.secondary.default}>
          {title}
        </Typography>
        <RPTooltip description={<Typography size="s">{t('design:money-bar-chart-warning')}</Typography>}>
          <SVGWrapper color={theme.v2.text.secondary.default} icon={InfoSvg} size="m" />
        </RPTooltip>
      </ChartTitle>
      <DesignComparisonSortSelector>
        <Dropdown options={sortOptions(t)} placeholder={t('design:sort.sort')} onChange={handleSort} />
      </DesignComparisonSortSelector>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart margin={{ top: 8, right: 32, left: 32, bottom: 30 }} data={chartData}>
          <CartesianGrid stroke="#EEEEEE" strokeDasharray="5 5" />

          <Tooltip
            formatter={(value, name, props) => [
              // eslint-disable-next-line react/prop-types
              `${value} ${props.payload.symbol ?? '$'}${units.y}`,
              tooltipLabels[name],
            ]}
          />
          <XAxis
            dataKey="x"
            allowDuplicatedCategory
            // tickCount={formattedData.length}
            type="category"
            tickMargin={20}
            angle={30}
            // domain={['auto', 'auto']}
            padding={{ right: 20, left: 20 }}
            tickFormatter={tickFormatterX}
          >
            <Label
              position="center"
              offset={-15}
              style={{ fill: theme.v2.text.secondary.default }}
              value={axisLabels.x}
            />
          </XAxis>
          <YAxis
            tickCount={6}
            type="number"
            domain={['auto', 'auto']}
            padding={{ top: 20 }}
            tickFormatter={tickFormatterY}
          >
            <Label
              angle={-90}
              position="insideLeft"
              offset={-5}
              style={{ textAnchor: 'middle', fill: theme.v2.text.secondary.default }}
              value={axisLabels.y}
            />
          </YAxis>
          <Bar dataKey="y" minPointSize={5} barSize={20}>
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fillColor} strokeWidth={index === 2 ? 4 : 1} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </DesignComparisonGenericChartContainer>
  );
};

export default DesignComparisonMoneyBarChart;
