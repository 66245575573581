import React from 'react';
import DesignComparison from './design-comparison';
import { DesignComparisonProvider } from './design-comparison-state';

const DesignComparisonWrapper: React.FC = () => (
  <DesignComparisonProvider>
    <DesignComparison />
  </DesignComparisonProvider>
);

export default DesignComparisonWrapper;
