import styled from 'styled-components';
import { Typography } from '@ratedpower/components';

export const HelpButtonContainer = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.v2.btn.accent.bg.default};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  color: ${({ theme }) => theme.v2.btn.accent.icon.default};
  display: flex;
  justify-content: center;
  position: fixed;
  width: 32px;
  height: 32px;
  z-index: 20;

  &:hover {
    background-color: ${({ theme }) => theme.v2.btn.accent.bg.hover};
    transition: background-color 0.1s linear;
  }

  .info-tooltip {
    background-color: ${({ theme }) => theme.v2.bg.primary.default};
    border-radius: ${({ theme }) => theme.v2.radius.s};
    bottom: 45px;
    box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
    color: ${({ theme }) => theme.v2.text.primary.default};
    padding: 0.5rem;
    position: absolute;
    right: 0;
    text-align: left;
    width: max-content;
  }
`;

export const ListContainer = styled.span`
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  border-radius: ${({ theme }) => theme.v2.radius.s};
  bottom: 45px;
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  display: flex;
  flex-direction: column;
  max-width: 250px;
  position: absolute;
  right: 0;
  width: max-content;
`;

export const ListItem = styled(Typography).attrs({ as: 'a' })`
  border-radius: ${({ theme }) => theme.v2.radius.s};
  padding: 1rem;
  text-align: left;
  transition: background 0.1s linear;
  color: ${({ theme }) => theme.v2.text.primary.default};

  &:hover {
    background-color: ${({ theme }) => theme.v2.bg.accent.secondary};
  }
`;
