import React from 'react';
import * as S from 'utils/maps/map-popups.styled';
import { useTranslation } from 'react-i18next';
import { Separator, Typography } from '@ratedpower/components';

type FlexibleSchemaInfoPopupData = {
  componentName: string;
};
const FlexibleSchemaInfoPopup: React.FC<FlexibleSchemaInfoPopupData> = ({ componentName }) => {
  const { t } = useTranslation();

  const nameArray = componentName.split('-');
  const componentNumber = nameArray[1].includes('Polygon') ? nameArray[1].split('Polygon')[1] : nameArray[1];
  const facilityType = nameArray[0];

  function getTitle(_facilityType: string) {
    if (_facilityType === 'OHL') {
      return t('design-process:preview.overhead-line') + ' (' + _facilityType + ') ' + componentNumber;
    } else if (_facilityType === 'ST') {
      return t('design-process:grid-point-tab.substation') + ' (' + _facilityType + ') ' + componentNumber;
    } else {
      return (
        t('design-process:grid-point-tab.switching-and-breaking-station') +
        ' (' +
        _facilityType +
        ') ' +
        componentNumber
      );
    }
  }

  const title = getTitle(facilityType);

  return (
    <>
      <S.MapPopupRow>
        <Typography category="title" weight="bold" size="m">
          {title}
        </Typography>
      </S.MapPopupRow>
      <Separator />
      <S.MapPopupRow>
        <Typography category="display" size="s">
          {t('design-process:interactive-site.unused-facility-warning', { facilityType: facilityType })}
        </Typography>
      </S.MapPopupRow>
    </>
  );
};

export default FlexibleSchemaInfoPopup;
