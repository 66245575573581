import React from 'react';
import { IBessDesign, IDesign } from 'types/design';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { GenericStatus } from 'utils/constants/status';
import Skeleton from 'react-loading-skeleton';
import * as S from './home-design-item-content.styled';
import { useTranslation } from 'react-i18next';
import { getTagTextConfig, selectedColor } from 'utils/design-type-functions';

interface IHomeDesignItemContent {
  design: IDesign | IBessDesign;
}

export const designCardDimensions = {
  width: 276,
  height: 120,
};

const HomeDesignItemContent = ({ design }: IHomeDesignItemContent) => {
  const { t } = useTranslation(),
    isSimulationInProgress: boolean =
      design.simulationResult === GenericStatus.IN_PROGRESS || design.simulationResult === GenericStatus.QUEUED,
    tagVariant: string = selectedColor(design),
    tagText: string = t(getTagTextConfig(design));

  return (
    <>
      <S.ThumbnailContainer>
        <S.PositionedTag color={tagVariant}>{tagText}</S.PositionedTag>
        {isSimulationInProgress ? (
          <Skeleton width={designCardDimensions.width} height={designCardDimensions.height} />
        ) : (
          <RemoteImage
            width={designCardDimensions.width}
            height={designCardDimensions.height}
            url={design.satellitePicture?.thumbnail}
            alt="Layout view"
          />
        )}
      </S.ThumbnailContainer>
      {isSimulationInProgress && <Skeleton />}
    </>
  );
};

export default HomeDesignItemContent;
