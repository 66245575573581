import React, { useState } from 'react';
import * as S from './upgrade-plan-modal.styled';
import * as ModalPlan from './../plan-modal.styled';
import useForm, { IForm } from 'utils/hooks/use-form';
import {
  BaseTemplateInput,
  Checkbox,
  InputNumber,
  Dropdown,
  InputTextArea,
  LabelWrapper,
  NotificationBar,
  RadioGroup,
  Separator,
  Typography,
  IModalProps,
  Modal,
} from '@ratedpower/components';
import { IOption } from 'types/common';
import { ISubscriptionPlanDTO, Plan } from 'types/subscription-plan';
import { RootState } from 'redux/store';
import { plansOptions, customPlanTypeOptions } from 'utils/constants/support';
import { getOptionForValue } from 'utils/selector';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export interface IUpgradePlanModal extends IModalProps {
  currentPlan: ISubscriptionPlanDTO;
  handleUpgradePlan: (inputs: IForm) => void;
  handleUpgradeChangePlan: (inputs: IForm) => void;
}

const UpgradePlanModal: React.FC<IUpgradePlanModal> = (props) => {
  const { currentPlan, handleUpgradePlan, handleUpgradeChangePlan } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const currencies = useSelector((state: RootState) => state.currencies.currencyOptions);
  const [planSelected, setPlanSelected] = useState<Plan>(currentPlan.plan);
  const [upgradeChangePlan, setUpgradeChangePlan] = useState(false);

  const { formError, setFormError, inputs, handleInputChangeManual } = useForm(null, {
    plan: currentPlan.plan,
    customPlanType: currentPlan.customPlanType,
    notes: currentPlan.notes,
    currency: currentPlan.currency,
    expirationDate: currentPlan.expirationDate,
    startDate: currentPlan.startDate,
    projectsDone: currentPlan.projectsDone,
    usersCreated: currentPlan.usersCreated,
    price: 0,
    projectsPurchased: 0,
    sendWelcomeEmail: false,
  });

  function onSubmit() {
    if (
      (!upgradeChangePlan && new Date(inputs.expirationDate) < new Date(currentPlan.startDate)) ||
      (upgradeChangePlan &&
        (!inputs.plan ||
          !inputs.expirationDate ||
          (planSelected === Plan.CUSTOM && !inputs.customPlanType) ||
          !inputs.currency ||
          !inputs.startDate ||
          (inputs.price !== 0 && !inputs.price) ||
          (inputs.projectsPurchased !== 0 && !inputs.projectsPurchased)))
    ) {
      setFormError(true);
      return;
    }

    upgradeChangePlan ? handleUpgradeChangePlan(inputs) : handleUpgradePlan(inputs);
  }

  function getOptionLabels(options: IOption[]) {
    return options.map((option) => {
      return {
        value: option.value,
        label: t(`support:${option.label}`),
      };
    });
  }

  const selectedPlanOption = getOptionForValue(planSelected, getOptionLabels(plansOptions));
  const selectedCurrencyOption = getOptionForValue(inputs.currency, currencies);

  return (
    <Modal
      {...props}
      title={t('company:upgrade-plan')}
      buttons={{
        primary: { text: t('action:save'), type: 'submit', onClick: onSubmit },
        secondary: {
          text: t('action:cancel'),
          onClick: props.onClose,
        },
      }}
    >
      <Typography weight={700}>{t('company:plan-details')}</Typography>
      <S.ModalUpgradeSwitch>
        <RadioGroup
          id="upgradeType"
          selectedValue={upgradeChangePlan ? 'upgrade-change' : 'upgrade-current'}
          onChange={(selectedOption) => setUpgradeChangePlan(selectedOption.value === 'upgrade-change')}
          options={[
            {
              label: t('company:upgrade-current-plan'),
              value: 'upgrade-current',
            },
            {
              label: t('company:change-plan'),
              value: 'upgrade-change',
            },
          ]}
          direction="column"
        />
      </S.ModalUpgradeSwitch>

      <ModalPlan.ModalPlanForm>
        <Separator />

        <LabelWrapper
          color={!upgradeChangePlan ? theme.gray : undefined}
          title={t(upgradeChangePlan ? 'company:new-plan' : 'company:current-plan')}
          htmlFor="plan"
        >
          {upgradeChangePlan ? (
            <Dropdown
              options={getOptionLabels(plansOptions)}
              onChange={(e) => {
                setPlanSelected(e[0].value as Plan);
                handleInputChangeManual('plan', e[0].value);
              }}
              name="plan"
              error={formError && !inputs.plan}
              value={selectedPlanOption ? [selectedPlanOption] : undefined}
            />
          ) : (
            <Typography>{t(`support:${getOptionForValue(currentPlan.plan, plansOptions)?.label}`)}</Typography>
          )}
        </LabelWrapper>
        {upgradeChangePlan && planSelected === Plan.CUSTOM && (
          <LabelWrapper title={t('type')} htmlFor="customPlanType">
            <Dropdown
              error={formError && !inputs.customPlanType}
              options={getOptionLabels(customPlanTypeOptions)}
              defaultValue={inputs.customPlanType}
              name="customPlanType"
              onChange={(newType) => handleInputChangeManual('customPlanType', newType[0].value)}
            />
          </LabelWrapper>
        )}
        <LabelWrapper
          color={!upgradeChangePlan ? theme.gray : undefined}
          title={t('capex:currency')}
          htmlFor="currency"
        >
          {upgradeChangePlan ? (
            <Dropdown
              name="currency"
              options={currencies}
              error={formError && !inputs.currency}
              value={selectedCurrencyOption ? [selectedCurrencyOption] : undefined}
              onChange={(newCurrency) => handleInputChangeManual('currency', newCurrency[0].value)}
            />
          ) : (
            <Typography>{getOptionForValue(inputs.currency, currencies)?.label}</Typography>
          )}
        </LabelWrapper>

        <LabelWrapper title={t(upgradeChangePlan ? 'company:total-price' : 'company:extra-price')} htmlFor="price">
          <InputNumber
            hasBackgroundContrast
            name="price"
            min={0}
            prefix={upgradeChangePlan ? '' : '+'}
            value={inputs.price}
            onChange={(newPrice) => handleInputChangeManual('price', newPrice)}
          />
        </LabelWrapper>
        {upgradeChangePlan && planSelected === Plan.CUSTOM && (
          <LabelWrapper title={t('notes')} htmlFor="notes">
            <InputTextArea
              hasBackgroundContrast
              placeholder={t('notes')}
              name="notes"
              size="m"
              defaultValue={inputs.notes}
              onChange={(e) => handleInputChangeManual('notes', e.target.value)}
            />
          </LabelWrapper>
        )}
        <LabelWrapper
          title={t(upgradeChangePlan ? 'company:total-purchased-projects' : 'company:added-projects')}
          htmlFor="projectsPurchased"
        >
          {inputs.type === Plan.ENTERPRISE ? (
            <Typography>{t('company:unlimited')}</Typography>
          ) : (
            <InputNumber
              name="projectsPurchased"
              min={0}
              prefix={upgradeChangePlan ? '' : '+'}
              value={inputs.projectsPurchased}
              onChange={(newProjects) => handleInputChangeManual('projectsPurchased', newProjects)}
            />
          )}
        </LabelWrapper>

        <LabelWrapper color={theme.gray} title={t('company:used-projects')}>
          <Typography>{inputs.projectsDone}</Typography>
        </LabelWrapper>
        <LabelWrapper color={theme.gray} title={t('company:number-of-active-users')}>
          <Typography>{inputs.usersCreated}</Typography>
        </LabelWrapper>

        {upgradeChangePlan && (
          <LabelWrapper title={t('company:new-start-date')} htmlFor="startDate">
            <BaseTemplateInput
              error={formError && new Date(inputs.expirationDate) < new Date(inputs.startDate)}
              type="date"
              name="startDate"
              defaultValue={inputs.startDate}
              max="2999-12-31"
              onChange={(newStartDate) => handleInputChangeManual('startDate', newStartDate)}
            />
          </LabelWrapper>
        )}

        <LabelWrapper title={t('company:new-expiration-date')} htmlFor="expirationDate">
          <BaseTemplateInput
            error={formError && new Date(inputs.expirationDate) < new Date(currentPlan.startDate)}
            type="date"
            hasBackgroundContrast
            name="expirationDate"
            value={inputs.expirationDate}
            max="2999-12-31"
            helperText={
              formError && new Date(inputs.expirationDate) < new Date(currentPlan.startDate)
                ? t('errors:start-date-past-expiration')
                : ''
            }
            onChange={(newExpirationDate) => handleInputChangeManual('expirationDate', newExpirationDate)}
          />
        </LabelWrapper>
        <LabelWrapper title={t('support:send-welcome-mail')} htmlFor="sendWelcomeEmail" maxWidth="210px">
          <Checkbox
            id="sendWelcomeEmail"
            name="sendWelcomeEmail"
            checked={inputs.sendWelcomeEmail}
            onChange={(e) => {
              handleInputChangeManual('sendWelcomeEmail', e.target.checked);
            }}
          />
        </LabelWrapper>
        {upgradeChangePlan && (
          <NotificationBar status="informative" description={t('company:upgrade-change-plan-info')} />
        )}
      </ModalPlan.ModalPlanForm>
    </Modal>
  );
};

export { UpgradePlanModal };
