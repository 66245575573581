export const highlightedLayerColors = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#3366cc',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
];

const getHighLightedLayers = (map: any, layerId: string) => {
  highlightedLayerColors.forEach((color, key) => {
    map.current.addLayer({
      id: `${layerId}-highlighted-${key}`,
      type: 'fill',
      source: layerId,
      paint: {
        'fill-outline-color': color,
        'fill-color': color,
        'fill-opacity': 0.85,
      },
      filter: ['in', 'plotAreaId', ''],
    });
  });
};

export const getGroupedAreasLayers = (map: any, layerId: string) => {
  getHighLightedLayers(map, layerId);
  map.current.addLayer({
    id: `${layerId}-selected`,
    type: 'line',
    source: layerId,
    paint: {
      'line-width': 8,
      'line-color': '#1AA5BD',
    },
    filter: ['in', 'plotAreaId', ''],
  });
};
