import React from 'react';
import classNames from 'classnames';
import { IComparisonFilter } from 'types/design-comparison.types';
import * as S from './comparison-charts.styled';
import DesignComparisonScatterChart from './design-comparison-scatter-chart/design-comparison-scatter-chart';
import { designAttributes } from 'utils/constants/design-attributes';
import { useDesignComparisonData } from '../design-comparison-state';
import DesignComparisonLineChart from './design-comparison-line-chart/design-comparison-line-chart';
import DesignComparisonMoneyBarChart from './design-comparison-money-bar-chart/design-comparison-money-bar-chart';
import DesignComparisonGCRScatterChart from './design-comparison-scatter-chart/design-comparison-gcr-scatter-chart';
import { graphicLineAttributes } from './graphic-attributes/line-chart-attributes';
import { graphicMoneyBarAttributes } from './graphic-attributes/money-bar-chart-attributes';
import { graphicGCRScatterAttributes } from './graphic-attributes/gcr-scatter-chart-attributes';
import { graphicScatterAttributes } from './graphic-attributes/scatter-chart-attributes';
import { StatusSVGWrapper, SVGWrapper, Tag } from '@ratedpower/components';
import { CrossSvg, FavouriteOutlineSvg, FavouriteSvg } from 'assets/icons';

interface IProps {
  activeAttributes: IComparisonFilter[];
  onAddDesign: () => void;
}
const ComparisonCharts: React.FC<IProps> = ({ activeAttributes }) => {
  const { designs, removeDesign, toggleFavorite } = useDesignComparisonData();

  return (
    <S.ComparisonChartsContainer designsCount={designs.length}>
      <S.DesignsSelected>
        {designs.map((design) => (
          <Tag
            key={design.id}
            dotColor={design.fillColor}
            color="white"
            minWidth="88px"
            maxWidth="176px"
            leftIcon={
              <StatusSVGWrapper
                active={design.isFavorite}
                icon={design.isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
                size="xs"
                onClick={() => toggleFavorite(design.id)}
              />
            }
            rightIcon={<SVGWrapper icon={CrossSvg} size="xs" onClick={() => removeDesign(design.id)} />}
          >
            {design.name}
          </Tag>
        ))}
      </S.DesignsSelected>

      <div className="charts-wrapper">
        <div className="charts-container">
          {activeAttributes.map((activeAttribute: IComparisonFilter) => {
            const lineChartAttributes = graphicLineAttributes(designs, designAttributes, activeAttribute);
            if (lineChartAttributes) {
              return (
                <div
                  key={activeAttribute.label}
                  className={classNames('comparison-chart-container', {
                    big: designs.length > 6,
                  })}
                >
                  <S.ComparisonChartSizeWrapper>
                    <DesignComparisonLineChart {...lineChartAttributes} />
                  </S.ComparisonChartSizeWrapper>
                </div>
              );
            }
            const moneyBarChartAttributes = graphicMoneyBarAttributes(designs, designAttributes, activeAttribute);
            if (moneyBarChartAttributes) {
              return (
                <div
                  key={activeAttribute.label}
                  className={classNames('comparison-chart-container', {
                    big: designs.length > 6,
                  })}
                >
                  <S.ComparisonChartSizeWrapper>
                    <DesignComparisonMoneyBarChart {...moneyBarChartAttributes} />
                  </S.ComparisonChartSizeWrapper>
                </div>
              );
            }
            const gcrScatterChartAttributes = graphicGCRScatterAttributes(designs, designAttributes, activeAttribute);
            if (gcrScatterChartAttributes) {
              return (
                <div key={activeAttribute.label} className={classNames('comparison-chart-container')}>
                  <S.ComparisonChartSizeWrapper>
                    <DesignComparisonGCRScatterChart {...gcrScatterChartAttributes} />
                  </S.ComparisonChartSizeWrapper>
                </div>
              );
            }
            const scatterChartAttributes = graphicScatterAttributes(designs, designAttributes, activeAttribute);
            if (scatterChartAttributes) {
              scatterChartAttributes.xAttr = {
                ...scatterChartAttributes.xAttr,
                key: Array.isArray(scatterChartAttributes.xAttr.key)
                  ? scatterChartAttributes.xAttr.key[0]
                  : scatterChartAttributes.xAttr.key,
              };

              scatterChartAttributes.yAttr = {
                ...scatterChartAttributes.yAttr,
                key: Array.isArray(scatterChartAttributes.yAttr.key)
                  ? scatterChartAttributes.yAttr.key[0]
                  : scatterChartAttributes.yAttr.key,
              };

              return (
                <div key={activeAttribute.label} className={classNames('comparison-chart-container')}>
                  <S.ComparisonChartSizeWrapper>
                    <DesignComparisonScatterChart {...scatterChartAttributes} />
                  </S.ComparisonChartSizeWrapper>
                </div>
              );
            }
          })}
        </div>
      </div>
    </S.ComparisonChartsContainer>
  );
};

export default ComparisonCharts;
