import { useState } from 'react';
import { GET_COMPANY_PROJECTS } from 'services/support-gql';
import { IServerFilter, ISort } from 'types/filter';
import { PROJECTS_PER_PAGE } from 'utils/constants/pagination';
import { getSortDirection } from 'utils/sort';
import { useCustomQuery } from './use-custom-query';
import { useSearch } from './use-search';
import { useTranslation } from 'react-i18next';
import { PUBLIC_TEAMS_OPTION } from 'utils/constants/users';
import { GenericStatus } from 'utils/constants/status';

export const useGetCompanyProjects = (companyId: string,  filters?: IServerFilter[], teamId?: string) => {
  const { t } = useTranslation();
  const { inputSearchProps, getSearchFilter } = useSearch();
  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('equipment:publish-date'),
      code: 'publishedDate',
    },
    ascendent: false,
  });

  const {
    data,
    loading,
    error,
    paginationComponent,
    refetch: refetchProjects,
  } = useCustomQuery(GET_COMPANY_PROJECTS, {
    variables: {
      pagination: {
        page: 0,
        size: PROJECTS_PER_PAGE,
      },
      sorting: {
        property: sortedBy?.selector.code,
        direction: getSortDirection(sortedBy?.ascendent),
      },
      teamId,
      filters:
        teamId === PUBLIC_TEAMS_OPTION(t).value
          ? [
              ...(filters || []),
              {
                field: 'STATUS',
                operator: 'IN',
                values: [GenericStatus.ARCHIVED, GenericStatus.FINISHED, GenericStatus.REVIEWED],
              },
            ]
          : filters,
      search: getSearchFilter(),
      companyId,
    },
    fetchPolicy: 'network-only',
    skip: !companyId,
  });

  return {
    data: data?.projectsByCompany?.content,
    loading,
    error,
    paginationComponent,
    inputSearchProps,
    sortedBy,
    setSortedBy,
    refetchProjects,
  };
};
