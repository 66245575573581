import { AccountSvg, ConfigSvg, LogoutSvg, SubscriptionSvg } from 'assets/icons';
import { IPopupIconButtonOption } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { clearLocalStorage } from 'services/local-storage';
import { useAuth0 } from '@auth0/auth0-react';
import { getRedirectUri } from 'utils/redirect_uri';

interface IUseTopbarMyAccount {
  options: IPopupIconButtonOption[];
  onClickOption: (selectedOption: IPopupIconButtonOption) => void;
  handleLogout: () => void;
  handleCancelLogout: () => void;
  modal: IUseModal;
}

export const useTopbarMyAccount = (): IUseTopbarMyAccount => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const modal = useModal();

  const handleLogout = () => {
    clearLocalStorage();
    logout({ logoutParams: { returnTo: getRedirectUri() } });
  };

  const handleCancelLogout = () => {
    modal.setModalOpen(false);
  };

  const onClickOption = (selectedOption: IPopupIconButtonOption) => {
    if (selectedOption.value === 'logout') {
      modal.setModalOpen(true);
    } else {
      navigate(selectedOption.value);
    }
  };

  const defaultOptionsIconColor: string = theme.v2.icon.primary.default,
    options: IPopupIconButtonOption[] = [
      {
        label: t('user:my-profile'),
        Icon: AccountSvg,
        value: '/profile',
        iconColor: defaultOptionsIconColor,
      },
      {
        label: t('user:my-preferences'),
        Icon: ConfigSvg,
        value: '/profile/preferences',
        iconColor: defaultOptionsIconColor,
      },
      {
        label: t('user:my-plan'),
        Icon: SubscriptionSvg,
        value: '/profile/plan',
        iconColor: defaultOptionsIconColor,
      },
      {
        value: 'logout',
        label: t('auth:logout'),
        Icon: LogoutSvg,
        color: theme.v2.error.bg.contrast,
      },
    ];

  return { options, onClickOption, handleLogout, handleCancelLogout, modal };
};
