import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IVersionState {
  currentVersion?: Date;
  showPopup: boolean;
}

const initialState: IVersionState = {
  showPopup: false,
};

const versionSlice = createSlice({
  initialState,
  name: 'version',
  reducers: {
    setVersion(state, action: PayloadAction<{ currentVersion: Date }>) {
      const { currentVersion } = action.payload;
      state.currentVersion = currentVersion;
    },
    setShowPopup(state, action: PayloadAction<boolean>) {
      state.showPopup = action.payload;
    },
  },
});

export const { setVersion, setShowPopup } = versionSlice.actions;

export default versionSlice.reducer;
