import React, { ReactNode, useContext } from 'react';
import { IUseModal, useModal } from 'utils/hooks/use-modal';

export interface ContextState {
  addMembersModal: IUseModal;
  createProjectModal: IUseModal;
  addProjectsModal: IUseModal;
  deleteTeamModal: IUseModal;
}

export const Context = React.createContext<ContextState | null>(null);

export const useTeamsProvider = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useTeamsProvider must be used within a TeamsProvider tag');
  }
  return contextState;
};

export const TeamsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const addMembersModal = useModal();
  const addProjectsModal = useModal();
  const createProjectModal = useModal();
  const deleteTeamModal = useModal();

  return (
    <Context.Provider
      value={{
        addMembersModal,
        createProjectModal,
        addProjectsModal,
        deleteTeamModal,
      }}
    >
      {children}
    </Context.Provider>
  );
};
