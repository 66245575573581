import React from 'react';
import * as S from './pv-design-other-plan.styled';
import { Button, SVGWrapper, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { BUSINESS_EMAIL } from 'utils/constants/contact';
import { CheckSvg } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { PvOtherPlanContent, PvPlanCard } from '../pv-design-plans.styled';

interface IProps {
  title: string;
  color: 'primary' | 'secondary' | 'yellow_dark' | 'purple' | 'red';
  includedItems: string[];
  hasTopTitle: boolean;
}

const PvDesignOtherPlan: React.FC<IProps> = ({ title, color, includedItems, hasTopTitle }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <S.OtherPlanContainer>
      {hasTopTitle && <Typography weight="bold">{t('company:other-plans')}</Typography>}
      <PvPlanCard key={title} color={color} size="small">
        <Typography weight="bold">{t(`support:${title}`)}</Typography>
        <PvOtherPlanContent>
          <S.BenefitsContainer>
            {includedItems.map((benefit: string) => (
              <S.BenefitsItem key={benefit}>
                <Typography size="s">{t(`company:${benefit}`)}</Typography>
                <SVGWrapper icon={CheckSvg} size="s" color={theme.v2.icon.primary.default} />
              </S.BenefitsItem>
            ))}
          </S.BenefitsContainer>
        </PvOtherPlanContent>
        <Button
          variant="accent"
          className="request-more-info-btn"
          onClick={() => window.open(`mailto:${BUSINESS_EMAIL}`, '_blank')}
          text="Request more information"
          size="m"
        />
      </PvPlanCard>
    </S.OtherPlanContainer>
  );
};

export default PvDesignOtherPlan;
