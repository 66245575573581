import React from 'react';
import { Modal } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';

interface IProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteUserNotificationModal: React.FC<IProps> = ({ show, onClose, onConfirm }: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('communication:delete-notification')}
      status="warn"
      isActive={show}
      onClose={onClose}
      buttons={{
        primary: {
          text: t('action:delete'),
          onClick: onConfirm,
        },
        secondary: {
          text: t('action:cancel'),
          onClick: onClose,
        },
      }}
    >
      {t('communication:delete-notification-confirmation')}
    </Modal>
  );
};

export default DeleteUserNotificationModal;
