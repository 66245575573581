import { TFunction } from 'i18next';
import { DefaultTheme } from 'styled-components';
import { IReadOnlyPermissionSection } from 'types/role';

export const readOnlyPermissions = (
  roleName: string,
  t: TFunction,
  theme: DefaultTheme
): IReadOnlyPermissionSection[] => {
  const key = 'user:read-only-roles';
  switch (roleName) {
    case 'Admin': {
      return [
        {
          sectionTitle: t('project:projects'),
          permissions: [
            {
              title: t(`${key}.create-projects`),
              points: [t(`${key}.in-all-teams`), t(`${key}.public-projects`)],
            },
            {
              title: t(`${key}.delete-projects`),
              points: [t(`${key}.in-all-teams`), t(`${key}.public-projects`)],
            },
            {
              title: t(`${key}.edit-project-names`),
              points: [t(`${key}.in-all-teams`), t(`${key}.public-projects`)],
            },
          ],
        },
        {
          sectionTitle: t('designs'),
          permissions: [
            {
              title: t(`${key}.create-designs`),
              points: [t(`${key}.in-any-projects`)],
            },
            {
              title: t('design:delete-selected-designs-title'),
              points: [t(`${key}.any-designs`)],
            },
            {
              title: t(`${key}.edit-designs-names`),
              points: [t(`${key}.any-designs`)],
            },
            {
              title: t(`${key}.view-results`),
            },
            {
              title: t(`${key}.download-documents`),
            },
          ],
        },
        {
          sectionTitle: t('user:teams.tab-title'),
          permissions: [
            {
              title: t(`${key}.create-teams`),
            },
            {
              title: t(`${key}.delete-teams`),
              points: [t(`${key}.any-teams`)],
            },
            {
              title: t(`${key}.assign-existing-projects-to-team`),
              points: [t(`${key}.any-projects-any-teams`)],
            },
            {
              title: t(`${key}.change-team-settings`),
              points: [t(`${key}.any-teams`)],
            },
            {
              title: t(`${key}.remove-project-from-team`),
              points: [t(`${key}.any-projects-from-any-teams`)],
            },
          ],
        },
        {
          sectionTitle: t('company:users'),
          permissions: [
            {
              title: t(`${key}.create-users`),
              points: [t(`${key}.any-users-including-team-manager`)],
            },
            {
              title: t(`${key}.deactivate-users`),
              points: [t(`${key}.any-users`)],
            },
            {
              title: t(`${key}.add-users-in-a-team`),
              points: [t(`${key}.any-users-any-teams`)],
            },
            {
              title: t(`${key}.view-user-lists`),
            },
            {
              title: t(`${key}.view-which-teams-user-belongs-to`),
              points: [t(`${key}.any-users`)],
            },
            {
              title: t(`${key}.edit-users`),
              points: [t(`${key}.any-users`)],
            },
            {
              title: t(`${key}.remove-user-from-team`),
              points: [t(`${key}.any-users-from-any-teams`)],
            },
          ],
        },
        {
          sectionTitle: t('equipment'),
          permissions: [
            {
              title: t(`${key}.default-settings-company-level`),
              points: [t(`${key}.any-settings`)],
            },
            {
              title: t(`${key}.default-settings-project-level`),
              points: [t(`${key}.any-settings`)],
            },
            {
              title: t(`${key}.upload-equipment`),
            },
          ],
        },
        {
          sectionTitle: t('design:capex-templates'),
          permissions: [
            {
              title: t('action:create'),
            },
            {
              title: t('user:roles.READ'),
            },
            {
              title: t('user:roles.WRITE'),
            },
          ],
        },
        {
          sectionTitle: t('statistics:page-name'),
          permissions: [
            {
              title: t('user:roles.READ'),
            },
          ],
        },
      ];
    }
    case 'Team manager': {
      return [
        {
          sectionTitle: t('project:projects'),
          permissions: [
            {
              title: t(`${key}.create-projects`),
              points: [t(`${key}.in-their-teams`), t(`${key}.public-projects`)],
            },
            {
              title: t(`${key}.delete-projects`),
              points: [
                t(`${key}.in-their-teams`),
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.edit-project-names`),
              points: [
                t(`${key}.in-their-teams`),
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
          ],
        },
        {
          sectionTitle: t('designs'),
          permissions: [
            {
              title: t(`${key}.create-designs`),
              points: [t(`${key}.in-their-teams`), t(`${key}.public-projects`)],
            },
            {
              title: t('design:delete-selected-designs-title'),
              points: [
                t(`${key}.in-their-teams`),
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.edit-designs-names`),
              points: [
                t(`${key}.in-their-teams`),
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.view-results`),
            },
            {
              title: t(`${key}.download-documents`),
            },
          ],
        },
        {
          sectionTitle: t('user:teams.tab-title'),
          permissions: [
            {
              title: t(`${key}.assign-existing-projects-to-team`),
              points: [t(`${key}.only-for-their-teams`)],
            },
            {
              title: t(`${key}.change-team-settings`),
              points: [t(`${key}.only-for-their-teams`)],
            },
            {
              title: t(`${key}.remove-project-from-team`),
              points: [t(`${key}.only-for-their-teams`)],
            },
          ],
        },
        {
          sectionTitle: t('company:users'),
          permissions: [
            {
              title: t(`${key}.create-users`),
              points: [t(`${key}.only-for-their-teams-users`)],
            },
            {
              title: t(`${key}.add-users-in-a-team`),
              points: [t(`${key}.only-to-their-teams`)],
            },
            {
              title: t(`${key}.view-user-lists`),
            },
            {
              title: t(`${key}.view-which-teams-user-belongs-to`),
              points: [t(`${key}.only-if-the-user-belongs-to-their-teams`)],
            },
            {
              title: t(`${key}.edit-users`),
              points: [t(`${key}.only-of-their-teams`)],
            },
            {
              title: t(`${key}.remove-user-from-team`),
              points: [t(`${key}.only-from-their-teams`)],
            },
          ],
        },
        {
          sectionTitle: t('equipment'),
          permissions: [
            {
              title: t(`${key}.default-settings-project-level`),
              points: [
                t(`${key}.in-their-teams`),
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.upload-equipment`),
            },
          ],
        },
        {
          sectionTitle: t('design:capex-templates'),
          permissions: [
            {
              title: t('action:create'),
            },
            {
              title: t('user:roles.READ'),
            },
            {
              title: t('user:roles.WRITE'),
            },
          ],
        },
        {
          sectionTitle: t('statistics:page-name'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
      ];
    }
    case 'User': {
      return [
        {
          sectionTitle: t('project:projects'),
          permissions: [
            {
              title: t(`${key}.create-projects`),
              points: [t(`${key}.in-their-teams`), t(`${key}.public-projects`)],
            },
            {
              title: t(`${key}.delete-projects`),
              points: [
                `${t(`${key}.in-their-teams`)} - ${t(`${key}.only-if-they-create-them`)}`,
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.edit-project-names`),
              points: [
                `${t(`${key}.in-their-teams`)} - ${t(`${key}.only-if-they-create-them`)}`,
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
          ],
        },
        {
          sectionTitle: t('designs'),
          permissions: [
            {
              title: t(`${key}.create-designs`),
              points: [t(`${key}.in-their-teams`), t(`${key}.public-projects`)],
            },
            {
              title: t('design:delete-selected-designs-title'),
              points: [
                `${t(`${key}.in-their-teams`)} - ${t(`${key}.only-if-they-create-them`)}`,
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.edit-designs-names`),
              points: [
                `${t(`${key}.in-their-teams`)} - ${t(`${key}.only-if-they-create-them`)}`,
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.view-results`),
            },
            {
              title: t(`${key}.download-documents`),
            },
          ],
        },
        {
          sectionTitle: t('user:teams.tab-title'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('company:users'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('equipment'),
          permissions: [
            {
              title: t(`${key}.default-settings-project-level`),
              points: [t(`${key}.only-for-projects-they-created`)],
            },
            {
              title: t(`${key}.upload-equipment`),
            },
          ],
        },
        {
          sectionTitle: t('design:capex-templates'),
          permissions: [
            {
              title: t('action:create'),
            },
            {
              title: t('user:roles.READ'),
            },
            {
              title: t('user:roles.WRITE'),
            },
          ],
        },
        {
          sectionTitle: t('statistics:page-name'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
      ];
    }
    case 'Basic user': {
      return [
        {
          sectionTitle: t('project:projects'),
          permissions: [
            {
              title: t(`${key}.view-projects`),
            },
          ],
        },
        {
          sectionTitle: t('designs'),
          permissions: [
            {
              title: t(`${key}.create-designs`),
              points: [t(`${key}.in-their-teams`), t(`${key}.public-projects`)],
            },
            {
              title: t('design:delete-selected-designs-title'),
              points: [
                `${t(`${key}.in-their-teams`)} - ${t(`${key}.only-if-they-create-them`)}`,
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.edit-designs-names`),
              points: [
                `${t(`${key}.in-their-teams`)} - ${t(`${key}.only-if-they-create-them`)}`,
                `${t(`${key}.public-projects`)} - ${t(`${key}.only-if-they-create-them`)}`,
              ],
            },
            {
              title: t(`${key}.view-results`),
            },
            {
              title: t(`${key}.download-documents`),
            },
          ],
        },
        {
          sectionTitle: t('user:teams.tab-title'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('company:users'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('equipment'),
          permissions: [
            {
              title: t(`${key}.upload-equipment`),
            },
          ],
        },
        {
          sectionTitle: t('design:capex-templates'),
          permissions: [
            {
              title: t('action:create'),
            },
            {
              title: t('user:roles.READ'),
            },
            {
              title: t('user:roles.WRITE'),
            },
          ],
        },
        {
          sectionTitle: t('statistics:page-name'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
      ];
    }
    case 'Read-only': {
      return [
        {
          sectionTitle: t('project:projects'),
          permissions: [
            {
              title: t(`${key}.view-projects`),
            },
          ],
        },
        {
          sectionTitle: t('designs'),
          permissions: [
            {
              title: t(`${key}.view-results`),
            },
            {
              title: t(`${key}.download-documents`),
            },
          ],
        },
        {
          sectionTitle: t('user:teams.tab-title'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('company:users'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('equipment'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('design:capex-templates'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
        {
          sectionTitle: t('statistics:page-name'),
          permissions: [
            {
              title: t(`${key}.no-permissions`),
              titleColor: theme.red,
            },
          ],
        },
      ];
    }
    default: {
      return [];
    }
  }
};
