import React from 'react';
import useForm from 'utils/hooks/use-form';
import { useTranslation } from 'react-i18next';
import { IModalProps, Modal, InputTextArea } from '@ratedpower/components';

interface IProps extends IModalProps {
  notes?: string;
  id: string;
  userDetails?: {
    name: string;
    surname: string;
    email: string;
    role: string;
    codeLanguage: string;
  };
  onSubmit: (newValue: { id: string; notes: string }) => void;
}

const EditNotesModal: React.FC<IProps> = (props) => {
  const { notes, userDetails, id } = props;
  const { t } = useTranslation();

  const handleSave = () => {
    if (props.onSubmit) props.onSubmit({ notes: inputs.notes, id });
  };

  const { inputs, handleInputChange } = useForm(null, { notes, ...userDetails });

  return (
    <Modal
      {...props}
      disableClickOutside
      buttons={{
        primary: { text: t('action:save'), type: 'submit', onClick: handleSave },
        secondary: {
          text: t('action:cancel'),
          onClick: props.onClose,
        },
      }}
    >
      <form autoComplete="off">
        <InputTextArea
          name="notes"
          dataTest="input-project-notes"
          placeholder={t('notes')}
          value={inputs.notes}
          onChange={handleInputChange}
          maxLength={340}
          maxRows={5}
          size="m"
        />
      </form>
    </Modal>
  );
};

export default EditNotesModal;
