import React from 'react';
import { IBessDesign, IDesign } from 'types/design';
import { Typography } from '@ratedpower/components';
import { IDesignActions } from 'pages/designs/use-designs-actions';
import { useTranslation } from 'react-i18next';
import * as S from './home-projects-designs.styled';
import { HomeDesignTextWrapper } from './home-projects-designs.styled';
import HomeDesignItemHeader from 'pages/home/home-designs/home-project-item/home-projects-designs/home-design-item-header';
import HomeDesignItemContent from 'pages/home/home-designs/home-project-item/home-projects-designs/home-design-item-content';
import { Link } from 'react-router-dom';
import { buildDesignProcessUrl } from 'utils/navigation/navigation';

interface IProps {
  design: IDesign | IBessDesign;
  actions: IDesignActions;
  projectId: string;
}

const HomeDesignItem = ({ design, actions, projectId }: IProps) => {
  const { handleSwitchFavorite, handleClone } = actions,
    { t } = useTranslation();

  return (
    <Link to={buildDesignProcessUrl(projectId, design.id, design.simulationCategory, true)}>
      <S.HomeDesignItemContainer>
        <HomeDesignItemContent design={design} />
        <HomeDesignTextWrapper>
          <HomeDesignItemHeader design={design} handleSwitchFavorite={handleSwitchFavorite} handleClone={handleClone} />
          <S.HomeDesignItemSubheader>
            <Typography size={'s'} as={'p'} nowrap>
              {design.notes || t('home:designs.projects-list.no-notes')}
            </Typography>
          </S.HomeDesignItemSubheader>
        </HomeDesignTextWrapper>
      </S.HomeDesignItemContainer>
    </Link>
  );
};

export default HomeDesignItem;
