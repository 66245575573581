import { GridSvg, ListSvg } from 'assets/icons';

const designEnergyMock = {
  energyYield: 7338263114.311619,
  monthlyProduction: [
    {
      month: 1,
      performanceRatio: 0.8367153627684638,
      energyToGrid: 252939768.3274072,
    },
    {
      month: 2,
      performanceRatio: 0.8543991247814731,
      energyToGrid: 362280529.93322664,
    },
    {
      month: 3,
      performanceRatio: 0.8438913651114727,
      energyToGrid: 594030617.240566,
    },
    {
      month: 4,
      performanceRatio: 0.8265420925455762,
      energyToGrid: 653084477.495825,
    },
    {
      month: 5,
      performanceRatio: 0.821920623615784,
      energyToGrid: 849892387.9510105,
    },
    {
      month: 6,
      performanceRatio: 0.8007297198070084,
      energyToGrid: 1010003805.3361174,
    },
    {
      month: 7,
      performanceRatio: 0.8053561328360516,
      energyToGrid: 1093267952.8842578,
    },
    {
      month: 8,
      performanceRatio: 0.8044383754727245,
      energyToGrid: 902358111.7179841,
    },
    {
      month: 9,
      performanceRatio: 0.8085197357338901,
      energyToGrid: 654175647.1007333,
    },
    {
      month: 10,
      performanceRatio: 0.8269937173804393,
      energyToGrid: 464701562.6531895,
    },
    {
      month: 11,
      performanceRatio: 0.8223448781944988,
      energyToGrid: 233387632.44679824,
    },
    {
      month: 12,
      performanceRatio: 0.8464224068239377,
      energyToGrid: 268140621.2241973,
    },
  ],
};

const designMeteoData = {
  summary: {
    source: 'PVGIS',
    ghi: 1430378.45,
    bhi: 803510.65,
    dhi: 626867.8,
    tempAvg: 13.181391552511416,
    tempMin: -4.64,
    tempMax: 36.32,
  },
};

const designInputsMock = {
  module: {
    id: '333',
    manufacturer: 'Test Energy',
    model: 'Test AC-250M/156-60S',
    type: 'MONOFACIAL',
    voltage: 1000,
    power: 250,
  },
  inverter1: {
    id: '444',
    manufacturer: 'Test Energy Inc.',
    model: 'Test 500',
    type: 'CENTRAL',
    power: 500000,
  },
  inverter2: null,
  structure: {
    id: '555',
    manufacturer: 'Test Technologies',
    model: 'Test HZ v2.5',
    positionLetter: 'V',
    positionNumber: '1',
  },
  tiltAngle: 0,
  pitchDistance: 5.500000000000001,
  lvConfig: 'CONFIG_SBOX_L1',
  electricalConfig: {
    modulesPerString: 14,
  },
};

const designSiteMock = {
  name: 'Parcela 2',
  siteSummary: {
    lat: 42.7186646991,
    lng: -2.949179824280365,
    utmConvergence: 0.034476363300665065,
    areaAvailable: 180525.86021692594,
  },
  location: {
    country: 'Spain',
    city: 'Comunion',
  },
};

export const DESIGN_TEST_ITEM = {
  id: '123',
  name: 'Prueba 3_4',
  docsSaved: false,
  createdBy: {
    name: 'MyName',
    lastname: 'MySurname',
  },
  publishedDate: '2021-12-22T13:36:04.396+01:00',
  number: 19,
  status: 'FINISHED',
  simulationType: 'SINGLE',
  simulationResult: 'OK',
  isFavorite: false,
  notes: '',
  satellitePicture: {
    thumbnail: '?resolution=THUMBNAIL',
    low: '?resolution=LOW',
    high: '?resolution=LOW',
  },
  outputs: {
    ratedPower: 3500000,
    peakPower: 4200000,
    ratioDcAc: 1.2,
    specificProduction: 1540.7665303942347,
    plantPR: 0.8207270664463501,
    gcr: 0.2688524590163934,
    capexResult: {
      totalPrice: 0,
      specificPrice: 0,
      npv: 0,
      irr: 0,
      roi: 0,
      payback: 0,
      discountedPayback: 0,
      currency: 'USD',
    },
    lcoeResult: {
      lcoe: 0,
    },
  },
  energy: {
    energyYield: 6471219427.655786,
  },
  meteoData: {
    summary: {
      source: 'PVGIS',
      ghi: 1430378.45,
      bhi: 803510.65,
      dhi: 626867.8,
      tempAvg: 13.181391552511416,
      tempMin: -4.64,
      tempMax: 36.32,
    },
  },
  inputs: {
    module: {
      manufacturer: 'Test Energy',
      model: 'Test AC-250M/156-60S',
      type: 'MONOFACIAL',
      voltage: 1000,
      power: 250,
    },
    inverter1: {
      manufacturer: 'Test Energy Inc.',
      model: 'Test 500',
      type: 'CENTRAL',
      power: 500000,
    },
    inverter2: null,
    structure: {
      manufacturer: 'Test Technologies',
      model: 'Test HZ v2.5',
      positionLetter: 'V',
      positionNumber: '1',
    },
    tiltAngle: 0,
    pitchDistance: 6.100000000000001,
    lvConfig: 'CONFIG_SBOX_L1',
    electricalConfig: {
      modulesPerString: 14,
    },
  },
  otherInputs: {
    minGroundClearance: 0.5,
  },
};

export const DESIGN_TEST_LIST = [
  {
    id: '12',
    name: 'Prueba con Capex 5',
    docsSaved: false,
    createdBy: {
      id: '34',
      name: 'MyName ',
      lastname: 'MySurname',
    },
    publishedDate: '2021-12-27T14:59:17.512+01:00',
    number: 49,
    status: 'FINISHED',
    simulationType: 'SINGLE',
    simulationResult: 'OK ',
    isFavorite: false,
    notes: 'Test notas',
    satellitePicture: {
      thumbnail: '?resolution=THUMBNAIL',
      low: '?resolution=LOW',
    },
    outputs: {
      ratedPower: 4000000,
      peakPower: 4802000,
      ratioDcAc: 1.2005,
      specificProduction: 1528.1680787820947,
      plantPR: 0.8183993096984087,
      gcr: 0.2981818181818181,
      capexResult: {
        totalPrice: 24372,
        specificPrice: 5.08,
        currency: 'USD',
      },
      lcoeResult: {
        lcoe: 0.13812564392114948,
      },
    },
    energy: designEnergyMock,
    meteoData: designMeteoData,
    inputs: designInputsMock,
    site: designSiteMock,
    resultGlobal: {
      civil: {
        areaInstalled: 151230.70940654556,
      },
    },
    otherInputs: {
      minGroundClearance: 0.5,
    },
  },
  {
    id: '111',
    name: 'Prueba con Capex 4',
    docsSaved: false,
    createdBy: {
      id: '222',
      name: 'MyName',
      lastname: 'MySurname',
    },
    publishedDate: '2021-12-27T14:58:57.272+01:00',
    number: 48,
    status: 'FINISHED',
    simulationType: 'SINGLE',
    simulationResult: 'OK',
    isFavorite: false,
    notes: 'Test notass',
    satellitePicture: {
      thumbnail: '?resolution=THUMBNAIL ',
      low: '?resolution=LOW ',
    },
    outputs: {
      ratedPower: 4000000,
      peakPower: 4802000,
      ratioDcAc: 1.2005,
      specificProduction: 1528.1680787820947,
      plantPR: 0.8183993096984087,
      gcr: 0.2981818181818181,
      capexResult: {
        totalPrice: 50189,
        specificPrice: 10.45,
        currency: 'USD ',
      },
      lcoeResult: {
        lcoe: 0.32300899794733,
      },
    },
    energy: designEnergyMock,
    meteoData: designMeteoData,
    inputs: designInputsMock,
    site: designSiteMock,
    resultGlobal: {
      civil: {
        areaInstalled: 151230.70940654556,
      },
    },
    otherInputs: {
      minGroundClearance: 0.5,
    },
  },
];

export const iconOptions = [
  {
    Icon: ListSvg,
    value: 'list',
  },
  {
    Icon: GridSvg,
    value: 'grid',
  },
];
