import styled from 'styled-components';
import { IconButton } from '@ratedpower/components';

const CarouselIconButton = styled(IconButton)`
  position: absolute;
  top: calc(50% - 8px);
  color: ${({ theme }) => theme.gray_dark};
`;

export const PrevCarouselIconButton = styled(CarouselIconButton)`
  left: 8px;
`;

export const NextCarouselIconButton = styled(CarouselIconButton)`
  right: 8px;
`;
