import { useEffect } from 'react';

export const useOutsideClick = (ref: React.RefObject<any> | React.RefObject<any>[], callback: any) => {
  const handleClick = (e: MouseEvent) => {
    if (!Array.isArray(ref)) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    } else {
      if (!ref.some((element) => element.current && element.current.contains(e.target))) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });
};
