import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrencies } from 'redux/currencies-slide';
import { GET_AVAILABLE_CURRENCY } from 'services/capex-gql';
import { IOption } from 'types/common';

export function useCapexCurrencies() {
  const dispatch = useDispatch();

  const { data } = useQuery(GET_AVAILABLE_CURRENCY);
  useEffect(() => {
    if (data && data.availableCurrencies) {
      const newCurrencyOptions: IOption[] = data.availableCurrencies.map(
        (item: { currency: string; symbol: string }) => {
          return {
            label: `${item.currency} (${item.symbol})`,
            value: item.currency,
          };
        }
      );

      const currenciesObj: { [key: string]: string } = {};

      data.availableCurrencies.forEach((elem: { currency: string; symbol: string }) => {
        currenciesObj[elem.currency] = elem.symbol;
      });

      dispatch(setCurrencies({ currencyOptions: newCurrencyOptions, currencies: currenciesObj }));
    }
  }, [data]);
}
