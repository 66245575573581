import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export const useKeyboardShortcut = (
  keys: any,
  callback: any,
  node?: any,
  eventKeys?: { ctrlKey?: boolean; shiftKey?: boolean }
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event) => {
      // only execute once per key press
      if (event.repeat) return;
      // check if one of the key is part of the ones we want
      if (
        keys.some(
          (key: any) =>
            event.key.toLowerCase() === key &&
            (event.ctrlKey === !!eventKeys?.ctrlKey || event.metaKey === !!eventKeys?.ctrlKey) &&
            event.shiftKey === !!eventKeys?.shiftKey
        )
      ) {
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};
