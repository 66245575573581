import { useTheme } from 'styled-components';

export const useChartColors = () => {
  const theme = useTheme();

  const chartColors = [
    theme.v2.accent[500],
    theme.v2.yellow[500],
    theme.v2.green[500],
    theme.v2.blue[500],
    theme.v2.pink[500],
    theme.v2.brown[500],
    theme.v2.orange[500],
    theme.v2.teal[500],
    theme.v2.red[500],
    theme.v2.brand[500],

    theme.v2.accent[800],
    theme.v2.yellow[800],
    theme.v2.green[800],
    theme.v2.blue[800],
    theme.v2.pink[800],
    theme.v2.brown[800],
    theme.v2.orange[800],
    theme.v2.teal[800],
    theme.v2.red[800],
    theme.v2.brand[800],

    theme.v2.accent[200],
    theme.v2.yellow[200],
    theme.v2.green[200],
    theme.v2.blue[200],
    theme.v2.pink[200],
    theme.v2.brown[200],
    theme.v2.orange[200],
    theme.v2.teal[200],
    theme.v2.red[200],
    theme.v2.brand[200],
  ];

  const mapChartColors = [200, 400, 600, 700, 800].map((shade) => theme.v2.neutral[shade]) as string[];

  return { chartColors, mapChartColors };
};
