import React, { useState } from 'react';
import * as S from './../plan-modal.styled';
import useForm, { IForm } from 'utils/hooks/use-form';
import {
  IModalProps,
  Modal,
  Checkbox,
  InputNumber,
  Dropdown,
  InputTextArea,
  LabelWrapper,
  Typography,
  BaseTemplateInput,
} from '@ratedpower/components';
import { IOption } from 'types/common';
import { ISubscriptionPlanDTO, Plan, ISubscriptionPlanExtensionDTO } from 'types/subscription-plan';
import { RootState } from 'redux/store';
import { plansOptions, customPlanTypeOptions } from 'utils/constants/support';
import { getOptionForValue } from 'utils/selector';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getShortDate } from 'utils/date';

export interface IEditPlanModal extends IModalProps {
  isCurrentPlan: boolean;
  plan: ISubscriptionPlanDTO;
  handleEditPlan: (inputs: IForm, planId: string, extensionId?: string) => void;
  handleRenewPlan: (inputs: IForm) => void;
  isRenew: boolean;
  extension?: ISubscriptionPlanExtensionDTO;
}

const EditPlanModal: React.FC<IEditPlanModal> = (props) => {
  const { isCurrentPlan, plan, handleEditPlan, handleRenewPlan, isRenew, extension } = props;
  const { t } = useTranslation();

  const { formError, setFormError, inputs, handleInputChangeManual } = useForm(null, {
    plan: plan.plan,
    currency: plan.currency,
    price: extension ? extension.price : plan.price,
    projectsPurchased: extension ? extension.projectsPurchased : plan.projectsPurchased,
    projectsDone: extension ? extension.projectsDone : plan.projectsDone,
    usersCreated: plan.usersCreated,
    startDate: extension ? extension.publishedDate : plan.startDate,
    expirationDate: extension ? extension.expirationDate : plan.expirationDate,
    notes: plan.notes,
    customPlanType: plan.customPlanType,
    sendWelcomeEmail: false,
  });

  const currencies = useSelector((state: RootState) => state.currencies.currencyOptions);
  const [planSelected, setPlanSelected] = useState<Plan>(plan.plan);

  function onSubmit() {
    if (
      (inputs.price !== 0 && !inputs.price) ||
      (inputs.projectsPurchased !== 0 && !inputs.projectsPurchased) ||
      !inputs.currency ||
      !inputs.startDate ||
      !inputs.expirationDate ||
      (!extension && new Date(inputs.startDate) > new Date(inputs.expirationDate)) ||
      (planSelected === Plan.CUSTOM && !inputs.customPlanType)
    ) {
      setFormError(true);
      return;
    }
    inputs.plan = planSelected;

    isRenew ? handleRenewPlan(inputs) : handleEditPlan(inputs, plan.id, extension?.id);
  }

  function getOptionLabels(options: IOption[]) {
    return options.map((option) => {
      return {
        value: option.value,
        label: t(`support:${option.label}`),
      };
    });
  }

  const selectedPlanOption = getOptionForValue(planSelected, getOptionLabels(plansOptions));
  const selectedCustomPlanTypeOption = getOptionForValue(inputs.customPlanType, getOptionLabels(customPlanTypeOptions));
  const selectedCurrencyOption = getOptionForValue(inputs.currency, currencies);

  return (
    <Modal
      {...props}
      title={t(`company:${isRenew ? 'renew' : 'edit'}-${isCurrentPlan ? 'current-' : ''}plan`)}
      disableClickOutside
      buttons={{
        secondary: {
          text: t('action:cancel'),
          onClick: props.onClose,
        },
        primary: {
          text: t('action:save'),
          onClick: onSubmit,
        },
      }}
    >
      <Typography weight={700}>{t('company:plan-details')}</Typography>
      <S.ModalPlanForm>
        <LabelWrapper title={t('company:plan')} htmlFor="plan">
          {!!extension ? (
            <Typography>{t(`support:${getOptionForValue(plan.plan, plansOptions)?.label}`)}</Typography>
          ) : (
            <Dropdown
              options={getOptionLabels(plansOptions)}
              onChange={(e: IOption[]) => setPlanSelected(e[0].value as Plan)}
              name="plan"
              error={formError && !inputs.plan}
              value={selectedPlanOption ? [selectedPlanOption] : undefined}
            />
          )}
        </LabelWrapper>
        {planSelected === Plan.CUSTOM && (
          <LabelWrapper title={t('type')} htmlFor="customPlanType">
            {!!extension ? (
              <Typography>
                {getOptionForValue(inputs.customPlanType, getOptionLabels(customPlanTypeOptions))?.label}
              </Typography>
            ) : (
              <Dropdown
                error={formError && !inputs.customPlanType}
                options={getOptionLabels(customPlanTypeOptions)}
                value={selectedCustomPlanTypeOption ? [selectedCustomPlanTypeOption] : undefined}
                name="customPlanType"
                onChange={(newType) => handleInputChangeManual('customPlanType', newType[0].value)}
              />
            )}
          </LabelWrapper>
        )}
        <LabelWrapper title={t('capex:currency')} htmlFor="currency">
          {!!extension ? (
            <Typography>{selectedCurrencyOption?.label}</Typography>
          ) : (
            <Dropdown
              name="currency"
              options={currencies}
              error={formError && !inputs.currency}
              value={selectedCurrencyOption ? [selectedCurrencyOption] : undefined}
              onChange={(newCurrency) => handleInputChangeManual('currency', newCurrency[0].value)}
            />
          )}
        </LabelWrapper>
        <LabelWrapper title={t('price')} htmlFor="price">
          <InputNumber
            warning={formError && inputs.price !== 0 && !inputs.price}
            name="price"
            min={0}
            value={inputs.price}
            helperText={formError && inputs.price !== 0 && !inputs.price && t('errors:field-empty')}
            onChange={(newPrice) => handleInputChangeManual('price', newPrice)}
          />
        </LabelWrapper>
        {planSelected === Plan.CUSTOM && (
          <LabelWrapper title={t('notes')} htmlFor="notes">
            {!!extension ? (
              <Typography>{inputs.notes}</Typography>
            ) : (
              <InputTextArea
                placeholder={t('notes')}
                name="notes"
                defaultValue={inputs.notes}
                onChange={(e) => handleInputChangeManual('notes', e.target.value)}
                size="m"
              />
            )}
          </LabelWrapper>
        )}
        <LabelWrapper title={t('company:projects-purchased')} htmlFor="projectsPurchased">
          {planSelected === Plan.ENTERPRISE ? (
            <Typography>{t('company:unlimited')}</Typography>
          ) : (
            <InputNumber
              warning={formError && inputs.projectsPurchased !== 0 && !inputs.projectsPurchased}
              name="projectsPurchased"
              min={0}
              value={inputs.projectsPurchased}
              helperText={
                formError && inputs.projectsPurchased !== 0 && !inputs.projectsPurchased && t('errors:field-empty')
              }
              onChange={(newProjects) => handleInputChangeManual('projectsPurchased', newProjects)}
            />
          )}
        </LabelWrapper>
        {!isRenew && (
          <>
            <LabelWrapper title={t('company:used-projects')}>
              <Typography>{inputs.projectsDone}</Typography>
            </LabelWrapper>
            <LabelWrapper title={t('company:number-of-active-users')}>
              <Typography>{inputs.usersCreated}</Typography>
            </LabelWrapper>
          </>
        )}
        <LabelWrapper title={t('start-date')} htmlFor="startDate">
          {!!extension ? (
            <Typography>{getShortDate(inputs.startDate)}</Typography>
          ) : (
            <BaseTemplateInput
              warning={formError && new Date(inputs.expirationDate) < new Date(inputs.startDate)}
              type="date"
              name="startDate"
              value={inputs.startDate}
              max="2999-12-31"
              onChange={(newStartDate) => handleInputChangeManual('startDate', newStartDate)}
            />
          )}
        </LabelWrapper>
        <LabelWrapper title={t('expiration-date')} htmlFor="expirationDate">
          <BaseTemplateInput
            warning={formError && new Date(inputs.expirationDate) < new Date(inputs.startDate)}
            type="date"
            name="expirationDate"
            value={inputs.expirationDate}
            max={!!extension ? plan.expirationDate : '2999-12-31'}
            onChange={(newExpirationDate) => handleInputChangeManual('expirationDate', newExpirationDate)}
            helperText={
              formError &&
              new Date(inputs.expirationDate) < new Date(inputs.startDate) &&
              t('errors:start-date-past-expiration')
            }
          />
        </LabelWrapper>
        {!extension?.id && (
          <LabelWrapper title={t('support:send-welcome-mail')} htmlFor="sendWelcomeEmail">
            <Checkbox
              id="sendWelcomeEmail"
              name="sendWelcomeEmail"
              checked={inputs.sendWelcomeEmail}
              onChange={(e) => {
                handleInputChangeManual('sendWelcomeEmail', e.target.checked);
              }}
            />
          </LabelWrapper>
        )}
      </S.ModalPlanForm>
    </Modal>
  );
};

export { EditPlanModal };
