import styled from 'styled-components';

export const CurrentPlanContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
`;

export const CurrentPlanLoading = styled.div`
  display: flex;
  row-gap: 16px;
  width: 100%;
  flex-direction: column;
`;

export const PlanRowWrapper = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  flex-direction: row;
`;
