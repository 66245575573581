import { Plan } from 'types/subscription-plan';
import { getDateYYYYMMDD, addDays, addYears } from 'utils/date';

interface IUseChangePlanOrDates {
  handleSetStartDate: (date: string) => void;
  handleSetExpirationDate: (date: string) => void;
  plan: Plan;
}

export const useChangePlanOrDates = ({ plan, handleSetStartDate, handleSetExpirationDate }: IUseChangePlanOrDates) => {
  const autoChangeExpirationDate = (newStartDate: string, newPlanSelected: Plan) => {
    if (newPlanSelected !== Plan.DEMO) {
      handleSetExpirationDate(getDateYYYYMMDD(addYears(new Date(newStartDate), 1)));
      return;
    }
    handleSetExpirationDate(getDateYYYYMMDD(addDays(new Date(newStartDate), 14)));
  };

  const onChangeStartDate = (value: string) => {
    handleSetStartDate(getDateYYYYMMDD(new Date(value)));
    autoChangeExpirationDate(value, plan);
  };

  const onChangeExpirationDate = (value: string) => {
    handleSetExpirationDate(getDateYYYYMMDD(new Date(value)));
  };

  return { onChangeExpirationDate, onChangeStartDate, autoChangeExpirationDate };
};
