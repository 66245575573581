import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileFooterContainer } from './profile.styled';
import { Button } from '@ratedpower/components';

interface IProps {
  onDiscard: () => void;
  handleSaveProfile: () => void;
}

const ProfileFooter: React.FC<IProps> = ({ onDiscard, handleSaveProfile }) => {
  const { t } = useTranslation('action');

  return (
    <ProfileFooterContainer>
      <Button variant="ghost" onClick={onDiscard} text={t('cancel')} fullWidth />
      <Button variant="accent" onClick={handleSaveProfile} text={t('save-changes')} fullWidth />
    </ProfileFooterContainer>
  );
};

export default ProfileFooter;
