import { IRadioOption } from '@ratedpower/components';
import { getOnCurrentTimezoneDate, toMidnight } from 'utils/date';

export const companyFilters: { label: string; value: boolean }[] = [
  {
    label: 'filters.all-companies-rated-power-exc',
    value: false,
  },
  {
    label: 'filters.all-companies-rated-power-inc',
    value: true,
  },
];

export const dateFilters: IRadioOption[] = [
  {
    label: 'filters.today',
    value: getOnCurrentTimezoneDate(toMidnight(new Date())),
  },
  {
    label: 'filters.last-week',
    value: getOnCurrentTimezoneDate(toMidnight(new Date(new Date().setDate(new Date().getDate() - 7)))),
  },
  {
    label: 'filters.last-month',
    value: getOnCurrentTimezoneDate(toMidnight(new Date(new Date().setMonth(new Date().getMonth() - 1)))),
  },
  {
    label: 'filters.last-year',
    value: getOnCurrentTimezoneDate(toMidnight(new Date(new Date().setFullYear(new Date().getFullYear() - 1)))),
  },
];

export const NULL_FILTER_VALUE = 'NULL_FILTER_VALUE';
