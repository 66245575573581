import React from 'react';
import * as S from './teams-sidebar.styled';
import { useListTeams } from '../../hooks/use-list-teams';
import { Typography } from '@ratedpower/components';
import TeamSmallCard from '../../components/team-small-card';
import { SIDEBAR_VIEW_PAGE_SIZE } from 'utils/teams';
import { ITeam } from 'types/teams';
import { useTeamActions } from '../../hooks/use-team-actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useTeamsPermissions } from 'pages/teams/hooks/use-teams-permissions';

const TeamsSidebar: React.FC = () => {
  const { teamId, contentType } = useParams<{ teamId: string; contentType: string }>();
  const navigate = useNavigate();

  const { teams, paginationComponent } = useListTeams({
    infiniteScroll: true,
    pageSize: SIDEBAR_VIEW_PAGE_SIZE,
  });

  const { canCreateAndDeleteTeams, teamsAllowedDropdownActions } = useTeamsPermissions();

  const goToTeamView = (newTeamId: string) => () => {
    navigate(`../${newTeamId}/${contentType}`, {
      state: {
        teamName: teams.find((team: ITeam) => team.id === newTeamId)?.name,
      },
    });
  };

  const { handleTeamAction } = useTeamActions();

  return (
    <S.Container>
      <S.CardsContainer $fullHeight={!canCreateAndDeleteTeams}>
        {teams?.map((team: ITeam) => (
          <TeamSmallCard
            key={team.name}
            cover={team.coverColor || team.coverImage?.highResUrl}
            title={team.name}
            onClick={goToTeamView(team.id)}
            onClickOption={handleTeamAction(team.id, team.name)}
            popupIconOptions={teamsAllowedDropdownActions()}
            selected={teamId === team.id}
          >
            <Typography>{team.numProjects} projects</Typography>
          </TeamSmallCard>
        ))}
        {paginationComponent}
      </S.CardsContainer>
    </S.Container>
  );
};

export default TeamsSidebar;
