import { throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { MOBILE, TABLET, DEVICES } from '../constants/screen-size';

interface ISize {
  width?: number;
  height?: number;
}

function getSize(): ISize {
  const isClient = typeof window === 'object';

  const size: ISize = {
    width: isClient ? window.innerHeight : undefined,
    height: isClient ? window.innerWidth : undefined,
  };

  return size;
}

function getDevice(size: ISize) {
  if (size.width === undefined) {
    return undefined;
  }
  if (size.width <= MOBILE) {
    return DEVICES.MOBILE;
  }
  if (size.width <= TABLET) {
    return DEVICES.TABLET;
  }
  if (size.width > TABLET) {
    return DEVICES.DESKTOP;
  }
}

export const isSmallDevice = (device?: string) => device === DEVICES.MOBILE;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize());
  const [device, setDevice] = useState(getDevice(getSize()));

  function handleResize() {
    const size: ISize = getSize();
    setWindowSize(size);
    setDevice(getDevice(size));
  }

  const delayedHandle = useCallback(throttle(handleResize, 500), []);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) {
      return;
    }

    window.addEventListener('resize', delayedHandle);
    return () => {
      window.removeEventListener('resize', delayedHandle);
    };
  }, []);

  return {
    windowSize,
    device,
    isSmallDevice,
  };
};
