import { useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS, UPDATE_NOTIFICATION } from 'services/notifications-gql';
import { notificationFormToNotificationDTO } from 'pages/communication/utils/notifications.utils';
import { INewNotificationForm } from 'pages/communication/utils/types/create-notification-form.types';

interface IProps {
  formData: INewNotificationForm;
  onCompleted: () => void;
  onError: () => void;
  id: string;
}

const useEditNotification = ({ formData, onCompleted, onError, id }: IProps) => {
  const [editNotificationMutation, { error, data }] = useMutation(UPDATE_NOTIFICATION, {
    variables: { updateNotificationDTO: formData },
    refetchQueries: [GET_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS],
    onCompleted,
    onError,
  });

  const editNotification = (createNotificationDTO: INewNotificationForm) => {
    const adaptedFormData = {
      ...notificationFormToNotificationDTO(createNotificationDTO),
      id,
    };

    return editNotificationMutation({ variables: { updateNotificationDTO: adaptedFormData } });
  };

  return {
    error,
    data,
    editNotification,
  };
};

export default useEditNotification;
