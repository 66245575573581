import { useState } from 'react';

export interface ISearchProps {
  value: string;
  onChange: (search: string) => void;
}

export interface IUseSearch {
  getSearchFilter: () => string | string[];
  inputSearchProps: ISearchProps;
}

export const useSearch = (): IUseSearch => {
  const [search, setSearch] = useState('');

  function getSearchFilter() {
    if (search.match(/(["'])(?:(?=(\\?))\2.)*?\1/gis)) return search.replaceAll('"', '');
    return [...new Set(search.split(' '))].filter((e) => e !== '');
  }

  return {
    getSearchFilter,
    inputSearchProps: {
      value: search,
      onChange: setSearch,
    },
  };
};
