import React, { useState } from 'react';
import { ModalDeleteContainer } from './modal-delete.styled';
import { useTranslation } from 'react-i18next';
import { IModalProps, Modal, Checkbox, Typography } from '@ratedpower/components';

interface IProps extends IModalProps {
  id?: string;
  description?: string;
  onDontShowAgain?: (id: string) => void;
}

const ModalDelete: React.FC<IProps> = (props) => {
  const { description, onDontShowAgain } = props;
  const { t } = useTranslation();
  const [checkboxToggled, setCheckboxToggled] = useState(false);

  const handleToggle = () => {
    setCheckboxToggled(!checkboxToggled);
  };

  return (
    <Modal {...props}>
      <ModalDeleteContainer>
        <div className="body" data-testid="modal-delete">
          <Typography>{description}</Typography>
          {onDontShowAgain && (
            <Checkbox
              id="dont-show-again"
              label={t('action:dont-show-again')}
              onChange={handleToggle}
              checked={checkboxToggled}
            />
          )}
        </div>
      </ModalDeleteContainer>
    </Modal>
  );
};

export default ModalDelete;
