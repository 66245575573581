import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  row-gap: 16px;
`;

export const TableContainer = styled.div`
  min-height: 200px;
  width: 100%;
`;

export const Pagination = styled.div`
  align-self: center;
`;

export const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
