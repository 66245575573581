import { combineReducers } from '@reduxjs/toolkit';
import currenciesSlide from './currencies-slide';
import currentUserSlice from './current-user-slice';
import helpButtonSlice from './help-button-slice';
import sidebarSlice from './sidebar-slice';
import themeSlice from './theme-slice';
import versionSlice from './version-slice';
import serverErrorSlice from 'redux/server-error-slice';

const rootReducer = combineReducers({
  currentUser: currentUserSlice,
  currencies: currenciesSlide,
  sidebar: sidebarSlice,
  theme: themeSlice,
  helpButton: helpButtonSlice,
  version: versionSlice,
  serverError: serverErrorSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
