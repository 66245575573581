import styled from 'styled-components';

export const HomeContainer = styled.div`
  padding: ${({ theme }) => `${theme.v2.spacing.xl} ${theme.v2.spacing.l}`};
  gap: ${({ theme }) => theme.v2.spacing.xl};
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const HomeTitleContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;
