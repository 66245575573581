import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from 'pages/communication/create-notification/create-notification-form/create-notification-form.styled';
import { Checkbox, InputSelect, InputText, InputTextArea, LabelWrapper } from '@ratedpower/components';
import { IOption } from 'types/common';
import CreateNotificationIconOptions from 'pages/communication/create-notification/create-notification-form/create-notification-icon-options';
import { TFunction } from 'i18next';
import ListItemsNotification from 'pages/communication/create-notification/list-items-notification/list-items-notification';
import { IListItemNotificationRecord } from 'pages/communication/create-notification/list-items-notification/types/list-item-notification';
import LinkItemsNotification from 'pages/communication/create-notification/link-items-notification/link-items-notification';
import { ILinkItemNotificationRecord } from 'pages/communication/create-notification/link-items-notification/types/link-items-notification';
import {
  INewNotificationForm,
  INewNotificationFormValueTypes,
} from 'pages/communication/utils/types/create-notification-form.types';

interface IProps {
  formData: INewNotificationForm;
  handleChange: (key: string, value: INewNotificationFormValueTypes) => void;
  updateMode?: boolean;
}

const getTranslatedOptions = (t: TFunction): IOption[] => {
  return CreateNotificationIconOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }));
};

const CreateNotificationForm: React.FC<IProps> = ({ formData, handleChange, updateMode = false }) => {
  const { t } = useTranslation(),
    translatedOptions = getTranslatedOptions(t),
    selectedIcon = translatedOptions.find((option) => option.value === formData.iconName.value);

  return (
    <S.ModalWrapper>
      <S.ModalForm>
        <LabelWrapper title={t('communication:create-new-notification-title')} htmlFor={formData.title.key} size="l">
          <InputText
            size="l"
            name={formData.title.key}
            value={formData.title.value}
            onChange={(value: string) => handleChange(formData.title.key, value)}
            warning={formData.title.error}
            maxLength={formData.title.maxLength}
          />
        </LabelWrapper>
        <LabelWrapper title={t('communication:create-new-notification-icon')} htmlFor={formData.iconName.key} size="l">
          <InputSelect
            name={formData.iconName.key}
            error={formData.iconName.error}
            {...(selectedIcon && { value: [selectedIcon] })}
            options={translatedOptions}
            onChange={(option: IOption[]) => handleChange(formData.iconName.key, option[0].value)}
            placeholder={t('action:search-placeholder')}
            isSearchable
            size="l"
          />
        </LabelWrapper>
        <LabelWrapper
          title={t('communication:create-new-notification-description')}
          htmlFor={formData.description.key}
          size="l"
        >
          <InputTextArea
            name={formData.description.key}
            minRows={5}
            maxRows={5}
            maxLength={formData.description.maxLength}
            value={formData.description.value}
            error={formData.description.error}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleChange(formData.description.key, e.target.value)
            }
            size="l"
          />
        </LabelWrapper>
      </S.ModalForm>
      <ListItemsNotification
        notificationSubsections={formData.notificationSubsections}
        handleChange={(newValue: IListItemNotificationRecord) =>
          handleChange(formData.notificationSubsections.key, newValue)
        }
      />
      <LinkItemsNotification
        knowledgeBaseArticles={formData.knowledgeBaseArticles}
        handleChange={(newValue: ILinkItemNotificationRecord) =>
          handleChange(formData.knowledgeBaseArticles.key, newValue)
        }
      />
      {updateMode && (
        <Checkbox
          label={t('communication:notify-all-users')}
          id={formData.resendUserNotifications.key}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(formData.resendUserNotifications.key, event.target.checked)
          }
          checked={formData.resendUserNotifications.value}
        />
      )}
    </S.ModalWrapper>
  );
};

export default CreateNotificationForm;
