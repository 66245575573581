import styled from 'styled-components';

export const LinkItemsNotificationWrapper = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  display: grid;
`;

export const ListItemsTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: end;

  button {
    margin-bottom: ${({ theme }) => theme.v2.spacing.xxs};
  }
`;

export const ButtonWrapper = styled.div`
  justify-content: flex-start;
`;
