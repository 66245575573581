export const locales = {
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  pt: 'pt-PT',
  de: 'de-DE',
  zh: 'zh-CN',
  jp: 'ja-JP',
} as const;

interface FixedAmountOfLanguages<T, L extends number> extends Array<T> {
  0: T;
  length: L;
}

/*
  IMPORTANT: if number of languages increase/decrase this type has to change the number of items
  Currently we have 8 supported languages
*/
type LabelsPerLanguage = FixedAmountOfLanguages<{ label: string; value: SupportedLanguagesType }, 8>;

export type SupportedLanguagesType = keyof typeof locales;

export const supportedLanguages = Object.keys(locales) as SupportedLanguagesType[];

export function createLanguageOptions(labelStrings: LabelsPerLanguage) {
  return labelStrings.map((element) => ({
    label: element.label,
    value: element.value,
  }));
}
