import styled, { css } from 'styled-components';
interface IListItem {
  $sidebarCollapsed: boolean;
  $active?: boolean;
}

const activeOrHoverStyles = css<IListItem>`
  color: ${({ theme }) => theme.v2.text.accent.default};
  svg {
    color: ${({ theme }) => theme.v2.icon.accent.default};
  }
`;

export const ListItem = styled.li<IListItem>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.v2.spacing.xs};
  gap: ${({ theme }) => theme.v2.spacing.xs};
  color: ${({ theme }) => theme.v2.text.secondary.default};
  border-radius: ${({ theme }) => theme.v2.radius.s};
  svg {
    color: ${({ theme }) => theme.v2.icon.primary.default};
  }

  ${({ $active }) => $active && activeOrHoverStyles};
  ${({ $active }) =>
    $active &&
    css`
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.v2.stroke.accent.default};
    `};

  &:hover {
    ${activeOrHoverStyles};
  }
`;
