import React from 'react';
import * as S from './alerts-combo-box.styled';
import { Tag } from '@ratedpower/components';

interface IProps {
  alerts: {
    errors: number;
    warnings: number;
    suppAlert: number;
  };
}

const AlertsComboBox: React.FC<IProps> = ({ alerts }) => {
  type AlertKey = 'errors' | 'warnings' | 'suppAlert';

  const getColor = (key: AlertKey) => {
    const keyToColor = {
      errors: 'orange',
      warnings: 'yellow',
      suppAlert: 'teal',
    };
    return keyToColor[key];
  };

  return (
    <S.Container>
      {Object.entries(alerts).map(([key, value]) => {
        return (
          <Tag key={key} color={getColor(key as AlertKey)}>
            {value}
          </Tag>
        );
      })}
    </S.Container>
  );
};

export default AlertsComboBox;
