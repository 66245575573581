import React from 'react';
import { UnsavedChangesModalContainer } from 'components/modals/unsaved-changes-modal/unsaved-changes-modal.styled';
import { useTranslation } from 'react-i18next';
import { IModalProps, Modal, Typography } from '@ratedpower/components';
import { useUnsavedChangesModal } from 'components/modals/unsaved-changes-modal/use-unsaved-changes-modal';

interface IProps extends Omit<IModalProps, 'onClose' | 'isActive'> {
  hasUnsavedChanges: boolean;
}

const UnsavedChangesModal: React.FC<IProps> = (props) => {
  const { hasUnsavedChanges, ...rest } = props;
  const { isOpen, onCancel, onProceed } = useUnsavedChangesModal(hasUnsavedChanges);
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onCancel}
      onClickSecondaryBtn={onProceed}
      onClickPrimaryBtn={onCancel}
      isActive={isOpen}
      secondaryBtnText={t('action:yes-leave-anyway')}
      primaryBtnText={t('action:no-return')}
      title={t('common:unsaved-changes-modal.title')}
      status="warn"
      {...rest}
    >
      <UnsavedChangesModalContainer>
        <div className="body">
          <Typography>{t('common:unsaved-changes-modal.description')}</Typography>
        </div>
      </UnsavedChangesModalContainer>
    </Modal>
  );
};

export default UnsavedChangesModal;
