import React from 'react';
import { IBaseTemplateInput, BaseTemplateInput } from '@ratedpower/components';
import { SearchSvg } from 'assets/icons';
import { useTranslation } from 'react-i18next';

export interface IExtraProps {
  value?: string;
  defaultValue?: string;
}

type IProps = IBaseTemplateInput & IExtraProps;

const InputSearch: React.FC<IProps> = ({ size = 'm', ...props }) => {
  const { t } = useTranslation();
  return (
    <BaseTemplateInput
      {...props}
      placeholder={props.placeholder ?? t('action:search')}
      type="text"
      Icon={SearchSvg}
      clearBtn
      size={size}
    />
  );
};

export default InputSearch;
