import React from 'react';
import { DesignGridItemDetailsContainer } from './design-grid-item-loading-details.styled';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '@ratedpower/components';

interface IProps {
  showDetails?: boolean;
}

const EMPTY_FIELD = '-';

const DesignGridItemLoadingDetails: React.FC<IProps> = ({ showDetails }) => {
  const getSectionRow = (emptyCols?: string[]) => (
    <div className="section-row">
      <p className="body-col body-col-2">{!emptyCols?.includes('1') && <Skeleton count={1} />}</p>
      <p className="body-col body-col-1">{!emptyCols?.includes('2') && <Skeleton count={1} />}</p>
      <p className="body-col body-col-1">{!emptyCols?.includes('3') && <Skeleton count={1} />}</p>
    </div>
  );

  return (
    <>
      <DesignGridItemDetailsContainer $noBorder={!showDetails}>
        <Typography nowrap className="section-header" size="s">
          {EMPTY_FIELD}
        </Typography>
        {getSectionRow()}

        {showDetails && (
          <>
            <Typography nowrap className="section-header" size="s">
              {EMPTY_FIELD}
            </Typography>

            {getSectionRow()}

            <Typography nowrap className="section-header" size="s">
              {EMPTY_FIELD}
            </Typography>

            {getSectionRow()}

            <Typography nowrap className="section-header" size="s">
              {EMPTY_FIELD}
            </Typography>

            {getSectionRow(['3'])}

            {getSectionRow(['3'])}

            {getSectionRow()}

            {getSectionRow(['1'])}
          </>
        )}
      </DesignGridItemDetailsContainer>
      {showDetails && (
        <DesignGridItemDetailsContainer $noBorder>
          <Skeleton count={1} />
          <Typography nowrap className="section-header" size="s">
            {EMPTY_FIELD}
          </Typography>
          {getSectionRow(['2'])}
        </DesignGridItemDetailsContainer>
      )}
    </>
  );
};

export default DesignGridItemLoadingDetails;
