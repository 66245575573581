import { IUnits } from 'types/preferences.types';

export const temperatureUnits: IUnits = {
  INTERNATIONAL: {
    M: {
      // API returns & expected unit
      unit: 'ºC',
      toShow: (value) => value,
      toSend: (value) => value,
    },
  },
  IMPERIAL: {
    M: {
      unit: 'ºF',
      toShow: (value) => (value * 9.0) / 5.0 + 32,
      toSend: (value) => ((value - 32) * 5.0) / 9.0,
    },
  },
};
