import React from 'react';
import HomeProjectItem from 'pages/home/home-designs/home-project-item/home-project-item';
import { useQuery } from '@apollo/client';
import {
  GET_LAST_PROJECTS_WITH_FAVOURITES_DESIGNS_BY_USER,
  GET_LAST_PROJECTS_WITH_SAVED_SIMULATIONS,
} from 'services/projects-gql';
import { IProjectListItem } from 'types/projects';
import * as S from 'pages/home/home-designs/home-designs.styled';
import HomeNoDesigns from 'pages/home/home-designs/home-no-designs/home-no-designs';

interface IHomeProjectsList {
  isFavorite: boolean;
}

const HomeProjectsList = ({ isFavorite }: IHomeProjectsList) => {
  const { data, loading } = useQuery(
    isFavorite ? GET_LAST_PROJECTS_WITH_FAVOURITES_DESIGNS_BY_USER : GET_LAST_PROJECTS_WITH_SAVED_SIMULATIONS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const dataKey = isFavorite ? 'lastProjectsWithFavouritesDesignsByUser' : 'lastsProjectsWithSavedSimulations';

  if (!loading && data[dataKey].length === 0) {
    return (
      <S.HomeProjectsListContainer>
        <HomeNoDesigns isFavorite={isFavorite} />
      </S.HomeProjectsListContainer>
    );
  }

  return (
    <S.HomeProjectsListContainer>
      {data &&
        data[dataKey]?.map((project: IProjectListItem) => (
          <HomeProjectItem project={project} isFavorite={isFavorite} key={project.id} />
        ))}
    </S.HomeProjectsListContainer>
  );
};

export default HomeProjectsList;
