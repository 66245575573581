import React, { useState } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNewProjectDone } from 'redux/current-user-slice';
import { CREATE_PROJECT, GET_PROJECT_CREATORS, GET_USER_PROJECTS } from 'services/projects-gql';
import { useToasts } from './use-toasts';
import useCurrentUser from './use-current-user';
import { hasValidCharacters, PROJECT_DESIGN_UNALLOWED_CHARS } from 'utils/validation';
import { ICreateProjectForm } from 'types/projects';
import { GET_TEAMS } from 'services/team-gql';
import { CREATE_PROJECT_BY_SUPPORT, GET_COMPANY_PROJECTS } from 'services/support-gql';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getRudderstackCreateProjectEventLocation } from 'utils/constants/rudderstack';
import rudderstack from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';

export const useCreateProject = (
  registerInRudderstack: boolean,
  isSupport?: boolean,
  onCreateUser?: (response: any) => void,

  defaultTeamId?: string | null
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: companyId } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const { addSuccessToast, addErrorToast } = useToasts();
  const dispatch = useDispatch();
  const { refetchUser } = useCurrentUser();
  const [formError, setFormError] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState(defaultTeamId === 'Public' ? null : defaultTeamId);

  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    refetchQueries: [GET_USER_PROJECTS, GET_TEAMS, GET_PROJECT_CREATORS],
    onCompleted: ({ createProject }) => {
      const location = getRudderstackCreateProjectEventLocation(pathname);
      if (location) rudderstack.track(RudderstackEvent.CREATE_PROJECT, { location });
      refetchUser();
      dispatch(setNewProjectDone());
      if (onCreateUser) onCreateUser(createProject);
      addSuccessToast(t('project:project-succesfully-created'));
    },
    onError: (error: ApolloError) => {
      if (error.message === 'InvalidPlanException') {
        addErrorToast(t('company:subscription-plans.PLAN_EXPIRED'));
      } else {
        addErrorToast(t('errors:GENERIC'));
      }
    },
  });

  const [createProjectBySupport, { loading: loadingSupport }] = useMutation<{
    createProjectBySupport: { id: string };
  }>(CREATE_PROJECT_BY_SUPPORT, {
    refetchQueries: [GET_COMPANY_PROJECTS],
    onError: (createProjectError: ApolloError) => {
      const messages = createProjectError.graphQLErrors.map(
        (gqlError: any) => gqlError?.extensions?.debugInfo?.messageError
      );
      addErrorToast(`${t('support:companies-error')}
      ${
        messages.length > 0 && (
          <>
            <br />
            {messages.join(',')}
          </>
        )
      }`);
    },
    onCompleted: (response: any) => {
      addSuccessToast(t('project:project-succesfully-created'));
      navigate(`/projects/${response.createProjectBySupport.id}`);
    },
  });

  const isFormValid = (name: string) => {
    if (!name || Array.from(name).every((character) => character === ' ')) {
      setFormError('errors:project-name-empty');
      return false;
    }
    if (!hasValidCharacters(PROJECT_DESIGN_UNALLOWED_CHARS)(name)) {
      setFormError('should-not-contain-invalid-symbols');
      return false;
    }
    return true;
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & ICreateProjectForm;
    if (!isFormValid(target.name.value)) return;
    const projectForm: { name: string; teamId?: string | null } = { name: target.name.value, teamId: selectedTeamId };
    if (!isSupport) {
      createProject({ variables: { projectForm } });
    } else {
      createProjectBySupport({ variables: { projectCreateSupportDTO: { ...projectForm, companyId } } });
    }
  };

  return {
    handleSave,
    formError,
    loading: loading || loadingSupport,
    selectedTeamId,
    setSelectedTeamId,
    isFormValid,
  };
};
