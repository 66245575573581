import { IServerFilter } from 'types/filter';

export const PROJECTS_TABS = {
  projects: 'projects',
  favorites: 'favorites',
  archived: 'archived',
};

export const PROJECTS_STATUS = {
  in_progress: 'FINISHED',
  favorite: 'FAVORITE',
  archived: 'ARCHIVED',
  deleted: 'DELETED_FOR_USER',
};

export const PROJECT_SERVER_FILTER_BY_TAB: { [key: string]: IServerFilter[] } = {
  projects: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['FINISHED', 'REVIEWED'],
    },
  ],
  favorites: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['FINISHED', 'REVIEWED'],
    },
  ],
  archived: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['ARCHIVED'],
    },
  ],
};

export const DESIGNS_TABS: { [key: string]: string } = {
  designs: 'designs',
  favorites: 'favorites',
  archived: 'archived',
};

export const DESIGNS_STATUS = {
  in_progress: 'FINISHED',
  favorite: 'FAVORITE',
  archived: 'ARCHIVED',
  deleted: 'DELETED_FOR_USER',
};

export const DESIGN_SERVER_FILTER_BY_TAB: { [key: string]: IServerFilter[] } = {
  designs: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['FINISHED', 'REVIEWED', 'ERROR', 'IN_PROGRESS', 'SAVED_USER', 'SAVED_SUPPORT'],
    },
  ],
  favorites: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['FINISHED', 'REVIEWED', 'ERROR', 'IN_PROGRESS', 'SAVED_USER', 'SAVED_SUPPORT'],
    },
  ],
  archived: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['ARCHIVED'],
    },
  ],
};

export const COMPANY_TABS = {
  users: 'users',
  account: 'account',
  plan: 'plan',
  integrations: 'integrations',
};

export const DESIGN_RESULTS_TABS = {
  overview: 'overview',
  energy: 'energy',
  configuration: 'configuration',
  threed: '3d',
  gridPoint: 'gridPoint',
  costs: 'costs',
};

export const DESIGN_PROCESS_TABS = {
  batteries: 'batteries',
  financial: 'financial',
  electrical: 'electrical',
  energy: 'energy',
  equipment: 'equipment',
  gridPoint: 'grid-point',
  layout: 'layout',
  location: 'location',
  topography: 'topography',
};

export const STATISTICS_TABS = {
  projects: 'projects',
  simulations: 'tabs.simulations',
  users: 'users',
  sites: 'tabs.sites',
  elevations: 'tabs.elevations',
  meteo: 'meteo',
  estimated_power: 'tabs.estimate-power',
  errors: 'tabs.errors',
};
