import React, { useEffect } from 'react';
import Login from 'pages/login';
import { Outlet } from 'react-router-dom';
import { RatedPowerLoader } from '@ratedpower/components';
import useCurrentUser from 'utils/hooks/use-current-user';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useUpdateLastLogin } from 'pages/login/use-update-last-login';

const AuthRequired: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.currentUser);
  const { isAuthenticated, isLoading } = useAuth0();
  const updateLastLogin = useUpdateLastLogin();
  const { error } = useCurrentUser();

  useEffect(() => {
    if (isAuthenticated && !localStorage.lastLoginDate && profile) {
      updateLastLogin();
    }
  }, [isAuthenticated, profile]);

  if ((!isAuthenticated && !isLoading) || error) {
    return <Login apiError={!!error} />;
  }
  if (isAuthenticated && profile && !isLoading) return <Outlet />;

  return <RatedPowerLoader />;
};

export default AuthRequired;
