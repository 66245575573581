import { INewNotificationForm } from 'pages/communication/utils/types/create-notification-form.types';

// The client works with records for optimal UI performance, but the server expects arrays.
const notificationFormToNotificationDTO = (form: INewNotificationForm) => {
  return Object.fromEntries(
    Object.entries(form).map(([key, field]) => {
      return [
        key,
        typeof field.value === 'object' && !Array.isArray(field.value) ? Object.values(field.value) : field.value,
      ];
    })
  );
};

export { notificationFormToNotificationDTO };
