import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { UPDATE_SECRET } from 'services/user-gql';
import { Secret } from 'types/company.types';

export const useIntegration = (integrationSecret: Secret, companyId: string) => {
  const [isEditing, setIsEditing] = useState(false);
  const [config, setConfig] = useState({ ...integrationSecret });
  const secretIsAutomatic = config.mode === 'AUTOMATIC';
  const fakeKey = '************';
  const apiKey = config.status === 'ACTIVATED' ? fakeKey : '';
  const [checkAutoGenerate, setCheckAutoGenerate] = useState(secretIsAutomatic);

  const [updateSecret, { loading }] = useMutation(UPDATE_SECRET, {
    onCompleted: (data) => {
      const { mode, status, secret } = data.updatedSecretIntegration;
      const publicName = integrationSecret.publicName;

      setConfig({
        mode,
        status,
        secret,
        publicName,
      });
    },
  });

  const toggleCheckAutogenerate = () => {
    setCheckAutoGenerate((prevState) => !prevState);
  };

  const handleDiscard = async () => {
    setIsEditing(false);
    if (checkAutoGenerate !== secretIsAutomatic) {
      toggleCheckAutogenerate();
    }
  };

  const handleSave = async (form: { apiKey: string }) => {
    const fakeApiKey = form.apiKey === fakeKey;
    const commonVariables = {
      companyId,
      form: { activated: checkAutoGenerate, secret: config.secret },
    };
    // If the apiKey has not been modified, we don't send the value to save
    const variables = fakeApiKey
      ? commonVariables
      : { companyId, form: { ...commonVariables.form, value: form.apiKey } };
    updateSecret({
      variables,
    });
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  return {
    loading,
    isEditing,
    apiKey,
    handleDiscard,
    handleSave,
    config,
    checkAutoGenerate,
    toggleCheckAutogenerate,
    onEdit,
  };
};
