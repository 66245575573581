import styled from 'styled-components';
import { Typography } from '@ratedpower/components';

export const ZeroCreatedContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  white-space: pre-line;
  padding: 83px 0;

  p + p {
    margin-top: 0;
  }

  svg {
    width: 120px;
    height: 120px;
  }
`;

export const ZeroCreatedSubtitleContainer = styled(Typography)`
  max-width: 400px;
`;
