import React from 'react';
import { useTranslation } from 'react-i18next';
import { IConverters } from 'types/preferences.types';
import { Separator, Typography } from '@ratedpower/components';
import { MapPopupRow } from 'utils/maps/map-popups.styled';

interface IEarthworksWarning {
  area: number;
  cutVolume: number;
  fillVolume: number;
  planePitchSlope: number;
  planeRowSlope: number;
  earthWorksMotives: string[];
}

interface IProps {
  data: IEarthworksWarning;
  converters: IConverters | undefined;
}

const StructureEarthworksInfowindow: React.FC<IProps> = ({ data, converters }) => {
  const { t } = useTranslation('design-process');
  const motives = () => {
    let result = '';
    data.earthWorksMotives.forEach((item) => {
      result = `${result} ${item}, `;
    });
    return result.substring(0, result.length - 1);
  };

  const rows = [
    {
      title: t('layout-tab.area'),
      value: `${converters?.area.toShow(data.area, { decimals: 2, size: 'M' }).value} ${
        converters?.area.toShow(data.area, { decimals: 2, size: 'M' }).unit
      }`,
    },
    {
      title: t('layout-tab.fill-volume'),
      value: `${converters?.volume.toShow(data.fillVolume, { decimals: 2, size: 'M' }).value} ${
        converters?.volume.toShow(data.fillVolume, { decimals: 2, size: 'M' }).unit
      }`,
    },
    {
      title: t('layout-tab.cut-volume'),
      value: `${converters?.volume.toShow(data.cutVolume, { decimals: 2, size: 'M' }).value} ${
        converters?.volume.toShow(data.cutVolume, { decimals: 2, size: 'M' }).unit
      }`,
    },
    { title: t('layout-tab.plane-slope-row'), value: (data.planeRowSlope * 100).toFixed(2) },
    { title: t('layout-tab.plane-slope-pitch'), value: (data.planePitchSlope * 100).toFixed(2) },
    { title: t('layout-tab.motives-applying-earthworks'), value: motives() },
  ];

  return (
    <>
      <MapPopupRow className="infowindow-header">
        <Typography category="title" weight="bold">
          {t('layout-tab.block-underwent-earthworks')}
        </Typography>
      </MapPopupRow>
      <Separator />
      {rows.map(({ title, value }) => (
        <MapPopupRow key={title}>
          <Typography category="display" size="s" weight="bold">
            {title}:
          </Typography>
          <Typography category="display" size="s">
            {value}
          </Typography>
        </MapPopupRow>
      ))}
    </>
  );
};

export default StructureEarthworksInfowindow;
