import React, { useEffect, useState } from 'react';
import * as S from './map-style-selector.styled';
import { useTranslation } from 'react-i18next';
import { ChevronLeftSvg, ChevronRightSvg } from 'assets/icons';
import { Toggle, Tooltip, Typography } from '@ratedpower/components';
import { useOutsideClick } from 'utils/mouse';
import { useTheme } from 'styled-components';
import { SatelliteOption } from './satellite-options';

interface ISelectorProps {
  selected: SatelliteOption;
  options: SatelliteOption[];
  optionsFor3D?: {
    toggle3DView: () => void;
    has3D: boolean | undefined;
    is3DActive: boolean;
    isDisabled: boolean;
  };
  onSelect: (option: SatelliteOption) => void;
}

const MapStyleSelector: React.FC<ISelectorProps> = ({ selected, options, onSelect, optionsFor3D }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showList, setShowList] = useState(false);
  const [optionSelected, setOptionSelected] = useState(selected);
  const listRef: React.RefObject<any> = React.createRef();
  useOutsideClick(listRef, () => {
    setShowList(false);
  });

  function handleSelect(option: SatelliteOption) {
    onSelect(option);
    setShowList(false);
  }

  useEffect(() => {
    if (selected?.value !== optionSelected?.value) {
      setOptionSelected(selected);
    }
  }, [selected]);

  return (
    <S.MapStyleSelectorContainer>
      <Tooltip description={showList ? '' : t('design-process:interactive-site.change-map-view-options')}>
        <S.SelectedContainer
          style={{ background: `url(${optionSelected.image}), lightgray 50% / cover no-repeat` }}
          onClick={() => setShowList(!showList)}
          className={showList ? 'open' : ''}
        >
          <S.SelectedContainerInfo>
            <S.SelectedContainerSvg>{showList ? <ChevronLeftSvg /> : <ChevronRightSvg />}</S.SelectedContainerSvg>
            <S.SelectedContainerText>
              <Typography size="xs" color={theme.v2.bg.primary.default}>
                {!!optionSelected && <> {t(`common:map-styles.${optionSelected.label}`)}</>}
              </Typography>
            </S.SelectedContainerText>
          </S.SelectedContainerInfo>
        </S.SelectedContainer>
      </Tooltip>
      <div className="option-list">
        {showList && (
          <ul ref={listRef}>
            {options.map((option, key) => (
              <S.SatelliteOption data-testid="option-item" key={key} onClick={() => handleSelect(option)}>
                <S.SatelliteImageContainer
                  style={{ background: `url(${option.image}), lightgray 50% / cover no-repeat` }}
                  isSelected={optionSelected && option.value === optionSelected.value}
                />
                <Typography size="xs">{t(`common:map-styles.${option.label}`)}</Typography>
              </S.SatelliteOption>
            ))}
            {optionsFor3D && optionsFor3D.has3D && (
              <S.SwitchBtnContainer>
                <div className="separator" />
                <Toggle
                  isOn={optionsFor3D.is3DActive}
                  label={t('design-process:interactive-site.3d-terrain')}
                  name={'3d-terrain'}
                  onToggle={optionsFor3D.toggle3DView}
                  disabled={optionsFor3D.isDisabled}
                />
              </S.SwitchBtnContainer>
            )}
          </ul>
        )}
      </div>
    </S.MapStyleSelectorContainer>
  );
};

export default MapStyleSelector;
