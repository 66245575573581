import { useQuery } from '@apollo/client';
import { useAuthorization } from 'access-control/can';
import { Permission, permissionsToShow } from 'access-control/permissions';
import { useEffect, useMemo, useState } from 'react';
import { GET_PERMISSION_IDS } from 'services/roles-gql';
import { IPermission, IPermissionToShow, IPermissionType, IRoleListItem, ROLE_TYPE } from 'types/role';
import { useUsersManagementContext } from '../../users-management-data';

export const usePermissionList = (
  onSave: (permissionsIds: IPermission[], id?: string) => void,
  onCancel: (roleId: string) => void,
  roleSelected?: IRoleListItem
) => {
  const {
    useRolesObject: { mapSelectedRoleTypes },
  } = useUsersManagementContext();

  const { data: totalPermissions } = useQuery(GET_PERMISSION_IDS, { fetchPolicy: 'cache-first' });
  const { isAuthorized } = useAuthorization();
  const canEditPermissions = isAuthorized(Permission.ROLE_SUPPORT_WRITE);

  const [checkedPermissions, setCheckedPermissions] = useState<IPermission[]>([]);

  const filteredPermissionsToShow = useMemo(() => {
    if (!totalPermissions || !totalPermissions.permissions.length) return [];

    return permissionsToShow.filter((permission: IPermissionToShow) =>
      permission.permissionType.some(
        (permissionType: IPermissionType) =>
          !!filterPermissionsByType(totalPermissions.permissions)?.find(
            (filteredPermission: IPermission) => filteredPermission.name === permissionType.value
          )
      )
    );
  }, [mapSelectedRoleTypes, totalPermissions, roleSelected]);

  useEffect(() => {
    setCheckedPermissions(filterPermissionsByType(roleSelected?.permissions || []));
  }, [mapSelectedRoleTypes]);

  useEffect(() => {
    setCheckedPermissions(roleSelected?.permissions || []);
  }, [roleSelected?.permissions]);

  function filterPermissionsByType(permissions: IPermission[]) {
    return getCurrentType() === 'INTERNAL'
      ? permissions
      : permissions.filter((permission: IPermission) => permission.type === 'EXTERNAL');
  }

  function getCurrentType(): ROLE_TYPE | undefined {
    if (mapSelectedRoleTypes) {
      if (!roleSelected?.id) {
        return mapSelectedRoleTypes['New'];
      }
      return mapSelectedRoleTypes[roleSelected?.id] ? mapSelectedRoleTypes[roleSelected?.id] : roleSelected?.type;
    }
    return roleSelected?.type;
  }

  const isChecked = (permission: Permission) =>
    !!checkedPermissions?.find((checkedPermission: IPermission) => checkedPermission.name === permission);

  const getPermissionsToCheckOrUncheck = (selected: Permission, isNowChecked: boolean) => {
    const findStringPermission = (tokenPermission: Permission, added: string): Permission => {
      const splitWrite = tokenPermission.split('_');
      splitWrite.pop();
      const readPermissionString = (splitWrite.join('_') + '_' + added) as keyof typeof Permission;
      return Permission[readPermissionString];
    };

    const permissionsToAdd: Permission[] = [selected];
    if (isNowChecked) {
      if (selected.includes('READ')) {
        permissionsToAdd.push(findStringPermission(selected, 'WRITE'), findStringPermission(selected, 'DELETE'));
      }
      if (selected.includes('WRITE')) {
        permissionsToAdd.push(findStringPermission(selected, 'DELETE'));
      }
    } else {
      if (selected.includes('WRITE')) {
        permissionsToAdd.push(findStringPermission(selected, 'READ'));
      }
      if (selected.includes('DELETE')) {
        permissionsToAdd.push(findStringPermission(selected, 'READ'), findStringPermission(selected, 'WRITE'));
      }
    }

    return permissionsToAdd;
  };

  const handleCheckPermission = (selected: Permission) => {
    const permissionsToAdd = getPermissionsToCheckOrUncheck(selected, isChecked(selected));

    if (isChecked(selected)) {
      setCheckedPermissions(
        checkedPermissions?.filter((permission) => !permissionsToAdd.includes(permission.name as Permission))
      );
      return;
    }
    setCheckedPermissions([
      ...checkedPermissions,
      ...(filterPermissionsByType(totalPermissions?.permissions).filter((permission: IPermission) =>
        permissionsToAdd.includes(permission.name as Permission)
      ) || ({} as IPermission)),
    ]);
  };

  const handleSave = () => {
    onSave(
      checkedPermissions.map((permission: IPermission) => ({ id: permission.id, name: permission.name })) || [],
      roleSelected?.id
    );
  };

  const handleCancel = () => {
    setCheckedPermissions(roleSelected?.permissions || []);
    onCancel(roleSelected?.id || '');
  };

  return {
    canEditPermissions,
    filteredPermissionsToShow,
    checkedPermissions,
    isChecked,
    handleCheckPermission,
    handleSave,
    handleCancel,
    setCheckedPermissions,
  };
};
