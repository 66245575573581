import styled from 'styled-components';

export const ProfilePageWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.l};
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
`;

export const AccountSettingSection = styled.div`
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  padding: ${({ theme }) => theme.v2.spacing.l};
  width: max-content;
  display: flex;
`;

export const ProfileSection = styled.div`
  max-width: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.m};
`;

export const ProfileSubSection = styled.div`
  & > * {
    margin-bottom: 16px;
  }
`;

export const ProfileHeaderContainer = styled.div`
  display: flex;
  align-items: center;

  .buttons-group {
    display: flex;
    align-items: center;
  }

  p {
    flex-grow: 1;
  }
`;

export const ProfileFooterContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
`;

export const RadioGroupContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
`;
