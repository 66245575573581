import styled from 'styled-components';

export const ZeroCreatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    width: 60px;
    margin-bottom: 32px;
    color: ${({ theme }) => theme.secondary};
  }

  .info {
    font-size: 2rem;
    line-height: 25px;
    max-width: 60%;
    text-align: center;
  }
`;
