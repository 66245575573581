import React from 'react';
import { objectEquals } from 'utils/objects';
import { DesignsCustomTooltipContainer } from './designs-tooltip.styled';

interface IProps {
  currentTooltipShown: {
    coordinates: { x: number; y: number };
    payload: any;
  } | null;
  [key: string]: any;
  coordinate?: any;
  payload?: any;
  formatter?: any;
  separator?: any;
}

const DesignsCustomTooltip: React.FC<IProps> = ({ currentTooltipShown, ...props }) => {
  if (!props || !currentTooltipShown) return null;

  const isActive = objectEquals(props.coordinate, currentTooltipShown.coordinates);
  const payloadUsed =
    props.payload && props.payload.length
      ? props.payload[0].payload
      : currentTooltipShown && currentTooltipShown.payload
      ? currentTooltipShown.payload
      : null;
  if (!payloadUsed || !isActive) return null;

  const { name: designNames } = payloadUsed;
  const formattedData: any[] = props.payload.map(({ value, name, ...payload }: any) =>
    props.formatter(value, name, payload)
  );

  return (
    <DesignsCustomTooltipContainer className="designs-custom-tooltip">
      {formattedData &&
        formattedData.map((item, i) => (
          <p key={i}>{`${item[1]}${props.separator}${item[0]} ${item[2]?.unit ?? ''}`}</p>
        ))}
      <div className="design-list">
        {designNames.map((e: string, key: any) => (
          <p key={key}>{e}</p>
        ))}
      </div>
    </DesignsCustomTooltipContainer>
  );
};

export default DesignsCustomTooltip;
