import { Permission } from 'access-control/permissions';
import { ITab } from '@ratedpower/components';

export const createProjectPermissions: Permission[] = [
  Permission.PROJECT_SUPPORT_CREATE,
  Permission.PROJECT_CREATE_IN_USER_COMPANY,
  Permission.PROJECT_CREATE_IN_USER_TEAM_AND_PUBLIC,
];

export const FavouriteTab: ITab = {
  id: 'favourites',
  label: 'MY_FAVOURITES',
};

export const defaultHomeTabs: ITab[] = [FavouriteTab];

export const withPermissionHomeTabs: ITab[] = [
  {
    id: 'created-by-me',
    label: 'CREATED_BY_ME',
  },
];

export const allHomeTabs = [...withPermissionHomeTabs, ...defaultHomeTabs];
