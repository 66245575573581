import { GET_ALL_COMPANY_SECRETS, GET_ALL_AVAILABLE_COMPANY_SECRETS } from 'services/user-gql';
import { useQuery } from '@apollo/client';
import { Secret, SecretFromAvailable, SecretFromSaved } from 'types/company.types';
import { RootState } from 'redux/root-reducer';
import { useSelector } from 'react-redux';

const useSecrets = (companyId?: string, skip?: boolean) => {
  const profile = useSelector((state: RootState) => state.currentUser.profile);

  const { loading: loadingAllSecrets, data: allSecrets } = useQuery(GET_ALL_COMPANY_SECRETS, {
    fetchPolicy: 'cache-and-network',
    variables: { companyId: companyId ? companyId : profile?.companyId },
    skip,
  });

  const { loading: loadingAvailableSecrets, data: availableSecrets } = useQuery(GET_ALL_AVAILABLE_COMPANY_SECRETS, {
    fetchPolicy: 'cache-first',
    skip,
  });

  const loading = loadingAllSecrets || loadingAvailableSecrets;

  const integrationSecrets: Secret[] =
    !loading && allSecrets && availableSecrets
      ? availableSecrets.companyAvailableSecrets.map((availableIntegration: SecretFromAvailable) => {
          const { secret, publicName } = availableIntegration;
          const savedIntegration: SecretFromSaved | undefined = allSecrets.companySavedSecrets.find(
            (savedIntegrationFromSaved: SecretFromSaved) => savedIntegrationFromSaved.secret === secret
          );
          return {
            publicName,
            secret,
            status: savedIntegration ? savedIntegration.status : 'DISABLED',
            mode: savedIntegration ? savedIntegration.mode : 'MANUAL',
          };
        })
      : [];

  return {
    loadingSecrets: loading,
    integrationSecrets,
  };
};

export default useSecrets;
