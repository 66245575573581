export interface IListItemNotification {
  description: string;
  title: string;
}

export interface IListItemNotificationRecord {
  [key: number]: IListItemNotification;
}

export const DefaultListItemNotification: IListItemNotification = {
  description: '',
  title: '',
};