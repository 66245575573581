import { Modal } from '@ratedpower/components';
import styled from 'styled-components';

export const ModalAdd = styled(Modal)`
  display: flex;
  flex-direction: column;
`;

export const ModalAddDesignsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const ModalAddDesignContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 320px;
  width: 100%;

  .designs-grid {
    grid-gap: ${({ theme }) => theme.v2.spacing.m};
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 328px);
    grid-auto-rows: 1fr;
    overflow: auto;
    justify-content: space-between;
  }

  .pagination {
    margin: 6px 0 12px;
  }
`;

export const ModalBodyEmpty = styled.div`
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  align-items: center;
`;

export const ModalBottomBar = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.white};
  bottom: 0;
  display: contents;
  justify-content: right;
  left: 0;
  padding: 0 16px;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

export const ButtonsWrapper = styled.div`
  padding-top: ${({ theme }) => theme.v2.spacing.m};
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  justify-content: right;
`;
