import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRestClient } from './use-rest-client';

async function readFileAsDataURL(file: any): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.readAsDataURL(file);
  });
}

export const useRemoteImage = (url: string, options: { skip?: boolean } = {}) => {
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState('');
  const { getFile } = useRestClient();

  useEffect(() => {
    if (url && !options.skip) {
      getFile(url)
        .then((response) => readFileAsDataURL(response.data))
        .then((dataBase64) => setImageSrc(dataBase64))
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [url, options.skip]);

  return {
    loading,
    src: imageSrc,
  };
};

interface IRemoteImageProps {
  url: string;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
}
export const RemoteImage: React.FC<IRemoteImageProps> = (props) => {
  const { url, alt, className, width, height } = props;
  const { src, loading } = useRemoteImage(url);

  if (loading) return <Skeleton width={width || 150} height={height || 75} className={className} />;

  return <img {...{ src, alt, className, width, height }} />;
};
