import React, { useEffect, useState } from 'react';
import * as S from './checkbox-list-filter.styled';
import { t } from 'i18next';
import { Button, Checkbox, useFiltersContext } from '@ratedpower/components';
import { ISearchListItem } from 'types/filter';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import InputSearch from 'components/form-components/input-search';

export interface IProps {
  id?: string;
  error?: any;
  items: ISearchListItem[];
  searchValue?: string;
  onSearch: (search: string) => void;
  onToggleItem: (items: string[]) => void;
  paginationComponent?: any;
}

const CheckboxListFilter: React.FC<IProps> = ({
  id: idElement,
  error,
  items,
  searchValue,
  onSearch,
  onToggleItem,
  paginationComponent,
}) => {
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

  const handleClear = () => {
    setSelectedItems(new Set());
    onToggleItem([]);
  };

  const filtersContext = useFiltersContext();

  useEffect(() => {
    if (searchValue !== undefined) return;
    if (items && items.length > 0) {
      const defaultSelectedItems = items.filter((item) => item.checked).map((item) => item.id);
      setSelectedItems((selectedItems) => new Set([...defaultSelectedItems, ...new Set(selectedItems)]));
    }
  }, [items?.length, searchValue]);

  useEffect(() => {
    if (filtersContext?.clearAll) {
      handleClear();
    }
  }, [filtersContext?.clearAll]);

  const handleToggleItem = (id: string) => () => {
    if (selectedItems) {
      filtersContext?.setClearAll(false);
      if (selectedItems.has(id)) {
        const items = new Set(selectedItems);
        items.delete(id);
        setSelectedItems(items);
        onToggleItem(Array.from(items));
      } else {
        const items = new Set(selectedItems.add(id));
        setSelectedItems(items);
        onToggleItem(Array.from(items));
      }
    }
  };

  return (
    <S.Container>
      <InputSearch data-testid="input-search" value={searchValue} onChange={onSearch} />
      <S.CompanyResults>
        {error ? (
          <ErrorRetrievingData />
        ) : (
          items?.map(({ name, id }: ISearchListItem) => (
            <S.CompanyItem key={id}>
              <Checkbox
                data-testid="input-checkbox"
                label={name}
                id={`${id}${idElement || ''}`}
                checked={selectedItems?.has(id)}
                onChange={handleToggleItem(id)}
              />
            </S.CompanyItem>
          ))
        )}
        {paginationComponent}
      </S.CompanyResults>
      <Button
        variant="ghost"
        className="clear"
        onClick={handleClear}
        text={`${t('action:clear')} (${selectedItems?.size})`}
      />
    </S.Container>
  );
};

export default CheckboxListFilter;
