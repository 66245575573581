import styled, { css } from 'styled-components';

const gridColumn = (column: number) => css`
  display: grid;
  grid-column: ${column} / ${column};
`;

export const ListItemsNotificationWrapper = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  grid-template-columns: max-content max-content;
  display: grid;
`;

export const ListItemsNotificationInputWrapper = styled.div`
  ${gridColumn(1)}
`;

export const ListItemsNotificationIconWrapper = styled.div`
  align-items: center;
  ${gridColumn(2)}
`;

export const ButtonWrapper = styled.div`
  justify-content: flex-start;
  align-items: center;
`;
