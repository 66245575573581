import { IPopupIconButtonOption } from '@ratedpower/components';
import { TeamActions, TeamContentType } from 'types/teams';
import { useTeamsProvider } from '../teams-data';
import { useNavigate, useParams } from 'react-router-dom';

export const useTeamActions = () => {
  const navigate = useNavigate();
  const urlParamsNames = Object.keys(useParams());
  const { addProjectsModal, addMembersModal, deleteTeamModal } = useTeamsProvider();

  const handleTeamAction = (id: string, teamName: string) => (option: IPopupIconButtonOption) => {
    switch (option.value) {
      case TeamActions.ADD_PROJECT:
        addProjectsModal.setData({ id, teamName });
        addProjectsModal.setModalOpen(true);
        break;
      case TeamActions.ADD_MEMBER:
        addMembersModal.setData({ id, teamName });
        addMembersModal.setModalOpen(true);
        break;
      case TeamActions.TEAM_SETTINGS:
        if (urlParamsNames.includes('teamId')) {
          navigate(`../${id}/${TeamContentType.SETTINGS}`, { state: { teamName } });
        } else {
          navigate(`${id}/${TeamContentType.SETTINGS}`, { state: { teamName } });
        }
        break;
      case TeamActions.DELETE_TEAM:
        deleteTeamModal.setData({ id, teamName });
        deleteTeamModal.setModalOpen(true);
        break;
    }
  };

  return {
    handleTeamAction,
  };
};
