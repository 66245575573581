import React from 'react';
import { FolderErrorError } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@ratedpower/components';
import * as S from './no-results-filter.styled';

interface INoResultsFilterProps {
  onClick?: () => void;
}

const NoResultsFilter: React.FC<INoResultsFilterProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <S.NoResultsFilterContainer>
      <FolderErrorError />
      <Typography weight="bold" className="title">
        {t('no-results-matched')}
      </Typography>
      <Typography size="s">{t('filters.no-results-after-filter')}</Typography>
      {onClick && <Button variant="accent" text={t('filters.clear-filters')} onClick={onClick} />}
    </S.NoResultsFilterContainer>
  );
};

export default NoResultsFilter;
