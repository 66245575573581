import React from 'react';
import DesignItem from './item/design-item';
import { DesignsListContainer } from './designs-list.styled';
import { IBessDesign, IDesign } from 'types/design';
interface IProps {
  designs: (IDesign | IBessDesign)[];
  viewDetailed: boolean;
  rowsSelected: (IDesign | IBessDesign)[];
  zeroDesignsCreated: boolean;
  onPerform: (action: string, item: IDesign | IBessDesign) => void;
  onEditDesignName: (id: string, newName: string) => void;
}

const DesignsList: React.FC<IProps> = ({ designs, viewDetailed, rowsSelected, onPerform, onEditDesignName }) => {
  const handleEditDesignName = (id: string) => (newName: string) => {
    onEditDesignName(id, newName);
  };

  return (
    <DesignsListContainer className="list">
      {designs.map((item) => (
        <DesignItem
          key={item.id}
          {...{ item }}
          showDetails={viewDetailed}
          selected={rowsSelected.some((element) => element.id === item.id)}
          onPerform={(action) => onPerform(action, item)}
          onEditName={handleEditDesignName(item.id)}
        />
      ))}
    </DesignsListContainer>
  );
};

export default DesignsList;
