import React, { useRef, useState } from 'react';
import * as S from 'components/buttons/help-button/help-button.styled';
import { AnimatePresence, motion } from 'framer-motion';
import { CrossSvg, QuestionMarkSvg } from 'assets/icons';
import { RootState } from 'redux/root-reducer';
import { useLocation } from 'react-router-dom';
import { useOutsideClick } from 'utils/mouse';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useKnowledgeBase } from 'utils/knowledge-base/use-knowledge-base';
import { SVGWrapper } from '@ratedpower/components';

const HelpButton: React.FC = () => {
  const {
    KBNewChanges,
    KBhowToCreateSiteInPvDesign,
    KBmethodologies,
    knowledgeBaseLocalized,
    KBhowToUploadEquipment,
  } = useKnowledgeBase();
  const [isActive, setIsActive] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const helpButtonRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  useOutsideClick(helpButtonRef, () => {
    isActive && setIsActive(false);
  });

  const { isShown, right, bottom } = useSelector((state: RootState) => state.helpButton);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <AnimatePresence>
      {isShown && (
        <S.HelpButtonContainer
          key="help-button"
          data-testid="help-button-id"
          onClick={handleClick}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          ref={helpButtonRef}
          initial={{ right: -100, bottom: -100 }}
          animate={{ opacity: 1, right: right, bottom: bottom }}
          exit={{ opacity: 0, right: -100, bottom: -100 }}
          as={motion.button}
        >
          {isActive && (
            <S.ListContainer data-testid="help-button-menu-id">
              <S.ListItem
                target="_blank"
                rel="noopener noreferrer"
                href={location.pathname.includes('equipment') ? KBhowToUploadEquipment : KBhowToCreateSiteInPvDesign}
              >
                {location.pathname.includes('equipment')
                  ? t('action:help-button.how-to-create-structure')
                  : t('action:help-button.how-to-upload-site')}
              </S.ListItem>
              <S.ListItem target="_blank" rel="noopener noreferrer" href={knowledgeBaseLocalized}>
                {t('action:help-button.knowledge-base')}
              </S.ListItem>
              <S.ListItem target="_blank" rel="noopener noreferrer" href={KBNewChanges}>
                {t('check-out-new-changes')}
              </S.ListItem>
              <S.ListItem target="_blank" rel="noopener noreferrer" href={KBmethodologies}>
                {t('action:help-button.methodologies')}
              </S.ListItem>
              <S.ListItem target="_blank" rel="noopener noreferrer" href={'mailto:support@ratedpower.com'}>
                {t('action:help-button.contact-us')}
              </S.ListItem>
            </S.ListContainer>
          )}
          <SVGWrapper icon={!isActive ? QuestionMarkSvg : CrossSvg} size={!isActive ? 'm' : 's'} />
          {!isActive && isHover && (
            <span data-testid="help-button-tooltip-id" className="info-tooltip">
              {t('action:help-button.help-and-support')}
            </span>
          )}
        </S.HelpButtonContainer>
      )}
    </AnimatePresence>
  );
};

export default HelpButton;
