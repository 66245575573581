import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GET_TEAMS, GET_TEAMS_BY_COMPANY, GET_TEAM_BY_ID, UPDATE_TEAM } from 'services/team-gql';
import { IUpdateTeamDTO } from 'types/teams';
import useForm from 'utils/hooks/use-form';
import { useToasts } from 'utils/hooks/use-toasts';

export const useUpdateTeam = (teamId: string) => {
  const { t } = useTranslation();
  const { id: companyId } = useParams<{ id: string }>();
  const { addErrorToast, addSuccessToast } = useToasts();
  const coverColorRef = useRef<string>();
  const coverImageRef = useRef<File | undefined>();
  const [currentCover, setCurrentCover] = useState<string | undefined>();
  const [isFormEditable, setIsFormEditable] = useState(false);

  const { data, loading } = useQuery(GET_TEAM_BY_ID, { variables: { teamId }, fetchPolicy: 'network-only' });
  const [updateTeam] = useMutation(UPDATE_TEAM, {
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
    notifyOnNetworkStatusChange: true,
    refetchQueries: companyId ? [GET_TEAM_BY_ID, GET_TEAMS_BY_COMPANY] : [GET_TEAM_BY_ID, GET_TEAMS],
    onCompleted: () => {
      addSuccessToast(t('user:teams.team-updated-succesfully'));
      setIsFormEditable(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'IllegalArgumentException') {
        setFormError({ name: t('user:teams.error-team-name-repeated') });
        return;
      }
      addErrorToast(t('user:teams.team-update-error'));
    },
  });

  const saveCurrentCover = (newCoverColor: string, newImage?: File) => {
    if (newImage) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        setCurrentCover(e.target?.result as string);
      };
      reader.readAsDataURL(newImage);
    } else {
      setCurrentCover(newCoverColor);
    }
    coverColorRef.current = newCoverColor;
    coverImageRef.current = newImage;
  };

  const handleTeamSubmit = () => {
    if (inputs.name && Array.from(inputs.name).every((character) => character === ' ')) {
      setFormError(t('user:teams.team-name-error'));
      return;
    }
    handleUpdateTeam(
      {
        id: teamId,
        name: inputs.name || data?.getTeamById.name,
        description: inputs.description || data?.getTeamById.description,
        coverColor: coverColorRef.current ?? data?.getTeamById.coverColor,
        companyId,
      },
      coverImageRef.current
    );
  };

  const resetValues = () => {
    clearValue('name');
    clearValue('description');
    setCurrentCover(undefined);
    coverColorRef.current = undefined;
    coverImageRef.current = undefined;
  };

  const { inputs, formError, setFormError, handleInputChangeManual, handleSubmit, clearValue } =
    useForm(handleTeamSubmit);

  useEffect(() => {
    return () => {
      resetValues();
      setIsFormEditable(false);
    };
  }, [teamId]);

  const handleUpdateTeam = (updateTeamDTO: IUpdateTeamDTO, coverImage?: File) => {
    updateTeam({ variables: { updateTeamDTO, coverImage } });
  };

  return {
    loadingTeamInfo: loading,
    currentTeam: data?.getTeamById,
    handleUpdateTeam,
    onChangeInput: handleInputChangeManual,
    formError,
    isFormEditable,
    currentCover,
    setCurrentCover,
    setIsFormEditable,
    saveCurrentCover,
    handleSubmit,
    resetValues,
  };
};
