import styled from 'styled-components';

export const ErrorFooterContainer = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

export const MailTo = styled.a`
  display: block;
  color: ${({ theme }) => theme.v2.text.secondary.default};
  text-decoration: underline;
`;
