import { IServerFilter } from 'types/filter';
import { IOverlayFilter } from './hooks/use-filters';
import { operators } from 'services/operators';

export function getFilter(filter: IOverlayFilter, operator?: string): IServerFilter | null {
  const values = filter.options.reduce((result: string[], opt) => {
    if (opt.checked) return [...result, ...[opt.code]];
    return result;
  }, []);

  if (!values.length) return null;

  return {
    values,
    field: filter.code,
    operator: operator ?? operators.IN,
  };
}

export function getRangeFilters(filter: IOverlayFilter): IServerFilter[] {
  const serverFilters: IServerFilter[] = [];

  for (const option of filter.options) {
    if (option.checked) {
      if (option.code.includes('<')) {
        serverFilters.push({ field: filter.code, operator: operators.LESS, values: [option.code.split('< ')[1]] });
      }
      if (option.code.includes('-')) {
        serverFilters.push({
          field: filter.code,
          operator: operators.BETWEEN,
          values: [option.code.split(' - ')[0], option.code.split(' - ')[1]],
        });
      }
      if (option.code.includes('>')) {
        serverFilters.push({
          field: filter.code,
          operator: operators.GREATER,
          values: [option.code.split('> ')[1]],
        });
      }
    }
  }

  return serverFilters;
}

export const getSelectedFilters = (filters: IOverlayFilter[]) => {
  return filters.filter((filter) => filter.options.some((option) => option.checked)).map((filter) => filter.code);
};
