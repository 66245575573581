import React, { useState } from 'react';
import * as S from './users-tab.styled';
import ModalDelete from 'components/modals/modal-delete/modal-delete';
import NewUserModal from './new-user-modal/new-user-modal';
import { Button, RatedPowerLoader, RPTable, Typography } from '@ratedpower/components';
import { ReactComponent as EmptyUsersImg } from 'assets/images/empty-users.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useCompanyActivityState } from '../company-activity-state';
import { useUsersTab } from '../../use-users-tab';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import UsersTabRoleSwitch from 'components/users-tab-role-switch';
import { ContentTopBar } from 'components/content-top-bar';
import UsersTabFiltersPanel from './users-tab-filters-panel';
import { IUserRoleSummary } from 'types/users';
import { IServerFilter } from 'types/filter';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import UserTeamsAssignedModal from 'pages/teams/components/user-teams-assigned-modal';
import { RootState } from 'redux/store';
import { ROLE_TYPE } from 'types/role';

const UsersTab: React.FC = () => {
  const { t } = useTranslation();
  const { newUserFormModal, loadingCreation, companyId } = useCompanyActivityState();
  const { profile } = useSelector((state: RootState) => state.currentUser);

  const {
    query: { paginationComponent, loading, error },
    editUserFormModal,
    inputSearchProps,
    deleteModal,
    teamsAssignedModal,
    table,
    isNewCompany,
    userRoleSummary,
    userSummaryLoading,
    currentFilters,
    handleDeleteUser,
    handleEditUser,
    setCurrentFilters,
  } = useUsersTab();
  const theme = useTheme();
  const [isFilterPanelOpened, setFilterPanelOpened] = useState(false);
  const isRatedPower = profile?.companyId === companyId;

  const filtersWithoutRole = currentFilters?.filter((serverFilter) => serverFilter.field !== 'ROLE');

  const handleApplyFilters = (filters: IServerFilter[]) => {
    setCurrentFilters(filters);
    handleOpenFilterPanel(false)();
  };

  const handleOpenFilterPanel = (opened: boolean) => () => setFilterPanelOpened(opened);

  return (
    <>
      {loadingCreation ? (
        <RatedPowerLoader />
      ) : !isNewCompany ? (
        <S.ContentContainer>
          <S.TopContainer>
            <ContentTopBar searchOnChange={inputSearchProps.onChange} filterOnClick={handleOpenFilterPanel(true)} />
          </S.TopContainer>
          <S.RoleSwithContainer>
            <UsersTabRoleSwitch
              roleFilterSelected={currentFilters.find(
                (filter: IServerFilter) => filter.field === 'ROLE' && filter.values.length === 1
              )}
              onClickRole={(roleSelected: string) =>
                setCurrentFilters([...filtersWithoutRole, { field: 'ROLE', operator: 'IN', values: [roleSelected] }])
              }
              onClickAll={() => setCurrentFilters(filtersWithoutRole)}
              loading={userSummaryLoading}
              userRoleSummary={
                isRatedPower
                  ? userRoleSummary
                  : userRoleSummary.filter((summary: IUserRoleSummary) => summary.role.type === ROLE_TYPE.EXTERNAL)
              }
            />
          </S.RoleSwithContainer>
          <S.TableContainer>
            {loading ? (
              <RatedPowerLoader />
            ) : error ? (
              <ErrorRetrievingData />
            ) : (
              <>
                <RPTable table={table as any} zeroResultsFoundText={t('zero-results-found')} />
                {paginationComponent}
              </>
            )}
          </S.TableContainer>
        </S.ContentContainer>
      ) : (
        <S.NoUsersContainer>
          <EmptyUsersImg />
          <span>{t('support:users-tab.no-users')}</span>
          <Button onClick={() => newUserFormModal.setModalOpen(true)} text={t('support:users-tab.create-user')} />
        </S.NoUsersContainer>
      )}

      {editUserFormModal.isOpen && (
        <NewUserModal
          isActive={editUserFormModal.isOpen}
          loading={loading}
          user={editUserFormModal.data}
          showUserTypes={companyId === profile?.companyId}
          isEditing
          title={t('support:users-tab.edit-profile')}
          onClose={() => editUserFormModal.setModalOpen(false)}
          onSubmit={(newUser) => {
            handleEditUser(newUser);
          }}
        />
      )}

      {deleteModal.isOpen && (
        <ModalDelete
          status="error"
          isActive={deleteModal.isOpen}
          title={
            <Typography color={theme.orange}>
              <Trans i18nKey="user:delete-x-user" values={{ name: deleteModal.data.name }}>
                Disable <b>{deleteModal.data.name}</b> user?
              </Trans>
            </Typography>
          }
          description={t('user:modal-delete-description')}
          primaryBtnText={t('action:delete')}
          secondaryBtnText={t('action:cancel')}
          onClose={() => deleteModal.setModalOpen(false)}
          onClickSecondaryBtn={() => deleteModal.setModalOpen(false)}
          onClickPrimaryBtn={() => {
            handleDeleteUser(deleteModal.data.id);
            deleteModal.setModalOpen(false);
          }}
        />
      )}
      {teamsAssignedModal.isOpen && (
        <UserTeamsAssignedModal
          title={t('user:teams.teams-assigned')}
          isActive={teamsAssignedModal.isOpen}
          userId={teamsAssignedModal.data}
          onClose={() => teamsAssignedModal.setModalOpen(false)}
        />
      )}
      <UsersTabFiltersPanel
        isOpen={isFilterPanelOpened}
        roles={userRoleSummary
          ?.filter((summary: IUserRoleSummary) => summary.userCounter > 0)
          .map((summary: IUserRoleSummary) => summary.role)}
        onHide={handleOpenFilterPanel(false)}
        onApplyFilters={handleApplyFilters}
      />
    </>
  );
};

export default UsersTab;
