/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Button, IModalProps, Modal, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { ModalButtons } from '../../teams-tab.styled';
import { useDeleteTeam } from '../../hooks/use-delete-team';

interface IProps extends IModalProps {
  selectedTeamId: string;
  description: string;
}

const DeleteTeamModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { selectedTeamId, description, onClose } = props;
  const { handleDeleteTeam } = useDeleteTeam(selectedTeamId, onClose);

  return (
    <Modal {...props}>
      <Typography>{description}</Typography>
      <ModalButtons>
        <Button variant="ghost" text={t('action:cancel')} onClick={onClose} />
        <Button text={t('user:teams.delete-team')} onClick={handleDeleteTeam} variant="error" />
      </ModalButtons>
    </Modal>
  );
};

export default DeleteTeamModal;
