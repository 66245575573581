import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOption } from 'types/common';

interface ICurrenciesState {
  currencies: { [key: string]: string };
  currencyOptions: IOption[];
}

const initialState: ICurrenciesState = {
  currencies: {},
  currencyOptions: [],
};

const currenciesSlide = createSlice({
  initialState,
  name: 'currencies',
  reducers: {
    setCurrencies(state, action: PayloadAction<ICurrenciesState>) {
      const { currencies, currencyOptions } = action.payload;
      state.currencies = currencies;
      state.currencyOptions = currencyOptions;
    },
  },
});

export const { setCurrencies } = currenciesSlide.actions;

export default currenciesSlide.reducer;
