import styled from 'styled-components';

export const DesignsListContainer = styled.div`
  height: 100%;

  @media screen and (max-width: 1024px) {
    .item-col {
      &.checkbox,
      &.favorite,
      &.more {
        width: 7% !important;
      }

      &.name {
        width: 14% !important;
      }

      &.createdAt {
        width: 8%;
      }

      &.error {
        width: 54%;
      }
    }
  }
`;
