import styled from 'styled-components';

export const TopBarContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.v2.spacing.l};
`;

export const FiltersContainer = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  grid-template-rows: 1fr 1fr;
`;

export const Selectors = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.xxs};
  display: flex;
  align-items: center;
`;

export const InputsWrapper = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  display: grid;
  grid-template-columns: max-content max-content auto max-content;

  & > :nth-child(3) {
    grid-column: 4;
  }
`;

export const SelectedDesignsWrapper = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xxs};
  display: flex;

  & > *:not(:last-child)::after {
    content: '.';
  }
`;
