import React, { useRef } from 'react';
import * as S from './home-projects-designs.styled';
import { useHomeProjectsDesigns } from 'pages/home/home-designs/home-project-item/home-projects-designs/use-home-projects-designs';
import HomeDesignItem from 'pages/home/home-designs/home-project-item/home-projects-designs/home-design-item';
import { useContainerDimensions } from 'utils/hooks/use-container-dimensions';

interface IHomeProjectDesigns {
  projectId: string;
  isFavorite: boolean;
}

const HomeProjectsDesigns = ({ projectId, isFavorite }: IHomeProjectDesigns) => {
  const ref = useRef(null);
  const { width } = useContainerDimensions(ref);
  const { data, paginationComponent, designActions } = useHomeProjectsDesigns(projectId, isFavorite, width - 72);

  return (
    <S.HomeProjectsDesignsContainer ref={ref}>
      {data.map((design) => (
        <HomeDesignItem design={design} actions={designActions} projectId={projectId} key={design.id} />
      ))}
      {paginationComponent}
    </S.HomeProjectsDesignsContainer>
  );
};

export default HomeProjectsDesigns;
