import { gql } from '@apollo/client';

export const GET_POP_UP = gql`
  query GetPopUp {
    result: getPopUp {
      id
      title
      version
      expirationDate
      modifiedDate
      sections {
        id
        title
        description
        knowledgeBaseArticles {
          link
        }
        listItems {
          id
          content
        }
      }
    }
  }
`;

export const UPDATE_POPUP = gql`
  mutation UpdatePopup($popUpForm: PopUpUpdateDTO!) {
    updatePopup(popUpForm: $popUpForm) {
        id
    }
  }
`;
