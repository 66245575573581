import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(328px, 328px));
  justify-content: space-between;
  grid-auto-rows: 1fr;
  gap: 1.2rem;
  margin-bottom: 48px;
`;

export const DescriptionContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  flex-direction: column;
  max-width: 296px;
`;
