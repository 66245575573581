import React from 'react';
import * as S from './projects.styled';
import TopbarProjects from './topbar-projects/topbar-projects';
import ProjectsList from './list/projects-list';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Typography, LinkText, Banner } from '@ratedpower/components';
import { DESIGN_ACTIONS } from 'utils/constants/designs';
import { RootState } from 'redux/root-reducer';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ProjectsMapBox from './map/projects-mapbox';
import { useProjects } from './use-projects';
import { useProjectsActions } from './use-projects-actions';
import { useTheme } from 'styled-components';
import { BUSINESS_EMAIL } from 'utils/constants/contact';
import CreateProjectModal from 'pages/teams/components/create-project-modal';
import ProjectsFilterPanel from 'components/filters/projects-filter-panel/projects-filter-panel';
import { useAuthorization } from 'access-control/can';
import ErrorForbidden from 'pages/error/error-forbidden/error-forbidden';

const Projects: React.FC = () => {
  const { subscriptionPlan } = useSelector((state: RootState) => state.currentUser);
  const theme = useTheme();
  const { t } = useTranslation();
  const { isAuthorizedToPermissionSuffix } = useAuthorization();

  const {
    projectsError,
    projects,
    loading,
    viewNumber,
    mapNumber,
    selectedTab,
    toggleOverlay,
    overlayProps,
    inputSearchProps,
    sortedBy,
    setSortedBy,
    paginationComponent,
    zeroProjectsCreated,
    view,
    teamSelected,
    handleSelectTeam,
    handleViewChange,
    handleTabChange,
    handleApplyFilters,
    noFiltersApplied,
    hasFoundResultsBanner,
  } = useProjects();

  const {
    handlePerform,
    handleNewProjectModal,
    handleCloseNewProjectModal,
    handleEditName,
    loadingUpdateProjects,
    newProjectFormModal,
    deleteProjectModal,
  } = useProjectsActions();

  const handleClearFilters = () => {
    overlayProps.onClear();
    inputSearchProps.onChange('');
  };

  const handleOnProjectCreate = () => {
    if (selectedTab !== 'default') {
      handleTabChange('default');
    }
  };

  if (!isAuthorizedToPermissionSuffix('PROJECT')) return <ErrorForbidden />;

  return (
    <S.ProjectsContainer data-test="projects-page">
      <TopbarProjects
        createProject={projectsError ? undefined : handleNewProjectModal}
        openFilters={() => toggleOverlay()}
        changeView={handleViewChange}
        onTabChange={handleTabChange}
        onSearch={inputSearchProps.onChange}
        search={inputSearchProps.value}
        selectedTab={selectedTab}
        loading={(loading || loadingUpdateProjects) && projects.length !== 0}
        view={view}
      />

      {!!subscriptionPlan && !!subscriptionPlan.banner.projects && !subscriptionPlan.unlimitedProjects && (
        <S.ProjectsBanner>
          <Banner
            status={subscriptionPlan.banner.projects.type}
            description={
              <Trans i18nKey={subscriptionPlan.banner.projects.contactUs}>
                <></>
                <LinkText textUnderlined href={BUSINESS_EMAIL}>
                  contact us
                </LinkText>
              </Trans>
            }
            header={subscriptionPlan.banner.projects.cause}
            closable
          />
        </S.ProjectsBanner>
      )}

      <div className="main-view">
        <div className="split-view">
          <div className={classNames('split-item', `split-item-${viewNumber}`)}>
            <ProjectsList
              loading={(loading || loadingUpdateProjects) && projects.length === 0}
              sortedBy={sortedBy}
              projects={projects}
              zeroProjectsCreated={zeroProjectsCreated}
              selectedTab={selectedTab}
              onSort={setSortedBy}
              paginationComponent={paginationComponent}
              projectsError={projectsError}
              onEditName={handleEditName}
              onPerform={handlePerform}
              onChangeTeamSelected={handleSelectTeam}
              onClearFilters={handleClearFilters}
              noFiltersApplied={noFiltersApplied}
              hasFoundResultsBanner={hasFoundResultsBanner}
            />
          </div>
          <div className={classNames('split-item', `split-item-${mapNumber}`)}>
            {(mapNumber === '1' || mapNumber === '2') && (
              <ProjectsMapBox viewMapStatus={mapNumber} projects={projects} />
            )}
          </div>
        </div>
      </div>

      {/* MODALS */}
      {newProjectFormModal.isOpen && (
        <CreateProjectModal
          addPublicOption
          selectedTeamId={teamSelected}
          onSubmitCallback={handleOnProjectCreate}
          isActive={newProjectFormModal.isOpen}
          title={t('user:teams.create-new-project')}
          disableClickOutside
          onClickPrimaryBtn={handleCloseNewProjectModal}
          onClickSecondaryBtn={handleCloseNewProjectModal}
          onClose={handleCloseNewProjectModal}
          width="456px"
        />
      )}
      {deleteProjectModal.isOpen && (
        <Modal
          status="error"
          isActive={deleteProjectModal.isOpen}
          width="small"
          primaryBtnText={t('action:delete')}
          secondaryBtnText={t('action:cancel')}
          title={
            <Typography color={theme.red} weight={700}>
              {t('project:delete-modal-title', { projectName: deleteProjectModal.data.name })}
            </Typography>
          }
          onClose={() => deleteProjectModal.setModalOpen(false)}
          onClickSecondaryBtn={() => deleteProjectModal.setModalOpen(false)}
          onClickPrimaryBtn={() => handlePerform(DESIGN_ACTIONS.confirmDelete, deleteProjectModal.data)}
        >
          <Typography>
            <Trans i18nKey="project:delete-modal-description">
              If you delete it, all its designs will be deleted too.
              <br />
              This action can't be undone.
            </Trans>
          </Typography>
        </Modal>
      )}
      <ProjectsFilterPanel
        filters={overlayProps.filters}
        onSelect={overlayProps.onSelect}
        onApply={handleApplyFilters}
        onClear={overlayProps.onClear}
        isOpen={overlayProps.isOpen}
        onClose={toggleOverlay}
      />
    </S.ProjectsContainer>
  );
};

export default Projects;
