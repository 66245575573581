import React, { useMemo } from 'react';
import ZeroCreated2 from 'components/error-components/zero-created-2/zero-created-2';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { FolderBasicSvg, ZeroDesignsSvg } from 'assets/icons';
import { Link } from 'react-router-dom';
import * as S from './home-no-designs.styled';

interface IProps {
  isFavorite: boolean;
}

const HomeNoDesigns = ({ isFavorite }: IProps) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const { projectsDone } = useSelector((state: RootState) => state.currentUser.subscriptionPlan) || {};

  const hasRunDesignPermissions =
    isAuthorized(Permission.SIMULATION_SUPPORT_CREATE) ||
    isAuthorized(Permission.SIMULATION_CREATE_IN_USER_COMPANY) ||
    isAuthorized(Permission.SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC);

  const { title, subtitle, Icon } = useMemo(() => {
    const messageKeyPrefix = 'home:designs.no-designs';

    if (isFavorite) {
      return {
        title: t(`${messageKeyPrefix}.favourites.title`),
        subtitle: <Trans i18nKey={t(`${messageKeyPrefix}.favourites.subtitle`)} />,
        Icon: ZeroDesignsSvg,
      };
    }
    if (!hasRunDesignPermissions) {
      return {
        title: t(`${messageKeyPrefix}.current-user-no-permission.title`),
        subtitle: t(`${messageKeyPrefix}.current-user-no-permission.subtitle`),
        Icon: FolderBasicSvg,
      };
    }
    if (projectsDone === 0) {
      return {
        title: t(`${messageKeyPrefix}.company.title`),
        subtitle: (
          <S.HomeNoDesignsTitleContainer>{t(`${messageKeyPrefix}.company.subtitle`)}</S.HomeNoDesignsTitleContainer>
        ),
        Icon: ZeroDesignsSvg,
      };
    }
    return {
      title: t(`${messageKeyPrefix}.current-user.title`),
      subtitle: (
        <Trans i18nKey={`${messageKeyPrefix}.current-user.subtitle`}>
          <Link to="/projects" />
        </Trans>
      ),
      Icon: ZeroDesignsSvg,
    };
  }, []);

  return (
    <S.HomeNoDesignsContainer>
      <ZeroCreated2 Icon={Icon} title={title} subtitle={subtitle} />
    </S.HomeNoDesignsContainer>
  );
};

export default HomeNoDesigns;
