import { useMutation } from '@apollo/client';
import { DELETE_NOTIFICATION, GET_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS } from 'services/notifications-gql';

const useDeleteNotification = (notificationId: string, onCompleted: () => void, onError: () => void) => {
  const [deleteNotification, { error, data }] = useMutation(DELETE_NOTIFICATION, {
    variables: {
      notificationId,
    },
    refetchQueries: [GET_UNREAD_NOTIFICATIONS, GET_NOTIFICATIONS],
    onCompleted,
    onError,
  });

  return { deleteNotification, error: error || data?.errors };
};

export default useDeleteNotification;
