import React from 'react';
import * as S from './content-top-bar.styled';
import { FilterSvg } from 'assets/icons';
import { Button } from '@ratedpower/components';
import { useDebouncedInput } from 'utils/hooks/use-debounced-input';
import { useTheme } from 'styled-components';
import InputSearch from 'components/form-components/input-search';

interface IContentTopbar {
  searchPlaceholder?: string;
  marginBottom?: string;
  hideFilterButton?: boolean;
  searchOnChange: (value: string) => void;
  filterOnClick: () => void;
}

export const ContentTopBar: React.FC<IContentTopbar> = ({
  searchPlaceholder,
  marginBottom,
  hideFilterButton,
  searchOnChange,
  filterOnClick,
}) => {
  const theme = useTheme();
  const { value, onChange } = useDebouncedInput(300, searchOnChange);

  return (
    <S.Container $marginBottom={marginBottom}>
      <InputSearch value={value} onChange={onChange} size="s" placeholder={searchPlaceholder} />
      {!hideFilterButton && (
        <Button
          fontColor={theme.v2.text.accent.default}
          fontColorHover={theme.v2.text.accent.hover}
          variant="ghost"
          onClick={filterOnClick}
          Icon={FilterSvg}
        />
      )}
    </S.Container>
  );
};
