import styled from 'styled-components';
import { DesignItemContainer } from '../design-item.styled';

export const DesignItemLoadingContainer = styled(DesignItemContainer)`
  cursor: default;
  .checkbox {
    opacity: 0;
  }
  .favorite {
    opacity: 0;
  }
  .main-row {
    border: none;
  }
  .skeleton {
    width: 14%;
    span {
      width: 80%;
    }
    span[class*='react-loading-skeleton'] {
      font-size: 13px;
    }
  }
`;
