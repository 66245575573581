import styled from 'styled-components';

export const Container = styled.div<{ $noPadding?: boolean }>`
  padding: ${({ $noPadding, theme }) => ($noPadding ? 0 : theme.v2.spacing.l)};
  row-gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;

  :nth-child(2) {
    overflow-x: initial;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NotificationsContainer = styled.div`
  position: relative;
  overflow-y: auto;
  min-height: 100%;
`;
