import React from 'react';
import { ISelectorOptions } from 'types/selector.types';
import { Dropdown, LabelWrapper } from '@ratedpower/components';
import { getOptionForValue } from 'utils/selector';

interface IProps {
  loading?: boolean;
  customerSupportUsers: ISelectorOptions[];
  businessUsers: ISelectorOptions[];
  sdrUsers: ISelectorOptions[];
  edition: boolean;
  loader?: JSX.Element;
  inputs: Record<string, any>;
  handleInputChangeManual: (name: string, value: any) => void;
  inputSize: 's' | 'm';
}

const CompanyFormManagerSelectors: React.FC<IProps> = ({
  loading,
  customerSupportUsers,
  businessUsers,
  sdrUsers,
  edition,
  loader,
  inputs,
  handleInputChangeManual,
  inputSize,
}) => {
  const selectedSupportedById = inputs.supportedById
    ? getOptionForValue(inputs.supportedById, customerSupportUsers)
    : null;

  const selectedBusinessManagerId = inputs.businessManagerId
    ? getOptionForValue(inputs.businessManagerId, businessUsers)
    : null;

  const selectedSalesDevRepresentativeId = inputs.salesDevRepresentativeId
    ? getOptionForValue(inputs.salesDevRepresentativeId, sdrUsers)
    : null;

  return (
    <>
      <LabelWrapper title="CS" loading={loading} Loader={loader} size={inputSize} alignItems="center">
        <Dropdown
          name="supportedById"
          options={customerSupportUsers}
          value={selectedSupportedById ? [selectedSupportedById] : []}
          onChange={([selectedItem]) => handleInputChangeManual('supportedById', selectedItem.value)}
          size={inputSize}
          readonly={!edition}
        />
      </LabelWrapper>
      <LabelWrapper title="BIZ" loading={loading} Loader={loader} size={inputSize} alignItems="center">
        <Dropdown
          size={inputSize}
          name="businessManagerId"
          options={businessUsers}
          value={selectedBusinessManagerId ? [selectedBusinessManagerId] : []}
          onChange={([selectedItem]) => handleInputChangeManual('businessManagerId', selectedItem.value)}
          readonly={!edition}
        />
      </LabelWrapper>
      <LabelWrapper title="SDR" loading={loading} Loader={loader} size={inputSize} alignItems="center">
        <Dropdown
          size={inputSize}
          name="salesDevRepresentativeId"
          options={sdrUsers}
          value={selectedSalesDevRepresentativeId ? [selectedSalesDevRepresentativeId] : []}
          onChange={([selectedItem]) => handleInputChangeManual('salesDevRepresentativeId', selectedItem.value)}
          readonly={!edition}
        />
      </LabelWrapper>
    </>
  );
};

export { CompanyFormManagerSelectors };
