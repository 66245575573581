import React, { useEffect, useState } from 'react';
import * as S from './attributes-panel.styled';
import { useTranslation } from 'react-i18next';
import { objectEquals } from 'utils/objects';
import { IComparisonFilter, IComparisonFilterGroup } from 'types/design-comparison.types';
import { ChipGroup, FilterSidebar, FilterSidebarItem, Toggle, Typography } from '@ratedpower/components';

export function isFilterActive(attr: IComparisonFilter, attrsShown: IComparisonFilter[]): boolean {
  if (!attrsShown.length || !attr) return false;
  return !!attrsShown.find((e) => objectEquals(e.key, attr.key));
}

interface IProps {
  saveFiltersConfiguration?: boolean;
  setSaveFiltersConfiguration?: (value: boolean) => void;
  active: boolean;
  availableFilters: IComparisonFilterGroup[];
  activeFilters: IComparisonFilter[];
  viewShown: string;
  updateActiveFilters: (updatedFilters: IComparisonFilter[]) => void;
  hideAttributesPanel: () => void;
}

const ComparisonAttributesPanel: React.FC<IProps> = ({
  active,
  saveFiltersConfiguration = false,
  setSaveFiltersConfiguration,
  availableFilters,
  activeFilters,
  updateActiveFilters,
  hideAttributesPanel,
  viewShown,
}) => {
  const { t } = useTranslation();
  const [currentActiveFilters, setCurrentActiveFilters] = useState(activeFilters);

  useEffect(() => {
    setCurrentActiveFilters(activeFilters);
  }, [activeFilters.length]);

  const handleAttributeClick = (clickedFilter?: IComparisonFilter) => {
    if (!clickedFilter) return;
    let newAttributesShown = [...currentActiveFilters];

    if (isFilterActive(clickedFilter, currentActiveFilters)) {
      newAttributesShown = newAttributesShown.filter((e) => !objectEquals(e.key, clickedFilter.key));
    } else {
      newAttributesShown.push(clickedFilter);
    }
    setCurrentActiveFilters(newAttributesShown);
  };

  const handleClearFilters = () => {
    setCurrentActiveFilters([]);
  };

  const handleApplyFilters = () => {
    updateActiveFilters(currentActiveFilters);
    hideAttributesPanel();
  };

  return (
    <FilterSidebar
      onClose={hideAttributesPanel}
      isOpen={active}
      onApply={handleApplyFilters}
      onClear={handleClearFilters}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
      width="320px"
    >
      {(viewShown === 'table' || viewShown === 'chart') && setSaveFiltersConfiguration && (
        <S.SaveSelectionWrapper>
          <Toggle
            name="enable-secondary-inverter"
            isOn={saveFiltersConfiguration}
            onToggle={() => setSaveFiltersConfiguration(!saveFiltersConfiguration)}
          />
          <Typography>{t('design:save-selection-for-comparison')}</Typography>
        </S.SaveSelectionWrapper>
      )}
      {availableFilters.map((group, i) => (
        <FilterSidebarItem
          key={i}
          title={t(i === 0 ? 'design-process:layout' : group.label || '')}
          hasBorderBottom={i !== availableFilters.length - 1}
        >
          <ChipGroup
            id="attributes-panel"
            options={group.filters.map((filter) => ({
              label: t(`design:${filter.label}`),
              value: filter.key as string,
              checked: isFilterActive(filter, currentActiveFilters),
            }))}
            onChange={(clickedFilter) => {
              handleAttributeClick(group.filters.find((e) => e.key === clickedFilter.value));
            }}
          />
        </FilterSidebarItem>
      ))}
    </FilterSidebar>
  );
};
export default ComparisonAttributesPanel;
