import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSidebar } from 'redux/sidebar-slice';

interface IUseSidebarProps {
  collapsed?: boolean;
}

const useSidebar = ({ collapsed = false }: IUseSidebarProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebar({ collapsed: !!collapsed }));
  }, []);
};

export default useSidebar;
