import { getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';
import { useProjectFilters } from 'pages/projects/use-project-filters';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_USER_PROJECTS } from 'services/projects-gql';
import { useTheme } from 'styled-components';
import { IProjectListItem } from 'types/projects';
import { ITeam } from 'types/teams';
import { GenericStatus } from 'utils/constants/status';
import { PUBLIC_TEAMS_OPTION } from 'utils/constants/users';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useGetCompanyProjects } from 'utils/hooks/use-get-company-projects';
import { useModal } from 'utils/hooks/use-modal';
import { useSearch } from 'utils/hooks/use-search';
import { getSortDirection } from 'utils/sort';
import { teamProjectsListColumns } from 'utils/teams';

export const useListTeamProjects = (pageSize: number, teamId?: string) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { inputSearchProps, getSearchFilter } = useSearch();
  const isEmptyData = useRef(false);
  const [sortedBy, setSortedBy] = useState({
    selector: {
      label: t('date'),
      code: 'publishedDate',
    },
    ascendent: false,
  });
  const unassignProjectModal = useModal();
  const navigate = useNavigate();
  const projectFilters = useProjectFilters();
  const { id: companyId } = useParams<{ id: string }>();
  const useGetCompanyProjectsObject = useGetCompanyProjects(companyId ?? '', projectFilters.serverFilters, teamId);

  const { data, error, loading, paginationComponent } = useCustomQuery(GET_USER_PROJECTS, {
    variables: {
      pagination: {
        page: 0,
        size: pageSize,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
      search: getSearchFilter(),
      filters:
        teamId === PUBLIC_TEAMS_OPTION(t).value
          ? [
              ...(projectFilters.serverFilters || []),
              {
                field: 'STATUS',
                operator: 'IN',
                values: [GenericStatus.ARCHIVED, GenericStatus.FINISHED, GenericStatus.REVIEWED],
              },
            ]
          : projectFilters.serverFilters,
      teamId: teamId || 'Public',
      isFavorite: false,
    },
    skip: !!companyId,
    fetchPolicy: 'network-only',
  });

  const projectNameClickable = !!teamId;
  const canUnassignProject = teamId !== PUBLIC_TEAMS_OPTION(t).value;

  useEffect(() => {
    if (!data || !data.result || loading || !!companyId) return;
    setIsEmptyData(data.result.content);
  }, [data]);

  useEffect(() => {
    const { data: companyTeamProjects } = useGetCompanyProjectsObject;
    if (!companyTeamProjects || !companyId) return;
    setIsEmptyData(companyTeamProjects);
  }, [useGetCompanyProjectsObject.data]);

  const setIsEmptyData = (teamProjects: ITeam[]) => {
    if (teamProjects.length === 0 && !inputSearchProps.value && !projectFilters.serverFilters.length) {
      isEmptyData.current = true;
      return;
    }
    isEmptyData.current = false;
  };

  const onUnassignProject = (projectId: string, projectName: string) => {
    unassignProjectModal.setData({ projectId, projectName });
    unassignProjectModal.setModalOpen(true);
  };

  const tableData = useMemo(
    () => (companyId ? useGetCompanyProjectsObject.data : data?.result?.content) || [],
    [companyId, useGetCompanyProjectsObject.data, data]
  );

  const table: Table<IProjectListItem> = useReactTable({
    data: tableData,
    columns: teamProjectsListColumns(t, theme, navigate, {
      sortedBy: companyId ? useGetCompanyProjectsObject.sortedBy : sortedBy,
      projectNameClickable,
      setSortedBy: companyId ? useGetCompanyProjectsObject.setSortedBy : setSortedBy,
      onUnassignProject: canUnassignProject ? onUnassignProject : undefined,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  if (companyId) {
    return {
      isEmptyData: isEmptyData.current,
      table,
      error: useGetCompanyProjectsObject.error,
      loading: useGetCompanyProjectsObject.loading,
      inputSearchProps: useGetCompanyProjectsObject.inputSearchProps,
      projectFilterPanelProps: projectFilters,
      unassignProjectModal,
      paginationComponent: useGetCompanyProjectsObject.paginationComponent,
    };
  }

  return {
    isEmptyData: isEmptyData.current,
    table,
    error,
    loading,
    inputSearchProps,
    projectFilterPanelProps: projectFilters,
    unassignProjectModal,
    paginationComponent,
  };
};
