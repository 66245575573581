import { useMemo, useState } from 'react';
import { ISort } from 'types/filter';
import { ISubscriptionPlanDTO, Plan, PlanStatus } from 'types/subscription-plan';
import { Permission } from 'access-control/permissions';
import { getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';
import { getSortDirection } from 'utils/sort';
import { planHistoryColumns, planHistorySupportColumns } from './plan-history-constants';
import { useAuthorization } from 'access-control/can';
import { useLocation, useParams } from 'react-router-dom';
import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { IUseModal } from 'utils/hooks/use-modal';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { locales, SupportedLanguagesType } from 'utils/constants/locales';
import { pvDesignPlanTitles } from '../pv-design-plans/pv-design-plans-constants';
import { isDateBetweenDates, toDayEnd } from 'utils/date';
import { IForm } from 'utils/hooks/use-form';
import {
  GET_SUPPORT_PLANS,
  GET_PLANS,
  GET_SUPPORT_CURRENT_PLAN,
  GET_CURRENT_PLAN,
} from 'services/subscription-plan-gql';
import { useDeletePlan } from './use-delete-plan';
import { useUpgradePlan } from './use-upgrade-plan';
import { useEditPlan } from './use-edit-plan';

export interface IUsePlanHistory {
  table: Table<ISubscriptionPlanDTO>;
  error: ApolloError | undefined;
  loading: boolean;
  confirmDeleteModal: IUseModal;
  editPlanModal: IUseModal;
  upgradePlanModal: IUseModal;
  supportView: boolean;
  showAddPlanButton: boolean;
  handleDeletePlan: () => void;
  handleEditPlan: (inputs: IForm, planId: string, extensionId?: string) => void;
  handleRenewPlan: (inputs: IForm) => void;
  handleUpgradePlan: (inputs: IForm) => void;
  handleUpgradeChangePlan: (inputs: IForm) => void;
}

export const usePlanHistory = (): IUsePlanHistory => {
  const { t } = useTranslation();
  const {
    currentUser: { preferences, profile },
    currencies: { currencies },
  } = useSelector((state: RootState) => state);
  const { id: supportPagesCompanyId } = useParams<{ id: string }>();
  const { isAuthorized } = useAuthorization();
  const location = useLocation();
  const supportView = isAuthorized(Permission.COMPANY_SUPPORT_READ) && location.pathname.includes('companies-activity');

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('start-date'),
      code: 'startDate',
    },
    ascendent: false,
  });

  const { data, error, loading } = useQuery(supportView ? GET_SUPPORT_PLANS : GET_PLANS, {
    variables: {
      companyId: supportView ? supportPagesCompanyId : undefined,
      pagination: {
        page: 0,
        size: 10,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
    },
    skip: supportView ? !supportPagesCompanyId : false,
    fetchPolicy: 'network-only',
  });

  const { data: currentPlan } = useQuery(GET_SUPPORT_CURRENT_PLAN, {
    variables: {
      companyId: supportPagesCompanyId,
    },
    fetchPolicy: 'network-only',
    skip: !supportView,
  });

  const { data: currentPlanFromMyCompany } = useQuery(GET_CURRENT_PLAN, {
    variables: {
      companyId: profile?.companyId,
    },
    fetchPolicy: 'network-only',
    skip: supportView,
  });

  const currentPlanFromSupport: ISubscriptionPlanDTO = currentPlan?.currentSubscriptionPlanSupport;
  const subscriptionPlanData: ISubscriptionPlanDTO[] = data?.result?.content;
  const formatedPlansData: ISubscriptionPlanDTO[] = useMemo(
    () =>
      subscriptionPlanData?.map((plan) => {
        return {
          ...plan,
          title: t(`support:${pvDesignPlanTitles[plan.plan]}`),
        };
      }),
    [subscriptionPlanData]
  );
  const { confirmDeleteModal, handleDeletePlan, handleOpenConfirmDeleteModal } = useDeletePlan();
  const { editPlanModal, handleEditPlan, handleRenewPlan, handleOpenEditModal, handleUpdatePlanStatus } = useEditPlan(
    currentPlanFromSupport,
    subscriptionPlanData
  );
  const { upgradePlanModal, handleUpgradePlan, handleUpgradeChangePlan, handleOpenUpgradeModal } =
    useUpgradePlan(currentPlanFromSupport);

  const planHistoryActions = {
    hide: (id: string) => handleUpdatePlanStatus(id, PlanStatus.HIDDEN),
    show: (id: string) => handleUpdatePlanStatus(id, PlanStatus.VALID),
    edit: (id: string, extensionId: string) => handleOpenEditModal(id, false, extensionId),
    delete: handleOpenConfirmDeleteModal,
    renew: (id: string) => handleOpenEditModal(id, true),
    upgrade: handleOpenUpgradeModal,
  };

  const table = useReactTable({
    data: formatedPlansData,
    columns: supportView
      ? planHistorySupportColumns(t, currentPlanFromSupport?.id, {
          sortedBy,
          setSortedBy,
          locale: locales[preferences.language as SupportedLanguagesType],
          planHistoryActions,
          currencies,
        })
      : planHistoryColumns(t, currentPlanFromMyCompany?.result.id, {
          sortedBy,
          setSortedBy,
          locale: locales[preferences.language as SupportedLanguagesType],
        }),
    getCoreRowModel: getCoreRowModel(),
  });

  const showAddPlanButton =
    (!isDateBetweenDates(
      new Date(),
      new Date(currentPlanFromSupport?.startDate),
      new Date(currentPlanFromSupport?.expirationDate)
    ) &&
      toDayEnd(new Date()) !== toDayEnd(new Date(currentPlanFromSupport?.expirationDate))) ||
    currentPlanFromSupport.plan === Plan.DEMO;

  return {
    table,
    error,
    loading,
    confirmDeleteModal,
    editPlanModal,
    upgradePlanModal,
    supportView,
    showAddPlanButton,
    handleDeletePlan,
    handleEditPlan,
    handleRenewPlan,
    handleUpgradePlan,
    handleUpgradeChangePlan,
  };
};
