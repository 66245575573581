export const truncateDecimals = (num: number, digits: number) => {
  const multiplier = Math.pow(10, digits);
  const adjustedNum = num * multiplier;
  const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
};

export function toFixedIfNeeded(num: number, fixed: number) {
  let value = fixed || 0;
  value = Math.pow(10, value);
  return Math.floor(num * value) / value;
}

const ONE_MILLION = 1_000_000;
const ONE_THOUSAND = 1_000;

function groupThousandsWithComma(number: string) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatThousandsNumbers(value: number) {
  const parts = value.toString().split('.');
  parts[0] = groupThousandsWithComma(parts[0]);
  return parts.join('.');
}

/**
 * Returns a string representation of a large positive number (1 - 1e9) which will always be a maximum of 4 digits and
 * may contain a 'unit qualifier' such as 'k' -> 1e3, 'M' -> 1e6.
 * Example: 123456 -> '123.4k', 1234567 -> '1.234M', 1234567890 -> '1,234M'
 */
export function fourDigitNotationForLargeNumbers(value: number) {
  const significantFigures = Number.parseFloat(Math.abs(value).toPrecision(4));
  const sign = value < 0 ? '-' : '';

  if (significantFigures >= ONE_MILLION) {
    return sign + formatThousandsNumbers(significantFigures / ONE_MILLION) + 'M';
  }

  if (significantFigures >= ONE_THOUSAND) {
    return sign + formatThousandsNumbers(significantFigures / ONE_THOUSAND) + 'k';
  }

  return sign + formatThousandsNumbers(significantFigures);
}

export function truncateExponentialNumber(value: number, digits?: number): string {
  if (!digits) return value.toExponential();
  const str = value.toExponential().split('e');
  const result = `${parseFloat(str[0]).toFixed(digits)}e${str[1]}`;
  return result;
}
