import React, { useState } from 'react';
import { ITab, Tabs, Tooltip, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import * as S from 'pages/home/home-designs/home-designs.styled';
import HomeProjectsList from 'pages/home/home-designs/home-projects-list/home-projects-list';
import { useAuthorization } from 'access-control/can';
import { allHomeTabs, createProjectPermissions, defaultHomeTabs, FavouriteTab } from 'pages/home/utils/constants';

const getHomeTabs = (canCreateProject: boolean): ITab[] => [...(canCreateProject ? allHomeTabs : defaultHomeTabs)];

const HomeDesigns = () => {
  const { isAuthorized } = useAuthorization(),
    canCreateProject = createProjectPermissions.some((action) => isAuthorized(action)),
    homeTabs: ITab[] = getHomeTabs(canCreateProject),
    [selectedTab, setSelectedTab] = useState(homeTabs[0].id),
    { t } = useTranslation(),
    translatedTabs = homeTabs.map((tab) => ({
      ...tab,
      label: t(`home:designs.${tab.label}`),
    })),
    tooltipTranslation = t(canCreateProject ? 'home:designs.title-info' : 'home:design-tooltip-can-not-create');

  const onClickTab = (tabId: string): void => {
    setSelectedTab(tabId);
  };

  return (
    <S.HomeDesignsContainer>
      <Tooltip description={tooltipTranslation} place="left">
        <Typography size={'l'} weight={'bold'}>
          {t('home:designs.title')}
        </Typography>
      </Tooltip>
      <Tabs tabs={translatedTabs} onClickTab={onClickTab} tabActive={selectedTab} />
      <HomeProjectsList isFavorite={selectedTab === FavouriteTab.id} key={selectedTab} />
    </S.HomeDesignsContainer>
  );
};

export default HomeDesigns;
