import styled from 'styled-components';

export const EmptyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
`;

export const EmptyContentButtons = styled.div`
  display: flex;
  column-gap: 8px;
`;
