import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs, Breadcrumb, TopbarWrapper } from '@ratedpower/components';
import { PlusSvg } from 'assets/icons';
import { Helmet } from 'react-helmet';
import { useUsersManagementContext } from '../users-management-data';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useTheme } from 'styled-components';

interface ITopbarUsersManagement {
  onClickTab: (tabSelected: string) => void;
  activeTab: string;
}

interface ITopbarButton {
  hasPermission: boolean;
  buttonText: string;
  buttonAction?: () => void;
  dropdownActions?: {
    buttonText: string;
    buttonAction: () => void;
  }[];
}

const TopbarRoles: React.FC<ITopbarUsersManagement> = ({ activeTab, onClickTab }) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const theme = useTheme();

  const {
    tabs,
    useRolesObject: { handleNewRoleAction, roles },
  } = useUsersManagementContext();

  const getTopbarButton = (): ITopbarButton => {
    return {
      buttonText: t('user:roles.new-role'),
      buttonAction: handleNewRoleAction,
      hasPermission: isAuthorized(Permission.ROLE_SUPPORT_WRITE),
    };
  };

  const { hasPermission, buttonText, buttonAction } = getTopbarButton();

  return (
    <>
      <Helmet>
        <title>{t('user:users-management')}</title>
      </Helmet>
      <TopbarWrapper
        type="main"
        left={<Breadcrumb currentPage={{ name: t('user:users-management') }} />}
        right={
          hasPermission && (
            <Button disabled={!!roles.length && !roles[0].id} onClick={buttonAction} Icon={PlusSvg} text={buttonText} />
          )
        }
      />
      <TopbarWrapper
        padding={`${theme.v2.spacing.l} ${theme.v2.spacing.l} 0 ${theme.v2.spacing.l}`}
        type="secondary"
        left={
          <Tabs
            onClickTab={onClickTab}
            tabs={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
            tabActive={activeTab}
          />
        }
        noBorder
      />
    </>
  );
};

export default TopbarRoles;
