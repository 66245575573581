import React from 'react';
import { Tooltip, Typography, ConditionalWrapper, ITypographyProps } from '@ratedpower/components';
import * as S from './home-project-info-row.styled';

interface IProps extends ITypographyProps {
  content?: string;
  label?: string;
}

const HomeProjectInfoRow = ({ content = '', label, ...typographyProps }: IProps) => {
  return (
    <ConditionalWrapper
      condition={content?.length > 20}
      wrapper={(children) => (
        <Tooltip description={content} place="top">
          {children}
        </Tooltip>
      )}
    >
      <Typography size={'s'} weight={label ? 'regular' : 'bold'} as={'p'} nowrap {...typographyProps}>
        {label && <S.LabelWithMargin>{label}</S.LabelWithMargin>}
        {content}
      </Typography>
    </ConditionalWrapper>
  );
};

export default HomeProjectInfoRow;
