import React, { useEffect, useState } from 'react';
import * as S from './design-comparison.styled';
import ComparisonTable from './comparison-table/comparison-table';
import ComparisonAttributesPanel from './attributes-panel/attributes-panel';
import { useModal } from 'utils/hooks/use-modal';
import ModalAddDesigns from './modal-add-designs/modal-add-designs';
import TopbarDesignComparison from './topbar-design-comparison';
import useSidebar from 'utils/hooks/use-sidebar';
import { useTranslation } from 'react-i18next';
import ComparisonCharts from './comparison-charts/comparison-charts';
import useComparisonActiveFilters from './attributes-panel/use-comparison-active-filters';
import {
  defaultChartFilters,
  defaultDesignComparisonAttributes as defaultTableFilters,
  DesignComparisonChartFilters as allChartFilters,
  DesignComparisonTableFilters as allTableFilters,
} from 'utils/constants/design-comparison';
import { useDesignComparisonData } from './design-comparison-state';
import { deepSearch } from 'utils/objects';
import { Button, ConditionalWrapper, ISegmentedControlItem, SegmentedControl, Tooltip } from '@ratedpower/components';
import { IComparisonFilter, IComparisonFilterGroup } from 'types/design-comparison.types';
import rudderstack from 'rudderstack/utils';
import { useRestClient } from 'utils/hooks/use-rest-client';
import saveAs from 'file-saver';
import { DownloadSvg, FilterSvg } from 'assets/icons';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { getApiUrl } from 'utils/api_url';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import ErrorForbidden from 'pages/error/error-forbidden/error-forbidden';
import { ComparisonExportType, RudderstackEvent } from 'rudderstack/types';

type ComparisonView = 'table' | 'chart';

const DesignComparison: React.FC = () => {
  const { t } = useTranslation();
  const [saveFiltersConfiguration, setSaveFiltersConfiguration] = useState(
    localStorage.getItem('tableFilters') ? true : false
  );
  const [saveChartFiltersConfiguration, setSaveChartFiltersConfiguration] = useState(
    localStorage.getItem('chartFilters') ? true : false
  );

  useSidebar({ collapsed: true });
  const { isAuthorized, isAuthorizedToPermissionSuffix } = useAuthorization();

  const [viewShown, setViewShown] = useState<ComparisonView>('table');
  const [showAttributesPanel, setShowAttributesPanel] = useState(false);
  const { selectedDesigns, project } = useDesignComparisonData();
  const { post } = useRestClient();

  const addDesignsModal = useModal();
  const profile = useSelector((state: RootState) => state.currentUser.profile);

  useEffect(() => {
    if (!saveFiltersConfiguration) {
      localStorage.removeItem('tableFilters');
    }
    if (saveFiltersConfiguration) {
      if (localStorage.getItem('tableFilters')) return;
      localStorage.setItem(
        'tableFilters',
        JSON.stringify(tableFilters.activeFilters.map((attribute) => attribute.label))
      );
    }
  }, [saveFiltersConfiguration]);

  useEffect(() => {
    if (!saveChartFiltersConfiguration) {
      localStorage.removeItem('chartFilters');
    }
    if (saveChartFiltersConfiguration) {
      if (localStorage.getItem('chartFilters')) return;
      localStorage.setItem(
        'chartFilters',
        JSON.stringify(chartFilters.activeFilters.map((attribute) => attribute.label))
      );
    }
  }, [saveChartFiltersConfiguration]);

  // Get saved table filters
  const savedTableFilters = localStorage.getItem('tableFilters');
  const parsedTableFilters = savedTableFilters && JSON.parse(savedTableFilters);
  const availableTableFilters: IComparisonFilter[] = savedTableFilters
    ? parsedTableFilters
        .map((attribute: any) => deepSearch(allTableFilters, 'key', (k: any, v: any) => v === attribute))
        .filter((attribute: any) => !!attribute)
    : defaultTableFilters;

  // Get saved chart filters
  const savedChartFilters = localStorage.getItem('chartFilters');
  const parsedChartFilters = savedChartFilters && JSON.parse(savedChartFilters);
  const availableChartFilters: IComparisonFilter[] = savedChartFilters
    ? parsedChartFilters.map((attribute: any) =>
        deepSearch(allChartFilters, 'key', (k: any, v: any) => v === attribute)
      )
    : defaultChartFilters[0].filters;
  // Filters
  const tableFilters: {
    availableFilters: IComparisonFilterGroup[];
    activeFilters: IComparisonFilter[];
    updateActiveFilters: React.Dispatch<React.SetStateAction<IComparisonFilter[]>>;
  } = useComparisonActiveFilters(allTableFilters, availableTableFilters);
  const chartFilters: {
    availableFilters: IComparisonFilterGroup[];
    activeFilters: IComparisonFilter[];
    updateActiveFilters: React.Dispatch<React.SetStateAction<IComparisonFilter[]>>;
  } = useComparisonActiveFilters(allChartFilters, availableChartFilters);
  const disableExportComparisonCondition =
    process.env.NODE_ENV === 'production' && !!project.companyId && project.companyId !== profile?.companyId;

  useEffect(() => {
    if (saveFiltersConfiguration) {
      localStorage.setItem(
        'tableFilters',
        JSON.stringify(tableFilters.activeFilters.map((attribute) => attribute.key))
      );
    }
  }, [tableFilters.activeFilters, saveFiltersConfiguration]);

  useEffect(() => {
    if (saveChartFiltersConfiguration) {
      localStorage.setItem(
        'chartFilters',
        JSON.stringify(chartFilters.activeFilters.map((attribute) => attribute.key))
      );
    }
  }, [chartFilters.activeFilters, saveChartFiltersConfiguration]);

  const switchItems: ISegmentedControlItem[] = [
    {
      label: t('datatable'),
      value: 'table',
      width: '147px',
    },
    {
      label: t('charts'),
      value: 'chart',
      width: '147px',
    },
  ];

  const getTranslates = () => {
    const tableTranslates: { field: string; translate: string }[] = [];
    allTableFilters.forEach((table) => {
      table.filters.forEach((filter) =>
        tableTranslates.push({
          field: filter.label,
          translate: t(`design:${filter.label}`),
        })
      );
    });

    const extraTranslates = [
      {
        field: 'design',
        translate: t('design:design'),
      },
      {
        field: 'structurePitchMax',
        translate: t('design:structurePitchMax'),
      },
      {
        field: 'structurePitchMin',
        translate: t('design:structurePitchMin'),
      },
      {
        field: 'gcr_min',
        translate: t('design:gcr_min'),
      },
      {
        field: 'gcr_max',
        translate: t('design:gcr_max'),
      },
      {
        field: 'currency',
        translate: t('design:currency'),
      },
      {
        field: 'energyMonthly',
        translate: t('design:comparison-charts.monthly-energy'),
      },
      {
        field: 'monthly',
        translate: t('common:monthly'),
      },
      {
        field: 'month.1',
        translate: t('common:month-1'),
      },
      {
        field: 'month.2',
        translate: t('common:month-2'),
      },
      {
        field: 'month.3',
        translate: t('common:month-3'),
      },
      {
        field: 'month.4',
        translate: t('common:month-4'),
      },
      {
        field: 'month.5',
        translate: t('common:month-5'),
      },
      {
        field: 'month.6',
        translate: t('common:month-6'),
      },
      {
        field: 'month.7',
        translate: t('common:month-7'),
      },
      {
        field: 'month.8',
        translate: t('common:month-8'),
      },
      {
        field: 'month.9',
        translate: t('common:month-9'),
      },
      {
        field: 'month.10',
        translate: t('common:month-10'),
      },
      {
        field: 'month.11',
        translate: t('common:month-11'),
      },
      {
        field: 'month.12',
        translate: t('common:month-12'),
      },
    ];

    return [...tableTranslates, ...extraTranslates];
  };

  const handleSendIds = async () => {
    if (viewShown === 'chart') {
      rudderstack.track(RudderstackEvent.COMPARISON_EXPORTED, { export_type: ComparisonExportType.CHART });
      const url = `${getApiUrl()}/simulations/comparison-excel-chart`;

      const body = {
        ids: selectedDesigns,
        charts: chartFilters.activeFilters.map((chartAttribute) => chartAttribute.label.split('comparison-charts.')[1]),
        translates: getTranslates(),
      };

      const response = await post(url, body, { responseType: 'blob' });
      if (response.data) {
        const blob = new Blob([response.data]);
        saveAs(blob, 'comparison_charts.xlsx');
      }
    } else {
      rudderstack.track(RudderstackEvent.COMPARISON_EXPORTED, { export_type: ComparisonExportType.TABLE });

      const url = `${getApiUrl()}/simulations/comparison-excel`;

      const body = {
        ids: selectedDesigns,
        fields: tableFilters.activeFilters.map((tableAttribute) => tableAttribute.label),
        translates: getTranslates(),
      };

      const response = await post(url, body, { responseType: 'blob' });
      if (response.data) {
        const blob = new Blob([response.data]);
        saveAs(blob, 'comparison.xlsx');
      }
    }
  };

  if (!isAuthorizedToPermissionSuffix('SIMULATION')) {
    return <ErrorForbidden />;
  }

  return (
    <S.DesignComparisonContainer>
      <TopbarDesignComparison onADesign={() => addDesignsModal.setModalOpen(true)} />
      <S.ComparisonContent>
        <S.ComparisonSubTab>
          <SegmentedControl
            items={switchItems}
            selectedValue={viewShown}
            onChange={(selectedItem) => {
              setViewShown(selectedItem.value as ComparisonView);
            }}
          />
          <S.ComparisonTopRightControls>
            {isAuthorized(Permission.COMPARISON_DOWNLOAD) && (
              <ConditionalWrapper
                condition={disableExportComparisonCondition}
                wrapper={(children) => (
                  <Tooltip description={t('design:export-button-disabled-tooltip')}>{children}</Tooltip>
                )}
              >
                <Button
                  onClick={handleSendIds}
                  text={t('common:export')}
                  variant="accent"
                  Icon={DownloadSvg}
                  disabled={disableExportComparisonCondition}
                />
              </ConditionalWrapper>
            )}
            <Button
              variant="ghost"
              text={String(
                t('filters.title') +
                  ' (' +
                  (viewShown === 'table' ? tableFilters.activeFilters.length : chartFilters.activeFilters.length) +
                  ')'
              )}
              Icon={() => <FilterSvg />}
              onClick={() => setShowAttributesPanel(true)}
            />
          </S.ComparisonTopRightControls>
        </S.ComparisonSubTab>
        <ComparisonAttributesPanel
          {...(viewShown === 'table' ? { ...tableFilters } : { ...chartFilters })}
          saveFiltersConfiguration={viewShown === 'table' ? saveFiltersConfiguration : saveChartFiltersConfiguration}
          setSaveFiltersConfiguration={
            viewShown === 'table' ? setSaveFiltersConfiguration : setSaveChartFiltersConfiguration
          }
          viewShown={viewShown}
          active={showAttributesPanel}
          hideAttributesPanel={() => setShowAttributesPanel(false)}
        />
        <S.ComparisonDesignsPanel className={viewShown === 'table' ? '' : 'hidden'}>
          <ComparisonTable
            updateActiveFilters={tableFilters.updateActiveFilters}
            activeAttributes={tableFilters.activeFilters}
          />
        </S.ComparisonDesignsPanel>

        {viewShown === 'chart' && (
          <S.ComparisonDesignsPanel className={viewShown === 'chart' ? '' : 'hidden'}>
            <ComparisonCharts
              activeAttributes={chartFilters.activeFilters}
              onAddDesign={() => addDesignsModal.setModalOpen(true)}
            />
          </S.ComparisonDesignsPanel>
        )}
      </S.ComparisonContent>

      {addDesignsModal.isOpen && (
        <ModalAddDesigns
          isActive={addDesignsModal.isOpen}
          size="l"
          onClose={() => addDesignsModal.setModalOpen(false)}
        />
      )}
    </S.DesignComparisonContainer>
  );
};

export default DesignComparison;
