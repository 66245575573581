import React, { useState } from 'react';
import { CartesianGrid, Label, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import DesignsCustomTooltip from '../designs-tooltip/designs-tooltip';
import { DesignComparisonGenericChartContainer } from '../design-comparison-generic-chart.styled';
import { Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { fourDigitNotationForLargeNumbers } from 'utils/numbers';

interface ITooltipShown {
  coordinates: { x: number; y: number };
  payload: any;
}

interface IChartAxisData {
  x: string;
  y: string;
  [key: string]: string;
}

interface IProps {
  title: string;
  data: any[];
  units: IChartAxisData;
  axisLabels: IChartAxisData;
  formattedData: any[];
  tooltipLabels: IChartAxisData;
}
const DesignComparisonScatterChartContainer: React.FC<IProps> = ({
  title,
  tooltipLabels,
  formattedData,
  axisLabels,
}) => {
  const theme = useTheme();
  const [tooltipShown, setTooltipShown] = useState<null | ITooltipShown>(null);

  return (
    <DesignComparisonGenericChartContainer>
      <Typography weight="bold" color={theme.v2.text.secondary.default}>
        {title}
      </Typography>

      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart margin={{ top: 8, right: 32, left: 32, bottom: 16 }}>
          <CartesianGrid stroke="#EEEEEE" strokeDasharray="5 5" />

          <Tooltip
            formatter={(value, name) => [value, tooltipLabels[name]]}
            content={<DesignsCustomTooltip currentTooltipShown={tooltipShown} />}
          />
          <XAxis dataKey="x" tickCount={6} type="number" domain={['auto', 'auto']} padding={{ right: 20, left: 20 }}>
            <Label
              position="insideBottom"
              offset={-15}
              style={{ fill: theme.v2.text.secondary.default }}
              value={axisLabels.x}
            />
          </XAxis>
          <YAxis
            dataKey="y"
            tickCount={6}
            type="number"
            domain={['auto', 'auto']}
            padding={{ top: 20, bottom: 20 }}
            tickFormatter={(value) => fourDigitNotationForLargeNumbers(value)}
          >
            <Label
              angle={-90}
              position="insideLeft"
              offset={-5}
              style={{ textAnchor: 'middle', fill: theme.v2.text.secondary.default }}
              value={axisLabels.y}
            />
          </YAxis>
          {formattedData &&
            formattedData.map((item) => (
              <Scatter
                key={item[0].name.join()}
                name="Design"
                data={item}
                fill={item[0].filterColor}
                onClick={({ tooltipPosition, payload }) => {
                  setTooltipShown({
                    payload,
                    coordinates: tooltipPosition,
                  });
                }}
              />
            ))}
        </ScatterChart>
      </ResponsiveContainer>
    </DesignComparisonGenericChartContainer>
  );
};

export default DesignComparisonScatterChartContainer;
