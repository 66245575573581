import React from 'react';
import MeteoIntegrationsForm from './integration-form';
import { Secret } from 'types/company.types';
import { IntegrationWrapper } from '../integrations.styled';
import Skeleton from 'react-loading-skeleton';
import { useIntegration } from './use-integration';

interface IProps {
  integrationSecret: Secret;
  companyId: string;
}
const Integration: React.FC<IProps> = ({ integrationSecret, companyId }) => {
  const {
    loading,
    isEditing,
    apiKey,
    handleDiscard,
    handleSave,
    config,
    checkAutoGenerate,
    toggleCheckAutogenerate,
    onEdit,
  } = useIntegration(integrationSecret, companyId);

  return (
    <>
      {loading && <Skeleton height={140} width="100%" />}
      {!loading && (
        <IntegrationWrapper>
          <MeteoIntegrationsForm
            isEditing={isEditing}
            data={{ apiKey }}
            onSaveIntegration={handleSave}
            onDiscard={handleDiscard}
            integrationSecret={config}
            checkAutoGenerate={checkAutoGenerate}
            toggleCheckAutogenerate={toggleCheckAutogenerate}
            onEdit={onEdit}
          />
        </IntegrationWrapper>
      )}
    </>
  );
};

export default Integration;
