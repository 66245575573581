import { useEffect, useRef, useState } from 'react';
import { GET_DESIGN_FILTERS } from 'services/designs-gql';
import { IOverlayFilter, useFilters } from 'utils/hooks/use-filters';
import { IServerFilter } from 'types/filter';
import { getFilter, getSelectedFilters } from 'utils/filters';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { track } from 'rudderstack/utils';
import { stringArraysSameElements } from 'utils/array';
import { DesignFilters, RudderstackEvent } from 'rudderstack/types';

const designFiltersTypes = {
  meteoSource: 'METEO_DATA_SOURCE',
  creator: 'CREATED_BY',
  simulationType: 'SIMULATION_TYPE',
  simulationCategory: 'SIMULATION_CATEGORY',
};

interface IUseDesignFilters {
  serverFilters: { filters: IServerFilter[] };
  toggleOverlay: () => void;
  overlayProps: {
    loading: boolean;
    filters: IOverlayFilter[];
    isOpen: boolean;
    onClear: () => void;
    onApply: () => void;
    onSelect: (filter: string, value: any, checked: boolean) => void;
  };
}
export const useDesignFilters = (selectedTab: string): IUseDesignFilters => {
  const { t } = useTranslation();
  const { id: projectId } = useParams<{ id: string }>();

  const [serverFilters, setServerFilters] = useState<{
    filters: IServerFilter[];
  }>({
    filters: [],
  });

  const selectedFilterCodesRef = useRef<string[]>([]);

  const {
    filters,
    setFilters,
    overlayOpen,
    setOverlayOpen,
    onClickFilterOption,
    clearFilters: clearOverlayFilters,
  } = useFilters();

  // Fetch project filters
  const { loading, data: fetchedData } = useQuery(GET_DESIGN_FILTERS, {
    variables: {
      projectId,
      creatorsPagination: {
        page: 0,
        size: 20,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!fetchedData) return;
    const { meteoSources, creators } = fetchedData;

    // Countries & No. Designs
    const newFilters: IOverlayFilter[] = [
      {
        title: t('design:meteo-source'),
        code: designFiltersTypes.meteoSource, // strings
        options: meteoSources.map((c: string) => ({ code: c, label: c, checked: false })),
      },
    ];

    // Type of designs
    newFilters.push({
      title: t('design:type-of-designs'),
      code: designFiltersTypes.simulationType,
      options: [
        {
          checked: false,
          code: 'SINGLE',
          label: t('design-process:batch.single-designs'),
        },
        {
          checked: false,
          code: 'BATCH_SIMULATION',
          label: t('design-process:batch.multiple-designs'),
        },
      ],
    });

    newFilters.push({
      title: t('design:category-of-designs'),
      code: designFiltersTypes.simulationCategory,
      options: [
        {
          checked: false,
          code: 'PV',
          label: t('design:pv'),
        },
        {
          checked: false,
          code: 'STANDALONE_BATTERY',
          label: t('project:standalone-bess'),
        },
      ],
    });

    // Creators
    if (!!creators && !!creators.content && !!creators.pageInfo) {
      const { content: fetchedCreators } = creators;

      newFilters.push({
        title: t('filters.creator'),
        code: designFiltersTypes.creator,
        options: fetchedCreators.map((e: any) => ({
          label: `${e.name} ${e.lastname}`,
          code: e.id,
          checked: false,
        })),
      });
    }

    setFilters(newFilters);
  }, [fetchedData]);

  function applyFilters() {
    const updatedServerFilters: { filters: IServerFilter[] } = { filters: [] };
    for (const filter of filters) {
      let serverFilter: IServerFilter[] = [];
      switch (filter.code) {
        case designFiltersTypes.meteoSource: {
          const meteoSourceFilter = getFilter(filter);
          serverFilter = meteoSourceFilter ? [meteoSourceFilter] : [];
          break;
        }
        case designFiltersTypes.creator: {
          const creatorFilter = getFilter(filter);
          serverFilter = creatorFilter ? [creatorFilter] : [];
          break;
        }
        case designFiltersTypes.simulationType: {
          const designTypeFilter = getFilter(filter);
          if (designTypeFilter?.values.includes('BATCH_SIMULATION')) designTypeFilter?.values.push('BATCH_CONTAINER');
          serverFilter = designTypeFilter ? [designTypeFilter] : [];
          break;
        }
        case designFiltersTypes.simulationCategory: {
          const categoryFilter = getFilter(filter);
          serverFilter = categoryFilter ? [categoryFilter] : [];
          break;
        }
      }

      if (!!serverFilter && !!serverFilter.length) {
        updatedServerFilters.filters = [...updatedServerFilters.filters, ...serverFilter];
      }
    }
    const selectedFilters = getSelectedFilters(filters);
    if (!selectedFilterCodesRef.current || !stringArraysSameElements(selectedFilters, selectedFilterCodesRef.current)) {
      rudderstackTrackDesignsFilters(selectedFilters);
    }
    setServerFilters({
      filters: updatedServerFilters.filters,
    });
    setOverlayOpen(false);
  }

  const rudderstackTrackDesignsFilters = (selectedFilterCodes: string[]) => {
    selectedFilterCodesRef.current = selectedFilterCodes;
    if (selectedFilterCodes.includes(designFiltersTypes.meteoSource)) {
      track(RudderstackEvent.APPLY_DESIGNS_FILTERS, { filter_applied: DesignFilters.METEO_SOURCE, tab: selectedTab });
    }
    if (selectedFilterCodes.includes(designFiltersTypes.simulationType)) {
      track(RudderstackEvent.APPLY_DESIGNS_FILTERS, { filter_applied: DesignFilters.TYPE_OF_DESIGN, tab: selectedTab });
    }
    if (selectedFilterCodes.includes(designFiltersTypes.creator)) {
      track(RudderstackEvent.APPLY_DESIGNS_FILTERS, { filter_applied: DesignFilters.CREATOR, tab: selectedTab });
    }
  };

  function clearFilters() {
    setServerFilters({ filters: [] });
    clearOverlayFilters();
  }

  return {
    serverFilters,
    toggleOverlay: () => setOverlayOpen(!overlayOpen),
    overlayProps: {
      loading,
      filters,
      isOpen: overlayOpen,
      onClear: () => clearFilters(),
      onApply: () => applyFilters(),
      onSelect: (filter, option, value) => onClickFilterOption(filter, option, value),
    },
  };
};
