import styled from 'styled-components';

export const MapStyleSelectorContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  z-index: 4;

  .option-list {
    border-radius: ${({ theme }) => theme.v2.radius.s};
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: ${({ theme }) => theme.v2.spacing.xxs};
    background-color: ${({ theme }) => theme.v2.bg.primary.default};

    ul {
      display: flex;
      gap: ${({ theme }) => theme.v2.spacing.xs};
      list-style-type: none;
      box-shadow: ${({ theme }) => theme.v2.elevation.shadow.m};
      border-radius: ${({ theme }) => theme.v2.radius.s};
      padding: ${({ theme }) => theme.v2.spacing.xs};
      margin: 0;
    }
  }
`;

export const SelectedContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.v2.radius.s};
  position: relative;
`;

export const SelectedContainerInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const SelectedContainerSvg = styled.p`
  text-align: right;

  > svg {
    color: ${({ theme }) => theme.v2.bg.primary.default};
    width: 13px;
  }
`;

export const SelectedContainerText = styled.div`
  padding: 0 2px 1px;

  > p {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 1));
    text-align: center;
    overflow: hidden;
  }
`;

export const SatelliteImageContainer = styled.div<{ isSelected: boolean }>`
  width: 45px;
  height: 45px;
  border-radius: 4px;
  box-shadow: ${({ theme, isSelected }) => (isSelected ? `inset 0px 0px 0px 1.8px ${theme.primary_60}` : 'none')};
  margin-bottom: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const SatelliteOption = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover ${SatelliteImageContainer} {
    box-shadow: ${({ theme }) => `inset 0px 0px 0px 1.8px ${theme.primary_60}`};
  }

  > p {
    text-align: center;
  }
`;

export const SwitchBtnContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  white-space: nowrap;
  gap: 16px;

  & > .separator {
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.gray_dark};
  }
`;
