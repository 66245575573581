import styled from 'styled-components';

export const OtherPlanContainer = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
  align-items: self-start;
`;

export const BenefitsItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BenefitsContainer = styled.ul`
  gap: ${({ theme }) => theme.v2.spacing.m};
  margin-bottom: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
  align-self: start;
`;
