import styled from 'styled-components';
import { DesignGridItemContainer } from '../design-grid-item.styled';

const DesignGridItemErrorContainer = styled(DesignGridItemContainer)`
  cursor: default;
  display: flex;
  flex-direction: column;
`;

export const ImageSpace = styled.div<{ $showDetails: boolean }>`
  border-radius: ${({ theme }) => theme.v2.radius.s};
  gap: ${({ theme }) => theme.v2.spacing.m};
  background: ${({ theme }) => theme.v2.error.bg.default};
  height: ${({ $showDetails }) => ($showDetails ? '535px' : '200px')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  height: 78.5px;
`;

export { DesignGridItemErrorContainer };
