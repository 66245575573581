import { useState } from 'react';

export interface IFilterOption {
  code: string;
  label: React.ReactElement | string;
  checked: boolean;
}

export interface IOverlayFilter {
  title: string;
  code: string;
  options: IFilterOption[];
}

export const useFilters = () => {
  const [filters, setFilters] = useState<IOverlayFilter[]>([]);
  const [overlayOpen, setOverlayOpen] = useState(false);

  const onClickFilterOption = (filter: string, value: any, checked: boolean) => {
    let newFilters = [...filters];
    newFilters = newFilters.map((e) => {
      if (e.code === filter) {
        e.options = e.options.map((option) => {
          if (option.code !== value) return option;
          return {
            ...option,
            checked,
          };
        });
      }
      return e;
    });
    setFilters(newFilters);
  };

  const handleSingleSelection = (filter: string, value: any, checked: boolean) => {
    let newFilters = [...filters];
    newFilters = newFilters.map((e) => {
      if (e.code === filter) {
        e.options = e.options.map((option) => {
          if (option.code !== value) return { ...option, checked: false };
          return {
            ...option,
            checked,
          };
        });
      }
      return e;
    });
    setFilters(newFilters);
  };

  const clearFilters = () => {
    let clearedFilters = [...filters];
    clearedFilters = clearedFilters.map((e) => {
      e.options = e.options.map((option) => {
        return {
          ...option,
          checked: false,
        };
      });
      return e;
    });
    setFilters(clearedFilters);
    setOverlayOpen(false);
  };

  const getFiltersActive = () => {
    return filters.reduce((sum, filter) => {
      const hasActiveOptions = filter.options.reduce((result, option) => {
        if (option.checked) return true;
        return result;
      }, false);
      if (hasActiveOptions) return sum + 1;
      return sum;
    }, 0);
  };

  return {
    filters,
    setFilters,
    overlayOpen,
    setOverlayOpen,
    onClickFilterOption,
    clearFilters,
    getFiltersActive,
    handleSingleSelection,
  };
};
