/***********************************************************************
_________________________________
# Usage of <FeaturedFlagsProvider>

const flags = [
  { name: 'vipOnly', isActive: false },
  { name: 'adminOnly', isActive: true }
];
ReactDOM.render(
  <FeaturedFlagsProvider value={flags}>
    <App />
  </FeaturedFlagsProvider>,
  document.getElementById('root')
);

______________________
# Use cases of <Flags>

## With children props
<Flags authorizedFlags={['adminOnly']}>
  <h1>For admin</h1>
</Flags>

## With the renderOn prop
<Flags authorizedFlags={['adminOnly']}
  renderOn={(authorizedFlags) => <h1>For admin</h1>}
/>

## With the the renderOn prop and renderOff prop as a fallback
<Flags authorizedFlags={['adminOnly']}
  renderOn={() => <h1>For admin</h1>}
  renderOff={() => <h1>For customers</h1>}
/>

## When all flags are required
<Flags
  exactFlags
  authorizedFlags={['flagA', 'flagB']}
  renderOn={() => <h1>visible when flagA AND flagB are active</h1>}
/>

## Optionally, you can pass active flags down to children components
<Flags
  exactFlags
  authorizedFlags={['flagA', 'flagB']}
  renderOn={(activeFlags) => <SomeComponent />}
/>

Note. Adaptation from https://github.com/romaindso/react-feature-flags

***********************************************************************/

import React, { PropsWithChildren } from 'react';

export type FlagType = {
  name: string;
  isActive: boolean;
};
export const FeaturedFlagsContext = React.createContext<FlagType[]>([]);

interface IFlags {
  authorizedFlags: string[];
  exactFlags?: boolean;
  renderOn?: (matchingFlags: FlagType[]) => React.ReactNode | undefined;
  renderOff?: (matchingFlags: FlagType[]) => React.ReactNode | undefined;
  children: React.ReactNode;
}
export const Flags = ({
  authorizedFlags,
  exactFlags = false,
  renderOn = () => undefined,
  renderOff = () => undefined,
  children,
}: IFlags) => {
  const getMatchingFlags = (flags: FlagType[]) => {
    return flags.filter((flag) => {
      return flag.isActive && authorizedFlags.includes(flag.name);
    });
  };

  const resolveRender = (matchingFlags: FlagType[]) => {
    return children ? children : renderOn(matchingFlags);
  };

  return (
    <FeaturedFlagsContext.Consumer>
      {(flags) => {
        const matchingFlags = getMatchingFlags(flags);
        if (exactFlags) {
          return matchingFlags.length === authorizedFlags.length
            ? resolveRender(matchingFlags)
            : renderOff(matchingFlags);
        } else {
          return matchingFlags.length ? resolveRender(matchingFlags) : renderOff(matchingFlags);
        }
      }}
    </FeaturedFlagsContext.Consumer>
  );
};

interface IFeaturedFlagsProvider {
  flags: FlagType[];
}
export const FeaturedFlagsProvider = ({ children, flags }: PropsWithChildren<IFeaturedFlagsProvider>) => {
  return <FeaturedFlagsContext.Provider value={flags}>{children}</FeaturedFlagsContext.Provider>;
};
