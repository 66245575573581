import { t } from 'i18next';
import { DESIGN_COMPARISON_CHARTS } from 'utils/constants/design-comparison';
import { IComparisonFilter } from 'types/design-comparison.types';
import { IDesignAttributes } from 'utils/constants/design-attributes';
import { IPropsDesignComparisonGCRScatterChart } from '../design-comparison-scatter-chart/design-comparison-gcr-scatter-chart';
import { ChartFunction } from './types';

export const graphicGCRScatterAttributes = (
  designs: any[],
  designAttributes: IDesignAttributes,
  activeAttribute: IComparisonFilter
): IPropsDesignComparisonGCRScatterChart | null => {
  const chartFunction = chartFunctions[activeAttribute.label];
  if (!chartFunction) return null;
  return chartFunction(designs, designAttributes);
};

const getPeakPowerVsGCRAttributes = (
  designs: any[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonGCRScatterChart => ({
  title: t('design:comparison-charts.peak-power-vs-gcr'),
  data: designs,
  xAttr: designAttributes.equipment.gcr,
  yAttr: designAttributes.power.peakPower,
  tooltipLabels: {
    x: t('design:gcr'),
    y: `Design's ${t('design:peakPower')}`,
  },
  formatUnits: (dimensionUnits: any) => ({
    x: '%',
    y: `${dimensionUnits.y}Wdc`,
  }),
  formatAxisLabels: (dimensionUnits: any) => ({
    x: `${t('design:gcr')} %`,
    y: `Design's ${t('design:peakPower')} (${dimensionUnits.y}Wdc)`,
  }),
});

const getEnergyVsGCRAttributes = (
  designs: any[],
  designAttributes: IDesignAttributes
): IPropsDesignComparisonGCRScatterChart => ({
  title: t('design:comparison-charts.energy-vs-gcr'),
  data: designs,
  xAttr: designAttributes.equipment.gcr,
  yAttr: designAttributes.energy.eneryYield,
  tooltipLabels: {
    x: t('design:gcr'),
    y: `Design's ${t('design:energy')}`,
  },
  formatUnits: (dimensionUnits: any) => ({
    x: '%',
    y: `${dimensionUnits.y}Wh`,
  }),
  formatAxisLabels: (dimensionUnits: any) => ({
    x: `${t('design:gcr')} %`,
    y: `Design's ${t('design:energy')} (${dimensionUnits.y}Wh)`,
  }),
});

const chartFunctions: {
  [key: string]: ChartFunction<IPropsDesignComparisonGCRScatterChart>;
} = {
  [DESIGN_COMPARISON_CHARTS.peakPowerVsGCR]: getPeakPowerVsGCRAttributes,
  [DESIGN_COMPARISON_CHARTS.energyVsGCR]: getEnergyVsGCRAttributes,
};
