import styled from 'styled-components';

export const DesignItemContainer = styled.li<{ $designWithError?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
  background-color: ${({ theme, $designWithError }) =>
    $designWithError ? theme.v2.error.bg.default : theme.v2.bg.default};
  display: flex;
  cursor: pointer;

  &:not(.sort-controls) {
    flex-direction: column;
  }

  .main-row {
    padding: ${({ theme }) => theme.v2.spacing.xs};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .editable-title {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  .detail-row {
    padding-right: ${({ theme }) => theme.v2.spacing.l};
    padding-left: ${({ theme }) => theme.v2.spacing.l};
    display: flex;
    min-height: 310px;
    overflow: hidden;
    transition: height 250ms ease-out, min-height 250ms ease-out, overflow 250ms ease-out, transform 250ms ease-out;

    &.hidden {
      padding: 0;
      height: 0;
      min-height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
`;
