import React from 'react';
import { Typography } from '@ratedpower/components';
import { useConverter } from 'utils/conversor';
import { IBessDesign } from 'types/design';
import { bessDesignAttributes } from 'utils/constants/bess-design-attributes';

export const BessDesignGridItemHeaderDetails: React.FC<{ item: IBessDesign }> = ({ item }) => {
  const { getValueOf } = useConverter();

  return (
    <div className="card-main-row">
      <Typography size="s">{getValueOf(bessDesignAttributes.bess.energyCapacity, item)}</Typography>
      <Typography size="s">{getValueOf(bessDesignAttributes.bess.activePowerAc, item)}</Typography>
      <Typography size="s">{getValueOf(bessDesignAttributes.site.installedArea, item)}</Typography>
    </div>
  );
};
