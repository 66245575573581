import React from 'react';
import * as S from './projects-list.styled';
import { useAuthorization } from 'access-control/can';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import NoResultsFilter from 'components/no-results-filter/no-results-filter';
import ZeroCreated from 'components/error-components/zero-created/zero-created';
import { IProjectListItem } from 'types/projects';
import { ISort } from 'types/filter';
import { LayersSvg } from 'assets/icons';
import { Permission } from 'access-control/permissions';
import { RatedPowerLoader, RPTable, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';
import { useTheme } from 'styled-components';
import { projectListColumns } from './project-list-constants';
import TeamsSelector from 'components/teams-selector';
import { RootState } from 'redux/root-reducer';
import { useSelector } from 'react-redux';
import { useListTeams } from 'pages/teams/hooks/use-list-teams';
import { useNavigate } from 'react-router-dom';
import { buildProjectUrl } from 'utils/navigation/navigation';

interface IProps {
  onEditName: (newName: string, id: string) => void;
  onPerform: (action: string, item: IProjectListItem) => void;
  onSort: (sortBy: ISort) => void;
  onClearFilters: () => void;
  onChangeTeamSelected: (teamId: string) => void;
  loading: boolean;
  paginationComponent: string | React.ReactNode;
  projects: IProjectListItem[];
  projectsError: boolean;
  selectedTab: string;
  sortedBy: ISort;
  zeroProjectsCreated: boolean;
  noFiltersApplied: boolean;
  hasFoundResultsBanner: boolean;
}

const ProjectsList: React.FC<IProps> = ({
  onEditName,
  onPerform,
  onSort,
  onClearFilters,
  loading,
  paginationComponent,
  projects,
  projectsError,
  selectedTab,
  sortedBy,
  zeroProjectsCreated,
  noFiltersApplied,
  hasFoundResultsBanner,
  onChangeTeamSelected,
}) => {
  const { isAuthorized } = useAuthorization(),
    { t } = useTranslation(),
    theme = useTheme(),
    navigate = useNavigate(),
    profile = useSelector((state: RootState) => state.currentUser.profile),
    { teams } = useListTeams({ infiniteScroll: false, pageSize: 30 }),
    table: Table<IProjectListItem> = useReactTable({
      data: projects,
      columns: projectListColumns(t, theme, {
        sortedBy,
        onSort,
        onPerform,
        onEditName,
        userId: profile?.id,
        companyHasTeams: !!teams?.length,
        canAlwaysEdit:
          isAuthorized(Permission.PROJECT_SUPPORT_WRITE) || isAuthorized(Permission.PROJECT_WRITE_IN_USER_COMPANY),
        canEditInUserTeam: isAuthorized(Permission.PROJECT_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR),
        canEditOnlyOwnProjects: isAuthorized(Permission.PROJECT_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR),
      }),
      getCoreRowModel: getCoreRowModel(),
    }),
    teamsSelectorPadding = `0 0 ${theme.v2.spacing.l} 0`;

  if (loading) return <RatedPowerLoader />;
  if (projectsError) return <ErrorRetrievingData />;
  if (zeroProjectsCreated) return <ZeroCreated selectedTab={selectedTab} Icon={LayersSvg} message="project" />;

  return (
    <S.ProjectsListContainer>
      <TeamsSelector padding={teamsSelectorPadding} teams={teams} onChangeTeam={onChangeTeamSelected} addPublicOption />
      {hasFoundResultsBanner && (
        <S.FoundResultsContainer noPadding={!!teams.length}>
          <Typography as={'h2'} weight={'bold'}>
            {t('project:filtered-results')}:
          </Typography>
        </S.FoundResultsContainer>
      )}
      {projects.length === 0 && !noFiltersApplied ? (
        <NoResultsFilter onClick={onClearFilters} />
      ) : (
        <RPTable
          table={table as any}
          onRowClick={(row) => navigate(buildProjectUrl(row.id))}
          zeroResultsFoundText={t('zero-results-found')}
        />
      )}

      {paginationComponent}
    </S.ProjectsListContainer>
  );
};

export default ProjectsList;
