import React from 'react';
import { SVGWrapper, Typography } from '@ratedpower/components';
import { HomeContainer, HomeTitleContainer } from 'pages/home/home.styled';
import { useTranslation } from 'react-i18next';
import { WavingHandSvg } from 'assets/icons';
import HomeProjectsMap from 'pages/home/home-projects-map/home-projects-map';
import HomeDesigns from 'pages/home/home-designs/home-designs';
// import { useFlags } from 'launchdarkly-react-client-sdk';

const Home: React.FC = () => {
  const { t } = useTranslation();
  // TODO - Feature flag example
  // const flags = useFlags();
  // if (flags.skUsPoc) return <>Show me this new feature</>;
  return (
    <>
      <HomeContainer>
        <HomeTitleContainer>
          <div className="title">
            <SVGWrapper icon={WavingHandSvg} size={'m'} svgDimensions={{ width: '24px', height: '24px' }} />
            <Typography size={'xl'}>{t('home:page-title')}</Typography>
          </div>
          <Typography size={'m'} as={'div'}>
            {t('home:subtitle')}
          </Typography>
        </HomeTitleContainer>
        <HomeProjectsMap />
        <HomeDesigns />
      </HomeContainer>
    </>
  );
};

export default Home;
