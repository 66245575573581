import { useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS, GET_UNREAD_NOTIFICATIONS, MARK_NOTIFICATION_AS_UNREAD } from 'services/notifications-gql';

const useMarkNotificationAsUnRead = (notificationId: string, onCompleted: () => void, onError: () => void) => {
  const [markNotificationAsUnRead, { error }] = useMutation(MARK_NOTIFICATION_AS_UNREAD, {
    variables: {
      notificationId,
    },
    refetchQueries: [GET_UNREAD_NOTIFICATIONS, GET_NOTIFICATIONS],
    onCompleted,
    onError,
  });

  return { markNotificationAsUnread: markNotificationAsUnRead, error };
};

export default useMarkNotificationAsUnRead;
