import { IUnits } from 'types/preferences.types';

export const unitaryMassUnits: IUnits = {
  INTERNATIONAL: {
    L: {
      unit: 'kg/km',
      toShow: (value) => value * 1e3,
      toSend: (value) => value * 1e-3,
    },
    M: {
      unit: 'kg/m',
      toShow: (value) => value,
      toSend: (value) => value,
    },
  },
  IMPERIAL: {
    L: {
      unit: 'lb/ft',
      toShow: (value) => value / 1.48816,
      toSend: (value) => value * 1.48816,
    },
    M: {
      unit: 'lb/ft',
      toShow: (value) => value / 1.48816,
      toSend: (value) => value * 1.48816,
    },
  },
};
