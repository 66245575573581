import { Separator } from '@ratedpower/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => `${theme.v2.spacing.xl} 0`};
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const VerticalSeparator = styled(Separator).attrs({
  marginLeft: '10px',
  marginRight: '10px',
  position: 'vertical',
  height: '20px',
})``;

export const IconsWrapper = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  align-items: center;
`;

export const SelectedDesignsWrapper = styled.div`
  margin-left: ${({ theme }) => theme.v2.spacing.xs};
`;
