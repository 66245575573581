import React from 'react';
import { useTranslation } from 'react-i18next';
import { IModalProps, Modal, Typography } from '@ratedpower/components';

interface IConfirmDeleteModalProps extends IModalProps {
  handleDeletePlan: () => void;
}

const ConfirmDeleteModal: React.FC<IConfirmDeleteModalProps> = ({ isActive, onClose, handleDeletePlan }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('company:confirm-delete-plan-modal-title')}
      isActive={isActive}
      onClose={onClose}
      status="error"
      disableClickOutside
      buttons={{
        secondary: {
          text: t('action:cancel'),
          onClick: onClose,
        },
        primary: {
          text: t('action:delete'),
          onClick: handleDeletePlan,
        },
      }}
    >
      <Typography>{t('company:confirm-delete-plan-modal-description')}</Typography>
    </Modal>
  );
};

export { ConfirmDeleteModal };
