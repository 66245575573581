export interface ILinkItemNotification {
  link: string;
  title: string;
}

export interface ILinkItemNotificationRecord {
  [key: number]: ILinkItemNotification;
}

export const DefaultLinkItemNotification: ILinkItemNotification = {
  link: '',
  title: '',
};