import { useQuery } from '@apollo/client';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { GET_TEAM_MANAGER_TEAMS_MEMBERS } from 'services/team-gql';

export const useTeamManagersTeamsMembers = () => {
  const { isAuthorized } = useAuthorization();
  const { data } = useQuery(GET_TEAM_MANAGER_TEAMS_MEMBERS, {
    fetchPolicy: 'network-only',
    skip: !isAuthorized(Permission.TEAM_READ_FROM_USER_TEAM),
  });

  return {
    data: data?.getTeamManagerTeamsMembers?.map((teamId: { id: string }) => teamId.id) || [],
  };
};
