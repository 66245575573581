import styled from 'styled-components';

export const PvPlansContainer = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: flex-start;
  overflow-x: auto;
`;

interface IProps {
  color: 'purple' | 'yellow_dark' | 'primary' | 'secondary' | 'red';
  size: 'big' | 'small';
}

export const PvPlanContent = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: max-content;
`;

export const PvOtherPlanContent = styled(PvPlanContent)`
  padding-top: 10px;
`;

export const PvPlanCard = styled.div<IProps>`
  background: ${({ theme }) => theme.v2.bg.primary.default};
  padding: ${({ theme }) => theme.v2.spacing.m};
  flex-shrink: 0;
  width: ${({ size }) => (size === 'small' ? '320px' : '250px')};
  border-radius: 5px;
  position: relative;
  height: max-content;
  overflow-y: hidden;
  border-top: 0;

  .request-more-info-btn {
    margin: 0 auto;
  }

  .current-plan-title {
    margin-bottom: 14px;
  }
`;
