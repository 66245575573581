import React from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'access-control/permissions';
import Can from 'access-control/can';
import { Button, Typography } from '@ratedpower/components';
import { DetailsInfoHeaderContainer } from './details-info-header.styled';

interface IProps {
  isEditing: boolean;
  onEdit: () => void;
}

const DetailsInfoHeader: React.FC<IProps> = ({ isEditing, onEdit }) => {
  const { t } = useTranslation();

  return (
    <DetailsInfoHeaderContainer>
      <Typography weight="bold" size="l">
        {t('user:account-info')}
      </Typography>
      {!isEditing && (
        <Can perform={Permission.COMPANY_WRITE}>
          <Button textUnderlined={true} variant="link" onClick={() => onEdit()} text={t('action:edit')} />
        </Can>
      )}
    </DetailsInfoHeaderContainer>
  );
};

export default DetailsInfoHeader;
