import gql from 'graphql-tag';

const fragments = {
  supportPlanCommon: gql`
    fragment SupportPlanCommon on SubscriptionPlanDTO {
      id
      plan
      customPlanType
      notes
      price
      projectsPurchased
      activeUsers
      currency
      usersCreated
      projectsDone
      designsDone
      startDate
      expirationDate
      originalExpirationDate
      publishedDate
      unlimitedProjects
      modificationType
    }
  `,
};

export const GET_SUPPORT_CURRENT_PLAN = gql`
  query CurrentSubscriptionPlanSupport($companyId: ID!) {
    currentSubscriptionPlanSupport(companyId: $companyId) {
      ...SupportPlanCommon
    }
  }
  ${fragments.supportPlanCommon}
`;

export const GET_CURRENT_PLAN = gql`
  query CurrentSubscriptionPlan($companyId: ID!) {
    result: currentSubscriptionPlan(companyId: $companyId) {
      id
    }
  }
`;

export const GET_SUPPORT_PLANS = gql`
  query SubscriptionPlansSupport($companyId: ID!, $pagination: PaginationForm!, $sorting: [SortOrder]) {
    result: subscriptionPlansSupport(companyId: $companyId, pagination: $pagination, sorting: $sorting) {
      content {
        ...SupportPlanCommon
        extensions {
          id
          expirationDate
          publishedDate
          price
          projectsPurchased
          projectsDone
          designsDone
        }
        status
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.supportPlanCommon}
`;

export const GET_PLANS = gql`
  query SubscriptionPlans($pagination: PaginationForm!, $sorting: [SortOrder]) {
    result: subscriptionPlans(pagination: $pagination, sorting: $sorting) {
      content {
        id
        plan
        extensions {
          id
          expirationDate
          publishedDate
          projectsPurchased
          projectsDone
          designsDone
        }
        projectsPurchased
        projectsDone
        designsDone
        startDate
        expirationDate
        publishedDate
        originalExpirationDate
        unlimitedProjects
        modificationType
        usersCreated
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CreateSubscriptionPlan(
    $companyId: ID!
    $subscriptionPlanCreateDTO: SubscriptionPlanCreateDTO!
    $sendWelcomeEmail: Boolean!
  ) {
    createSubscriptionPlan(
      companyId: $companyId
      subscriptionPlanCreateDTO: $subscriptionPlanCreateDTO
      sendWelcomeEmail: $sendWelcomeEmail
    ) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation UpdateSubscriptionPlan($subscriptionPlanUpdateDTO: SubscriptionPlanUpdateDTO!, $sendWelcomeEmail: Boolean!) {
    updateSubscriptionPlan(subscriptionPlanUpdateDTO: $subscriptionPlanUpdateDTO, sendWelcomeEmail: $sendWelcomeEmail) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN_EXTENSION = gql`
  mutation UpdateSubscriptionPlanExtension($subscriptionPlanExtensionUpdateDTO: SubscriptionPlanExtensionUpdateDTO!) {
    updateSubscriptionPlanExtension(subscriptionPlanExtensionUpdateDTO: $subscriptionPlanExtensionUpdateDTO) {
      id
    }
  }
`;

export const UPGRADE_CURRENT_PLAN = gql`
  mutation UpgradeCurrentPlan(
    $companyId: ID!
    $subscriptionPlanUpdateDTO: SubscriptionPlanUpdateDTO!
    $sendWelcomeEmail: Boolean!
  ) {
    upgradeCurrentPlan(
      companyId: $companyId
      subscriptionPlanUpdateDTO: $subscriptionPlanUpdateDTO
      sendWelcomeEmail: $sendWelcomeEmail
    ) {
      id
    }
  }
`;

export const UPGRADE_CHANGE_PLAN = gql`
  mutation UpgradeChangePlan(
    $companyId: ID!
    $subscriptionPlanCreateDTO: SubscriptionPlanCreateDTO!
    $sendWelcomeEmail: Boolean!
  ) {
    upgradeChangePlan(
      companyId: $companyId
      subscriptionPlanCreateDTO: $subscriptionPlanCreateDTO
      sendWelcomeEmail: $sendWelcomeEmail
    ) {
      id
    }
  }
`;

export const RENEW_SUBSCRIPTION_PLAN = gql`
  mutation RenewSubscriptionPlan(
    $companyId: ID!
    $subscriptionPlanCreateDTO: SubscriptionPlanCreateDTO!
    $sendWelcomeEmail: Boolean!
  ) {
    renewSubscriptionPlan(
      companyId: $companyId
      subscriptionPlanCreateDTO: $subscriptionPlanCreateDTO
      sendWelcomeEmail: $sendWelcomeEmail
    ) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN_STATUS = gql`
  mutation UpdateSubscriptionPlanStatus($subscriptionPlanUpdateStatusDTO: SubscriptionPlanUpdateStatusDTO!) {
    updateSubscriptionPlanStatus(subscriptionPlanUpdateStatusDTO: $subscriptionPlanUpdateStatusDTO) {
      id
    }
  }
`;

export const DELETE_SUBSCRIPTION_PLAN = gql`
  mutation DeleteSubscriptionPlan($planId: ID!) {
    deleteSubscriptionPlan(planId: $planId) {
      id
    }
  }
`;
