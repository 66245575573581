import { gql } from '@apollo/client';

const NOTIFICATION_RESPONSE_FIELDS = `
  id
  title
  description
  iconName
  notificationRead
  publishedDate
  knowledgeBaseArticles {
    id
    title
    link
    __typename @skip(if: true)
  }
  notificationSubsections {
    id
    title
    description
    __typename @skip(if: true)
  }
`;

export const GET_UNREAD_NOTIFICATIONS = gql`
  query getUnreadNotificationsCount {
    getUnreadNotificationsCount
  }
`;

export const GET_NOTIFICATIONS = gql`
  query Notifications($pagination: PaginationForm!, $sorting: [SortOrder], $notificationReadStatus: NotificationReadStatus!) {
    notifications(pagination: $pagination, sorting: $sorting, notificationReadStatus: $notificationReadStatus) {
      content {
        ${NOTIFICATION_RESPONSE_FIELDS}
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation setAllNotificationsAsRead($pagination: PaginationForm!) {
    setAllNotificationsAsRead(pagination: $pagination) {
      content {
        ${NOTIFICATION_RESPONSE_FIELDS}
      }
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification($createNotificationDTO: CreateNotificationDTO!) {
    createNotification(createNotificationDTO: $createNotificationDTO) {
      ${NOTIFICATION_RESPONSE_FIELDS}
    }
  }
`;

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation setNotificationAsRead($notificationId: ID!) {
    setNotificationAsRead(notificationId: $notificationId) {
      ${NOTIFICATION_RESPONSE_FIELDS}
    }
  }
`;

export const MARK_NOTIFICATION_AS_UNREAD = gql`
  mutation setNotificationAsUnread($notificationId: ID!) {
    setNotificationAsUnread(notificationId: $notificationId) {
      ${NOTIFICATION_RESPONSE_FIELDS}
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($notificationId: ID!) {
    deleteNotification(notificationId: $notificationId)
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($updateNotificationDTO: UpdateNotificationDTO!) {
    updateNotification(updateNotificationDTO: $updateNotificationDTO) {
      ${NOTIFICATION_RESPONSE_FIELDS}
    }
  }
`;
