import styled from 'styled-components';

export const DesignComparisonGenericChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding: 1.2rem 0.6rem;
  box-sizing: border-box;
  position: relative;
`;

export const ChartTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const DesignComparisonSortSelector = styled.div`
  position: absolute;
  right: 36px;
`;
