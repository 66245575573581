import React, { useEffect, useState } from 'react';
import * as S from './create-project-modal.styled';
import { Button, Dropdown, IModalProps, InputText, LabelWrapper, Modal } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { useListTeams } from '../../hooks/use-list-teams';
import { ModalButtons } from '../../teams-tab.styled';
import { useCreateProject } from 'utils/hooks/use-create-project';
import { IOption } from 'types/common';
import { ITeam } from 'types/teams';
import { PUBLIC_TEAMS_OPTION } from 'utils/constants/users';

interface IProps extends IModalProps {
  selectedTeamId?: string | null;
  addPublicOption: boolean;
  isSupport?: boolean;
  onSubmitCallback?: (response: any) => void;
}

const CreateProjectModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { teams } = useListTeams({ infiniteScroll: true, pageSize: 30 });
  const { addPublicOption } = props;
  const [inputName, setInputName] = useState('');
  const [disabled, setDisabled] = useState(true);

  const { handleSave, formError, setSelectedTeamId, isFormValid, loading } = useCreateProject(
    false,
    !!props.isSupport,
    (response) => {
      props.onClose();
      if (props.onSubmitCallback) {
        props.onSubmitCallback(response);
      }
    },
    props.selectedTeamId
  );

  const teamOptions: IOption[] = teams?.map((team: ITeam) => ({ label: team.name, value: team.id }));
  const companyHasTeams = !!teams?.length;
  const thereAreErrors = !!formError;
  const isPublicTeamSelected = addPublicOption && teamOptions;
  const publicOption = { label: PUBLIC_TEAMS_OPTION(t).label, value: null };
  const [dropdownValue, setDropdownValue] = useState<IOption[]>([]);

  useEffect(() => {
    setDropdownValue(addPublicOption && teamOptions?.length > 0 ? [publicOption] : []);
  }, [addPublicOption, teams]);

  if (isPublicTeamSelected) teamOptions.unshift(publicOption);

  const handleTeamChange = (selectedTeamOption: IOption[]): void => {
    setSelectedTeamId(selectedTeamOption[0].value);
    setDropdownValue(selectedTeamOption);
  };

  const handleInputChange = (value: string): void => {
    setInputName(value);
    setDisabled(!isFormValid(value));
  };

  return (
    <Modal {...props}>
      <form onSubmit={handleSave}>
        <S.CreateProjectFormInputsWrapper>
          <LabelWrapper title={`${t('project:project-name')}*`}>
            <InputText
              autofocus
              warning={thereAreErrors}
              helperText={disabled ? t(formError) : ''}
              name="name"
              size={'m'}
              placeholder={t('project:project-name-placeholder')}
              value={inputName}
              onChange={handleInputChange}
            />
          </LabelWrapper>
          {companyHasTeams && (
            <LabelWrapper title={`${t('user:teams.team')}`}>
              <Dropdown
                name="teamId"
                options={teamOptions}
                placeholder={t('project:project-team-placeholder')}
                size={'m'}
                onChange={handleTeamChange}
                value={dropdownValue}
              />
            </LabelWrapper>
          )}
        </S.CreateProjectFormInputsWrapper>
        <ModalButtons>
          <Button variant="ghost" text={t('action:cancel')} onClick={props.onClickSecondaryBtn} />
          <Button text={t('action:create')} type="submit" variant="accent" disabled={disabled || loading} />
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default CreateProjectModal;
