import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import React, { ReactNode, useContext } from 'react';
import { ITab } from '@ratedpower/components';
import { IUseRolesObject, IUseUsersObject } from 'types/users';
import { usersManagementTabs } from 'utils/constants/users';
import { useRoles } from './roles-tab/hooks/use-roles';
import { useUsers } from './users-tab/hooks/use-users';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export interface ContextState {
  tabs: ITab[];
  useUsersObject: IUseUsersObject;
  useRolesObject: IUseRolesObject;
}

export const Context = React.createContext<ContextState | null>(null);

export const useUsersManagementContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useUsersManagementContext must be used within a UsersManagementProvider tag');
  }
  return contextState;
};

export const UsersManagementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const useUsersObject: IUseUsersObject = useUsers();
  const { userRoleSummary } = useUsersObject;
  const useRolesObject: IUseRolesObject = useRoles(userRoleSummary);
  const {
    currentUser: { profile },
  } = useSelector((state: RootState) => state);

  const { isAuthorized } = useAuthorization();
  const tabs = checkTabAccess(usersManagementTabs);

  function checkTabAccess(allTabs: ITab[]): ITab[] {
    const canAccessReadOnlyRolesTab = ['Support limited', 'Product General', 'Admin'].includes(
      profile?.roleName as string
    );
    return allTabs.filter((tab) => {
      if (tab.id === usersManagementTabs[0].id) {
        return (
          (isAuthorized(Permission.USER_SUPPORT_READ) ||
            isAuthorized(Permission.USER_READ_FROM_USER_COMPANY) ||
            isAuthorized(Permission.USER_READ_FROM_USER_TEAM)) &&
          tab
        );
      }
      if (tab.id === usersManagementTabs[1].id) {
        return (
          (isAuthorized(Permission.TEAM_SUPPORT_READ) ||
            isAuthorized(Permission.TEAM_READ_FROM_USER_COMPANY) ||
            isAuthorized(Permission.TEAM_READ_FROM_USER_TEAM)) &&
          tab
        );
      }
      if (isAuthorized(Permission.ROLE_SUPPORT_READ) || canAccessReadOnlyRolesTab) {
        return tab;
      }
    });
  }

  return (
    <Context.Provider
      value={{
        tabs,
        useUsersObject,
        useRolesObject,
      }}
    >
      {children}
    </Context.Provider>
  );
};
