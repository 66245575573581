export const removeItemAtIndex = <T>(arr: T[], index: number): T[] => {
  if (index < 0 || index >= arr.length) {
    throw new Error('Index out of bounds');
  }

  const newArray: T[] = arr.slice();

  newArray.splice(index, 1);

  return newArray;
};

export const stringArraysEqual = (arr1: string[], arr2: string[]) => {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Iterate through the arrays and compare each element
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If the loop completes, the arrays are equal
  return true;
};

export const stringArraysSameElements = (arr1: string[], arr2: string[]) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  return [...set1].every((element) => set2.has(element));
};
