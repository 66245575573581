import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@ratedpower/components';
import { NewTabSvg } from 'assets/icons';
import { GET_PROJECT_INFO } from 'services/projects-gql';
import { useTheme } from 'styled-components';
import * as S from './company-info-with-link.styled';

const CompanyInfoWithLink: React.FC<{ projectId: string; isDesignProcess?: boolean }> = ({
  projectId,
  isDesignProcess,
}) => {
  const theme = useTheme();

  const { data: projectInfo, loading } = useQuery(GET_PROJECT_INFO, {
    variables: { id: projectId },
    fetchPolicy: 'cache-first',
  });

  if (loading && !projectInfo) return null;
  return (
    <S.CompanyInfoWithLinkContainer>
      <Link
        className="company-info-link"
        target="_blank"
        rel="noopener noreferrer"
        to={`/companies-activity/${projectInfo?.project?.company?.id}`}
      >
        <Typography color={theme.primary}>{projectInfo?.project?.company?.name}</Typography>
        <NewTabSvg />
      </Link>
    </S.CompanyInfoWithLinkContainer>
  );
};

export default CompanyInfoWithLink;
