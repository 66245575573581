import { useState, useEffect } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { usePaginationWithQuery } from './use-pagination-with-query';

export interface IUseCustomQuery {
  loading: boolean;
  data: any;
  infiniteData: any;
  refetch: () => void;
  error: any;
  stopPolling: () => void;
  startPolling: (newValue: any) => void;
  paginationComponent: any;
  currentPage: number;
}

export const useCustomQuery = (
  query: any,
  queryObject: any,
  infiniteScroll = false,
  carousel = false
): IUseCustomQuery => {
  const [{ totalPages, currentPage }, setPagination] = useState({
    totalPages: 0,
    currentPage: 1,
  });
  const [infiniteData, setInfiniteData] = useState<any>();
  const [isRefetchingAll, setIsRefetchingAll] = useState(false);

  const { loading, data, refetch, error, stopPolling, startPolling, networkStatus } = useQuery(query, {
    ...queryObject,
    notifyOnNetworkStatusChange: true,
  });
  const {
    updateTotalPages,
    paginationComponent,
    handlePageChange,
    infiniteScrollComponent,
    carouselPaginationComponent,
  } = usePaginationWithQuery(totalPages, currentPage, setPagination, loading);

  // Infinite Loading Logic
  useEffect(() => {
    if (!infiniteScroll) return;
    if (!data || !data.result) return;
    const dataPageInfo = data.result.pageInfo;
    if (data || data.result.content || dataPageInfo) {
      if (!infiniteData) {
        setInfiniteData(data);
      }
      if (infiniteData) {
        if (dataPageInfo.pageNumber === 0) {
          setInfiniteData(data);
        }
        if (!loading && infiniteData.result.pageInfo.pageNumber !== dataPageInfo.pageNumber) {
          setInfiniteData({
            ...data,
            result: {
              content: [...infiniteData.result.content, ...data.result.content],
              pageInfo: { ...dataPageInfo },
            },
          });
        }
      }
    }
  }, [data, infiniteScroll]);

  useEffect(() => {
    // Avoid multiple queries
    if (!loading && data) {
      const fetchedData: any = Object.values(data)[0];
      if (!fetchedData || !fetchedData?.pageInfo) return;
      const {
        pageInfo: { pageNumber },
      } = fetchedData;
      if (currentPage - 1 === pageNumber) return;
    }
    if (!queryObject.skip) {
      refetch({
        ...queryObject.variables,
        pagination: {
          page: currentPage - 1,
          size: queryObject.variables.pagination.size,
        },
      });
    }
  }, [currentPage]);

  // Total Pages update
  useEffect(() => {
    if (!loading && data) {
      const fetchedData: any = Object.values(data)[0];
      if (!fetchedData || !fetchedData?.pageInfo) return;
      const {
        pageInfo: { totalPages: newTotalPages },
      } = fetchedData;
      updateTotalPages(newTotalPages);
    }
  }, [data, currentPage, loading]);

  // Handling page update based on networkStatus (for search, filters)
  useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) return;
    if (data) {
      const fetchedData: any = Object.values(data)[0];
      if (!fetchedData || !fetchedData?.pageInfo) return;
      const {
        pageInfo: { pageNumber },
      } = fetchedData;
      if (currentPage - 1 !== pageNumber && !isRefetchingAll) {
        handlePageChange(1);
        setInfiniteData(data);
      }
    }
  }, [data, networkStatus]);

  const refetchAllFetched = async () => {
    const nPagesFetched = infiniteData.result.pageInfo.pageNumber;
    setInfiniteData(undefined);
    setIsRefetchingAll(true);
    for (let page = 0; page <= nPagesFetched; page++) {
      await refetch({
        ...queryObject.variables,
        pagination: {
          page,
          size: queryObject.variables.pagination.size,
        },
      });
    }
    setIsRefetchingAll(false);
  };

  const getPaginationComponent = () => {
    if (infiniteScroll) return infiniteScrollComponent;
    if (carousel) return carouselPaginationComponent;
    return paginationComponent;
  };

  return {
    loading,
    infiniteData,
    refetch: infiniteScroll ? refetchAllFetched : refetch,
    error,
    stopPolling,
    startPolling,
    currentPage,
    paginationComponent: getPaginationComponent(),
    data: infiniteScroll ? infiniteData : data,
  };
};
