import { useQuery } from '@apollo/client';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/root-reducer';
import { GET_CAPEX_TEMPLATE } from 'services/capex-gql';
import { ICapexLocationState, ICapexTemplate } from 'types/capex';
import { dataToCapexTemplate, pageTabs } from 'utils/constants/capex-constants';

interface IUseCapexTemplateData {
  templateData?: ICapexTemplate;
  loadingTemplate: boolean;
}

export const useCapexTemplateData = (id?: string): IUseCapexTemplateData => {
  const { t } = useTranslation();
  const { currencyOptions } = useSelector((currencyState: RootState) => currencyState.currencies);
  const { state } = useLocation();
  const locationState = state as ICapexLocationState;
  const { isAuthorized } = useAuthorization();

  const { data: templateData, loading: loadingTemplate } = useQuery(GET_CAPEX_TEMPLATE, {
    variables: { id },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  const mapTemplateValuesToShow = (template: any): ICapexTemplate => {
    const resultantTemplate = { ...template };
    if (locationState?.clone) {
      // From cloning a template
      resultantTemplate.countryDefault = false;
      if (resultantTemplate.company) {
        if (resultantTemplate.name) {
          resultantTemplate.name += ' - ' + t('capex:cloned');
        } else {
          resultantTemplate.name = t('capex:untitled') + ' - ' + t('capex:cloned');
        }
      } else {
        resultantTemplate.name = resultantTemplate.country?.translation || t('capex:rest-of-the-world');
      }

      if (!isAuthorized(Permission.FINANCIAL_SUPPORT_WRITE) && !resultantTemplate.company) {
        // Map values to clone a template public into private
        localStorage.setItem('capex-type', pageTabs[1].id);
        return {
          name: resultantTemplate.name,
          folder: undefined,
          countryDefault: undefined,
          currency: resultantTemplate.currency,
          id: '',
          type: 'private',
          countryCode: null,
          entries: resultantTemplate.entries,
        };
      }
    }
    return resultantTemplate;
  };

  const getTemplateData = (): ICapexTemplate | undefined => {
    if (!id) return locationState?.templatePreselectedValues; // Create from scratch
    if (templateData?.capexTemplate) {
      if (locationState?.templatePreselectedValues && !locationState?.clone)
        // Based on existing template (preselected values + selected capex entries)
        return { ...locationState?.templatePreselectedValues, entries: templateData?.capexTemplate.entries };
    }
    // Get template for edition, cloning
    return !loadingTemplate && templateData
      ? dataToCapexTemplate(mapTemplateValuesToShow(templateData.capexTemplate), currencyOptions)
      : undefined;
  };

  return {
    templateData: getTemplateData(),
    loadingTemplate: id ? loadingTemplate : false,
  };
};
