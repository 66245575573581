import styled from 'styled-components';

export const PageContainer = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  height: 100%;
`;

export const TeamContentContainer = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;
