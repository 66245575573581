/* eslint-disable max-len */
import React from 'react';
import {
  DesignItemDetailsContainer,
  DesignItemDetailsRowWrapper,
  DesignItemSectionRow,
} from './design-item-details.styled';
import { IBessDesign } from 'types/design';
import { useConverter } from 'utils/conversor';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';
import { bessDesignAttributes } from 'utils/constants/bess-design-attributes';

interface IProps {
  item: IBessDesign;
  hidden?: boolean;
}

const BessDesignItemDetails: React.FC<IProps> = ({ item, hidden }) => {
  const { getValueOf } = useConverter();
  const { t } = useTranslation('design');

  if (!item.standaloneBatterySimulationOutputs) return null;

  return (
    <DesignItemDetailsContainer>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('design:power')}
        </Typography>
        <DesignItemSectionRow>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(bessDesignAttributes.bess.energyCapacity, item)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {getValueOf(bessDesignAttributes.bess.activePowerAc, item)}
          </Typography>
        </DesignItemSectionRow>
      </DesignItemDetailsRowWrapper>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('design:supply')}
        </Typography>
        <DesignItemSectionRow>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {t(`design:${bessDesignAttributes.bess.operationHours.label}`)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {getValueOf(bessDesignAttributes.bess.operationHours, item)} <span>hours</span>
          </Typography>
        </DesignItemSectionRow>
      </DesignItemDetailsRowWrapper>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('design-process:site')}
        </Typography>
        <DesignItemSectionRow>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {t(`design:${bessDesignAttributes.site.installedArea.label}`)}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {getValueOf(bessDesignAttributes.site.installedArea, item)}
          </Typography>
        </DesignItemSectionRow>
      </DesignItemDetailsRowWrapper>
      <DesignItemDetailsRowWrapper>
        <Typography nowrap size={'m'} className="section-header" weight={'bold'}>
          {t('equipment')}
        </Typography>
        <DesignItemSectionRow>
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {t('bess-container')}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(bessDesignAttributes.equipment.bessContainerCapacity, item)}
          </Typography>
        </DesignItemSectionRow>
        <DesignItemSectionRow>
          <Typography nowrap size={'m'} className="body-col body-col-2">
            {`${item.standaloneBatterySimulationInputs.inverter.manufacturer} ${item.standaloneBatterySimulationInputs.inverter.model}`}
          </Typography>
          <Typography nowrap size={'m'} className="body-col body-col-1">
            {getValueOf(bessDesignAttributes.equipment.storageInverterRatedPower, item)}
          </Typography>
        </DesignItemSectionRow>
      </DesignItemDetailsRowWrapper>
    </DesignItemDetailsContainer>
  );
};

export default BessDesignItemDetails;
