import styled, { css } from 'styled-components';

export const ProjectsListContainer = styled.div`
  padding: ${({ theme }) => `${theme.v2.spacing.xl} ${theme.v2.spacing.l} 0 ${theme.v2.spacing.l}`};
  height: 100%;

  .pagination {
    background-color: ${({ theme }) => theme.white};
    bottom: 0;
    display: flex;
    justify-content: center;
  }

  .created-by {
    margin-top: 0.4rem;
  }

  .collapsed {
    display: none;
  }

  .min-cell {
    width: 2%;
  }

  .expanded {
    column-gap: ${({ theme }) => theme.v2.spacing.m};
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    .sticky-column {
      background-color: ${({ theme }) => theme.white};
      position: sticky;
      right: 0;
      top: 0;
    }

    .collapsed {
      display: block;
    }

    .expanded {
      display: none;
    }
  }
`;

export const FoundResultsContainer = styled.div<{ noPadding: boolean }>`
  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding-top: 8px;
    `}

  padding-left: 16px;
  margin-bottom: 12px;
`;
