import React from 'react';
import * as S from './read-only-roles-tab.styled';
import { useRolesByCurrentUser } from 'utils/hooks/use-roles-by-current-user';
import ReadOnlyPermissionsList from './read-only-permissions-list';
import { useUsersManagementContext } from '../users-management-data';
import TopbarReadOnlyRolesTab from './topbar-read-only-roles-tab';
import { usersManagementTabs } from 'utils/constants/users';
import { useReadOnlyRolesTable } from './hooks/use-read-only-roles-table';
import { useNavigate } from 'react-router-dom';
import { RPTable, Tabs } from '@ratedpower/components';
import { IRole } from 'types/role';
import { useTranslation } from 'react-i18next';

const ReadOnlyRolesTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    useUsersObject: { userRoleSummary },
  } = useUsersManagementContext();
  const { rolesByCurrentUser } = useRolesByCurrentUser(false);
  const { table, handleExpandSection } = useReadOnlyRolesTable(userRoleSummary, rolesByCurrentUser);
  const { tabs } = useUsersManagementContext();

  const navigateToTab = (tab: string) => {
    navigate(`/users-management/${tab.toLowerCase()}`);
  };

  return (
    <>
      <TopbarReadOnlyRolesTab />
      <S.Container>
        <Tabs
          onClickTab={navigateToTab}
          tabs={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
          tabActive={usersManagementTabs[2].id}
        />
        <RPTable
          table={table as any}
          onRowClick={(role: IRole) => handleExpandSection(role.id)}
          collapsibleComponent={<ReadOnlyPermissionsList />}
          zeroResultsFoundText={t('zero-results-found')}
        />
      </S.Container>
    </>
  );
};

export default ReadOnlyRolesTab;
