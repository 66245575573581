import React from 'react';
import * as S from '../notifications.styled';
import { useTranslation } from 'react-i18next';
import { notificationTabs } from 'utils/constants/notifications';
import { Button, Tabs } from '@ratedpower/components';
import { useGetNotificationsCount } from 'utils/hooks/use-get-notifications-count';
import useMarkAllNotificationsAsRead from 'components/notifications/hooks/use-mark-all-notifications-as-read';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';

interface IProps {
  currentPage: number;
  tabActive: string;
  onClickTab: (tabSelected: string) => void;
}

const NotificationsTabsBar: React.FC<IProps> = ({ currentPage, tabActive, onClickTab }: IProps) => {
  const { t } = useTranslation(),
    notificationsCount = useGetNotificationsCount(),
    thereAreUnreadNotifications = notificationsCount > 0,
    { markAllAsRead, error } = useMarkAllNotificationsAsRead(currentPage);

  const handleClick = (): void => {
    if (thereAreUnreadNotifications) markAllAsRead();
  };

  return (
    <>
      {error && <ErrorRetrievingData />}
      <S.TopContent>
        <Tabs
          tabs={notificationTabs.map((tab) => ({ label: t(tab.label), id: tab.id }))}
          tabActive={tabActive}
          onClickTab={onClickTab}
        />
        <Button
          variant="ghost"
          size="l"
          text={t('home:mark-all-as-read')}
          onClick={handleClick}
          disabled={!thereAreUnreadNotifications}
        />
      </S.TopContent>
    </>
  );
};

export default NotificationsTabsBar;
