import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import StructureTopographyInfowindow from 'pages/design-process/topography-tab/topography-analysis/structure-topography-infowindow/structure-topography-infowindow';
import StructureEarthworksInfowindow from 'pages/design-process/topography-tab/topography-analysis/structures-earthworks-infowindow/structures-earthworks-infowindow';
import { useMapStyleSelector } from './use-map-style-selector';
import bbox from '@turf/bbox';
import PlotAreaPopup from 'components/maps/plot-area-popup/plot-area-popup';
import length from '@turf/length';
import along from '@turf/along';
import InfowindowProject from 'pages/projects/map/infowindow-project/infowindow-project';
import { BBox } from '@turf/helpers';
import isWebGlEnabled from 'is-webgl-enabled';
import OhlTowerPopup from '../../pages/design-process/preview/overview-map-layers/ohl-tower-popup';
import { useConverter } from 'utils/conversor';
import { IConverters } from 'types/preferences.types';
import HomeProjectsMapTooltip from 'pages/home/home-projects-map/home-projects-map-tooltip/home-projects-map-tooltip';
import { useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { useTheme } from 'styled-components';
import FlexibleSchemaInfoPopup from '../../components/maps/flexible-schema-info-popup';
import { ThemeProvider } from '@ratedpower/components';
import { MapPopupsContainer } from 'utils/maps/map-popups.styled';

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_API_KEY}`;

const calculateCenterOfBBox = (feature: any) => {
  const [minX, minY, maxX, maxY] = bbox(feature);
  const center_x = (minX + maxX) * 0.5;
  const center_y = (minY + maxY) * 0.5;

  return { lng: center_x, lat: center_y };
};

const calculateCenterOfLine = (feature: any) => {
  const midpointDistance = length(feature, { units: 'meters' }) / 2;
  const midpoint = along(feature, midpointDistance, { units: 'meters' });

  return { lng: midpoint.geometry.coordinates[0], lat: midpoint.geometry.coordinates[1] };
};
type CenterMapOptions = {
  lng: number;
  lat: number;
  zoom: number;
};

interface PopupData {
  coordinates: { lat: number; lng: number };
  dataType: string;

  [key: string]: any;
}

export function useBuildInfowindow() {
  const navigate = useNavigate();
  const theme = useTheme();

  const navigateToProject = (projectId: string) => {
    navigate(`/projects/${projectId}`);
  };
  // here
  // eslint-disable-next-line react/display-name
  return (data: any, converters: IConverters | undefined): React.ReactElement | undefined => {
    let component;
    if (data.dataType === 'STRUCTURE_TOPOGRAPHY_DATA') {
      component = <StructureTopographyInfowindow incidents={data.structureMessages} theme={theme} />;
    }
    if (data.dataType === 'STRUCTURE_EARTH_WORKS_DATA') {
      component = <StructureEarthworksInfowindow converters={converters} data={data} />;
    }

    if (data.dataType === 'PLOT_AREA') {
      component = <PlotAreaPopup data={data} converters={converters} />;
    }
    if (data.dataType === 'BASIC_INTERCONNECTION_COMPONENT_DATA') {
      component = <FlexibleSchemaInfoPopup componentName={data.name} />;
    }
    if (data.dataType === 'PROJECT_DATA') {
      component = <InfowindowProject project={data.content} onClickProjectName={navigateToProject} />;
    }
    if (data.dataType === 'HOME_PAGE_PROJECT_DATA') {
      component = <HomeProjectsMapTooltip project={data.content} onClickProjectName={navigateToProject} />;
    }
    if (data.dataType === 'OHL_TOWERS_DATA') {
      component = <OhlTowerPopup data={data} converters={converters} />;
    }
    if (component) {
      return (
        <ThemeProvider>
          <MapPopupsContainer>{component}</MapPopupsContainer>
        </ThemeProvider>
      );
    }
    return undefined;
  };
}

export const useMap = () => {
  const { converters } = useConverter();
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }));
  const { mapStyleSelector } = useMapStyleSelector(map);
  const updateMapCenter = (options: CenterMapOptions) => {
    map.current.flyTo({
      center: [options.lng, options.lat],
      zoom: options.zoom,
    });
  };

  const fitMapToBBox = (bboxValue: number[][] | BBox) => {
    if (map.current) {
      map.current.resize();
      map.current.fitBounds(bboxValue, { padding: { top: 50, bottom: 50, left: 50, right: 50 } });
    }
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (isWebGlEnabled()) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [0, 0],
        zoom: 2,
        pitchWithRotate: false,
        touchPitch: false,
        touchZoomRotate: false,
        trackResize: true,
        maxPitch: 0,
        dragRotate: false,
        preserveDrawingBuffer: false,
      });
    } else {
      console.warn('WebGL is not active');
    }
  });

  const resizeMap = () => {
    if (map.current) map.current.resize();
  };

  const removeCurrentPopup = () => {
    popUpRef.current.remove();
  };

  const buildInfowindow = useBuildInfowindow();

  function buildPopupData(targetFeature: any) {
    if (targetFeature.properties?.data) {
      return typeof targetFeature.properties.data === 'string'
        ? JSON.parse(targetFeature.properties.data)
        : targetFeature.properties.data;
    }
    return {};
  }

  const createPopup = (e: any) => {
    const targetFeature = e.features[0];
    if (targetFeature) {
      const type = targetFeature.geometry.type;
      const coordinatesObj =
        type === 'LineString' ? calculateCenterOfLine(targetFeature) : calculateCenterOfBBox(targetFeature);
      const data: PopupData = {
        ...buildPopupData(targetFeature),
        coordinates: coordinatesObj,
      };
      const component = buildInfowindow(data, converters);
      if (component) {
        const popupNode = document.createElement('div');
        createRoot(popupNode).render(component);
        new mapboxgl.Popup({ closeButton: false })
          .setLngLat(data.coordinates)
          .setDOMContent(popupNode)
          .addTo(map.current);
      }
    }
  };

  const createPopupForPoints = (e: any) => {
    const coordinatesObj = {
      lng: e.features[0].geometry.coordinates[0],
      lat: e.features[0].geometry.coordinates[1],
    };
    const data: any = {
      ...(e.features[0].properties?.data ? JSON.parse(e.features[0].properties.data) : {}),
      coordinates: coordinatesObj,
    };
    const component = buildInfowindow(data, converters);
    if (component) {
      const popupNode = document.createElement('div');
      createRoot(popupNode).render(component);
      new mapboxgl.Popup({ closeButton: false }).setLngLat(coordinatesObj).setDOMContent(popupNode).addTo(map.current);
    }
  };

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      setMapLoaded(true);
      map.current.resize();

      map.current.on('mouseenter', 'STRUCTURES-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseenter', 'ACCEPTED_STRUCTURE_WITH_WARNINGS_LAYER-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'FILTERED_STRUCTURE_LAYER-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'ACCEPTED_STRUCTURE_WITH_EARTH_WORKS_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'ACCEPTED_STRUCTURE_WITH_EARTH_WORKS_LAYER-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'STRUCTURE_OUTSIDE_DEM_BOUNDARIES-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_ROW_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_NEGATIVE_ROW_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_POSITIVE_ROW_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_NEGATIVE_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_POSITIVE_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseenter', 'MAXIMUM_LINKED_ROWS_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'UNDULATION_TOLERANCE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseleave', 'STRUCTURES-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });

      map.current.on('mouseleave', 'ACCEPTED_STRUCTURE_WITH_WARNINGS_LAYER-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'FILTERED_STRUCTURE_LAYER-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'ACCEPTED_STRUCTURE_WITH_EARTH_WORKS_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'ACCEPTED_STRUCTURE_WITH_EARTH_WORKS_LAYER-fill', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'STRUCTURE_OUTSIDE_DEM_BOUNDARIES-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_ROW_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_NEGATIVE_ROW_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_POSITIVE_ROW_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_NEGATIVE_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_POSITIVE_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });

      map.current.on('mouseleave', 'MAXIMUM_LINKED_ROWS_PITCH_SLOPE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'UNDULATION_TOLERANCE_EXCEEDED-fill', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseenter', 'STRUCTURES-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseenter', 'ACCEPTED_STRUCTURE_WITH_EARTH_WORKS_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'ACCEPTED_STRUCTURE_WITH_WARNINGS_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'FILTERED_STRUCTURE_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'STRUCTURE_OUTSIDE_DEM_BOUNDARIES-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_ROW_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_NEGATIVE_ROW_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_POSITIVE_ROW_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_NEGATIVE_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'MAXIMUM_POSITIVE_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseenter', 'MAXIMUM_LINKED_ROWS_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseenter', 'UNDULATION_TOLERANCE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseleave', 'STRUCTURES-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });

      map.current.on('mouseleave', 'ACCEPTED_STRUCTURE_WITH_EARTH_WORKS_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'ACCEPTED_STRUCTURE_WITH_WARNINGS_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'FILTERED_STRUCTURE_LAYER-line', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'STRUCTURE_OUTSIDE_DEM_BOUNDARIES-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_ROW_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_NEGATIVE_ROW_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_POSITIVE_ROW_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_NEGATIVE_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'MAXIMUM_POSITIVE_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });

      map.current.on('mouseleave', 'MAXIMUM_LINKED_ROWS_PITCH_SLOPE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('mouseleave', 'UNDULATION_TOLERANCE_EXCEEDED-line', () => {
        map.current.getCanvas().style.cursor = 'default';
      });

      map.current.on('click', 'EARTHWORKS_PLATFORMS-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'EARTHWORKS_PLATFORMS-line', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'STRUCTURES-fill', (e: any) => {
        createPopup(e);
      });

      map.current.on('click', 'ACCEPTED_STRUCTURE_WITH_WARNINGS_LAYER-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'FILTERED_STRUCTURE_LAYER-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'STRUCTURE_OUTSIDE_DEM_BOUNDARIES-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'MAXIMUM_ROW_SLOPE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'MAXIMUM_LINKED_ROWS_PITCH_SLOPE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'MAXIMUM_PITCH_SLOPE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'MAXIMUM_NEGATIVE_ROW_SLOPE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'MAXIMUM_POSITIVE_ROW_SLOPE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'MAXIMUM_NEGATIVE_PITCH_SLOPE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'MAXIMUM_POSITIVE_PITCH_SLOPE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'UNDULATION_TOLERANCE_EXCEEDED-fill', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'STRUCTURES-line', (e: any) => {
        createPopup(e);
      });
      map.current.on('click', 'GEO_PINS-locationMarker', (e: any) => {
        createPopupForPoints(e);
      });

      map.current.on('mouseenter', 'OVERHEAD_LINE_TOWERS-circleMarkers', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'OVERHEAD_LINE_TOWERS-circleMarkers', () => {
        map.current.getCanvas().style.cursor = 'default';
      });
      map.current.on('click', 'OVERHEAD_LINE_TOWERS-circleMarkers', (e: any) => {
        createPopupForPoints(e);
      });
    });
    return () => map.current.remove();
  }, []);

  return {
    map,
    mapLoaded,
    mapContainer,
    updateMapCenter,
    mapStyleSelector,
    resizeMap,
    fitMapToBBox,
    popUpMapUtils: { createPopup, removeCurrentPopup },
  };
};
