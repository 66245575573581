import styled from 'styled-components';

export const MapTopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const MapContainer = styled.div`
  padding-top: ${({ theme }) => theme.v2.spacing.l};
  width: 100%;
  height: 260px;

  .mapboxgl-popup-close-button {
    display: none;
  }

  .mapboxgl-map {
    max-height: 220px;
  }
`;

export const MapSectionTitle = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  flex-direction: column;

  p + p {
    margin-top: 0;
  }
`;
