import React from 'react';
import { IExtraProps, InputNumber, IBaseTemplateInput } from '@ratedpower/components';
import { Converter, IConvertToShowOptions, UnitSystem } from 'types/preferences.types';
import { useConverter } from 'utils/conversor';
import { useConvertedInputNumber } from 'utils/conversor/use-input-number';

type ConverterTo = {
  type: Converter;
  options?: IConvertToShowOptions;
};

interface IProps extends IExtraProps {
  baseValue: number;
  updateForm: (newValue: string) => void;
  converterTo?: ConverterTo;
  unitSystem?: UnitSystem;
  showUnits?: boolean;
  size?: IBaseTemplateInput['size'];
}

const ConverterInputNumber: React.FC<IProps & IBaseTemplateInput> = ({
  baseValue = 0,
  updateForm,
  converterTo,
  unitSystem,
  showUnits = true,
  size,
  readOnly,
  ...rest
}) => {
  const { converters } = useConverter();
  const { onChange, onBlur, value } = useConvertedInputNumber(baseValue, updateForm, converterTo, unitSystem);
  if (!converters) return <></>;

  const getSuffixFromConverterTo = (converterToArg: ConverterTo | undefined): string => {
    if (!showUnits || !converterToArg) {
      return '';
    }
    if (converterToArg.options?.displayUnit) {
      return converterToArg.options.displayUnit;
    }
    return converters[converterToArg.type].toShow(baseValue, converterToArg.options).unit || '';
  };

  return (
    <InputNumber
      suffix={getSuffixFromConverterTo(converterTo)}
      readOnly={readOnly}
      {...rest}
      onChange={(_, event) => {
        if (event && !readOnly && onChange) onChange(event);
      }}
      onBlur={(event) => {
        if (!readOnly && onBlur) onBlur(event);
      }}
      value={value}
      size={size}
      textAlign={size === 's' ? 'right' : 'left'}
    ></InputNumber>
  );
};

export default ConverterInputNumber;
