import { useLazyQuery } from '@apollo/client';
import { GET_CAPEX_ENTRIES } from 'services/capex-gql';
import { IEntry } from 'types/capex';

export const useCapexEntries = () => {
  const [getCapexEntries, { data: capexEntries }] = useLazyQuery(GET_CAPEX_ENTRIES, { fetchPolicy: 'network-only' });

  const formatCapexDataToSend = (data: any, isCreating: boolean): IEntry[] => {
    if (data) {
      return data.map((item: any) => {
        return {
          name: item.name,
          visible: item.visible,
          ...(isCreating
            ? {
                parentId: item?.parentId,
                templateId: item?.templateId,
                simulationId: item?.simulationId,
              }
            : { id: item.id }),
          priceDefinition: item?.priceDefinition && {
            name: item.priceDefinition.name,
            baseParam: item.priceDefinition.baseParam,
            paramMultiplier: item.priceDefinition.paramMultiplier,
            tax: item.priceDefinition.tax,
            price: item.priceDefinition.price,
            ...(!isCreating
              ? !item.provisionalId && { id: item.priceDefinition.id }
              : { templateEntryId: item.priceDefinition.templateEntryId }),
          },
          children: item.children ? formatCapexDataToSend(item.children, isCreating) : [],
        };
      });
    }
    return [];
  };

  return {
    formatCapexDataToSend,
    getCapexEntries,
    capexEntries,
  };
};
