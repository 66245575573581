import React from 'react';
import { ApolloError } from '@apollo/client';
import { RatedPowerLoader } from '@ratedpower/components';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';

interface IProps {
  loading?: boolean;
  error?: ApolloError | boolean;
  children: React.ReactNode | React.ReactNode[];
}
const QueryStatusWrapper = ({ loading, error, children }: IProps) => {
  if (loading) {
    return <RatedPowerLoader />;
  }
  if (error) {
    return <ErrorRetrievingData />;
  }
  return <>{children}</>;
};

export default QueryStatusWrapper;
