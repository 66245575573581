import styled from 'styled-components';

export const NoResultsFilterContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .title {
    font-size: 2rem;
  }

  svg {
    width: 120px;
    height: 120px;
  }
`;
