import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
      transform: rotate(0deg);
    }

  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    border: 4px solid ${({ theme }) => theme.gray_300};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: ${({ theme }) => theme.secondary};
    animation: 1s ${spin} infinite;
  }
`;
