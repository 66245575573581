import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@ratedpower/components';
import { ErrorIcon, ErrorPageContainer } from '../error-page-container.styled';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetError } from 'redux/server-error-slice';

const ErrorForbidden: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current) {
      dispatch(resetError());
    } else {
      isMountedRef.current = true;
    }
  }, [pathname, isMountedRef]);

  return (
    <ErrorPageContainer>
      <ErrorIcon />
      <Typography weight="bold">{t('errors:access-forbidden')}</Typography>
      <Typography size="s">{t('errors:access-forbidden-description')}</Typography>
      {window.navigator.onLine && (
        <Button
          variant="accent"
          text={t('action:reload')}
          onClick={() => {
            window.location.replace('/projects');
          }}
        />
      )}
    </ErrorPageContainer>
  );
};

export default ErrorForbidden;
