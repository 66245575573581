import { useTranslation } from 'react-i18next';
import { useToasts } from 'utils/hooks/use-toasts';

export interface IUseErrorToast {
  errorNotification: (apolloError: any) => void;
  successNotification: (text: string) => void;
}
export const useNotificationToast = (): IUseErrorToast => {
  const { t } = useTranslation();
  const { addSuccessToast, addErrorToast } = useToasts();

  const errorNotification = (apolloError: any) => {
    const hasDebugInfo: any =
      'debugInfo' in apolloError.graphQLErrors[0].extensions ? apolloError.graphQLErrors[0].extensions.debugInfo : null;
    const errorMessage: string | null =
      hasDebugInfo && 'messageError' in hasDebugInfo ? hasDebugInfo.messageError : null;
    const message =
      errorMessage && ['BAD_START_DATE', 'OVERLAPPING_DATES', 'NOT_FOUND'].includes(errorMessage)
        ? t(`company:subscription-plans.${errorMessage}`)
        : errorMessage;

    addErrorToast(message ?? t('errors:something-went-wrong'));
  };

  const successNotification = (text: string) => addSuccessToast(t(text));

  return {
    errorNotification,
    successNotification,
  };
};
