import React from 'react';
import * as S from './new-user-modal.styled';
import preferences from 'utils/constants/user-preferences';
import useForm from 'utils/hooks/use-form';
import { ADMIN_EMAIL } from 'utils/constants/contact';
import { BulletPoint, Dropdown, InputText, IModalProps, Modal, LabelWrapper } from '@ratedpower/components';
import { Permission } from 'access-control/permissions';
import { ROLE_TYPE } from 'types/role';
import { USER_TYPES_OPTIONS, jobTitleOptions, getRolesOptions, getOriginalRolesColors } from 'utils/constants/users';
import { getOptionForValue } from 'utils/selector';
import { useAuthorization } from 'access-control/can';
import { useCompanyActivityState } from '../../company-activity-state';
import { useRolesByCurrentUser } from 'utils/hooks/use-roles-by-current-user';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IEditUserTemplate, INewSupportUser } from 'types/support.types';

interface IProps extends IModalProps {
  loading: boolean;
  user: IEditUserTemplate;
  showUserTypes?: boolean;
  isEditing?: boolean;
  onSubmit: (newUser: INewSupportUser) => void;
}

const NewUserModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { contextCompany } = useCompanyActivityState();
  const { user, showUserTypes, isEditing, loading, onClose, onSubmit } = props;
  const {
    inputs: { name, lastname, email, language, role, type, jobTitle },
    formError,
    handleInputChangeManual,
    handleSubmit,
    setFormError,
  } = useForm(null, user);

  const { rolesByCurrentUser } = useRolesByCurrentUser();
  const theme = useTheme();
  const { isAuthorized } = useAuthorization();
  const hasRoleChangePermissions = isAuthorized(Permission.ASSIGN_SUPPORT_ROLE_WRITE);

  function handleSave() {
    if (!name || !lastname || !email || (!type && showUserTypes) || !language || !role) {
      setFormError({ code: 'field-empty-plural' });
      return;
    }

    const newUser: INewSupportUser = { name, lastname, jobTitle, language, type, email };
    if (hasRoleChangePermissions || (!hasRoleChangePermissions && role.type === ROLE_TYPE.EXTERNAL)) {
      newUser.roleId = role.id;
    }
    onSubmit({ ...newUser, ...(user.id ? { id: user.id } : { email: email.trim() }) });
  }

  const rolesOptions = getRolesOptions(rolesByCurrentUser);
  const selectedRoleOption = rolesOptions.find((option) => option.value.id === role?.id);
  const selectedUserType = getOptionForValue(type, USER_TYPES_OPTIONS(t));
  const selectedLanguage = getOptionForValue(language, preferences.languageWithEnglishOptions);
  const selectedJobTitle = getOptionForValue(jobTitle, jobTitleOptions);
  const originalRolesColors = getOriginalRolesColors(theme);

  const filteredRoleOptions = rolesOptions.filter((_, index) => {
    if (!hasRoleChangePermissions || contextCompany?.email !== ADMIN_EMAIL) {
      return rolesByCurrentUser[index].type === ROLE_TYPE.EXTERNAL;
    }
    return hasRoleChangePermissions;
  });

  return (
    <Modal
      {...props}
      disableClickOutside
      buttons={{
        primary: {
          text: isEditing ? t('action:edit') : t('action:create'),
          onClick: handleSave,
          isLoading: loading,
        },
        secondary: {
          text: t('action:discard'),
          onClick: onClose,
        },
      }}
      size="fitContent"
    >
      <S.ModalForm onSubmit={handleSubmit} autoComplete="off">
        <LabelWrapper title={t('user:name-person')} size="m" alignItems="center">
          <InputText
            warning={formError?.code && !name}
            name="name"
            placeholder={t('user:name-person')}
            value={name}
            onChange={(newValue) => handleInputChangeManual('name', newValue)}
          />
        </LabelWrapper>
        <LabelWrapper title={t('user:lastname')} size="m" alignItems="center">
          <InputText
            warning={formError?.code && !lastname}
            name="lastname"
            placeholder={t('user:lastname')}
            value={lastname}
            onChange={(newValue) => handleInputChangeManual('lastname', newValue)}
          />
        </LabelWrapper>

        <LabelWrapper title={t('user:email')} size="m" alignItems="center">
          <InputText
            warning={formError?.code && !email}
            name="email"
            type="email"
            placeholder={t('user:email')}
            value={email}
            onChange={(newValue) => handleInputChangeManual('email', newValue)}
          />
        </LabelWrapper>

        {user && !hasRoleChangePermissions && role?.type === ROLE_TYPE.INTERNAL ? (
          <BulletPoint color={role.color ?? originalRolesColors[role.name]}>{role.name}</BulletPoint>
        ) : (
          <LabelWrapper title={t('user:role')} size="m" alignItems="center">
            <Dropdown
              error={formError?.code && !role?.id}
              name="role"
              placeholder={t('user:role')}
              value={selectedRoleOption ? [selectedRoleOption] : []}
              options={filteredRoleOptions}
              onChange={(option) => handleInputChangeManual('role', option[0].value)}
            />
          </LabelWrapper>
        )}

        {showUserTypes && (
          <LabelWrapper title={t('support:users-tab.user-type')} size="m" alignItems="center">
            <Dropdown
              error={formError?.code && !type}
              name="type"
              placeholder={t('support:users-tab.user-type')}
              value={selectedUserType ? [selectedUserType] : []}
              options={USER_TYPES_OPTIONS(t)}
              onChange={(option) => handleInputChangeManual('type', option[0].value)}
            />
          </LabelWrapper>
        )}

        <LabelWrapper title={t('language')} size="m" alignItems="center">
          <Dropdown
            error={formError?.code && !language}
            name="language"
            placeholder={t('language')}
            value={selectedLanguage ? [selectedLanguage] : []}
            options={preferences.languageWithEnglishOptions}
            onChange={(option) => handleInputChangeManual('language', option[0].value)}
          />
        </LabelWrapper>

        <LabelWrapper title={t('user:job-title')} size="m" alignItems="center">
          <Dropdown
            name="jobTitle"
            placeholder={t('user:job-title')}
            value={selectedJobTitle ? [selectedJobTitle] : []}
            options={jobTitleOptions}
            onChange={(option) => handleInputChangeManual('jobTitle', option[0].value)}
          />
        </LabelWrapper>

        {formError?.code && (
          <S.FormError color={theme.v2.error.text.contrast}>{t(`errors:${formError.code}`)}</S.FormError>
        )}
      </S.ModalForm>
    </Modal>
  );
};

export default NewUserModal;
