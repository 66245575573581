import styled, { css } from 'styled-components';
import { Tag } from '@ratedpower/components';

export const centerGrid = (width: string) => css`
  justify-content: center;
  display: grid;
  width: ${width};
`;

export const DesignColumn = styled.div`
  padding: 6px 0;

  p {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
  }

  &.checkbox {
    ${centerGrid('46px')};
  }

  &.favorite {
    ${centerGrid('52px')};
  }

  &.first-column {
    width: 18%;
    min-width: 200px;
    margin-right: 10px;

    .editable-input {
      max-width: 200px;
    }
  }

  &.data {
    width: 155px;
    justify-content: flex-start;

    &.tags {
      width: 155px;
      flex-direction: column;
      row-gap: 4px;
    }
  }

  &.error,
  &.details {
    width: 100%;
  }

  &.createdAt {
    width: 10%;
  }

  &.more {
    min-width: 122px;
  }

  &.first-column {
    ${centerGrid('200px')};
    justify-content: flex-start;
  }

  &.more {
    display: flex;
    justify-content: right;
    column-gap: 12px;
    align-items: center;
  }
`;

export const DesignColumnHeader = styled(DesignColumn)`
  padding: 14px 0;

  &.helper-text {
    width: 62%;
  }

  &.actions {
    width: 86%;
  }

  &.sortSelector {
    width: 24%;
  }

  &.first-column {
    #sortable-header {
      justify-content: flex-start;
    }
  }

  &.sortSelector {
    display: flex;
    justify-content: flex-end;
  }

  &.more {
    flex-direction: column;

    span {
      margin-bottom: 2px;
    }
  }

  &.actions {
    justify-content: flex-start;
    padding-left: 10px;

    .btn-action {
      margin-right: 10px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
`;

export const CustomTag = styled(Tag)`
  max-width: fit-content;
`;
