import React from 'react';
import * as S from './error-info-modal.styled';
import { IModalProps } from '@ratedpower/components';
import { useErrorInfoModal } from './use-error-info-modal';
import { useTranslation } from 'react-i18next';

interface IErrorInfoModal extends IModalProps {
  entityType: string;
  entityId: string;
}

const ErrorInfoModal: React.FC<IErrorInfoModal> = ({ isActive, onClose, entityType, entityId }) => {
  const { t } = useTranslation();
  const { errorInfo, KMLinfo } = useErrorInfoModal(entityType, entityId);

  const extractDynamicParams = KMLinfo?.dynamicParams.reduce(
    (previous, current, currentIndex) => ({
      ...previous,
      [current]: errorInfo?.message.split('|')[currentIndex],
    }),
    {}
  );

  return (
    <S.ErrorInfoModalContainer isActive={isActive} onClose={onClose} size="m" title={t('error:error')}>
      <S.ModalTitle>{`${entityType} error:  ${errorInfo?.error ?? 'Loading...'}`}</S.ModalTitle>
      {errorInfo?.message && <S.ModalContent>entity info: {errorInfo?.message}</S.ModalContent>}
      {KMLinfo?.message && KMLinfo?.message !== 'site.generic.message' && (
        <S.ModalContent>{t(`errors:${KMLinfo?.message}`, { ...extractDynamicParams })}</S.ModalContent>
      )}
      {KMLinfo?.image && <S.ModalImg src={KMLinfo?.image} alt={KMLinfo?.title} />}
    </S.ErrorInfoModalContainer>
  );
};

export default ErrorInfoModal;
