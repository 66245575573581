import React from 'react';
import Teams from '.';
import TeamManagement from './team-management';
import { Route, Routes } from 'react-router-dom';
import GeneralError from 'pages/error/general-error/general-error';

const TeamsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<Teams />} ErrorBoundary={GeneralError} />
      <Route path=":teamId/:contentType" element={<TeamManagement />} ErrorBoundary={GeneralError} />
    </Routes>
  );
};

export default TeamsRoutes;
