import { IBessDesign, IDesign } from 'types/design';

export const getTagTextConfig = (design: IDesign | IBessDesign): string => {
  const simulationTypeCategory = getSimulationTypeCategory(design);
  switch (simulationTypeCategory) {
    case 'SINGLE_PV':
      return 'design-process:batch.single-pv-design';
    case 'SINGLE_BESS':
      return 'design-process:batch.single-bess-design';
    case 'BATCH_PV':
      return 'design-process:batch.multiple-pv-design';
  }
};

export const selectedColor = (design: IDesign | IBessDesign): string => {
  const simulationTypeCategory = getSimulationTypeCategory(design);
  switch (simulationTypeCategory) {
    case 'SINGLE_PV':
      return 'brand';
    case 'SINGLE_BESS':
      return 'yellow';
    case 'BATCH_PV':
      return 'accent';
  }
};

export const getSimulationTypeCategory = (design: IDesign | IBessDesign): SimulationTypeCategory => {
  const simulationType = design.simulationType;
  const simulationCategory = design.simulationCategory;

  if (simulationCategory === 'PV' && simulationType === 'SINGLE') {
    return 'SINGLE_PV';
  } else if (simulationCategory === 'STANDALONE_BATTERY') {
    return 'SINGLE_BESS';
  }
  return 'BATCH_PV';
};

type SimulationTypeCategory = 'SINGLE_PV' | 'SINGLE_BESS' | 'BATCH_PV';
