import { useLayoutEffect, useMemo, useRef } from 'react';

const debounce = (fn: any, delay: any) => {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const useDebounce = (callback: any, delay: any) => {
  const callbackRef = useRef(callback);
  // Why useLayoutEffect? -> https://kcd.im/uselayouteffect
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  return useMemo(() => debounce((...args: any) => callbackRef.current(...args), delay), [delay]);
};
