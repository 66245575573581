import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, TopbarWrapper } from '@ratedpower/components';
import { TeamsProvider } from 'pages/teams/teams-data';
import TeamsRoutes from 'pages/teams/teams-routes';
import TeamsTopbar from 'pages/teams/topbar/teams-topbar';
import { usersManagementTabs } from 'utils/constants/users';
import { useUsersManagementContext } from '../users-management-data';
import { useNavigate } from 'react-router-dom';
import * as S from './users-management-teams-tab.styled';
import { useTheme } from 'styled-components';

const UsersManagementTeamsTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tabs } = useUsersManagementContext();
  const theme = useTheme();

  const navigateToTab = (tab: string) => {
    navigate(`/users-management/${tab.toLowerCase()}`);
  };

  return (
    <TeamsProvider>
      <TeamsTopbar />
      <S.UsersManagementTeamsWrapper>
        <TopbarWrapper
          padding={`${theme.v2.spacing.l} 0 0 0`}
          type="secondary"
          left={
            <Tabs
              tabActive={usersManagementTabs[1].id}
              onClickTab={navigateToTab}
              tabs={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
            />
          }
          noBorder
        />
        <TeamsRoutes />
      </S.UsersManagementTeamsWrapper>
    </TeamsProvider>
  );
};

export default UsersManagementTeamsTab;
