import React from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';

// Colors (Validated in pvDesign)
const primaryColors = {
  primary: '#1C5686',
  primary_3: '#1C5686',
  primary_5: '#F4F7F9',
  primary_30: '#BBCCDB',
  primary_60: '#779ab6',
  primary_dark: '#154064',
  white: '#FFFFFF',
  black: '#000000',
};
const secondaryColors = {
  secondary: '#1AA5BD',
  secondary_5: '#F4FBFC',
  secondary_30: '#BAE4EB',
  secondary_60: '#76C9D7',
  secondary_dark: '#1896AC',
  secondary_light: '#28C1D7',
};
const grayColors = {
  gray_darker: '#333333',
  gray_dark: '#828282',
  gray: '#C2C2C2',
  gray_light: '#EBEBEB',
  gray_lighter: '#F5F5F5',
  gray_lightest: '#FBFBFB',
};
const paletteColors = {
  purple: '#6723B0',
  purple_light: '#F1EAF9',
  red: '#BE2F2F',
  red_light: '#F2D7D7',
  orange: '#D88913',
  orange_light: '#F7E8D2',
  green: '#499847',
  green_light: '#EFFAF5',
  yellow: '#FFD809',
  yellow_dark: '#F2C94C',
};
const chartColors = {
  chart_blue_light: '#A8CDED',
  chart_blue_dark: '#031526',
  chart_cyan_light: '#DDF2F5',
  chart_cyan: '#74C7D6',
  chart_green_lighter: '#DAF7E3',
  chart_green_light: '#C9D165',
  chart_green: '#377D22',
  chart_green_dark: '#449071',
  chart_yellow_light: '#FEF3D7',
  chart_yellow: '#F0D596',
  chart_orange: '#D7A151',
  chart_brown: '#A9754F',
  chart_red_dark: '#FF4E02',
  chart_wine: '#911E58',
  chart_wine_dark: '#821B10',
  chart_pink: '#EB5757',
};

const light: DefaultTheme = {
  // TODO These colors are not validated in pvDesign. They should be replaced and removed.
  dark_blue_600: '#519CDB', //replace for primary_dark
  gray_800: '#202124',
  gray_600: '#4F4F4F', //replace for gray_darker
  gray_400: '#BDBDBD', //replace for gray
  gray_300: '#E0E0E0',
  gray_200: '#EEEEEE',
  util_red_500: '#E99C9C',
  util_pink_500: '#FA59F3',
  util_yellow_800: '#D69C06',
  util_green_400: '#6FCF97',
  util_tooltip: '#616161e6',
  // end TODO

  animation_time: '250ms',

  box_shadow_x_small: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  box_shadow_small: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
  box_shadow_big: '0 4px 8px rgba(38, 38, 38, 0.2)',

  opacity_75: 'bf',
  opacity_60: '99',
  opacity_40: '66',
  opacity_30: '4d',
  opacity_20: '33',
  opacity_15: '26',
  opacity_10: '1a',
  opacity_5: '0d',

  topbar_height: {
    main: '64px',
    main2: '40px',
    sub1: '48px',
    sub11: '35px',
    main_values: '64px',
    main_values2: '41px',
  },

  ...primaryColors,
  ...secondaryColors,
  ...grayColors,
  ...paletteColors,
  ...chartColors,
};

export type AvailableTheme = 'LIGHT' | 'DARK';

export const THEMES: { [key: string]: AvailableTheme } = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

interface IAppThemes {
  DARK: DefaultTheme;
  LIGHT: DefaultTheme;
}

export const AppThemes: IAppThemes = {
  DARK: light,
  LIGHT: light,
};

const Theme = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={AppThemes.LIGHT}>{children}</ThemeProvider>
);

export default Theme;
