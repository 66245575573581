import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import CompanyLogo from 'components/company-logo/company-logo';
import Skeleton from 'react-loading-skeleton';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import * as S from './details-info-logo.styled';

interface IProps {
  isEditing: boolean;
  image: File | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  loading: boolean;
  data: any;
}

const DetailsInfoLogo: React.FC<IProps> = ({ isEditing, setImage, loading, data, image }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isAuthorized } = useAuthorization();

  return (
    <S.DetailsInfoLogoWrapper>
      <Typography size="l">{t('company:company-logo')}</Typography>{' '}
      <Typography size="s" color={theme.v2.text.secondary.default}>
        {t('company:logo-used-at-documentation-updated')} {t('company:accepted-format-png')}
      </Typography>
      {loading ? (
        <Skeleton height={110} width={160} />
      ) : (
        <CompanyLogo
          url={image?.name || data?.logo}
          canSelect={isAuthorized(Permission.COMPANY_WRITE) && isEditing}
          hideInfo={true}
          customHeight="120px"
          onSelect={(file) => setImage(file)}
          customWidth="276px"
        />
      )}
    </S.DetailsInfoLogoWrapper>
  );
};

export default DetailsInfoLogo;
