import React, { ChangeEvent } from 'react';
import * as S from './team-settings.styled';
import { useParams } from 'react-router-dom';
import TopbarTeamContent from '../topbar-team-content';
import { TeamContentContainer } from '../team-management.styled';
import { Button, InputText, InputTextArea, LabelWrapper, Separator, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import TeamCover from '../../components/team-cover';
import { TrashSvg } from 'assets/icons';
import { useUpdateTeam } from '../../hooks/use-update-team';
import { useModal } from 'utils/hooks/use-modal';
import ChangeTeamCoverModal from '../../components/change-team-cover-modal';
import { useTeamsProvider } from 'pages/teams/teams-data';
import Skeleton from 'react-loading-skeleton';
import { useTeamsPermissions } from 'pages/teams/hooks/use-teams-permissions';
import { useTheme } from 'styled-components';

const TeamSettings: React.FC = () => {
  const { t } = useTranslation();
  const { teamId } = useParams<{ teamId: string }>();
  const theme = useTheme();

  const changeTeamCoverModal = useModal();
  const { deleteTeamModal } = useTeamsProvider();
  const {
    currentTeam,
    loadingTeamInfo,
    isFormEditable,
    setIsFormEditable,
    saveCurrentCover,
    currentCover,
    formError,
    resetValues,
    onChangeInput,
    handleSubmit,
  } = useUpdateTeam(teamId ?? '');

  const inputsValues = isFormEditable
    ? {
        name: undefined,
        description: undefined,
      }
    : {
        name: currentTeam?.name,
        description: currentTeam?.description,
      };

  const discardEdit = () => {
    resetValues();
    setIsFormEditable(false);
  };

  const handleSelectCover = (newCoverColor: string, newImage?: File) => {
    saveCurrentCover(newCoverColor, newImage);
    changeTeamCoverModal.setModalOpen(false);
  };

  const { canCreateAndDeleteTeams, hasWritePermissions, isSupportRatedPowerCompany } = useTeamsPermissions();

  return (
    <TeamContentContainer>
      <TopbarTeamContent />
      <S.FormContainer onSubmit={handleSubmit}>
        <S.Header>
          <Typography size="l" weight="bold">
            {t('user:teams.team-info')}
          </Typography>
          {isFormEditable ? (
            <S.FormButtons>
              <Button variant="ghost" text={t('action:discard')} onClick={discardEdit} textUnderlined={false} />
              <Button variant="accent" type="submit" text={t('action:save')} />
            </S.FormButtons>
          ) : (
            (hasWritePermissions || isSupportRatedPowerCompany) && (
              <Button variant="link" text={t('action:edit')} onClick={() => setIsFormEditable(true)} textUnderlined />
            )
          )}
        </S.Header>
        <S.FormInputs>
          <LabelWrapper alignItems="flex-start" title={t('user:teams.team-cover')} size="l">
            {loadingTeamInfo ? (
              <Skeleton width={276} height={120} />
            ) : (
              <>
                <TeamCover
                  cover={currentCover ?? currentTeam?.coverColor ?? currentTeam?.coverImage?.highResUrl}
                  width={208}
                  height={140}
                  editable={isFormEditable}
                  onEdit={() => changeTeamCoverModal.setModalOpen(true)}
                />
                <Separator />
              </>
            )}
          </LabelWrapper>
          <LabelWrapper title={t('user:teams.team-name')} size="l">
            {loadingTeamInfo && <Skeleton height={16} width={100} />}
            {currentTeam && (
              <InputText
                value={inputsValues.name}
                readOnly={!isFormEditable}
                name="name"
                defaultValue={currentTeam?.name}
                warning={!!formError}
                onChange={(value: string) => onChangeInput('name', value)}
              />
            )}
          </LabelWrapper>
          <Separator />
          <LabelWrapper title={t('description')} size="l">
            {loadingTeamInfo && <Skeleton height={16} width={100} />}
            <InputTextArea
              value={inputsValues.description}
              name="description"
              defaultValue={currentTeam?.description}
              size="m"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChangeInput('description', e.target.value)}
              readOnly={!isFormEditable}
            />
          </LabelWrapper>
          <Typography size="s">{formError?.name ? formError?.name : formError}</Typography>
        </S.FormInputs>
        <Separator marginBottom={theme.v2.spacing.m} />
        {canCreateAndDeleteTeams && (
          <Button
            disabled={!isFormEditable}
            variant="ghost"
            Icon={TrashSvg}
            text={t('user:teams.delete-team')}
            onClick={() => {
              deleteTeamModal.setData({ id: teamId, teamName: currentTeam?.name });
              deleteTeamModal.setModalOpen(true);
            }}
          />
        )}
        <Separator marginTop={theme.v2.spacing.m} />
      </S.FormContainer>
      {changeTeamCoverModal.isOpen && (
        <ChangeTeamCoverModal
          title={t('user:teams.change-team-cover')}
          isActive={changeTeamCoverModal.isOpen}
          onSelectCover={handleSelectCover}
          onClose={() => changeTeamCoverModal.setModalOpen(false)}
          onClickSecondaryBtn={() => changeTeamCoverModal.setModalOpen(false)}
          disableClickOutside
        />
      )}
    </TeamContentContainer>
  );
};

export default TeamSettings;
