import React, { useEffect, useState } from 'react';
import { IBessDesign, IDesign, SIMULATION_CATEGORY } from 'types/design';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DESIGNS_STATUS } from 'utils/constants/tabs';
import { DesignItemContainer } from './design-item.styled';
import DesignItemDetails from './design-item-details';
import { CustomTag, DesignColumn } from '../../design-column.styled';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { DESIGN_ACTIONS as action } from 'utils/constants/designs';
import DesignItemLoading from './loading/design-item-loading';
import classNames from 'classnames';
import { getDynamicDate } from 'utils/date';
import {
  Button,
  Checkbox,
  ConditionalWrapper,
  EditableInput,
  IPopupIconButtonOption,
  PopupWrapper,
  StatusSVGWrapper,
  Tooltip,
  Typography,
} from '@ratedpower/components';
import { hasValidCharacters, PROJECT_DESIGN_UNALLOWED_CHARS } from 'utils/validation';
import {
  ChevronDownSvg,
  ChevronUpSvg,
  CloneSvg,
  DotsSvg,
  FavouriteOutlineSvg,
  FavouriteSvg,
  FolderExportSvg,
  FolderImportSvg,
  TrashSvg,
} from 'assets/icons';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { isSimulationInProgress } from 'utils/constants/status';
import { TFunction } from 'i18next';
import { SUPPORT_EMAIL } from 'utils/constants/contact';
import { buildRelativeDesignProjectUrl } from 'utils/navigation/navigation';
import { getTagTextConfig, selectedColor } from 'utils/design-type-functions';
import BessDesignItemDetails from './bess-design-item-details';

interface IProps {
  item: IDesign | IBessDesign;
  showDetails?: boolean;
  selected: boolean;
  onEditName: (newName: string) => void;
  onPerform: (action: string) => void;
}

interface IDesignItemElectricalValues {
  ratedPower: string;
  peakPower: string;
  specificProduction: string;
  gcr: string;
}

const NO_VALUE = '-';

const getArchiveOption = (status: string, t: TFunction) => {
  return status === DESIGNS_STATUS.archived
    ? {
        Icon: () => <FolderExportSvg />,
        label: t('action:unarchive'),
        value: action.unarchive,
      }
    : {
        Icon: () => <FolderImportSvg />,
        label: t('action:archive'),
        value: action.archive,
      };
};

const DesignItem: React.FC<IProps> = ({ item, showDetails, onEditName, selected, onPerform }) => {
  const { t } = useTranslation(),
    { getValueOf } = useConverter(),
    canCloneDesigns = useCanCloneDesigns(),
    { canEditDesign } = useCanEditDesigns(),
    canEditThisDesign = canEditDesign(item.createdBy.id, item.projectTeamId),
    [showItemDetails, setShowItemDetails] = useState(false),
    isSimulationDesignInProgress: boolean = isSimulationInProgress(item.simulationResult),
    designWithError: boolean = !isSimulationDesignInProgress && item.simulationResult !== 'OK',
    tagText: string = t(getTagTextConfig(item)),
    options = [
      getArchiveOption(item.status, t),
      {
        Icon: () => <TrashSvg />,
        label: t('action:delete'),
        value: action.delete,
      },
    ],
    electricalValues: IDesignItemElectricalValues = designWithError
      ? {
          ratedPower: NO_VALUE,
          peakPower: NO_VALUE,
          specificProduction: NO_VALUE,
          gcr: NO_VALUE,
        }
      : {
          ratedPower: getValueOf(designAttributes.power.ratedPower, item),
          peakPower: getValueOf(designAttributes.power.peakPower, item),
          specificProduction: getValueOf(designAttributes.energy.specificProduction, item),
          gcr: getValueOf(designAttributes.equipment.gcr, item),
        };

  function isPvDesign(item: IDesign | IBessDesign): item is IDesign {
    return item.simulationCategory === SIMULATION_CATEGORY.PV;
  }

  useEffect(() => {
    if (showDetails !== undefined) {
      setShowItemDetails(showDetails);
    }
  }, [showDetails]);

  const handleStopPropagation = (e: any): void => {
    e.stopPropagation();
  };

  const handleToggleDetail = (e: any): void => {
    e.preventDefault();
    setShowItemDetails(!showItemDetails);
  };

  const handleClone = (e: any): void => {
    e.preventDefault();
    onPerform(action.clone);
  };

  const handleFavourite = (e: any): void => {
    e.preventDefault();
    onPerform(action.favorite);
  };

  const handleCheckBox = (): void => {
    onPerform(action.select);
  };

  const handleGetHelp = (): void => {
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  if (isSimulationDesignInProgress) {
    return <DesignItemLoading item={item} onPerform={onPerform} />;
  }

  return (
    <Link to={buildRelativeDesignProjectUrl(item.id, item.simulationCategory)}>
      <DesignItemContainer className={classNames('item', { selected })} $designWithError={designWithError}>
        <div className="main-row">
          <DesignColumn className="checkbox" onClick={handleStopPropagation}>
            <Checkbox id={item.id} checked={selected} onChange={handleCheckBox} />
          </DesignColumn>

          <DesignColumn className="favorite" onClick={handleStopPropagation}>
            <StatusSVGWrapper
              onClick={handleFavourite}
              disabled={!canEditThisDesign}
              active={item.isFavorite}
              icon={item.isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
              size="m"
            />
          </DesignColumn>

          <DesignColumn className="first-column">
            {canEditThisDesign ? (
              <EditableInput
                validationFunction={hasValidCharacters(PROJECT_DESIGN_UNALLOWED_CHARS)}
                value={item.name}
                titleSize="m"
                onEditName={onEditName}
                className="editable-input"
              />
            ) : (
              <ConditionalWrapper
                condition={item.name.length > 40}
                wrapper={(children) => <Tooltip description={item.name}>{children}</Tooltip>}
              >
                <Typography as="h3" className="name" weight={'bold'} nowrap>
                  {item.name}
                </Typography>
              </ConditionalWrapper>
            )}
          </DesignColumn>
          <DesignColumn className="data tags">
            <CustomTag color={selectedColor(item)}>{tagText}</CustomTag>
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{electricalValues.ratedPower}</Typography>
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{electricalValues.peakPower}</Typography>
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{electricalValues.specificProduction}</Typography>
          </DesignColumn>
          <DesignColumn className="data">
            <Typography>{electricalValues.gcr}</Typography>
          </DesignColumn>
          <DesignColumn className="createdAt">
            <Typography>
              {t('design:design-date', {
                dateFormat: getDynamicDate(item.publishedDate),
              })}
            </Typography>
          </DesignColumn>
          <DesignColumn className="more collapsed" onClick={handleStopPropagation}>
            {!designWithError ? (
              <StatusSVGWrapper
                icon={showItemDetails ? ChevronUpSvg : ChevronDownSvg}
                tooltip={{
                  text: t('action:show-detail'),
                  place: 'bottom',
                }}
                size={'s'}
                onClick={handleToggleDetail}
                active={false}
              />
            ) : (
              <Button onClick={handleGetHelp} text={t('design:get-help')} variant="link" textUnderlined />
            )}
            <StatusSVGWrapper
              icon={CloneSvg}
              tooltip={{
                text: t('action:clone'),
                place: 'bottom',
              }}
              size={'s'}
              onClick={handleClone}
              active={!canCloneDesigns}
            />
            {canEditThisDesign && (
              <PopupWrapper
                popupPosition="leftCenter"
                onClickOption={(selectedOption: IPopupIconButtonOption) => onPerform(selectedOption.value)}
                options={options}
                dataTest="more-btn"
              >
                <StatusSVGWrapper
                  active={false}
                  icon={DotsSvg}
                  tooltip={{ text: t('action:more'), place: 'bottom' }}
                  size={'s'}
                />
              </PopupWrapper>
            )}
          </DesignColumn>
        </div>
        {item.status !== 'ERROR' && (
          <div
            className={classNames('detail-row', {
              hidden: !showItemDetails,
            })}
          >
            <DesignColumn className="checkbox" />
            {canEditThisDesign && <DesignColumn className="favorite" />}
            <DesignColumn className="name" />
            <DesignColumn className="details">
              {isPvDesign(item) ? (
                <DesignItemDetails {...{ item }} hidden={!showItemDetails} />
              ) : (
                <BessDesignItemDetails {...{ item }} hidden={!showItemDetails} />
              )}
            </DesignColumn>
            <DesignColumn className="createdAt" />
            {canEditThisDesign && <DesignColumn className="more" />}
          </div>
        )}
      </DesignItemContainer>
    </Link>
  );
};

export default DesignItem;
