import {
  IConverter,
  IConvertedValueToShow,
  IConvertToShowOptions,
  IConvertToSendOptions,
} from 'types/preferences.types';
import { stringLitArray } from '../types';

const dimensionUnitsKeys = stringLitArray(['default', 'K', 'M', 'G', 'T']);
export type DimensionUnit = typeof dimensionUnitsKeys[number];

interface IDimensionConverter {
  factor: number;
  unit: string;
}
interface IDimensionUnitConverter {
  default: IDimensionConverter;
  K: IDimensionConverter;
  M: IDimensionConverter;
  G: IDimensionConverter;
  T: IDimensionConverter;
}

// Units for conversion
const DimensionUnits: IDimensionUnitConverter = {
  default: { factor: 1, unit: '' },
  K: { factor: 1e-3, unit: 'k' },
  M: { factor: 1e-6, unit: 'M' },
  G: { factor: 1e-9, unit: 'G' },
  T: { factor: 1e-12, unit: 'T' },
};

const MAX_VALUE_FOR_CONVERSION = 1000;

/**
 * Finds the dimension unit for convert all array values.
 * The reference unit returned will be one that fit with the maximum value.
 */
export function getDimensionUnitForArrayConvertion(data: number[]): DimensionUnit {
  const maxValue = Math.max(...data);
  return autoDimension(maxValue).unit.toUpperCase();
}

/**
 * Finds the best suitable unit dimension for converting some value
 *
 * @param {number} maxValue: the value to use as reference for the conversion
 * @param {Object} units: a valid units object (such as ENERGY_UNITS)
 */
function autoDimension(maxValue: number) {
  // Search for a valid dimension unit, which satisfies the condition that the biggest number ends up
  // lower than MAX_VALUE_FOR_CONVERSION when converted
  const suitableDimension = Object.values(DimensionUnits).find((e) => e.factor * maxValue < MAX_VALUE_FOR_CONVERSION);
  if (suitableDimension) return suitableDimension;

  // return the biggest unit
  return Object.values(DimensionUnits)[Object.keys(DimensionUnits).length - 1];
}

export const dimensionConverter = (): IConverter => {
  function toShow(value: number, options?: IConvertToShowOptions): IConvertedValueToShow {
    const converter = options?.dimension ? DimensionUnits[options?.dimension] : autoDimension(value);

    let convertedValue = value * converter.factor;
    if (options?.decimals !== undefined) convertedValue = parseFloat(convertedValue.toFixed(options?.decimals ?? 1));
    return { value: convertedValue, unit: converter.unit };
  }

  function toSend(value: number, options?: IConvertToSendOptions) {
    if (options?.dimension) {
      const converter = DimensionUnits[options?.dimension];
      return value / converter.factor;
    }

    return value;
  }

  return {
    toShow,
    toSend,
  };
};
