import React, { useState } from 'react';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import { RPTable } from '@ratedpower/components';
import TopbarTeamContent from './topbar-team-content';
import { ErrorWrapper } from './topbar-team-content/topbar-team-content.styled';
import { TeamContentContainer } from './team-management.styled';
import { useListTeamMembers } from '../hooks/use-list-team-members';
import UsersTabFiltersPanel from 'pages/users-management/users-tab/users-tab-filters-panel/users-tab-filters-panel';
import { IServerFilter } from 'types/filter';
import UserTeamsAssignedModal from '../components/user-teams-assigned-modal';
import UnassignMemberModal from '../components/unsasign-member-modal';
import { useTranslation } from 'react-i18next';
import { USERS_PER_PAGE } from 'utils/constants/pagination';
import { useLocation, useParams } from 'react-router-dom';

const TeamMembers: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { teamId } = useParams<{ teamId: string }>();
  const [filterPanelOpened, setFilterPanelOpened] = useState(false);

  const routeState = location.state as { teamName: string };

  const handleApplyFilters = (filters: IServerFilter[]) => {
    setFilterPanelOpened(false);
    setFilters(filters);
  };

  const handleOpenFiltersPanel = (open: boolean) => () => setFilterPanelOpened(open);

  const { table, error, inputSearchProps, teamsAssignedModal, unassignMemberModal, paginationComponent, setFilters } =
    useListTeamMembers({
      showCurrentTeamMembers: true,
      teamId: teamId ?? '',
      canUnassign: true,
      pageSize: USERS_PER_PAGE,
    });

  return (
    <TeamContentContainer>
      <TopbarTeamContent onSearch={inputSearchProps.onChange} onOpenFiltersPanel={handleOpenFiltersPanel(true)} />
      {table && !error && <RPTable table={table as any} zeroResultsFoundText={t('zero-results-found')} />}
      {paginationComponent}
      <UsersTabFiltersPanel
        id="team-members-filters"
        active={filterPanelOpened}
        onHide={handleOpenFiltersPanel(false)}
        onApplyFilters={handleApplyFilters}
      />
      {error && (
        <ErrorWrapper>
          <ErrorRetrievingData />
        </ErrorWrapper>
      )}
      {teamsAssignedModal.isOpen && (
        <UserTeamsAssignedModal
          title={t('user:teams.teams-assigned')}
          isActive={teamsAssignedModal.isOpen}
          userId={teamsAssignedModal.data}
          onClose={() => teamsAssignedModal.setModalOpen(false)}
        />
      )}
      {unassignMemberModal?.isOpen && (
        <UnassignMemberModal
          selectedTeamId={teamId ?? ''}
          description={t('user:teams.unassign-member-modal-description', {
            name: unassignMemberModal.data.userName,
            team: routeState?.teamName,
          })}
          selectedUserId={unassignMemberModal.data.userId}
          title={t('user:teams.remove-member-from-team')}
          isActive={unassignMemberModal?.isOpen}
          onClose={() => unassignMemberModal.setModalOpen(false)}
        />
      )}
    </TeamContentContainer>
  );
};

export default TeamMembers;
