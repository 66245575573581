import React from 'react';
import * as S from './team-management.styled';
import { Separator } from '@ratedpower/components';
import TeamsSidebar from './teams-sidebar';
import TeamProjects from './team-projects';
import { teamTabs } from 'utils/teams';
import TeamModals from './team-modals';
import TeamMembers from './team-members';
import TeamSettings from './team-settings';
import { useParams } from 'react-router-dom';

const TeamManagement: React.FC = () => {
  const { contentType } = useParams<{ contentType: string }>();

  return (
    <S.PageContainer>
      <TeamsSidebar />
      <Separator position="vertical" />
      {contentType === teamTabs[0].id && <TeamProjects />}
      {contentType === teamTabs[1].id && <TeamMembers />}
      {contentType === teamTabs[2].id && <TeamSettings />}
      <TeamModals />
    </S.PageContainer>
  );
};

export default TeamManagement;
