import React from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANY_TABS as tabs } from 'utils/constants/tabs';
import { Helmet } from 'react-helmet';
import { Breadcrumb, TopbarWrapper, Tabs, ITab } from '@ratedpower/components';
import { IntegrationSvg, BriefcaseSvg, SubscriptionSvg } from 'assets/icons';
import { useTheme } from 'styled-components';

interface IProps {
  onTabChange: (tab: string) => void;
  activeTab: any;
}

const TopbarCompany: React.FC<IProps> = ({ onTabChange, activeTab }) => {
  const { t } = useTranslation(),
    pageTabs: ITab[] = [
      {
        id: tabs.account,
        label: t(`company:${tabs.account}`),
        Icon: BriefcaseSvg,
      },
      {
        id: tabs.plan,
        label: t(`company:${tabs.plan}`),
        Icon: SubscriptionSvg,
      },
      {
        id: tabs.integrations,
        label: t(`company:${tabs.integrations}`),
        Icon: IntegrationSvg,
      },
    ],
    theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{t('company:my-company')}</title>
      </Helmet>

      <TopbarWrapper type="main" left={<Breadcrumb currentPage={{ name: t('company:my-company') }} />} />
      <TopbarWrapper
        padding={theme.v2.spacing.l}
        type="secondary"
        left={<Tabs onClickTab={onTabChange} tabs={pageTabs} tabActive={activeTab} />}
        noBorder
      />
    </>
  );
};

export default TopbarCompany;
