import styled, { css } from 'styled-components';

const HoverEffect = css`
  border: 1px solid ${({ theme }) => theme.primary};
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const TeamCoverTitle = styled.div`
  display: flex;
  justify-content: space-between;

  .cover-image {
    max-width: 208px;
  }
`;

export const TeamCoverContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const ColorPickerWrapper = styled.div<{ selected: boolean }>`
  .selected-color {
    ${({ selected }) => selected && HoverEffect}
    &:hover {
      ${HoverEffect}
    }
  }
`;

export const DefaultCoverColor = styled.div<{ color: string; selected: boolean }>`
  ${({ selected }) => selected && HoverEffect}

  height: 64px;
  width: 64px;
  background-color: ${({ color }) => color};
  cursor: pointer;

  &:hover {
    ${HoverEffect}
  }
`;

export const UploadFileContainer = styled.label`
  height: 64px;
  width: 64px;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.gray_light};
  cursor: pointer;
  text-align: center;
  padding-top: 22px;

  input[type='file'] {
    display: none;
  }

  &:hover {
    ${HoverEffect}
  }
`;
