import { SCREEN_LOCATION } from 'utils/constants/toasts';
import { ToastType } from 'utils/hooks/use-toasts';
import { useTranslation } from 'react-i18next';

export enum NotificationsOperation {
  CREATE = 'create',
  DELETE = 'delete',
  EDIT = 'edit',
  READ = 'read',
  UNREAD = 'unread',
  READ_ALL = 'read-all',
}

const useGetNotificationToast = () => {
  const { t } = useTranslation();

  return (operation: NotificationsOperation, type: ToastType, variant?: number) => {
    let messageKey = `communication:notifications-toasts.${operation}.${
      type === ToastType.SUCCESS ? 'success' : 'error'
    }`;

    if (variant) messageKey += `_${variant}`;

    return {
      location: SCREEN_LOCATION.topCenter,
      message: t(messageKey),
      type,
      duration: 5000,
    };
  };
};

export default useGetNotificationToast;
