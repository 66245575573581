import { t } from 'i18next';
import React from 'react';
import { DESIGN_COMPARISON_CHARTS } from 'utils/constants/design-comparison';
import { IPropsDesignComparisonLineChart } from '../design-comparison-line-chart/design-comparison-line-chart';
import { IComparisonFilter } from 'types/design-comparison.types';
import { IDesignAttributes } from 'utils/constants/design-attributes';
import { getTickFormatter } from './utils';
import { ChartFunction } from './types';

export const graphicLineAttributes = (
  designs: any[],
  designAttributes: IDesignAttributes,
  activeAttribute: IComparisonFilter
): IPropsDesignComparisonLineChart | null => {
  const chartFunction = chartFunctions[activeAttribute.label];
  if (!chartFunction) return null;
  return chartFunction(designs, designAttributes);
};

const getMonthlyEnergy = (designs: any[], designAttributes: IDesignAttributes): IPropsDesignComparisonLineChart => {
  return {
    title: t('design:comparison-charts.monthly-energy'),
    data: designs,
    complexAttr: designAttributes.energy.monthlyProduction,
    xAttr: {
      key: 'month',
      label: 'common:month',
      render: (data) => t(`month-${data}`),
    },
    tickFormatterX: getTickFormatter(3, false),
    yAttr: {
      key: 'energyToGrid',
      label: 'energy',
      converter: {
        name: 'dimension',
      },
      render: (data) => (
        <>
          {data.value}
          <span className="unit">{` ${data.unit}Wh`}</span>
        </>
      ),
    },
    tooltipLabels: designs.map((e) => ({ [e.id]: e.name })).reduce((obj, item) => Object.assign(obj, item), {}),
    formatUnits: (dimensionUnits) => ({
      x: '',
      y: `${dimensionUnits.y}Wh`,
    }),
    formatAxisLabels: (dimensionUnits) => ({
      x: `${t('common:month')}`,
      y: `${t('design:energy')} (${dimensionUnits.y}Wh)`,
    }),
  };
};

const chartFunctions: {
  [key: string]: ChartFunction<IPropsDesignComparisonLineChart>;
} = {
  [DESIGN_COMPARISON_CHARTS.monthlyEnergy]: getMonthlyEnergy,
};
