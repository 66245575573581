import React, { useState } from 'react';
import { t } from 'i18next';
import { IRoleFilters } from 'types/users';
import { initialRoleFilters, ROLE_STATUSES } from 'utils/constants/users';
import { ROLE_STATUS, ROLE_TYPE } from 'types/role';
import { IServerFilter } from 'types/filter';
import { FilterSidebar, FilterSidebarItem, RadioGroup } from '@ratedpower/components';

export interface IRoleTabFiltersPanel {
  active: boolean;
  onHide: () => void;
  onApplyFilters: (filters: IServerFilter[]) => void;
}

const RolesTabFiltersPanel: React.FC<IRoleTabFiltersPanel> = ({ active, onHide, onApplyFilters }) => {
  const [currentFilters, setCurrentFilters] = useState<IRoleFilters>(initialRoleFilters);

  const handleApply = () => {
    const serverFilters: IServerFilter[] = [];
    if (currentFilters.status) {
      serverFilters.push({
        field: 'STATUS',
        operator: 'IN',
        values: [currentFilters.status],
      });
    }
    if (currentFilters.type) {
      serverFilters.push({
        field: 'TYPE',
        operator: 'IN',
        values: [currentFilters.type],
      });
    }
    onApplyFilters(serverFilters);
  };

  const handleReset = () => {
    setCurrentFilters(initialRoleFilters);
  };

  return (
    <FilterSidebar
      onClose={onHide}
      isOpen={active}
      onApply={handleApply}
      onClear={handleReset}
      onClearTitle={t('action:reset')}
      onApplyTitle={t('action:apply')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem title={t('user:roles.type')} hasBorderBottom>
        <RadioGroup
          selectedValue={currentFilters.type ?? ''}
          direction="column"
          id="role-type"
          onChange={(option) =>
            setCurrentFilters({
              ...currentFilters,
              type: option.value,
            })
          }
          options={[
            {
              label: t('all'),
              value: '',
            },
            ...Object.values(ROLE_TYPE).map((option) => ({
              label: t(`user:roles.${option}`),
              value: option,
            })),
          ]}
        />
      </FilterSidebarItem>
      <FilterSidebarItem title={t('user:filters.status')}>
        <RadioGroup
          selectedValue={currentFilters.status ?? ''}
          direction="column"
          id="role-status"
          onChange={(option) =>
            setCurrentFilters({
              ...currentFilters,
              status: option.value,
            })
          }
          options={[
            {
              label: t('all'),
              value: '',
            },
            ...Object.values(ROLE_STATUS).map((option) => ({
              label: t(`user:${ROLE_STATUSES[option]}`),
              value: option,
            })),
          ]}
        />
      </FilterSidebarItem>
    </FilterSidebar>
  );
};

export default RolesTabFiltersPanel;
