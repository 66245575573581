import React from 'react';
import {
  CardHeader,
  CardHeaderAuthorRow,
  CardHeaderMainRow,
  CardHeaderTitleRow,
  DesignCardContainer,
  MainRowTypography,
  ProjectTitle,
  StyledTag,
} from './design-card.styled';
import { useTranslation } from 'react-i18next';
import { IDesign } from 'types/design';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { Checkbox, StatusSVGWrapper, Typography } from '@ratedpower/components';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { GenericStatus } from 'utils/constants/status';
import { FavouriteOutlineSvg } from 'assets/icons/index';
import { getDynamicDate } from 'utils/date';
import { getTagTextConfig, selectedColor } from 'utils/design-type-functions';

interface IProps {
  item: IDesign;
  selected: boolean;
  onSelect: (id: string) => void;
  onFavorite: (item: any) => void;
}

interface TagConfig {
  variant: string;
  text: string;
}

const DesignCard: React.FC<IProps> = ({ item, selected, onSelect, onFavorite }) => {
  const { t } = useTranslation(),
    { getValueOf } = useConverter(),
    { canEditDesign } = useCanEditDesigns(),
    canEditThisDesign: boolean = canEditDesign(item.createdBy.id, item.projectTeamId),
    itemHasErrorStatus: boolean = item.status === GenericStatus.ERROR,
    tagConfig: TagConfig = { variant: selectedColor(item), text: t(getTagTextConfig(item)) };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    e.preventDefault();
    onSelect(id);
  };

  const handleFavorite = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    onFavorite(item);
  };

  return (
    <DesignCardContainer
      className={selected ? 'selected' : ''}
      data-testid="design-card-container"
      onClick={() => onSelect(item.id)}
    >
      <div className="card-image">
        <StyledTag variant={tagConfig.variant}>{tagConfig.text}</StyledTag>
        {!itemHasErrorStatus && getValueOf(designAttributes.satellitePicture.thumbnail, item)}
      </div>
      <CardHeader onClick={(e) => e.stopPropagation()}>
        <CardHeaderTitleRow>
          <Checkbox id={item.id} checked={selected} onChange={(e) => handleCheckboxChange(e, item.id)} />
          <ProjectTitle>
            {item.number}. {item.name}
          </ProjectTitle>
          <StatusSVGWrapper
            size={'s'}
            onClick={handleFavorite}
            active={item.isFavorite}
            icon={FavouriteOutlineSvg}
            disabled={!canEditThisDesign}
          />
        </CardHeaderTitleRow>
        <CardHeaderMainRow>
          {!itemHasErrorStatus ? (
            <>
              <MainRowTypography>{getValueOf(designAttributes.power.ratedPower, item)}</MainRowTypography>
              <MainRowTypography>{getValueOf(designAttributes.power.peakPower, item)}</MainRowTypography>
              <MainRowTypography>{getValueOf(designAttributes.energy.specificProduction, item)}</MainRowTypography>
              <MainRowTypography>{getValueOf(designAttributes.equipment.gcr, item)}</MainRowTypography>
            </>
          ) : (
            <div className="error">
              <p>ERROR :D</p>
            </div>
          )}
        </CardHeaderMainRow>
        <CardHeaderAuthorRow>
          <Typography size="s">{`${item.createdBy.name} ${item.createdBy.lastname}`}</Typography>
          <Typography size="s">
            {t('design:design-date', {
              dateFormat: getDynamicDate(item.publishedDate),
            })}
          </Typography>
        </CardHeaderAuthorRow>
      </CardHeader>
    </DesignCardContainer>
  );
};

export default DesignCard;
