import { DimensionUnit } from 'utils/conversor/dimension';
import { stringLitArray } from 'utils/types';

const unitSystems = stringLitArray(['INTERNATIONAL', 'IMPERIAL']);
export type UnitSize = 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
export type Converter =
  | 'temperature'
  | 'length'
  | 'surface'
  | 'invertedSurface'
  | 'percentage'
  | 'dimension'
  | 'decimal'
  | 'area'
  | 'elasticity'
  | 'unitaryMass';

export type UnitSystem = (typeof unitSystems)[number];
export const isUnitSystem = (x: any): x is UnitSystem => unitSystems.includes(x);

export interface IUnitSystemPreferences {
  unitsId: string;
  temperature: UnitSystem;
  length: UnitSystem;
  surface: UnitSystem;
}

export interface IConvertedValueToShow {
  value: any;
  unit?: string;
}

interface IUnitConversor {
  unit: string;
  // From server's unit, convert to this unit
  toShow: (value: number) => number;
  // From this unit, convert to server expected unit
  toSend: (value: number) => number;
}
export interface IUnits {
  INTERNATIONAL: {
    XXL?: IUnitConversor;
    XL?: IUnitConversor;
    L?: IUnitConversor;
    M: IUnitConversor;
    S?: IUnitConversor;
    XS?: IUnitConversor;
  };
  IMPERIAL: {
    XXL?: IUnitConversor;
    XL?: IUnitConversor;
    L?: IUnitConversor;
    M: IUnitConversor;
    S?: IUnitConversor;
    XS?: IUnitConversor;
  };
}

export interface IConvertToShowOptions {
  decimals?: number;
  size?: UnitSize;
  dimension?: DimensionUnit;
  trailingZeros?: boolean;
  exponentialNotation?: boolean;
  displayUnit?: string;
}
export interface IConvertToSendOptions {
  size?: UnitSize;
  dimension?: DimensionUnit;
}

export interface ICallingCode {
  name: string;
  callingCodes: string[];
}
export interface IConverter {
  getUnit?: (size: UnitSize) => string;
  // From server's unit, convert to this unit
  toShow: (value: number, options?: IConvertToShowOptions) => IConvertedValueToShow;
  // From this unit, convert to server expected unit
  toSend: (value: number, options?: IConvertToSendOptions) => number;
}

export type IConverters = {
  [key in Converter]: IConverter;
} & {
  temperature: IConverter;
  length: IConverter;
  surface: IConverter;
  percentage: IConverter;
  dimension: IConverter;
  decimal: IConverter;
  area: IConverter;
  elasticity: IConverter;
  unitaryMass: IConverter;
  volume: IConverter;
};
