import { Modal, Typography } from '@ratedpower/components';
import styled from 'styled-components';

export const ErrorInfoModalContainer = styled(Modal)`
  max-height: 90% !important;
  padding: 0;
`;

export const ModalTitle = styled(Typography)`
  padding: ${({ theme }) => theme.v2.spacing.l};
  background-color: ${({ theme }) => theme.v2.error.bg.default};
`;

export const ModalContent = styled.div`
  padding: 20px;
`;

export const ModalImg = styled.img`
  max-width: 60%;
  min-width: 300px;
  margin: 0 auto;
`;
