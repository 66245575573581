import styled from 'styled-components';
import { HexColorPicker } from 'react-colorful';

export const Container = styled.div<{ colorWidth: number; hexPanelWidth: number }>`
  position: relative;

  .react-colorful {
    position: absolute;
    top: ${({ colorWidth }) => String(colorWidth + 8 + 'px')};
    z-index: 1;
    width: ${({ hexPanelWidth }) => hexPanelWidth + 'px'};
    height: ${({ hexPanelWidth }) => hexPanelWidth + 'px'};
  }
`;

export const HexPickerContainer = styled.div<{ top: number; right: number }>`
  position: absolute;
  z-index: 1002;
`;

export const HexPicker = styled(HexColorPicker)``;

export const HexImg = styled.img`
  transition: ${({ theme }) => theme.animation_time};
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
`;

export const SelectedColor = styled.div<{ color: string; width: string }>`
  cursor: pointer;
  background-color: ${({ color }) => color};
  width: ${({ width }) => width};
  height: ${({ width }) => width};
  border-radius: 50%;
`;
