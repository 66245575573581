import styled, { css } from 'styled-components';

export const TopbarContainer = styled.div<{ $companyHasTeams: boolean }>`
  ${({ $companyHasTeams }) =>
    !$companyHasTeams &&
    css`
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      column-gap: 32px;
    `}
`;

export const TopbarLeftContainer = styled.div<{ $companyHasTeams: boolean }>`
  display: flex;
  justify-content: ${({ $companyHasTeams }) => ($companyHasTeams ? 'space-between' : 'flex-end')};
`;

export const MainView = styled.div<{ $rowLength: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  ${({ $rowLength }) =>
    $rowLength >= 5 &&
    css`
      overflow-y: auto;
    `};

  th {
    width: 15%;
  }

  .pagination-container {
    margin-top: 1.5rem;
  }

  /* Temporal patch solution until the web gets responsive */
  @media screen and (min-width: 1400px) {
    .table-container {
      overflow-x: initial;

      tr {
        &:nth-last-child(-n + 3) {
          td .option-list ul,
          td .option-list {
            position: absolute;
            top: 16px;
          }
        }
      }
    }
  }
`;

export const TableActions = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  align-items: center;
  justify-content: right;

  &.disabled {
    svg {
      cursor: pointer;
      color: ${({ theme }) => theme.gray_dark};
    }
  }
`;

export const UsersTabWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

export const UsersTabContent = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.l};
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
`;
