import styled from 'styled-components';

interface IProps {
  $hasBanner: boolean;
}

export const DesignsContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  .pagination {
    display: flex;
    justify-content: center;
    padding: 4px 0;
    width: 100%;
    background-color: ${({ theme }) => theme.v2.bg.primary.default};
  }
`;

export const DesignsContent = styled.div`
  position: relative;
  padding: ${({ theme }) => `${theme.v2.spacing.l} ${theme.v2.spacing.l} 0 ${theme.v2.spacing.l}`};
  overflow-y: auto;
  height: 100%;

  .data-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    flex-grow: 1;

    .data-list-scrolleable {
      padding-bottom: ${({ theme }) => theme.v2.spacing.l};

      &:not(.error) {
        flex-grow: 1;
        overflow-y: auto;
      }

      height: 100%;
    }

    .stars-label {
      align-items: center;
      display: flex;
    }
  }
`;

interface IPaddingWrapperProps {
  $padding: string;
}

export const PaddingWrapper = styled.div<IPaddingWrapperProps>`
  padding: ${({ $padding }) => $padding};
  height: 100%;
  display: flex;
  flex-direction: column;
`;
