import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GET_USER_ROLE_SUMMARY } from 'services/roles-gql';
import {
  ADD_MEMBERS_TO_TEAM,
  GET_COMPANY_USERS,
  GET_TEAMS,
  GET_TEAMS_BY_COMPANY,
  GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM,
  GET_TEAM_MANAGER_TEAMS_MEMBERS,
} from 'services/team-gql';
import { ITeam } from 'types/teams';
import { useToasts } from 'utils/hooks/use-toasts';

export const useAddMembersToTeam = (teamId: string, callback?: () => void) => {
  const { t } = useTranslation();
  const { addSuccessToast, addErrorToast } = useToasts();
  const { id: companyId } = useParams<{ id: string }>();

  const refetchQueries = [GET_COMPANY_USERS, GET_TEAM_MANAGERS_THAT_BELONGS_TO_TEAM, GET_TEAM_MANAGER_TEAMS_MEMBERS];
  const refetchQueriesForSupport = [...refetchQueries, GET_TEAMS_BY_COMPANY];
  const refetchQueriesForUsers = [...refetchQueries, GET_TEAMS, GET_USER_ROLE_SUMMARY];

  const [addMember, { data, loading }] = useMutation(ADD_MEMBERS_TO_TEAM, {
    refetchQueries: companyId ? refetchQueriesForSupport : refetchQueriesForUsers,
    onCompleted: () => {
      addSuccessToast(t('user:teams.member-succesfully-added'));
      if (callback) callback();
    },
    onError: () => {
      addErrorToast(t('user:teams.error-adding-member'));
    },
  });

  const addMembersToTeam = (userIds: string[]) => {
    addMember({ variables: { teamId, memberIds: userIds } });
  };

  return {
    loading,
    addMembersToTeam,
    updatedTeam: data?.addMembersToTeam as ITeam | undefined,
  };
};
