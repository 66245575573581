import React, { useRef, useState } from 'react';
import * as S from './change-team-cover-modal-styled';
import { PlusSvg } from 'assets/icons';
import ColorPicker from 'components/color-picker/color-picker';
import { IModalProps, Modal, Button, Typography } from '@ratedpower/components';
import { getDefaultCoverColorsForUpdate } from 'utils/teams';
import UploadFile from 'components/upload/upload-file';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IProps extends IModalProps {
  onSelectCover: (newCoverColor: string, newImage?: File) => void;
}

const ChangeTeamCoverModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentColorPicked = useRef('');

  const [selectedCoverImage, setSelectedCoverImage] = useState<File | undefined>();
  const [selectedCoverColor, setSelectedCoverColor] = useState<string>('');

  const handleSelectColorCover = (color: string) => () => {
    setSelectedCoverColor(color);
    setSelectedCoverImage(undefined);
  };

  const handleSelectCoverImage = (file: File) => {
    setSelectedCoverImage(file);
    setSelectedCoverColor('');
  };

  const handleSelectCover = () => {
    props.onSelectCover(selectedCoverColor, selectedCoverImage);
  };

  return (
    <Modal {...props}>
      <S.TeamCoverTitle>
        <Typography className="cover-image" nowrap>
          {!selectedCoverColor && selectedCoverImage?.name}
        </Typography>
      </S.TeamCoverTitle>
      <S.ModalContent>
        <S.TeamCoverContainer>
          <S.ColorPickerWrapper
            selected={
              !!selectedCoverColor &&
              !getDefaultCoverColorsForUpdate(theme).includes(selectedCoverColor) &&
              !selectedCoverImage
            }
          >
            <ColorPicker
              onChangeColor={(newColor) => {
                currentColorPicked.current = newColor;
                handleSelectColorCover(newColor)();
              }}
              colorWheelWidth={76}
              hexPanelWidth={136}
              color={currentColorPicked.current || selectedCoverColor || ''}
              editable
            />
          </S.ColorPickerWrapper>
          {getDefaultCoverColorsForUpdate(theme).map((color) => (
            <S.DefaultCoverColor
              key={color}
              color={color}
              onClick={handleSelectColorCover(color)}
              selected={selectedCoverColor === color}
            />
          ))}
          <S.UploadFileContainer>
            <UploadFile
              onFileChange={handleSelectCoverImage}
              maxMbSize={1 * 1024 * 1024}
              accept="image/jpeg, image/png, image/jpg"
            />
            <PlusSvg />
          </S.UploadFileContainer>
        </S.TeamCoverContainer>
        <S.ActionButtons>
          <Button variant="text" text={t('action:cancel')} onClick={props.onClickSecondaryBtn} />
          <Button text={t('action:select')} onClick={handleSelectCover} type="button" />
        </S.ActionButtons>
      </S.ModalContent>
    </Modal>
  );
};

export default ChangeTeamCoverModal;
