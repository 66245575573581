import moment from 'moment';
import { resolveKey } from './objects';
import { isDate } from './validation';

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const getSortDirection = (sortAscendent: boolean) => {
  return sortAscendent ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;
};

const sortAlgorithm = (a: any, b: any) => {
  if (typeof a === 'string' && typeof b === 'string') {
    let aString: any = a.toString().toLowerCase();
    let bString: any = b.toString().toLowerCase();

    if (isDate(aString) && isDate(bString)) {
      aString = moment(aString, 'YYYY-MM-DD@HH:mm:ss').utc().unix();
      bString = moment(bString, 'YYYY-MM-DD@HH:mm:ss').utc().unix();

      return aString - bString;
    }

    if (aString < bString) return -1;
    if (bString < aString) return 1;
    return 0;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  // order undefined/null values as lowest values
  return !a ? -1 : 1;
};

function sortRecursive(a: any, b: any, selectors: string[], selectorIndex: number): number {
  const currentSelector = selectors[selectorIndex];

  const aValue = resolveKey(a, currentSelector);
  const bValue = resolveKey(b, currentSelector);

  const sortResult = sortAlgorithm(aValue, bValue);

  if (sortResult === 0 && selectorIndex < selectors.length - 1 && selectorIndex < 3) {
    // Limit recursion to 3 levels for avoid performance issues
    return sortRecursive(a, b, selectors, selectorIndex + 1);
  }
  return sortResult;
}

interface ISortBy {
  selector: string | string[];
  ascendent: boolean;
}

export const sortByKey = (data: any[], sortBy: ISortBy) => {
  const { selector, ascendent } = sortBy;
  data.sort((a, b) => {
    const selectors: string[] = typeof selector === 'string' ? [selector] : selector;
    return sortRecursive(a, b, selectors, 0);
  });

  if (ascendent) data.reverse();

  return data;
};
