import React from 'react';
import { useTranslation } from 'react-i18next';
import { Separator, SVGWrapper, Typography } from '@ratedpower/components';
import { MapPopupRow, MapPopupWarningRow } from 'utils/maps/map-popups.styled';
import { WarningSvg } from 'assets/icons';

interface ITopoWarningMessage {
  warningMotive: string;
  message: string;
}

interface ITopoStructureIncident {
  identifier: string;
  rejected: boolean;
  warningMessages: ITopoWarningMessage[];
}

interface IProps {
  incidents: ITopoStructureIncident[];
  theme?: any;
}

const StructureTopographyInfowindow: React.FC<IProps> = ({ incidents, theme }) => {
  const { t } = useTranslation('design-process');

  return (
    <>
      {incidents.map((incident) => (
        <React.Fragment key={incident.identifier}>
          <MapPopupRow>
            <Typography color={theme.v2.warning.text.contrast} category="title" weight="bold" size="m">
              {t(`layout-tab.${incidents[0].rejected ? 'rejections' : 'warnings'}`)} - {`${incident.identifier}`}
            </Typography>
          </MapPopupRow>
          <Separator />
          {incident.warningMessages.map((reason) => (
            <MapPopupWarningRow key={`${reason.warningMotive} ${reason.message}`}>
              <SVGWrapper icon={WarningSvg} size="s" />
              <div>
                <Typography category="display" size="s" weight="bold" as="div">
                  {`${reason.warningMotive}:`}
                </Typography>
                <Typography category="display" size="s" as="div">
                  {reason.message}
                </Typography>
              </div>
            </MapPopupWarningRow>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default StructureTopographyInfowindow;
