import styled from 'styled-components';

export const CompanyInfoWithLinkContainer = styled.div`
  margin-left: 10px;

  .company-info-link {
    align-items: center;
    display: flex;
    text-decoration: underline;

    svg {
      color: ${({ theme }) => theme.primary};
      margin-left: 6px;
      width: 13px;
    }
  }
`;
