import { useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_USERS, GET_TEAM_BY_ID } from 'services/team-gql';
import { useAddMembersToTeam } from './use-add-members-to-team';
import { useListTeams } from './use-list-teams';
import { useRemoveMemberFromTeam } from './use-remove-member-from-team';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { IUserListItem, IUserMinInfoDTO } from 'types/users';
import { IOption } from 'types/common';

export const useAssignMembersToTeam = (currentTeamId: string, onClose: () => void) => {
  const { id: companyIdFromUrl } = useParams<{ id: string }>();
  const profile = useSelector((state: RootState) => state.currentUser.profile);

  const isAdd = useRef(false);
  const prevTeamUsersRef = useRef<IUserMinInfoDTO[]>([]);
  const [selectedMember, setSelectedMember] = useState<IOption | undefined>();

  const {
    data,
    error: errorRetrievingUsers,
    refetch: refetchUsers,
  } = useQuery(GET_COMPANY_USERS, {
    variables: {
      companyId: companyIdFromUrl || profile?.companyId,
      currentTeamId,
      filters: [{ field: 'STATUS', operator: 'IN', values: ['ENABLED'] }],
      showCurrentTeamMembers: false,
    },
  });

  const { data: currentTeamData } = useQuery(GET_TEAM_BY_ID, {
    variables: { teamId: currentTeamId },
    fetchPolicy: 'network-only',
  });

  const companyUsers = useMemo(
    () =>
      data?.getCompanyUsers?.content
        ?.filter((user: IUserListItem) => user.name && user.lastname)
        .map((user: IUserListItem) => ({ label: user.name + ' ' + user.lastname, value: user.id })),
    [data]
  );

  const { refetch: refetchTeams } = useListTeams({ infiniteScroll: false, pageSize: 1 });

  const {
    loading: loadingAdd,
    updatedTeam: updatedTeamOnAdd,
    addMembersToTeam,
  } = useAddMembersToTeam(currentTeamId, afterAddingMember);

  const {
    loading: loadingRemove,
    updatedTeam: updatedTeamOnRemove,
    onRemoveMember,
  } = useRemoveMemberFromTeam(currentTeamId, refetchUsers);

  const teamUsers = useMemo(() => {
    if ((!updatedTeamOnAdd && isAdd.current) || (!updatedTeamOnRemove && !isAdd.current)) {
      return prevTeamUsersRef.current;
    }

    const newTeamUsers = isAdd.current ? updatedTeamOnAdd?.users : updatedTeamOnRemove?.users;
    prevTeamUsersRef.current = newTeamUsers || [];
    return newTeamUsers;
  }, [updatedTeamOnAdd, updatedTeamOnRemove]);

  const handleClose = () => {
    refetchTeams();
    onClose();
  };

  const onClickAddMember = () => {
    addMembersToTeam([selectedMember?.value]);
    isAdd.current = true;
  };

  function afterAddingMember() {
    refetchUsers();
    setSelectedMember(undefined);
  }

  const loading = isAdd.current ? loadingAdd : loadingRemove;

  return {
    companyUsers,
    loading,
    errorRetrievingUsers,
    teamUsers,
    isAdd,
    selectedMember,
    teamCover:
      currentTeamData?.getTeamById.coverImage?.highResUrl || currentTeamData?.getTeamById.coverColor || '#F1EAF9',
    handleClose,
    onClickAddMember,
    onRemoveMember,
    setSelectedMember,
  };
};
