/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import * as S from './assign-members-modal.styled';
import { Button, Modal, Typography, Dropdown, SpinnerLoader, IModalProps, Notification } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { coverIsColor } from 'utils/teams';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { IUserMinInfoDTO } from 'types/users';
import { CrossSvg } from 'assets/icons';
import { ColorCover } from '../team-cover/team-cover.styled';
import { useAssignMembersToTeam } from '../../hooks/use-assign-members-to-team-modal';

interface IProps extends IModalProps {
  teamId: string;
}

const AssignMembersModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const {
    companyUsers,
    errorRetrievingUsers,
    loading,
    teamUsers,
    isAdd,
    selectedMember,
    teamCover,
    handleClose,
    onClickAddMember,
    onRemoveMember,
    setSelectedMember,
  } = useAssignMembersToTeam(props.teamId, props.onClose);

  return (
    <Modal
      {...{ ...props, onClose: handleClose }}
      buttons={{
        primary: {
          text: t('action:close'),
          onClick: props.onClose,
        },
      }}
    >
      <S.ModalContent>
        {coverIsColor(teamCover) ? (
          <ColorCover color={teamCover} width="400px" height="100px" />
        ) : (
          <RemoteImage url={teamCover} width={400} height={104} alt="Default team image" className="team-image" />
        )}
        <Typography>{t('user:teams.add-member-description')}</Typography>

        <S.MemberSelection>
          {errorRetrievingUsers ? (
            <Notification
              status="informative"
              hideCloseButton
              description={t('user:teams.all-users-added')}
            />
          ) : (
            <>
              <Typography>{t('user:teams.new-member')}</Typography>
              <Dropdown
                placeholder={t('user:teams.search-users')}
                onChange={([item]) => setSelectedMember(item)}
                options={companyUsers}
                value={selectedMember ? [selectedMember] : []}
                isSearchable
              />
              <Button text={t('action:add')} onClick={onClickAddMember} variant="ghost" />
            </>
          )}
        </S.MemberSelection>

        {loading && <SpinnerLoader margin="0px 16px" />}
        {teamUsers?.map((member: IUserMinInfoDTO) => (
          <S.MemberContainer key={member.id}>
            <Typography>{member.name! + ' ' + member.surname!}</Typography>
            <Button
              Icon={CrossSvg}
              onClick={() => {
                isAdd.current = false;
                onRemoveMember(member.id);
              }}
              variant="ghost"
            />
          </S.MemberContainer>
        ))}
      </S.ModalContent>
    </Modal>
  );
};

export default AssignMembersModal;
