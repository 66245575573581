import { LabelWrapperStyled } from '@ratedpower/components';
import styled from 'styled-components';

export const CompanyIntegrationsContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.l};
  padding: 0 ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
`;

export const ToastWrapper = styled.div`
  width: max-content;
`;

export const CompanyIntegrationsTitle = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-items: center;
  display: flex;
`;

export const IntegrationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IntegrationsWrapper = styled.div`
  display: grid;
  gap: 24px;
  padding-right: 24px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1500px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1960px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  grid-template-columns: repeat(1, minmax(0, 1fr));
`;

export const IntegrationWrapper = styled.div`
  background: ${({ theme }) => theme.v2.bg.secondary.default};
  padding: ${({ theme }) => theme.v2.spacing.s};
  gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  flex-direction: column;
  width: 424px;
`;

export const IntegrationApiNeededWrapper = styled.div`
  button {
    margin-top: 8px;
  }
`;

export const IntegrationFormWrapper = styled.div`
  ${LabelWrapperStyled} {
    margin-bottom: ${({ theme }) => theme.v2.spacing.m};
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
`;
