import React, { useState } from 'react';
import { SCREEN_LOCATION, ToastLocation } from '../constants/toasts';
import { Typography, Toast, ToastsContainer } from '@ratedpower/components';

interface IToastContext {
  addToast: (location: ToastLocation, content: any, type?: ToastType, time?: number) => void;
  removeToast: (toast: IToast) => void;
  addErrorToast: (errorMessage: string, location?: ToastLocation) => void;
  addSuccessToast: (errorMessage: string, location?: ToastLocation) => void;
}

export enum ToastType {
  DEFAULT = 'default',
  ERROR = 'error',
  ERROR_CAPEX = 'error-capex',
  SUCCESS = 'success',
  WARNING = 'warning',
}

const Ctx = React.createContext<IToastContext | null>(null);

interface IToast {
  id: number;
  location: ToastLocation;
  type: ToastType;
  content: any;
}

export const useToasts = (): IToastContext => {
  const toastContext = React.useContext(Ctx);
  if (toastContext === null) {
    throw new Error('useToasts must be used within a ToastProvider tag');
  }
  return toastContext;
};

export const ToastProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<{ [key: string]: IToast[] }>({});

  const addToast = (
    location: ToastLocation,
    content: any,
    type: ToastType = ToastType.DEFAULT,
    time = 5000
  ): IToast => {
    const id = Date.now(); // Use timestamp as ID for uniqueness
    const toast = { content, location, type, id };
    setToasts((prevToasts) => {
      const newToasts = { ...prevToasts };
      newToasts[location] = newToasts[location] ? [...newToasts[location], toast] : [toast];
      return newToasts;
    });
    setTimeout(() => {
      removeToast(toast);
    }, time);

    return toast;
  };

  const removeToast = (toast: IToast) => {
    setToasts((prevToasts) => {
      const newToasts = { ...prevToasts };
      const toastsAtLocation = prevToasts[toast.location];

      newToasts[toast.location] = toastsAtLocation ? toastsAtLocation.filter((t) => t.id !== toast.id) : [];
      return newToasts;
    });
  };

  const addSuccessToast = (message: string, location?: ToastLocation) => {
    addToast(location ?? SCREEN_LOCATION.bottomCenter, <Typography>{message}</Typography>, ToastType.SUCCESS, 4000);
  };

  const addErrorToast = (errorMessage: string, location?: ToastLocation) => {
    addToast(location ?? SCREEN_LOCATION.bottomCenter, <Typography>{errorMessage}</Typography>, ToastType.ERROR, 4000);
  };

  const handleClickCrossButton = (toast: IToast) => () => {
    removeToast(toast);
  };

  return (
    <Ctx.Provider value={{ addToast, removeToast, addSuccessToast, addErrorToast }}>
      {children}
      {Object.keys(toasts).map((location) => (
        <ToastsContainer key={location} className={location}>
          {toasts[location] &&
            toasts[location].map((toast: IToast) => (
              <Toast
                key={toast.id}
                type={toast.type}
                text={toast.content}
                onClickCrossButton={handleClickCrossButton(toast)}
              />
            ))}
        </ToastsContainer>
      ))}
    </Ctx.Provider>
  );
};
