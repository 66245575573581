import styled from 'styled-components';
import { BulletPointContainer, TypographyBody } from '@ratedpower/components';

export const UsersSummaryContainer = styled.div`
  min-width: 600px;
`;

export const RoleDescription = styled.div`
  align-items: baseline;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;

  ${BulletPointContainer} {
    padding-right: 5px;

    ${TypographyBody} {
      font-weight: 700;
    }
  }
`;
