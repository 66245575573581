import { useState } from 'react';
import { useDebounce } from './use-debounce';

export const useDebouncedInput = (delay: number, callback?: any, defaultValue?: string) => {
  const [value, setValue] = useState(defaultValue);

  const callbackOnChange = () => {
    callback(value);
  };

  const debouncedOnChange = useDebounce(callbackOnChange, delay);

  const onChange = (newValue: string) => {
    setValue(newValue);
    debouncedOnChange();
  };

  return { value, onChange };
};
