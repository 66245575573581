import React from 'react';
import { IInfowindowProject } from 'types/projects';
import { useTranslation, Trans } from 'react-i18next';
import { getDateMonthDDYYYY } from 'utils/date';
import * as S from 'utils/maps/map-popups.styled';
import { Separator, Typography } from '@ratedpower/components';

interface IProps {
  project: IInfowindowProject;
  onClickProjectName: (id: string) => void;
}

const InfowindowProject: React.FC<IProps> = (props) => {
  const { id, name, numDesigns, publishedDate, createdBy } = props.project;
  const { t } = useTranslation();
  return (
    <>
      <S.MapPopupRow onClick={() => props.onClickProjectName(id)}>
        <Typography category="title" size="m" weight="bold">
          {name}
        </Typography>
      </S.MapPopupRow>
      <Separator />
      {numDesigns ? (
        <S.MapPopupRow>
          <Typography category="display" size="s">
            {t('project:n-designs', { count: numDesigns })}
          </Typography>
        </S.MapPopupRow>
      ) : (
        <S.MapPopupRow>
          <Typography category="display" size="s">
            <Trans i18nKey="project:zero-designs-start-one">
              <i>No designs yet.</i>&nbsp;<a href="/projects">Start one!</a>
            </Trans>
          </Typography>
        </S.MapPopupRow>
      )}
      <S.MapPopupRow>
        <Typography category="display" size="s">{`${createdBy.name} ${createdBy.surname}`}</Typography>
      </S.MapPopupRow>
      <S.MapPopupRow>
        <Typography category="display" size="s">
          {t('project:list-project-date', { dateFormat: getDateMonthDDYYYY(publishedDate) })}
        </Typography>
      </S.MapPopupRow>
    </>
  );
};

export default InfowindowProject;
