import React from 'react';
import * as S from './activity-tab.styled';
import EditNotesModal from './edit-notes-modal/edit-notes-modal';
import { ReloadSvg } from 'assets/icons';
import { SegmentedControl, Button, LabelWrapper, Dropdown, IOption } from '@ratedpower/components';
import { activityOptions, ACTIVITY_PER_PAGE_OPTIONS } from 'utils/constants/support';
import { useActivityTab } from './hooks/use-activity-tab';
import { useTranslation } from 'react-i18next';
import ActivityTabFiltersPanel from './filters/activity-tab-filters-panel';
import { IServerFilter } from 'types/filter';
import { ContentTopBar } from 'components/content-top-bar';
import QueryStatusWrapper from 'components/query-status-wrapper/query-status-wrapper';

const ActivityTab: React.FC = () => {
  const {
    filtersPanelOpened,
    optionSelected,
    inputSearchProps,
    handleChangeView,
    handleEditNotes,
    setFiltersPanelOpened,
    setServerFilters,
    notesModal,
    table,
    setActivitiesPerPage,
    activitiesPerPage,
    query: { loading, paginationComponent, error, fetchData },
  } = useActivityTab();
  const { t } = useTranslation();

  const activitySwitchItems = activityOptions.map((item) => {
    return {
      label: t(`support:${item.label}`),
      value: item.value,
      width: '150px',
    };
  });

  return (
    <>
      <S.Content>
        <S.TopContainer>
          <S.TopMenuActions>
            <SegmentedControl
              items={activitySwitchItems}
              selectedValue={optionSelected}
              onChange={(selectedItem) => {
                handleChangeView(selectedItem.value);
              }}
            />
            <Button variant="ghost" Icon={ReloadSvg} onClick={!loading ? () => fetchData() : undefined} />
          </S.TopMenuActions>
          <ContentTopBar filterOnClick={() => setFiltersPanelOpened(true)} searchOnChange={inputSearchProps.onChange} />
        </S.TopContainer>
        <S.PaginationNumberContainer>
          <LabelWrapper title={`${t('action:show')}:`} size="s" alignItems="center">
            <Dropdown
              onChange={(options: IOption[]) => setActivitiesPerPage(options[0])}
              value={[activitiesPerPage]}
              options={ACTIVITY_PER_PAGE_OPTIONS}
              size="s"
            />
          </LabelWrapper>
        </S.PaginationNumberContainer>
        <S.TableContainer className={optionSelected}>
          {notesModal.isOpen && (
            <EditNotesModal
              notes={notesModal.data.description}
              userDetails={notesModal.data.createdBy}
              title={t('design:edit-notes')}
              id={notesModal.data.id}
              isActive={notesModal.isOpen}
              onClose={() => notesModal.setModalOpen(false)}
              onSubmit={(newValue: { id: string; notes: string }) => {
                handleEditNotes(newValue.id, newValue.notes);
                notesModal.setModalOpen(false);
              }}
            />
          )}
          <QueryStatusWrapper error={error} loading={loading}>
            <S.StyledActivityTable table={table as any} zeroResultsFoundText={t('zero-results-found')} />
            {paginationComponent}
          </QueryStatusWrapper>
        </S.TableContainer>
        <ActivityTabFiltersPanel
          isOpened={filtersPanelOpened}
          onApply={(filters: IServerFilter[]) => {
            setServerFilters(JSON.parse(JSON.stringify(filters)));
            setFiltersPanelOpened(false);
          }}
          onClose={() => setFiltersPanelOpened(false)}
        />
      </S.Content>
    </>
  );
};

export default ActivityTab;
