import styled from 'styled-components';

export const LogoContainer = styled.div<{ $customHeight?: string; $customWidth?: string }>`
  display: flex;
  flex-direction: column;

  .logo-img {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
    display: flex;
    height: ${({ $customHeight }) => $customHeight || '250px'};
    justify-content: center;
    padding: 20px;
    position: relative;
    width: ${({ $customWidth }) => $customWidth || '340px'};

    &.default {
      .default-img {
        align-self: center;
        height: 40px;
        width: 40px;
      }
    }

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .logo-info {
    margin-top: 20px;
    padding-inline-start: 20px;
  }
`;

export const InputFileLabel = styled.label`
  align-items: center;
  background-color: ${({ theme }) => theme.v2.btn.accent.bg.default};
  color: ${({ theme }) => theme.v2.btn.accent.text.default};
  border-radius: 50%;
  bottom: -10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  position: absolute;
  right: -10px;
  transition: background 0.2s ease;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: ${({ theme }) => theme.v2.btn.accent.bg.hover};
  }

  svg {
    height: 10px;
    width: 10px;
  }

  input[type='file'] {
    display: none;
  }
`;
