/* eslint-disable max-len */
import { IPermissionToShow, PermissionType } from 'types/role';

export enum Permission {
  //Currencies
  AVAILABLE_CURRENCIES_READ = 'AVAILABLE_CURRENCIES_READ',
  //Company
  COMPANY_READ = 'COMPANY_READ',
  COMPANY_WRITE = 'COMPANY_WRITE',
  COMPANY_SUPPORT_READ = 'COMPANY_SUPPORT_READ',
  COMPANY_SUPPORT_WRITE = 'COMPANY_SUPPORT_WRITE',
  COMPANY_SUPPORT_DELETE = 'COMPANY_SUPPORT_DELETE',
  COMPANY_SUPPORT_SAML = 'COMPANY_SUPPORT_SAML',

  //Project
  PROJECT_SUPPORT_CREATE = 'PROJECT_SUPPORT_CREATE',
  PROJECT_SUPPORT_READ = 'PROJECT_SUPPORT_READ',
  PROJECT_SUPPORT_WRITE = 'PROJECT_SUPPORT_WRITE',
  PROJECT_CREATE_IN_USER_COMPANY = 'PROJECT_CREATE_IN_USER_COMPANY',
  PROJECT_CREATE_IN_USER_TEAM_AND_PUBLIC = 'PROJECT_CREATE_IN_USER_TEAM_AND_PUBLIC',
  PROJECT_READ_FROM_USER_COMPANY = 'PROJECT_READ_FROM_USER_COMPANY',
  PROJECT_READ_FROM_USER_TEAM_AND_PUBLIC = 'PROJECT_READ_FROM_USER_TEAM_AND_PUBLIC',
  PROJECT_WRITE_IN_USER_COMPANY = 'PROJECT_WRITE_IN_USER_COMPANY',
  PROJECT_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR = 'PROJECT_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR',
  PROJECT_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR = 'PROJECT_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR',

  //Simulation
  SIMULATION_RESULTS_PAGE_READ = 'SIMULATION_RESULTS_PAGE_READ',
  SIMULATION_SUPPORT_CREATE = 'SIMULATION_SUPPORT_CREATE',
  SIMULATION_SUPPORT_READ = 'SIMULATION_SUPPORT_READ',
  SIMULATION_SUPPORT_WRITE = 'SIMULATION_SUPPORT_WRITE',
  SIMULATION_CREATE_IN_USER_COMPANY = 'SIMULATION_CREATE_IN_USER_COMPANY',
  SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC = 'SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC',
  SIMULATION_READ_FROM_USER_COMPANY = 'SIMULATION_READ_FROM_USER_COMPANY',
  SIMULATION_READ_FROM_USER_TEAM_AND_PUBLIC = 'SIMULATION_READ_FROM_USER_TEAM_AND_PUBLIC',
  SIMULATION_WRITE_IN_USER_COMPANY = 'SIMULATION_WRITE_IN_USER_COMPANY',
  SIMULATION_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR = 'SIMULATION_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR',
  SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR = 'SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR',

  //Equipment
  EQUIPMENT_SUPPORT_READ = 'EQUIPMENT_SUPPORT_READ',
  EQUIPMENT_SUPPORT_WRITE = 'EQUIPMENT_SUPPORT_WRITE',
  EQUIPMENT_SUPPORT_DISABLE = 'EQUIPMENT_SUPPORT_DISABLE',
  EQUIPMENT_READ = 'EQUIPMENT_READ',
  EQUIPMENT_WRITE = 'EQUIPMENT_WRITE',
  EQUIPMENT_DISABLE = 'EQUIPMENT_DISABLE',

  //User
  USER_READ_FROM_USER_COMPANY = 'USER_READ_FROM_USER_COMPANY',
  USER_READ_FROM_USER_TEAM = 'USER_READ_FROM_USER_TEAM',
  USER_WRITE_IN_USER_COMPANY = 'USER_WRITE_IN_USER_COMPANY',
  USER_WRITE_IN_USER_TEAM = 'USER_WRITE_IN_USER_TEAM',
  USER_SUPPORT_DELETE = 'USER_SUPPORT_DELETE',
  USER_SUPPORT_READ = 'USER_SUPPORT_READ',
  USER_SUPPORT_WRITE = 'USER_SUPPORT_WRITE',
  USER_PROFILE_READ = 'USER_PROFILE_READ',
  USER_PROFILE_WRITE = 'USER_PROFILE_WRITE',
  USER_RESEND_PASSWORD = 'USER_RESEND_PASSWORD',
  USER_SUPPORT_RESEND_PASSWORD = 'USER_SUPPORT_RESEND_PASSWORD',
  USER_SUPPORT_DELETE_EXTERNAL = 'USER_SUPPORT_DELETE_EXTERNAL',

  //Equipment settings
  EQUIPMENT_SETTINGS_READ = 'EQUIPMENT_SETTINGS_READ',
  EQUIPMENT_SETTINGS_READ_AT_COMPANY_LEVEL = 'EQUIPMENT_SETTINGS_READ_AT_COMPANY_LEVEL',
  EQUIPMENT_SETTINGS_READ_AT_PROJECT_LEVEL_FROM_USER_COMPANY = 'EQUIPMENT_SETTINGS_READ_AT_PROJECT_LEVEL_FROM_USER_COMPANY',
  EQUIPMENT_SETTINGS_READ_AT_PROJECT_LEVEL_FROM_USER_TEAM = 'EQUIPMENT_SETTINGS_READ_AT_PROJECT_LEVEL_FROM_USER_TEAM',
  EQUIPMENT_SETTINGS_WRITE_AT_COMPANY_LEVEL = 'EQUIPMENT_SETTINGS_WRITE_AT_COMPANY_LEVEL',
  EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_COMPANY = 'EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_COMPANY',
  EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR = 'EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR',
  EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR = 'EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR',
  EQUIPMENT_SETTINGS_SUPPORT_READ_AT_COMPANY_LEVEL = 'EQUIPMENT_SETTINGS_SUPPORT_READ_AT_COMPANY_LEVEL',
  EQUIPMENT_SETTINGS_SUPPORT_READ_AT_PROJECT_LEVEL = 'EQUIPMENT_SETTINGS_SUPPORT_READ_AT_PROJECT_LEVEL',
  EQUIPMENT_SETTINGS_SUPPORT_WRITE_AT_COMPANY_LEVEL = 'EQUIPMENT_SETTINGS_SUPPORT_WRITE_AT_COMPANY_LEVEL',
  EQUIPMENT_SETTINGS_SUPPORT_WRITE_AT_PROJECT_LEVEL = 'EQUIPMENT_SETTINGS_SUPPORT_WRITE_AT_PROJECT_LEVEL',

  //Team
  TEAM_READ_FROM_USER_COMPANY = 'TEAM_READ_FROM_USER_COMPANY',
  TEAM_READ_FROM_USER_TEAM = 'TEAM_READ_FROM_USER_TEAM',
  TEAM_SUPPORT_READ = 'TEAM_SUPPORT_READ',
  TEAM_SUPPORT_WRITE = 'TEAM_SUPPORT_WRITE',
  TEAM_WRITE_IN_USER_COMPANY = 'TEAM_WRITE_IN_USER_COMPANY',
  TEAM_WRITE_IN_USER_TEAM = 'TEAM_WRITE_IN_USER_TEAM',

  //Financial
  FINANCIAL_READ = 'FINANCIAL_READ',
  FINANCIAL_SUPPORT_READ = 'FINANCIAL_SUPPORT_READ',
  FINANCIAL_SUPPORT_WRITE = 'FINANCIAL_SUPPORT_WRITE',
  FINANCIAL_WRITE = 'FINANCIAL_WRITE',

  //Statistics
  CUSTOMER_STATISTICS_READ = 'CUSTOMER_STATISTICS_READ',
  CUSTOMER_STATISTICS_DOWNLOAD = 'CUSTOMER_STATISTICS_DOWNLOAD',
  STATISTICS_SUPPORT_DOWNLOAD = 'STATISTICS_SUPPORT_DOWNLOAD',
  STATISTICS_SUPPORT_READ = 'STATISTICS_SUPPORT_READ',

  //Role & Permission
  PERMISSION_SUPPORT_READ = 'PERMISSION_SUPPORT_READ',
  ROLE_READ = 'ROLE_READ',
  ROLE_SUPPORT_READ = 'ROLE_SUPPORT_READ',
  ROLE_SUPPORT_WRITE = 'ROLE_SUPPORT_WRITE',

  // Notification
  NOTIFICATION_SUPPORT_WRITE = 'NOTIFICATION_SUPPORT_WRITE',
  NOTIFICATION_READ = 'NOTIFICATION_READ',
  NOTIFICATION_WRITE = 'NOTIFICATION_WRITE',

  // Popup
  POPUP_NEW_FEATURE_WRITE = 'POPUP_NEW_FEATURE_WRITE',

  //Others
  ASSIGN_SUPPORT_ROLE_WRITE = 'ASSIGN_SUPPORT_ROLE_WRITE',
  CLUSTER_SUPPORT_READ = 'CLUSTER_SUPPORT_READ',
  CLUSTER_SUPPORT_WRITE = 'CLUSTER_SUPPORT_WRITE',
  COMPARISON_DOWNLOAD = 'COMPARISON_DOWNLOAD',
  COUNTRY_READ = 'COUNTRY_READ',
  MFA_SUPPORT_WRITE = 'MFA_SUPPORT_WRITE',
  MIGRATION_SUPPORT_WRITE = 'MIGRATION_SUPPORT_WRITE',
  SUPPORT_ALERT_SUPPORT_READ = 'SUPPORT_ALERT_SUPPORT_READ',
  SUPPORT_ALERT_SUPPORT_WRITE = 'SUPPORT_ALERT_SUPPORT_WRITE',
}

export const permissionsToShow = Object.values(Permission).reduce<IPermissionToShow[]>((previousValue, permission) => {
  const permissionParts = permission.split('_');
  const permissionTypes: PermissionType[] = [
    'READ',
    'WRITE',
    'DISABLE',
    'VIEW',
    'DOWNLOAD',
    'SAML',
    'DELETE',
    'SCORE',
    'CREATE',
    'RESEND',
  ];
  const { type, group } = (() => {
    for (const permissionType of permissionTypes) {
      if (permission.includes(permissionType)) {
        const indexOfPermissionType = permissionParts.indexOf(permissionType);
        return {
          type: permissionParts.slice(indexOfPermissionType).join('_'),
          group: permissionParts.slice(0, indexOfPermissionType).join('_'),
        };
      }
    }
    throw new Error('No PermissionType');
  })();
  const foundPermissionIdx = previousValue.findIndex((current) => current.group === group);
  if (foundPermissionIdx === -1) {
    return [...previousValue, { group, permissionType: [{ text: type, value: permission }] }];
  }
  previousValue[foundPermissionIdx].permissionType.push({ text: type, value: permission });
  return previousValue;
}, []);
