import styled from 'styled-components';

export const PermissionsContainer = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  position: relative;
  display: grid;
  grid-template-rows: max-content max-content;
`;

export const PermissionsContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  grid-auto-flow: row;
  justify-content: space-between;

  p {
    max-width: 156px; // To prevent checkbox from shrinking when multiple lines 
  }
`;

export const EditButtonWrapper = styled.div`
  display: grid;
  justify-content: right;
`;

export const PermissionContainer = styled.div`
  display: flex;
  flex-direction: column;

  .group-title {
    margin-bottom: ${({ theme }) => theme.v2.spacing.s};
  }

  .checkbox-container {
    margin-bottom: ${({ theme }) => theme.v2.spacing.xs};
  }
`;

export const CheckboxContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  padding: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  justify-content: right;
`;

export const ActionsCancelSave = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  align-items: center;

  .primary {
    margin-left: 1.5rem;
  }
`;
