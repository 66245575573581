import { IUnits } from 'types/preferences.types';

export const elasticityUnits: IUnits = {
  INTERNATIONAL: {
    XXL: {
      unit: 'GPa',
      toShow: (value) => value * 1e-9,
      toSend: (value) => value * 1e9,
    },
    M: {
      unit: 'Pa',
      toShow: (value) => value,
      toSend: (value) => value,
    },
  },
  IMPERIAL: {
    XXL: {
      unit: 'PSI',
      toShow: (value) => value / 6894.7448,
      toSend: (value) => value * 6894.7448,
    },
    M: {
      unit: 'PSI',
      toShow: (value) => value / 6894.7448,
      toSend: (value) => value * 6894.7448,
    },
  },
};
