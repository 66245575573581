import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Breadcrumb, TopbarWrapper } from '@ratedpower/components';
import TeamsRightTopbar from './teams-right-topbar';

const TeamsTopbar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('user:users-management')}</title>
      </Helmet>
      <TopbarWrapper
        type="main"
        left={<Breadcrumb currentPage={{ name: t('user:users-management') }} />}
        right={<TeamsRightTopbar />}
      />
    </>
  );
};

export default TeamsTopbar;
