import { DefaultTheme } from 'styled-components';
import { IOptionWithColor } from 'types/common';
import { SIMULATION_CATEGORY, SIMULATION_TYPE } from 'types/design';

export enum GenericStatus {
  ARCHIVED = 'ARCHIVED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  FINISHED = 'FINISHED',
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  DRAFT = 'DRAFT',
  REVIEWED = 'REVIEWED',
  DELETED_USER = 'DELETED_USER',
  DELETED_SUPPORT = 'DELETED_SUPPORT',
  DELETED_ERROR = 'DELETED_ERROR',
  OK = 'OK',
}

export const STATUS_COLORS: { [key in GenericStatus]: string } = {
  ARCHIVED: 'gray',
  ERROR: 'red',
  PENDING: 'blue',
  FINISHED: 'brand',
  QUEUED: 'yellow',
  IN_PROGRESS: 'blue',
  DRAFT: 'orange',
  REVIEWED: 'yellow',
  DELETED_USER: 'red',
  DELETED_SUPPORT: 'red',
  DELETED_ERROR: 'red',
  OK: 'brand',
};

export const genericStatusList = (theme: DefaultTheme): IOptionWithColor[] => [
  {
    label: 'support:in-progress',
    value: 'IN_PROGRESS',
    background: theme.chart_yellow_light,
    color: theme.yellow_dark,
  },
  {
    label: 'errors:error',
    value: 'ERROR',
    background: theme.red_light,
    color: theme.red,
  },
  {
    label: 'support:finished',
    value: 'FINISHED',
    background: theme.green_light,
    color: theme.green,
  },
  {
    label: 'support:reviewed',
    value: 'REVIEWED',
    background: theme.secondary_30,
    color: theme.secondary,
  },
  {
    label: 'support:archived',
    value: 'ARCHIVED',
    background: theme.gray_lighter,
    color: theme.gray_dark,
  },
  {
    label: 'support:deleted-by-user',
    value: 'DELETED_USER',
    background: theme.chart_yellow_light,
    color: theme.yellow_dark,
  },
  {
    label: 'support:deleted-by-support',
    value: 'DELETED_SUPPORT',
    background: theme.purple_light,
    color: theme.purple,
  },
  {
    label: 'support:deleted-by-error',
    value: 'DELETED_ERROR',
    background: theme.orange_light,
    color: theme.orange,
  },
];

export const projectStatusList = (theme: DefaultTheme): IOptionWithColor[] => {
  return genericStatusList(theme).filter(
    (statusOption: IOptionWithColor) =>
      statusOption.value !== 'DELETED_ERROR' && statusOption.value !== 'ERROR' && statusOption.value !== 'IN_PROGRESS'
  );
};

export const siteStatusList = (theme: DefaultTheme): IOptionWithColor[] => {
  return [
    ...genericStatusList(theme),
    {
      label: 'design-process:interactive-site.draft',
      value: 'DRAFT',
      background: theme.secondary + theme.opacity_10,
      color: theme.secondary,
    },
  ];
};

export const isSinglePVDesignSimulation = (simulationType: string, simulationCategory: string): boolean =>
  simulationCategory === SIMULATION_CATEGORY.PV && simulationType === SIMULATION_TYPE.SINGLE;
export const isBessSimulation = (simulationCategory: string): boolean =>
  simulationCategory === SIMULATION_CATEGORY.STANDALONE_BATTERY;
export const isSimulationInProgress = (simulationType: string): boolean => simulationType === GenericStatus.IN_PROGRESS;
