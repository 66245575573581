import { ApolloError, useMutation } from '@apollo/client';
import { useCreateTeamInCompany } from 'pages/support/company-activity/teams-tab/hooks/use-create-team-in-company';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CREATE_TEAM, GET_TEAMS } from 'services/team-gql';
import { CreatedTeam, ICreateTeamDTO } from 'types/teams';
import useForm from 'utils/hooks/use-form';
import { useToasts } from 'utils/hooks/use-toasts';

export const useCreateTeam = (callback?: (createdTeam: CreatedTeam) => void) => {
  const { t } = useTranslation();
  const { addErrorToast, addSuccessToast } = useToasts();
  const { id: companyId } = useParams<{ id: string }>();

  const [selectedCoverImage, setSelectedCoverImage] = useState<File>();
  const [selectedCoverColor, setSelectedCoverColor] = useState<string>();
  const { inputs, formError, setFormError, handleInputChangeManual } = useForm(handleTeamSubmit);

  const { handleCreateTeamInSupport } = useCreateTeamInCompany(callback);

  const [createTeam] = useMutation(CREATE_TEAM, {
    refetchQueries: [GET_TEAMS],
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      addSuccessToast(t('user:teams.team-created-succesfully'));
      if (callback) callback(data.createTeam);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'IllegalArgumentException') {
        setFormError({ name: t('user:teams.error-team-name-repeated') });
        return;
      }
      addErrorToast(t('user:teams.team-creation-error'));
    },
  });

  function handleTeamSubmit() {
    if (!inputs.teamName || Array.from(inputs.teamName).every((character) => character === ' ')) {
      setFormError(t('user:teams.team-name-error'));
      return;
    }
    const createTeamDTO: ICreateTeamDTO = {
      name: inputs.teamName,
      description: inputs.description,
      coverColor: selectedCoverColor,
    };

    if (!companyId) {
      handleCreateTeam(createTeamDTO, selectedCoverImage);
      return;
    }
    handleCreateTeamInSupport({ ...createTeamDTO, companyId }, selectedCoverImage);
  }

  const handleSelectColorCover = (color: string) => () => {
    setSelectedCoverColor(color);
    setSelectedCoverImage(undefined);
  };

  const handleSelectCoverImage = (file: File) => {
    setSelectedCoverImage(file);
    setSelectedCoverColor(undefined);
  };

  const handleCreateTeam = (createTeamDTO: ICreateTeamDTO, coverImage?: File) => {
    createTeam({ variables: { createTeamDTO, coverImage } });
  };

  return {
    onChangeInput: handleInputChangeManual,
    formError,
    selectedCoverColor,
    selectedCoverImage,
    handleSelectColorCover,
    handleSelectCoverImage,
    handleTeamSubmit,
  };
};
