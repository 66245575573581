import React from 'react';
import * as S from './company-plan.styled';
import PvDesignPlans from './pv-design-plans/pv-design-plans';
import PlanHistory from './plan-history/plan-history';
import { Separator } from '@ratedpower/components';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Plan } from 'types/subscription-plan';

const CompanyPlan: React.FC = () => {
  const currentPlan = useSelector((state: RootState) => state.currentUser.subscriptionPlan);

  const isOldCompanyPlan =
    currentPlan &&
    new Date(currentPlan.publishedDate) < new Date(new Date('2023-04-04').setHours(11, 0, 0)) &&
    currentPlan.plan !== Plan.ENTERPRISE;

  return (
    <S.CompanyLicenseContainer>
      <PvDesignPlans isOldCompanyPlan={isOldCompanyPlan} />
      {!isOldCompanyPlan && (
        <>
          <Separator marginTop="3rem" marginBottom="3rem" />
          <PlanHistory />
        </>
      )}
    </S.CompanyLicenseContainer>
  );
};

export default CompanyPlan;
