import { useMutation } from '@apollo/client';
import { GET_COMPANY_PROJECTS, UPDATE_PROJECT } from 'services/support-gql';
import { useConverter } from 'utils/conversor';
import { useCompanyActivityState } from 'pages/support/company-activity/company-activity-state';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useProjectFilesColumns } from 'pages/support/company-activity/projects-files-tab/use-project-files-columns';
import { useGetCompanyProjects } from 'utils/hooks/use-get-company-projects';
import { IProjectListItem } from 'types/projects';
import { useProjectFilters } from 'pages/projects/use-project-filters';

export const useProjectsAndFiles = () => {
  const { companyId, newProjectFormModal } = useCompanyActivityState();
  const { converters } = useConverter();
  const { isAuthorized } = useAuthorization();
  const hasProjectSupportWritePermission = isAuthorized(Permission.PROJECT_SUPPORT_WRITE);
  const hasCapexSupportPermission = isAuthorized(Permission.FINANCIAL_SUPPORT_READ);

  const { serverFilters, toggleOverlay, overlayProps } = useProjectFilters(true, true);

  const {
    data: projects,
    error,
    loading,
    paginationComponent,
    inputSearchProps,
    sortedBy,
    setSortedBy,
    refetchProjects,
  } = useGetCompanyProjects(companyId, serverFilters);

  const [updateProject] = useMutation(UPDATE_PROJECT, { refetchQueries: [GET_COMPANY_PROJECTS] });

  async function handleChangeValues(id: string, status: string, distance: number) {
    const projectUpdateSupportDTO = {
      id,
      status,
      maxSiteToProjectDistance: distance,
    };
    await updateProject({ variables: { projectUpdateSupportDTO } });
    refetchProjects();
  }

  const columns = useProjectFilesColumns(
    {
      onChange: handleChangeValues,
      onSort: setSortedBy,
      hasWritePermissions: hasProjectSupportWritePermission,
      sortedBy,
      converters,
      companyId,
    },
    hasCapexSupportPermission
  );

  const table = useReactTable({
    data: projects,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    query: {
      data: projects?.map((project: IProjectListItem) => ({
        ...project,
        rowProps: { url: `/projects/${project.id}`, blank: true },
      })),
      loading,
      error,
      paginationComponent,
    },
    inputSearchProps,
    table,
    newProjectFormModal,
    toggleOverlay,
    overlayProps,
  };
};
