import MapStyleSelector from 'components/map-style-selector/map-style-selector';
import { SatelliteOption, satelliteOptions } from 'components/map-style-selector/satellite-options';
import React from 'react';
import { useState } from 'react';

export const useMapStyleSelector = (map: any, has3D?: boolean) => {
  const [selectedStyle, setSelectedStyle] = useState<SatelliteOption>(satelliteOptions[0]);

  const onChangeStyle = React.useCallback(
    (option: SatelliteOption) => {
      setSelectedStyle(option);
      map.current.setStyle('mapbox://styles/mapbox/' + option.value);
      setIs3DActive(false);
    },
    [selectedStyle]
  );
  const [is3DActive, setIs3DActive] = useState(false);
  const toggle3DView = React.useCallback(() => {
    if (map?.current?.getSource('mapbox-terrain')) {
      map.current.setTerrain();
      map.current.removeSource('mapbox-terrain');
      setIs3DActive(false);
    } else {
      map.current.addSource('mapbox-terrain', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14,
      });
      // add the DEM source as a terrain layer with exaggerated height
      map.current.setTerrain({ source: 'mapbox-terrain', exaggeration: 1.5 });
      setIs3DActive(true);
    }
  }, [map]);

  const optionsFor3D = {
    toggle3DView,
    has3D,
    is3DActive,
    isDisabled: !selectedStyle.value.includes('satellite'),
  };

  const StyleSelector = React.useMemo(() => {
    return (
      <MapStyleSelector
        optionsFor3D={optionsFor3D}
        selected={selectedStyle}
        onSelect={onChangeStyle}
        options={satelliteOptions}
      />
    );
  }, [selectedStyle, onChangeStyle, optionsFor3D]);

  return {
    mapStyleSelector: StyleSelector,
  };
};
