import React from 'react';
import * as S from './profile.styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from 'redux/root-reducer';
import ProfileTabs from 'pages/profile/profile-tabs';
import { Breadcrumb, TopbarWrapper } from '@ratedpower/components';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import ErrorForbidden from 'pages/error/error-forbidden/error-forbidden';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { profile, subscriptionPlan } = useSelector((state: RootState) => state.currentUser);

  const { isAuthorized } = useAuthorization();

  if (!isAuthorized(Permission.USER_PROFILE_READ)) return <ErrorForbidden />;
  if (!profile || !subscriptionPlan) return <></>;

  return (
    <>
      <TopbarWrapper type="main" left={<Breadcrumb currentPage={{ name: t('user:account-settings') }} />} />
      <S.ProfilePageWrapper>
        <ProfileTabs />
        <S.AccountSettingSection>
          <Outlet />
        </S.AccountSettingSection>
      </S.ProfilePageWrapper>
    </>
  );
};

export default Profile;
