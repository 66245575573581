import { useMemo } from 'react';
import { IAttribute } from 'utils/constants/design-attributes';
import { useConverter } from 'utils/conversor';
import { DimensionUnit, getDimensionUnitForArrayConvertion } from 'utils/conversor/dimension';
import { resolveKey } from 'utils/objects';

function getDimensionToConvert(data: any, attr: IAttribute): DimensionUnit {
  const dimensionToConvert = getDimensionUnitForArrayConvertion(
    data.map((e: any) => {
      const itemValue = resolveKey(e, attr.key);
      const castToNumber =
        typeof itemValue === 'number' ? itemValue : typeof itemValue === 'string' ? parseFloat(itemValue) : 0;
      return castToNumber;
    })
  );
  return dimensionToConvert;
}

export interface IChartDimensionUnits {
  x: DimensionUnit | null;
  y: DimensionUnit | null;
}

interface IProps {
  data: any;
  xAttr: IAttribute;
  yAttr: IAttribute;
}

const useDesignComparisonScatterChart = ({ data, xAttr, yAttr }: IProps) => {
  const { converters, convertValueOf } = useConverter();

  const formatDataForScatter = (array: any[]) => {
    const arrOfArrays: any[] = [];
    array.forEach((element, index) => {
      arrOfArrays.push(array.slice(index, index + 1));
    });
    return arrOfArrays;
  };

  const dimensionUnits = useMemo<IChartDimensionUnits>(() => {
    const initialState: IChartDimensionUnits = { x: null, y: null };
    if (!data || !xAttr || !yAttr) return initialState;

    const state = { ...initialState };

    if (xAttr.converter && xAttr.converter.name === 'dimension') {
      const overrideUnit =
        xAttr.converter.options && xAttr.converter.options.dimension ? xAttr.converter.options.dimension : null;
      state.x = overrideUnit ?? getDimensionToConvert(data, xAttr);
    }
    if (yAttr.converter && yAttr.converter.name === 'dimension') {
      const overrideUnit =
        yAttr.converter.options && yAttr.converter.options.dimension ? yAttr.converter.options.dimension : null;
      state.y = overrideUnit ?? getDimensionToConvert(data, yAttr);
    }

    return state;
  }, [data, xAttr, yAttr]);

  const formattedData = useMemo<any[]>(() => {
    if (!data) return [];

    const designConvertedData: any[] = data.map((item: any) => ({
      filterColor: item.fillColor,
      name: [`${item.number}. ${item.name}`],
      x: Number(
        convertValueOf(xAttr, item, { decimals: 2, ...(!!dimensionUnits.x && { dimension: dimensionUnits.x }) })
      ),
      y: Number(
        convertValueOf(yAttr, item, { decimals: 2, ...(!!dimensionUnits.y && { dimension: dimensionUnits.y }) })
      ),
    }));

    const designGroupedData = designConvertedData.reduce((result: any[], current) => {
      const match = result.find((e: any) => e.x === current.x && e.y === current.y);
      if (!!match) {
        const newResult = result.filter((e: any) => e.x !== current.x || e.y !== current.y);
        const updatedPoint = { ...match };
        updatedPoint.name = Array.isArray(match.name) ? [...match.name, ...[current.name]] : [match.name, current.name];
        newResult.push(updatedPoint);
        return newResult;
      }
      const formattedPoint = { ...current };
      formattedPoint.name = [current.name];
      result.push(formattedPoint);
      return result;
    }, []);

    return formatDataForScatter(designGroupedData);
  }, [data, dimensionUnits, converters]);

  return {
    dimensionUnits,
    formattedData,
  };
};

export default useDesignComparisonScatterChart;
