import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

const knowledgeBase = 'https://help.ratedpower.com/';
const knowledgeBaseSalesforce = `${knowledgeBase}s/`;
const knowledgeBaseArticle = `${knowledgeBaseSalesforce}article/`;
const knowledgeBaseTopic = `${knowledgeBaseSalesforce}topic/`;

const getLanguageQuery = (language: string) => {
  return language === 'es' ? '?language=es' : '?language=en_US';
};

export const useKnowledgeBase = () => {
  const { preferences } = useSelector((state: RootState) => state.currentUser);
  const languageQuery = getLanguageQuery(preferences.language);

  const knowledgeBaseLocalized = `${knowledgeBase}${languageQuery}`;

  const KBhowToCreateCapexTemplate = `${knowledgeBaseArticle}how-to-create-a-capex-template${languageQuery}`;
  const KBhowToCreateSiteInPvDesign = `${knowledgeBaseArticle}how-to-create-a-site-inside-pvdesign${languageQuery}`;
  const KBhowToCreateSiteInBessDesign = `${knowledgeBaseArticle}how-to-create-standalone-site${languageQuery}`;
  const KBhowToUploadEnergyPricesInPvDesign = `${knowledgeBaseArticle}how-to-upload-your-energy-prices-to-ratedpower${languageQuery}`;
  const KBhowToAddHorizon = `${knowledgeBaseArticle}how-to-upload-your-own-horizon-file${languageQuery}`;
  const KBhowToUploadEquipmentStructures = `${knowledgeBaseArticle}how-to-upload-your-own-equipment-structures${languageQuery}`;
  const KBhowToUploadEquipment = `${knowledgeBaseArticle}how-to-upload-you-own-equipment${languageQuery}`;
  const KBhowToMeteo = `${knowledgeBaseArticle}how-to-upload-your-meteorological-data${languageQuery}`;
  const KBhowToTopography = `${knowledgeBaseArticle}how-to-upload-your-topography${languageQuery}`;
  const KBhowToConnectionType = `${knowledgeBaseArticle}how-to-design-your-substation-in-pvdesign${languageQuery}`;
  const KBhowToGrid = `${knowledgeBaseArticle}how-to-define-the-power-factor${languageQuery}`;
  const KBhowToSetYourAPIMeteo = `${knowledgeBaseArticle}how-to-set-your-api-key-for-meteo-sources-integration${languageQuery}`;
  const KBhowToCreateAnOverheadLine = `${knowledgeBaseArticle}how-to-create-an-overhead-line${languageQuery}`;
  const KBhowToCustomizeAnOverheadLine = `${knowledgeBaseArticle}how-to-customize-your-overhead-line${languageQuery}`;
  // eslint-disable-next-line max-len
  const KBHowToCreateFinancialAnalysis = `${knowledgeBaseArticle}how-to-create-a-complete-financial-analysis-in-ratedpower${languageQuery}`;
  // eslint-disable-next-line max-len
  const KBHowToGoogleEarthApiIssueSeptember2024 = `${knowledgeBaseArticle}google-earth-api-issue-mitigation-september-2024${languageQuery}`;

  const KBmethodologies = `${knowledgeBaseTopic}0TOUv0000001vDxOAI/methodologies${languageQuery}`;
  const KBcreateTicket = `${knowledgeBaseSalesforce}contactsupport${languageQuery}`;
  const KBImportingShapeFileToDefineSite = `${knowledgeBaseArticle}/importing-site${languageQuery}`;
  const KBImportingCADToDefineSite = `${knowledgeBaseArticle}/how-to-import-cad-files-for-your-site-boundary${languageQuery}`;
  const KBCrsCadFile = `${knowledgeBaseArticle}how-to-identify-CRS-of-CAD${languageQuery}`;
  const KBImportingPrismParcelToDefineSite = `${knowledgeBaseArticle}how-to-import-parcel-from-PRISM-in-Rated-Power${languageQuery}`;

  // exception, this one remains on the old URL
  const KBNewChanges = 'https://knowledgebase.ratedpower.com/last-update';

  return {
    knowledgeBaseLocalized,
    KBhowToCreateCapexTemplate,
    KBhowToCreateSiteInPvDesign,
    KBhowToCreateSiteInBessDesign,
    KBhowToUploadEnergyPricesInPvDesign,
    KBhowToAddHorizon,
    KBhowToUploadEquipmentStructures,
    KBhowToUploadEquipment,
    KBhowToMeteo,
    KBhowToTopography,
    KBhowToConnectionType,
    KBhowToGrid,
    KBhowToSetYourAPIMeteo,
    KBhowToCreateAnOverheadLine,
    KBhowToCustomizeAnOverheadLine,
    KBHowToCreateFinancialAnalysis,
    KBHowToGoogleEarthApiIssueSeptember2024,
    KBmethodologies,
    KBcreateTicket,
    KBNewChanges,
    KBImportingShapeFileToDefineSite,
    KBImportingCADToDefineSite,
    KBCrsCadFile,
    KBImportingPrismParcelToDefineSite,
  };
};
