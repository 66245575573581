const CHINA_COUNTRY_CODE = 'CN';
function extractCountryCode(addressComponents: any[]) {
  const country = addressComponents.find(c => c.types.includes('country'));
  return country.short_name ?? null;
}
async function reverseGeocodingWithGoogle(latitude: number, longitude: number): Promise<string | null> {
  // china cords: 28.769043,111.051591
  const queryParams = `latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?${queryParams}`)
    .then(res => res.json())
    .then((response) => {
      if (!response || !response.results.length || !response.results[0].address_components.length) {
        return null;
      }
      return extractCountryCode(response.results[0].address_components);
    })
    .catch(() => {
      return null;
    });
}
async function getCountryCodeFromIP() {
  return fetch('https://extreme-ip-lookup.com/json')
    .then(res => res.json())
    .then((response) => {
      if (!response || !response.countryCode) {
        return null;
      }
      return response.countryCode;
    })
    .catch(() => {
      return null;
    });
}
/**
 * @function isGoogleMapsAPIRestricted
 * It will return true if user is accessing from China (where Google Maps ir restricted)
 * @returns {Promise<boolean>}
 */
export async function isGoogleMapsAPIRestricted(): Promise<boolean> {
  async function isRestricted(): Promise<boolean> {
    const countryCode = await getCountryCodeFromIP();
    if (countryCode && countryCode === CHINA_COUNTRY_CODE) {
      return true;
    }
    return false;
  }
  try {
    return await isRestricted();
  } catch {
    return false;
  }
}

export async function isGoogleMapsAPIRestrictedUsingGServices(): Promise<boolean> {
  if (!navigator.geolocation) {
    return false;
  }
  async function isRestrictedPosition(position: any): Promise<boolean> {
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;
    const countryCode = await reverseGeocodingWithGoogle(latitude, longitude);
    if (countryCode && countryCode === CHINA_COUNTRY_CODE) {
      return true;
    }
    return false;
  }
  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return isRestrictedPosition(position);
  } catch {
    return false;
  }
}
