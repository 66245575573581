import styled from 'styled-components';

export const PageContainer = styled.div`
  height: 100%;
`;

export const TopBarContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.4rem;
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 146px);
  padding: 0 2rem;
`;

export const SimulationStatusContainer = styled.div`
  display: flex;
  column-gap: 6px;
`;

export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180px;

  button {
    justify-content: flex-start;
  }
`;
