export const objectEquals = (x: any, y: any): boolean => {
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(x);
  return Object.keys(y).every((i) => p.indexOf(i) !== -1) && p.every((i) => objectEquals(x[i], y[i]));
};

/**
 * Obtain data from simple and nested keys from an object.
 * Similar to lodash._get(), but without all his vulnerabilities.
 * Also, with less funcionality :$ But, it's works!!
 *
 * @param {{[key: string]: any}} object Object from which resolve the key selector. Ex.: { a: { b: 'ey', c: 'bye' } }
 * @param {string | string[]} key Key to resolve for obtain data from object. Ex.: 'a.c'
 * @returns {(string | number | {[key: string]: any} | any[] | undefined)} Ex.: 'bye'
 */
export const resolveKey = (
  o: { [key: string]: any },
  s: string | string[]
): string | number | { [key: string]: any } | undefined => {
  if (typeof s === 'string') {
    return resolveIndividualKey(o, s);
  }
  if (Array.isArray(s)) {
    return s.map((key) => resolveIndividualKey(o, key));
  }
  return;
};

const resolveIndividualKey = (
  o: { [key: string]: any },
  s: string
): string | number | { [key: string]: any } | undefined => {
  let result = o;
  let selector = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  selector = selector.replace(/^\./, ''); // strip a leading dot
  const keys = selector.split('.');
  for (const actualKey of keys) {
    if (typeof result === 'object' && result !== null && actualKey in result) {
      result = result[actualKey];
    } else {
      return;
    }
  }
  return result;
};

export const editKeyValue = (
  o: { [key: string]: any },
  s: string,
  newValue: any
): { [key: string]: any } | undefined => {
  const deepCopiedObject = { ...o };

  let currentAccessObject = deepCopiedObject;

  let selector = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  selector = selector.replace(/^\./, ''); // strip a leading dot
  const keys = selector.split('.');

  for (const [i, actualKey] of keys.entries()) {
    // const actualKey = keys[i];

    if (typeof currentAccessObject === 'object' && currentAccessObject !== null && actualKey in currentAccessObject) {
      if (Number(i) !== keys.length - 1) {
        currentAccessObject = currentAccessObject[actualKey];
      } else {
        currentAccessObject[actualKey] = newValue;
      }
    } else {
      return;
    }
  }
  return deepCopiedObject;
};

export const deepSearch = (object: any, key: any, predicate: any): any => {
  // eslint-disable-next-line no-prototype-builtins
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object;
  for (const [i] of Object.keys(object).entries()) {
    const nextObject = object[Object.keys(object)[i]];
    if (nextObject && typeof nextObject === 'object') {
      const o = deepSearch(nextObject, key, predicate);
      if (o != null) return o;
    }
  }
  return null;
};
