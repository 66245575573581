import { useMutation } from '@apollo/client';
import { UPDATE_LAST_LOGIN } from 'services/user-gql';

export const useUpdateLastLogin = () => {
  const [updateLastLogin] = useMutation(UPDATE_LAST_LOGIN, {
    onCompleted: () => {
      localStorage.setItem('lastLoginDate', new Date().toDateString());
    },
  });

  return updateLastLogin;
};
