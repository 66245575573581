import styled from 'styled-components';

export const FormContainer = styled.form`
  padding: ${({ theme }) => theme.v2.spacing.l};
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
  width: 430px;
`;

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormButtons = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  align-items: center;
`;

export const FormInputs = styled.div`
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
`;
