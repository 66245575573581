import styled from 'styled-components';

export const DetailsInfoHeaderContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  align-items: center;

  p {
    flex-grow: 1;
  }
`;
