import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';

interface ISkeletonList {
  amount: number;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

const SkeletonList: React.FC<ISkeletonList> = ({ amount, width, height, style }) => {
  return (
    <>
      {Array(amount)
        .fill(<Skeleton style={style} width={width} height={height} />)
        .map((skeleton, key) => (
          <Fragment key={key}>{skeleton}</Fragment>
        ))}
    </>
  );
};

export default SkeletonList;
