export interface ITab {
  key: string;
  label: string;
  disabled?: boolean;
  icon?: any;
  warning?: boolean;
  new?: boolean;
  url?: string;
}

export const views = {
  LIST: 'list',
  MAP: 'map',
  LIST_MAP: 'mixed',
  GRID: 'grid',
};
