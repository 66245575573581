import { IUnits } from 'types/preferences.types';

export const surfaceUnits: IUnits = {
  INTERNATIONAL: {
    M: {
      unit: 'ha',
      toShow: (value) => value * 1e-4,
      toSend: (value) => value * 1e4,
    },
    S: {
      unit: 'm²',
      toShow: (value) => value,
      toSend: (value) => value,
    },
    XS: {
      unit: 'mm²',
      toShow: (value) => value * 1e-8,
      toSend: (value) => value * 1e8,
    },
  },
  IMPERIAL: {
    M: {
      unit: 'acre',
      toShow: (value) => value / 4046.856,
      toSend: (value) => value * 4046.856,
    },
    S: {
      unit: 'ft²',
      toShow: (value) => value / (0.3048 * 0.3048),
      toSend: (value) => value * 0.3048 * 0.3048,
    },
    XS: {
      unit: 'ft²',
      toShow: (value) => value / (0.3048 * 0.3048),
      toSend: (value) => value * 0.3048 * 0.3048,
    },
  },
};
