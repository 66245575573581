import React from 'react';
import ErrorInfoModal from '../error-info-modal/error-info-modal';
import { useModal } from 'utils/hooks/use-modal';
import { useTranslation } from 'react-i18next';
import { Button } from '@ratedpower/components';

interface IErrorInfoLink {
  entityType: string;
  entityId: string;
}

const ErrorInfoLink: React.FC<IErrorInfoLink> = ({ entityType, entityId }) => {
  const { t } = useTranslation();

  const errorInfoModal = useModal();

  return (
    <>
      <Button
        onClick={() => errorInfoModal.setModalOpen(true)}
        text={t('support:view-error')}
        variant="link"
        textUnderlined
        noPadding
      />

      {errorInfoModal.isOpen && (
        <ErrorInfoModal
          onClose={() => errorInfoModal.setModalOpen(false)}
          isActive={errorInfoModal.isOpen}
          entityType={entityType}
          entityId={entityId}
        />
      )}
    </>
  );
};

export default ErrorInfoLink;
