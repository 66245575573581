import React from 'react';
import * as S from './comparison-design-header.styled';
import { useTranslation } from 'react-i18next';
import { SVGWrapper, Tag, Typography } from '@ratedpower/components';
import { CrossSvg, FavouriteOutlineSvg, FavouriteSvg } from 'assets/icons';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { useTheme } from 'styled-components';
import { getDateYYYYMMDD } from 'utils/date';
import { SIMULATION_TYPE } from 'types/design';

interface IProps {
  design: {
    id: string;
    number: number;
    projectTeamId?: string;
    name: string;
    createdBy: {
      id: string;
      name: string;
      lastname: string;
    };
    isFavorite: boolean;
    simulationType: string;
    satellitePicture: {
      low: string;
      thumbnail: string;
    };
    publishedDate: string;
    notes: string;
  };
  isLayoutShown: boolean;
  onFavorite: () => void;
  onRemove: () => void;
}

const ComparisonDesignHeader: React.FC<IProps> = ({ design, isLayoutShown, onFavorite, onRemove }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { canEditDesign } = useCanEditDesigns();
  const canEditThisDesign = canEditDesign(design.createdBy.id, design.projectTeamId);

  return (
    <S.ComparisonDesignHeaderContainer>
      <S.ImageContainer>
        {isLayoutShown && (
          <>
            <Tag
              className="design-tag"
              color={design.simulationType === SIMULATION_TYPE.SINGLE ? 'brand' : 'accent'}
              ellipsis={false}
            >
              {t(
                design.simulationType === SIMULATION_TYPE.SINGLE
                  ? 'design-process:batch.single-design'
                  : 'design-process:batch.multiple-pv-designs'
              )}
            </Tag>
            <RemoteImage url={design.satellitePicture.thumbnail} alt={design.name} height={120} width={278} />
          </>
        )}
      </S.ImageContainer>
      <S.DesignInfo>
        <S.DesignInfoTop>
          <Typography weight="bold" nowrap>
            {design.number}. {design.name}
          </Typography>
          <div className="buttons">
            <SVGWrapper
              size="s"
              icon={design.isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
              onClick={onFavorite}
              disabled={!canEditThisDesign}
              color={theme.v2.icon.primary.default}
            />
            <SVGWrapper size="s" icon={CrossSvg} onClick={onRemove} color={theme.v2.icon.primary.default} />
          </div>
        </S.DesignInfoTop>
        <S.DesignInfoBottom>
          <S.BottomData>
            <Typography
              size="s"
              color={theme.v2.text.secondary.default}
            >{`${design.createdBy.name} ${design.createdBy.lastname}`}</Typography>
            <Typography as="span" size="s" color={theme.v2.text.secondary.default}>
              {getDateYYYYMMDD(new Date(design.publishedDate))}
            </Typography>
          </S.BottomData>
        </S.DesignInfoBottom>
      </S.DesignInfo>
    </S.ComparisonDesignHeaderContainer>
  );
};

export default ComparisonDesignHeader;
