import styled from 'styled-components';

export const UnreadCounter = styled.div`
  background-color: ${({ theme }) => theme.v2.warning.icon.default};
  width: ${({ theme }) => theme.v2.spacing.m};
  height: ${({ theme }) => theme.v2.spacing.m};
  border-radius: 50%;
  position: absolute;
  text-align: center;
  right: 0;
  top: -12px;
`;

export const ParentContainer = styled.div`
  position: relative;
`;
