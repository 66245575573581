import React from 'react';
import { FavouriteSvg, FavouriteOutlineSvg } from 'assets/icons';
import * as S from './btn-favorite.styled';
import classNames from 'classnames';

interface IProps {
  className?: string;
  isFav: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

const BtnFavorite: React.FC<IProps> = ({ isFav, onClick, className, disabled }) => {
  return (
    <S.BtnFavoriteContainer
      className={classNames(className, {
        disabled,
      })}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      Icon={isFav ? FavouriteSvg : FavouriteOutlineSvg}
    />
  );
};

export default BtnFavorite;
