import React from 'react';
import { SortAttributeLabelContainer } from './sort-attribute-label.styled';
import { useTranslation } from 'react-i18next';
import { IComparisonSortSelector, IComparisonSortBy } from 'types/comparison';
import { ArrowDownSvg, ArrowUpSvg } from 'assets/icons';
import { SVGWrapper, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';

interface IProps {
  cell: IComparisonSortSelector;
  sortedBy: IComparisonSortBy;
  onSort: (sortBy: IComparisonSortBy) => void;
}

const SortAttributeLabel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  function handleSort(clickedCell: IComparisonSortSelector) {
    if (props.sortedBy.selector.label !== clickedCell.label) {
      props.onSort({ selector: clickedCell, ascendent: false });
    } else {
      props.onSort({ selector: clickedCell, ascendent: !props.sortedBy.ascendent });
    }
  }

  const isActive = props.sortedBy.selector.label === props.cell.label;

  return (
    <SortAttributeLabelContainer onClick={() => handleSort(props.cell)}>
      <Typography color={theme.v2.text.secondary.default} weight={isActive ? 'bold' : 'regular'} nowrap>
        {t(`design:${props.cell.label}`)}
      </Typography>
      <SVGWrapper
        color={theme.v2.text.secondary.default}
        icon={(isActive && !props.sortedBy.ascendent) || !isActive ? ArrowUpSvg : ArrowDownSvg}
        size="s"
      />
    </SortAttributeLabelContainer>
  );
};

export default SortAttributeLabel;
