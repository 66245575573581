import { IOption } from 'types/common';
import {
  BatterySvg,
  BellSvg,
  BriefcaseSvg,
  ConfigSvg,
  CostsSvg,
  ElectricalSvg,
  EnergySvg,
  EquipmentSvg,
  FolderSvg,
  GridPointSvg,
  HomeSvg,
  MapPinSvg,
  MountainSvg,
  PeopleSvg,
  SheetSvg,
  TopographySvg,
} from 'assets/icons/index';

export enum CreateNotificationIcon {
  general = 'general',
  location = 'location',
  equipment = 'equipment',
  layout = 'layout',
  topography = 'topography',
  electrical = 'electrical',
  bess = 'bess',
  grid = 'grid',
  energy = 'energy',
  financial = 'financial',
  settings = 'settings',
  home = 'home',
  projects = 'projects',
  capex = 'cape',
  myCompany = 'my-company',
  users = 'users-and-teams',
}

const notificationIcons = [
  { label: 'communication:icon-general', value: CreateNotificationIcon.general, icon: BellSvg },
  { label: 'communication:icon-location', value: CreateNotificationIcon.location, icon: MapPinSvg },
  { label: 'communication:icon-equipment', value: CreateNotificationIcon.equipment, icon: EquipmentSvg },
  { label: 'communication:icon-layout', value: CreateNotificationIcon.layout, icon: MountainSvg },
  { label: 'communication:icon-topography', value: CreateNotificationIcon.topography, icon: TopographySvg },
  { label: 'communication:icon-electrical', value: CreateNotificationIcon.electrical, icon: ElectricalSvg },
  { label: 'communication:icon-bess', value: CreateNotificationIcon.bess, icon: BatterySvg },
  { label: 'communication:icon-grid', value: CreateNotificationIcon.grid, icon: GridPointSvg },
  { label: 'communication:icon-energy', value: CreateNotificationIcon.energy, icon: EnergySvg },
  { label: 'communication:icon-financial', value: CreateNotificationIcon.financial, icon: CostsSvg },
  { label: 'communication:icon-settings', value: CreateNotificationIcon.settings, icon: ConfigSvg },
  { label: 'communication:icon-home', value: CreateNotificationIcon.home, icon: HomeSvg },
  { label: 'communication:icon-projects', value: CreateNotificationIcon.projects, icon: FolderSvg },
  { label: 'communication:icon-capex', value: CreateNotificationIcon.capex, icon: SheetSvg },
  { label: 'communication:icon-my-company', value: CreateNotificationIcon.myCompany, icon: BriefcaseSvg },
  { label: 'communication:icon-users', value: CreateNotificationIcon.users, icon: PeopleSvg },
];

export const NotificationsIconMap: Map<CreateNotificationIcon, typeof BellSvg> = new Map(
  notificationIcons.map(({ value, icon }) => [value, icon])
);

const CreateNotificationIconOptions: IOption[] = notificationIcons.map(({ label, value, icon }) => ({
  label,
  value,
  labelIcon: icon,
}));

export default CreateNotificationIconOptions;
