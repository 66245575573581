import { IPvDesignPlan, Plan } from 'types/subscription-plan';

export const pvDesignPlanTitles = {
  DEMO: 'free-trial',
  ESSENTIALS: 'essentials-subscription',
  PROFESSIONAL: 'professional-subscription',
  ENTERPRISE: 'enterprise-subscription',
  CUSTOM: 'custom-subscription',
  BASIC: 'basic-subscription',
};

const pvDesignPlansConstants: IPvDesignPlan[] = [
  {
    id: Plan.ESSENTIALS,
    title: pvDesignPlanTitles[Plan.ESSENTIALS],
    color: 'primary',
    planDescription: 'basic-plan-description',
    includedItems: [
      'pay-per-project',
      'unlimited-designs',
      'unlimited-users',
      'downloadable-documentation',
      'email-support',
    ],
  },
  {
    id: Plan.PROFESSIONAL,
    title: pvDesignPlanTitles[Plan.PROFESSIONAL],
    color: 'yellow_dark',
    planDescription: 'professional-plan-description',
    includedItems: [
      'up-to-50-projects-per-year',
      'unlimited-designs',
      'unlimited-users',
      'downloadable-documentation',
      'basic-support',
      '1-onboarding-session',
    ],
  },
  {
    id: Plan.ENTERPRISE,
    title: pvDesignPlanTitles[Plan.ENTERPRISE],
    planDescription: 'enterprise-plan-description',
    color: 'purple',
    includedItems: [
      'unlimited-projects',
      'unlimited-designs',
      'unlimited-users',
      'downloadable-documentation',
      'priority-support',
      'designated-customer-success',
      'two-factor-authentication',
      'saml-access',
    ],
  },
];

const pvDesignDemoPlan: IPvDesignPlan = {
  id: Plan.DEMO,
  title: pvDesignPlanTitles[Plan.DEMO],
  color: 'secondary',
  includedItems: [],
  planDescription: '',
};

const pvDesignCustomPlan: IPvDesignPlan = {
  id: Plan.CUSTOM,
  title: pvDesignPlanTitles[Plan.CUSTOM],
  color: 'red',
  includedItems: [],
  planDescription: '',
};

export const getOtherPlans = (plan: Plan): IPvDesignPlan[] => {
  const currentPlanIndex = pvDesignPlansConstants.findIndex((pvDesignPlan) => pvDesignPlan.id === plan);

  return pvDesignPlansConstants.slice(currentPlanIndex + 1);
};

export const getCurrentPlan = (plan: Plan): IPvDesignPlan | undefined => {
  if (plan === Plan.DEMO) {
    return pvDesignDemoPlan;
  }
  if (plan === Plan.CUSTOM) {
    return pvDesignCustomPlan;
  }

  return pvDesignPlansConstants.find((pvDesignPlan) => pvDesignPlan.id === plan);
};
