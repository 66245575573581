import styled from 'styled-components';

export const ComparisonChartSizeWrapper = styled.div`
  border-radius: 0.4rem;
  height: 382px;
  background: white;
  position: relative;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

interface IProps {
  designsCount: number;
}

export const DesignsSelected = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.s};
  margin-bottom: ${({ theme }) => theme.v2.spacing.m};
  flex-wrap: wrap;
`;

export const ComparisonChartsContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .charts-wrapper {
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: ${({ theme }) => theme.v2.spacing.l};
  }

  .charts-container {
    width: 100%;

    .comparison-chart-container {
      display: inline-block;
      padding: 8px;
      width: 50%;

      &.big {
        width: 50%;
      }
    }
    @media screen and (max-width: 1800px) and (min-height: 900px) {
      .comparison-chart-container {
        width: 50%;

        &.big {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .comparison-chart-container {
        width: 100%;

        &.big {
          width: 100%;
        }
      }
    }
  }
`;
