import React, { useEffect } from 'react';
import { RatedPowerLoader } from '@ratedpower/components';
import { useAuth0 } from '@auth0/auth0-react';
import { LOGIN_REQUIRED } from 'utils/constants/users';

const Login: React.FC<{ apiError: boolean }> = ({ apiError }) => {
  const { loginWithRedirect, error } = useAuth0();

  useEffect(() => {
    if (!error) {
      // The user tries silently to log in (not authenticated in the app, but previously logged in the gallery)
      loginWithRedirect({ authorizationParams: { prompt: 'none', display: 'popup' } });
    }
    if (error?.message === LOGIN_REQUIRED || apiError) {
      // The user needs to enter credentials (not authenticated in the app and not logged in the gallery)
      loginWithRedirect({ authorizationParams: { prompt: 'login', display: 'popup' } });
    }
  }, [error, apiError]);

  return <RatedPowerLoader />;
};

export default Login;
