import { useAuth0 } from '@auth0/auth0-react';
import Axios, { AxiosRequestConfig } from 'axios';
import { clearLocalStorage } from 'services/local-storage';
import { getRedirectUri } from 'utils/redirect_uri';

interface IRestResult {
  error?: any;
  response: any;
  data?: any;
}

export const useRestClient = () => {
  const { logout, getAccessTokenSilently } = useAuth0();

  const get = async (uri: string, options?: AxiosRequestConfig): Promise<IRestResult> => {
    const token = await getAccessTokenSilently({ authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE } });
    try {
      const requestOptions: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...options,
      };
      const response = await Axios.get(uri, requestOptions);
      return { response, data: response.data };
    } catch (error: any) {
      handleError(error.response);
      return { error, response: null };
    }
  };

  const getFile = async (uri: string, options?: AxiosRequestConfig): Promise<IRestResult> => {
    const token = await getAccessTokenSilently({ authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE } });
    try {
      const requestOptions: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
        ...options,
      };
      const response = await Axios.get(uri, requestOptions);
      return { response, data: response.data };
    } catch (error: any) {
      handleError(error.response);
      return { error, response: null };
    }
  };

  const post = async (uri: string, data?: any, options?: AxiosRequestConfig): Promise<IRestResult> => {
    const token = await getAccessTokenSilently({ authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE } });
    const { headers: customHeaders, ...config } = options || {};
    const requestOptions: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        ...customHeaders,
      },
      ...config,
    };
    return Axios.post(uri, data, requestOptions)
      .then((response) => {
        return { response, data: response.data };
      })
      .catch((error: any) => {
        handleError(error.response);
        return { error, response: null };
      });
  };

  const handleError = (error: any) => {
    if (error && 'status' in error && error.status === 401) {
      logout({ logoutParams: { returnTo: getRedirectUri() } });
      clearLocalStorage();
    }
  };

  return {
    get,
    post,
    getFile,
  };
};
