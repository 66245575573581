import { useState } from 'react';

function useComparisonActiveFilters<T, U>(availableFilters: T[], defaultFilters: U[]) {
  const [activeFilters, updateActiveFilters] = useState<U[]>(defaultFilters);
  return {
    availableFilters,
    activeFilters,
    updateActiveFilters,
  };
}

export default useComparisonActiveFilters;
