import React, { useState } from 'react';
import CompanyDetails from './details/company-details';
import CompanyPlan from './plan/company-plan';
import Integrations from './integrations';
import TopbarCompany from './topbar-company';
import { COMPANY_TABS as tabs } from 'utils/constants/tabs';
import * as S from './company.styled';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { Banner, LinkText } from '@ratedpower/components';
import { BUSINESS_EMAIL } from 'utils/constants/contact';
import { useAuthorization } from 'access-control/can';
import ErrorForbidden from 'pages/error/error-forbidden/error-forbidden';
import { Permission } from 'access-control/permissions';
import { Trans } from 'react-i18next';

const TabViews = {
  account: CompanyDetails,
  plan: CompanyPlan,
  integrations: Integrations,
};

const Company: React.FC = () => {
  const { isAuthorized } = useAuthorization();
  const { subscriptionPlan } = useSelector((state: RootState) => state.currentUser);
  const [tabActive, setTabActive] = useState(tabs.account);

  const TabSpecificView = TabViews[tabActive as keyof typeof TabViews] || TabViews.account;
  if (!(isAuthorized(Permission.COMPANY_READ) || isAuthorized(Permission.COMPANY_SUPPORT_READ))) {
    return <ErrorForbidden />;
  }
  return (
    <S.CompanyContainer>
      <TopbarCompany onTabChange={setTabActive} activeTab={tabActive} />
      {!!subscriptionPlan && !!subscriptionPlan.banner.projects && !subscriptionPlan.unlimitedProjects && (
        <S.BannerContainer>
          <Banner
            status={subscriptionPlan.banner.projects.type}
            description={
              <div className="banner-description">
                <Trans i18nKey={subscriptionPlan.banner.projects.contactUs}>
                  <LinkText textUnderlined href={BUSINESS_EMAIL}>
                    contact us
                  </LinkText>
                </Trans>
              </div>
            }
            header={subscriptionPlan.banner.projects.cause}
            closable
          />
        </S.BannerContainer>
      )}
      <TabSpecificView />
    </S.CompanyContainer>
  );
};

export default Company;
