import { useEffect, useRef, useState } from 'react';
import { ILayer } from 'types/maps';
import { GeoJSONLayer } from './geojson';
import { getGroupedAreasLayers, highlightedLayerColors } from './layers-templates/grouped-areas-layers';
import { checkIfLayerIsHidden } from './map-constants';
import locationPng from 'assets/images/location.png';

export const useMapLayers = (map: any) => {
  const [layers, setLayers] = useState<ILayer[]>([]);
  const previousLayers = useRef<GeoJSONLayer[] | null>(null);

  const removeLayerById = (layerId: string) => {
    const mc = map?.current;
    mc.getLayer(layerId) && mc.removeLayer(layerId);
  };

  const createLayers = (layersArray: GeoJSONLayer[]) => {
    previousLayers.current = layersArray;

    if (layers.length > 0 && map?.current) {
      layers.forEach((layer) => {
        removeLayerById(`${layer.id}-fill`);
        removeLayerById(`${layer.id}-slopes`);
        removeLayerById(`${layer.id}-line`);
        removeLayerById(`${layer.id}-customMarkers`);
        removeLayerById(`${layer.id}-customMarkersWithIcon`);
        removeLayerById(`${layer.id}-circleMarkers`);
        removeLayerById(`${layer.id}-selected`);
        highlightedLayerColors.forEach((_, key) => {
          removeLayerById(`${layer.id}-highlighted-${key}`);
        });
      });
    }

    setLayers([]);
    layersArray.forEach((layer) => {
      setLayers((prev) => [
        ...prev,
        {
          id: layer.id,
          prettifiedName: layer.prettifiedName,
          visible: checkIfLayerIsHidden(layer.id),
          colors: layer.colors ? layer.colors : undefined,
          features: layer.geoJson?.features,
        },
      ]);
      if (map?.current?.getSource(layer.id)) {
        const source = map.current.getSource(layer.id);
        source.setData(layer.geoJson);
      } else {
        map.current.addSource(layer.id, {
          type: 'geojson',
          buffer: 256,
          data: layer.geoJson,
        });
      }
      if (layer.id !== 'GEO_PINS') {
        map.current.loadImage(
          'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
          (error: any, image: any) => {
            if (error) throw error;
            if (!map.current.hasImage('custom-marker')) {
              map.current.addImage('custom-marker', image);
            }
            map.current.addLayer({
              id: `${layer.id}-customMarkersWithIcon`,
              type: 'symbol',
              source: layer.id,
              layout: {
                'visibility': checkIfLayerIsHidden(layer.id) ? 'visible' : 'none',
                'icon-image': 'custom-marker',
                // get the title name from the source's "title" property
                'text-field': ['get', 'text'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'icon-size': 0.6,
                'icon-anchor': 'bottom',
                'text-anchor': 'top',
              },
              filter: ['any', ['==', 'showIcon', true], ['==', 'icon', 'CUSTOM_MARKER']],
            });
          }
        );
      } else {
        map.current.loadImage(locationPng, (error: any, image: any) => {
          if (error) throw error;
          if (!map.current.hasImage('location-marker')) {
            map.current.addImage('location-marker', image);
          }
          map.current.addLayer({
            id: `${layer.id}-locationMarker`,
            type: 'symbol',
            source: layer.id,
            layout: {
              'icon-image': 'location-marker',
            },
          });
        });
      }
      map.current.addLayer({
        id: `${layer.id}-customMarkers`,
        type: 'symbol',
        source: layer.id,
        layout: {
          'visibility': checkIfLayerIsHidden(layer.id) ? 'visible' : 'none',
          // get the title name from the source's "title" property
          'text-field': ['get', 'text'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-anchor': 'center',
        },
        filter: ['any', ['==', 'showIcon', false], ['==', 'icon', 'NO_ICON']],
      });

      map.current.addLayer({
        id: `${layer.id}-circleMarkers`,
        type: 'circle',
        source: layer.id,
        paint: {
          'circle-radius': 5,
          'circle-color': ['get', 'iconColor'],
          'circle-stroke-color': ['get', 'iconSecondaryColor'],
          'circle-stroke-width': ['case', ['==', ['get', 'icon'], 'HIGHLIGHT_CIRCLE'], 2, 0],
        },
        layout: {
          visibility: checkIfLayerIsHidden(layer.id) ? 'visible' : 'none',
        },
        filter: ['any', ['==', 'icon', 'CIRCLE'], ['==', 'icon', 'HIGHLIGHT_CIRCLE']],
      });

      if (layer.id !== 'EW-topographyDetails' && layer.id !== 'NS-topographyDetails') {
        getGroupedAreasLayers(map, layer.id);

        map.current.addLayer({
          id: `${layer.id}-fill`,
          type: 'fill',
          source: layer.id,
          layout: {
            visibility: checkIfLayerIsHidden(layer.id) ? 'visible' : 'none',
          },
          paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': ['get', 'opacity'],
          },
          filter: ['==', '$type', 'Polygon'],
        });
      }
      if (layer.id === 'EW-topographyDetails' || layer.id === 'NS-topographyDetails') {
        map.current.addLayer({
          id: `${layer.id}-slopes`,
          type: 'fill',
          source: layer.id,
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': {
              property: 'slopeAbsolute',
              type: 'interval',
              stops: [
                [0, '#00932e'],
                [5, '#ff7402'],
                [10, '#d60000'],
                [15, '#4800ac'],
              ],
            },
            'fill-opacity': ['get', 'opacity'],
          },
        });
      }

      map.current.addLayer({
        id: `${layer.id}-line`,
        type: 'line',
        source: layer.id,
        layout: {
          visibility: checkIfLayerIsHidden(layer.id) ? 'visible' : 'none',
        },
        paint: {
          'line-width': ['get', 'line_width'],
          'line-color': ['get', 'color'],
          'line-opacity': ['get', 'opacity'],
        },
        filter: ['==', '$type', 'LineString'],
      });
    });
  };

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      map.current.on('style.load', () => {
        previousLayers.current && createLayers(previousLayers.current);
      });
    });
  }, []);

  const updateLayerVisibilityState = (layerId: string, newVisibility: boolean) => {
    setLayers((prev) => prev.map((layer) => (layer.id === layerId ? { ...layer, visible: newVisibility } : layer)));
  };

  const hideAllLayers = () => {
    layers.forEach((layer) => {
      setLayerVisibility(layer.id, 'visible');
    });
  };

  const showAllLayers = () => {
    layers.forEach((layer) => {
      setLayerVisibility(layer.id, checkIfLayerIsHidden(layer.id) ? 'none' : 'visible');
    });
  };

  const setLayerVisibility = (layer: string, prevVisibility: 'visible' | 'none') => {
    updateLayerVisibilityState(layer, prevVisibility === 'none');
    if (prevVisibility === 'visible') {
      if (layer === 'EW-topographyDetails' || layer === 'NS-topographyDetails') {
        map.current.setLayoutProperty(`${layer}-slopes`, 'visibility', 'none');
        map.current.setLayoutProperty(`${layer}-slopes`, 'visibility', 'none');
      } else {
        map.current.setLayoutProperty(`${layer}-fill`, 'visibility', 'none');
        map.current.setLayoutProperty(`${layer}-line`, 'visibility', 'none');
        map.current.setLayoutProperty(`${layer}-customMarkers`, 'visibility', 'none');
        map.current.setLayoutProperty(`${layer}-customMarkersWithIcon`, 'visibility', 'none');
        map.current.setLayoutProperty(`${layer}-circleMarkers`, 'visibility', 'none');
      }
    } else {
      if (layer === 'EW-topographyDetails' || layer === 'NS-topographyDetails') {
        map.current.setLayoutProperty(`${layer}-slopes`, 'visibility', 'visible');
        map.current.setLayoutProperty(`${layer}-slopes`, 'visibility', 'visible');
      } else {
        map.current.setLayoutProperty(`${layer}-fill`, 'visibility', 'visible');
        map.current.setLayoutProperty(`${layer}-line`, 'visibility', 'visible');
        map.current.setLayoutProperty(`${layer}-customMarkers`, 'visibility', 'visible');
        map.current.setLayoutProperty(`${layer}-customMarkersWithIcon`, 'visibility', 'visible');
        map.current.setLayoutProperty(`${layer}-circleMarkers`, 'visibility', 'visible');
      }
    }
  };

  const toggleVisibility = (layer: string) => {
    const layerName = layer === 'EW-topographyDetails' || layer === 'NS-topographyDetails' ? 'slopes' : 'fill';
    const visibility = map.current.getLayoutProperty(`${layer}-${layerName}`, 'visibility');

    setLayerVisibility(layer, visibility);
  };

  return { layers, createLayers, toggleVisibility, hideAllLayers, showAllLayers };
};
