import styled from 'styled-components';
import { Tag, TooltipContainer } from '@ratedpower/components';

export const DesignGridItemContainer = styled.div<{ $isDefault?: boolean }>`
  background: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.v2.radius.s};
  box-shadow: 0px 4px 8px 0px ${({ theme }) => theme.v2.elevation.neutral900[16]};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.v2.bg.primary.hover};
    transition: all 0.2s ease-out;
  }

  .card-image {
    display: flex;
    justify-content: center;
    opacity: 0.8;
    height: 200px;

    img {
      border-top-left-radius: ${({ theme }) => theme.v2.radius.s};
      border-top-right-radius: ${({ theme }) => theme.v2.radius.s};
      height: 100%;
      max-height: 200px;
      overflow: hidden;
    }
  }

  .card-main-row {
    display: flex;
    align-self: center;
    width: 100%;
    justify-content: space-between;

    span {
      color: ${({ theme }) => theme.v2.text.secondary.default};
      padding-left: 2px;
    }
  }

  .card-detail-row {
    padding: ${({ theme }) => `0 ${theme.v2.spacing.m}`};
    min-height: 290px;
    overflow: hidden;
    transition: height 250ms ease-out, min-height 250ms ease-out, overflow 250ms ease-out, transform 250ms ease-out;

    &.hidden {
      height: 0;
      min-height: 0;
      overflow: hidden;
    }
  }
`;

export const CardHeader = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-items: center;
  display: flex;
  justify-content: center;

  .header {
    align-items: center;
    display: flex;
    flex-grow: 1;

    .editable-header {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .editable-title {
      font-weight: 700;
      max-width: 200px;
    }

    .editable-input {
      width: 160px;
      margin-right: 10px;

      .editable-title {
        font-size: 16px !important;
      }
    }

    .design-no-editable {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 180px;
    }

    .align-end {
      margin-left: auto;
    }

    ${TooltipContainer} {
      display: flex;
      align-items: center;
      max-width: 180px;
    }
  }
`;

export const CardHeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
`;

export const IconsWrapper = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.xxs};
  display: flex;
  align-items: center;
`;

export const PositionedTag = styled(Tag)`
  top: ${({ theme }) => theme.v2.spacing.s};
  right: ${({ theme }) => theme.v2.spacing.s};
  width: fit-content;
  position: absolute;
`;
