import React from 'react';
import userPreferences from 'utils/constants/user-preferences';
import preferencesLabel from 'utils/constants/user-preferences';
import { useTranslation } from 'react-i18next';
import { getOptionForValue } from 'utils/selector';
import { usePreferencesForm } from './hooks/use-preferences-form';
import { InputSelect, LabelWrapper, RadioGroup, Separator, Typography } from '@ratedpower/components';
import { ProfileSection, RadioGroupContainer } from './profile.styled';
import ProfileHeader from './profile-header';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import ProfileFooter from 'pages/profile/profile-footer';
import UnsavedChangesModal from 'components/modals/unsaved-changes-modal/unsaved-changes-modal';

const LanguageAndMetricsPreferences: React.FC = () => {
  const { t } = useTranslation();
  const { preferences } = useSelector((state: RootState) => state.currentUser);

  const {
    isEditable,
    codeLanguage,
    systemOfUnits,
    temperatureUnits,
    setIsEditable,
    handleCancelClick,
    handleEditLanguage,
    handleEditTemperatureUnits,
    handleEditSystemOfUnits,
    handleEditPreferences,
  } = usePreferencesForm(preferences);

  const language = useSelector((state: RootState) => state.currentUser.preferences.language);
  const selectedLanguage = getOptionForValue(codeLanguage ?? language, userPreferences.languageWithEnglishOptions);
  const { temperatureUnitsOptions, systemUnitsOptions } = preferencesLabel;
  const maxWidthLabel = '210px';

  return (
    <>
      <ProfileSection>
        <ProfileHeader title={t('user:my-preferences')} onEdit={() => setIsEditable(true)} isEditable={isEditable} />
        <LabelWrapper alignItems="baseline" title={t('language')} size="l">
          <InputSelect
            value={selectedLanguage ? [selectedLanguage] : undefined}
            options={userPreferences.languageWithEnglishOptions}
            onChange={handleEditLanguage}
            disabled={!isEditable}
            size="l"
          />
        </LabelWrapper>
        <Separator />
        <RadioGroupContainer>
          <Typography size="l">{t('preferences:systemOfUnits')}</Typography>
          <RadioGroup
            titleMaxWidth={maxWidthLabel}
            id="systemUnits"
            selectedValue={getOptionForValue(systemOfUnits ?? preferences.length, systemUnitsOptions)?.value}
            options={systemUnitsOptions}
            onChange={handleEditSystemOfUnits}
            disabled={!isEditable}
            disabledInfo={t('common:disabled-until-edit')}
            direction="column"
            size="l"
          />
        </RadioGroupContainer>
        <Separator />
        <RadioGroupContainer>
          <Typography size="l">{t('preferences:temperatureUnits')}</Typography>
          <RadioGroup
            titleMaxWidth={maxWidthLabel}
            id="temperatureUnits"
            selectedValue={
              getOptionForValue(temperatureUnits || preferences.temperature, temperatureUnitsOptions)?.value
            }
            options={temperatureUnitsOptions}
            onChange={handleEditTemperatureUnits}
            disabled={!isEditable}
            disabledInfo={t('common:disabled-until-edit')}
            direction="column"
            size="l"
          />
        </RadioGroupContainer>
        <Separator />
        {isEditable && <ProfileFooter handleSaveProfile={handleEditPreferences} onDiscard={handleCancelClick} />}
      </ProfileSection>
      <UnsavedChangesModal hasUnsavedChanges={isEditable} />
    </>
  );
};

export default LanguageAndMetricsPreferences;
