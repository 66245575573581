import styled from 'styled-components';

export const CardImageContainer = styled.div`
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.gray_light};
  position: relative;

  .team-image {
    object-fit: cover;
  }

  .edit-button {
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
`;
export const ColorCover = styled.div<{ width?: string; height?: string; color?: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${({ color, theme }) => color || theme.purple_light};
`;

export const ColorContainer = styled.div`
  position: relative;

  .edit-button {
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
`;
